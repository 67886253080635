import { AfterViewInit, Component, OnInit } from '@angular/core';
import { AuthenticationService } from '@app/auth/services/authentication.service';

@Component({
  selector: 'app-list-quotations',
  templateUrl: './list-quotations.component.html',
  styleUrls: ['./list-quotations.component.scss']
})
export class ListQuotationsComponent implements AfterViewInit {
  isUpdateOperation = false;
  constructor(public authService: AuthenticationService) { }

  ngAfterViewInit() {}

}
