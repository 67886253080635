import { Component, EventEmitter, Input, Output, OnInit, ViewChild } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { AuthenticationService } from "@app/auth/services/authentication.service";
import { AlertComponent } from "@app/shared/components/alert/alert.component";
import { SaveListComponent } from "@app/shared/components/save-list/save-list.component";
import { ShareService } from "@app/shared/services/share.service";
import { WorkService } from "@app/work/services/work.service";
import { forkJoin } from "rxjs";
import SignaturePad from "signature_pad";
import { v4 as uuid } from "uuid";

@Component({
  selector: "app-new-report",
  templateUrl: "./new-report.component.html",
  styleUrls: ["./new-report.component.scss"],
})
export class NewReportComponent implements OnInit {
  @Input()
  PK;
  @Input()
  isNewReport;
  @Input()
  isUpdateOperation;
  @Input()
  enterprise;
  @Output()
  action = new EventEmitter();
  @Output()
  message = new EventEmitter();

  globalError: string;
  uploadError: string;
  attachedError: string;
  successMessage: String;
  isInitializing = true;
  
  isUploading = false;

  fileName = "";
  file: File;
  extensionFile;
  url;

  imageName: Array<string> = [];
  imageAlert: Array<string> = [];
  image = [];
  extensionImage: Array<string> = [];
  sizeImage: Array<number> = [];
  imageUrl: Array<string> = [];
  errorCounter = 0;

  modify = false;

  sendReport = false;

  path;

  enterprises;
  contacts = [];

  search = "";
  selectedItem = [];
  itemMessage = false;
  equipments;
  pendingEquipments;

  parts = [];
  partsSelected = [];

  signPad: any;
  @ViewChild('signPadCanvas', {static: false}) signaturePadElement:any;
  signImage:any;

  showTable = false;
  statusError = "";
  successStatus = "";

  recommendations = [];

  tableNames = {
    nameEquipment: "Equipo",
    partName: "Parte",
    date: "Fecha|date",
    value: "Valor",
    description: "Descripción",    
    updateStatus: "Cerrar",    
  };
  
  getResume = (type, enterprisePK) => this.workService.getResume({type, enterprise: enterprisePK}).subscribe(data => {
    if(type === 'S') this.equipments = data["body"];
    else if(type === 'I') {
      this.pendingEquipments = data["body"];
      if(this.pendingEquipments.length > 0) {
        this.showTable = true;
        if(this.isUpdateOperation) {
          this.formControl.get("closeItems").value.forEach(element => {
            let index = this.pendingEquipments.findIndex(item => item.SK === element.SK)
            if(index !== -1) this.pendingEquipments[index].status = "added";                       
          });
        }
      } else this.showTable = false;
    }
  });  

  constructor(
    private formBuilder: FormBuilder,
    private workService: WorkService,
    private shareService: ShareService,
    public dialog: MatDialog,
    private router: Router,
    public authenticationService: AuthenticationService,
  ) {
    this.path = router.url;    
  }

  public formControl: FormGroup;
  profile1 = this.authenticationService.cognitoUser["profile"] == 'PROF1' ? true : false;

  tableAction(event) {
    console.log(event.element)
    let statusPK = event.element.PK;
    let statusSK = event.element.SK;
    let index = this.pendingEquipments.findIndex(element => element.SK === statusSK);

    if (event.action == "CLOSE_EQUIPMENT") {
      let label = "Cerrar equipo con pendiente";
      let updateStatus = true;            
      const dialogRef = this.dialog.open(AlertComponent, {
        width: "500px",
        data: {
          label,
          updateStatus
        },
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          result.PK = statusPK;
          result.SK = statusSK;
          result.partName = `${event.element.nameEquipment} - ${event.element.typeEquipmentName} - ${event.element.markName} - ${event.element.modelName}`;
          this.formControl.get('closeItems').value.push(result)
          this.pendingEquipments[index].status = "added";
        }        
      });
    } else if(event.action == "REMOVE_EQUIPMENT") {
      let indexCloseItems = this.formControl.get('closeItems').value.findIndex(element => element.SK === statusSK);
      this.pendingEquipments[index].status = "close";
      if(indexCloseItems !== -1) this.formControl.get('closeItems').value.splice(indexCloseItems, 1);
    }
  }  

  onFileSelected(event) {
    this.file = event.target.files[0];
    if (this.file) {
      this.fileName = this.file.name;
      this.extensionFile = this.fileName.split(".").pop();
      this.formControl.get("fileExtension").setValue(this.extensionFile);
      this.url = "";
    }
  }

  onImageSelected(event, actualPosition: number) {
    const actualSK = this.formArr.at(actualPosition).get("SK").value;
    const SK = actualSK ? actualSK : uuid();
    this.image[actualPosition] = { SK, file: event.target.files[0] };
    if (this.image[actualPosition].file) {
      this.imageName[actualPosition] = this.image[actualPosition].file.name;
      this.sizeImage[actualPosition] =
      this.image[actualPosition].file.size / 1024; //Convert bytes to kilobytes
      this.extensionImage[actualPosition] = this.imageName[actualPosition]
        .split(".")
        .pop();
      if (
        (this.extensionImage[actualPosition] == "jpg" ||
          this.extensionImage[actualPosition] == "png" ||
          this.extensionImage[actualPosition] == "jpeg") &&
        this.sizeImage[actualPosition] <= 300
      ) {
        this.imageAlert[actualPosition] = "";
        if (!this.imageUrl[actualPosition]) {
          this.formArr.at(actualPosition).get("SK").setValue(SK);
        } else {
          this.imageUrl[actualPosition] = undefined;
        }
        this.formArr
          .at(actualPosition)
          .get("fileExtension")
          .setValue(this.extensionImage[actualPosition]);
      } else {
        this.imageAlert[actualPosition] =
          "El archivo debe ser .png, .jpg o .jpeg e inferior a 300KB";
        this.image.splice(actualPosition, 1);
        this.imageName.splice(actualPosition, 1);
        this.sizeImage.splice(actualPosition, 1);
        this.extensionImage.splice(actualPosition, 1);
      }
    }
  }

  ngOnInit(): void {
    this.formControl = this.formBuilder.group({
      startDate: ["", [Validators.required]],
      typeDevice: ["", [Validators.required, Validators.maxLength(100)]],
      mark: ["", [Validators.required, Validators.maxLength(100)]],
      power: ["", [Validators.required, Validators.maxLength(100)]],
      modulationSystem: ["", [Validators.required, Validators.maxLength(100)]],
      description: ["", [Validators.required, Validators.maxLength(200)]],
      voltage: ["", [Validators.required, Validators.maxLength(200)]],
      airPressure: ["", [Validators.required, Validators.maxLength(200)]],
      gasPressure1: ["", [Validators.required, Validators.maxLength(200)]],
      gasPressure2: ["", [Validators.required, Validators.maxLength(200)]],
      setValve: ["", [Validators.required, Validators.maxLength(200)]],
      securityCheck: ["", [Validators.required]],
      burnerCheck: ["", [Validators.required]],
      switchCheck: ["", [Validators.required]],
      circuitCheck: ["", [Validators.required]],
      combustionAnalysis: ["", [Validators.required]],
      detail: ["", [Validators.required, Validators.maxLength(4000)]],
      recommendation: ["" || [], [Validators.required, Validators.maxLength(4000)]],
      items: this.formBuilder.array([]),
      intervenedItems: this.formBuilder.array([]),
      fileExtension: ["", []],
      send: ["", []],
      documentReceive: ["", []],
      nameReceive: ["", []],
      closeItems: [[], []],

      enterprise: ["", []],
      contact: ["", []],
      service: ["", []],
      placeService: ["", []],
    });

    this.formControl.get("enterprise").valueChanges.subscribe((value) => {      
      if(this.path == '/dashboard/informs') {        
        if (value) {
          this.workService.getEnterprises({contacts:value}).subscribe(data=>{this.contacts = data["body"]});
          this.getResume('S', value);          
          this.getResume('I', value);       
        } else {
          this.contacts = [];
          this.equipments = [];
          this.pendingEquipments = [];
          this.showTable = false;          
        }
      }      
    });    

    let workRq = this.path == '/dashboard/informs' ? this.workService.getInform({ PK: this.PK }) : this.workService.get({ PK: this.PK });
    let enterprisesRq = this.workService.getEnterprises({ all: "ALL" });
    if (this.PK.includes("WOIN")) {      
      forkJoin([workRq, enterprisesRq]).subscribe(
        ([workRs, enterprisesRs]) => {
          this.enterprises = enterprisesRs["body"];
          const WORK = workRs["body"].filter((item) => item.SK === this.PK)[0];
          if(typeof WORK.recommendation != 'string') this.recommendations = WORK.recommendation;
          console.log(this.recommendations)
          if(this.path == '/dashboard/order') {
            this.getResume('S', WORK.enterprise);
            this.getResume('I', WORK.enterprise);
          }          
          WORK.items = workRs["body"].filter((item) => item.SK !== this.PK && item.entity === "WOIN|ITIO");
          WORK.intervenedItems = workRs["body"].filter((item) => item.SK !== this.PK && item.entity === "WOIN|ITIN");
          for (let i = 0; i < WORK.items.length; i++) {
            let group = this.initItemRows();
            this.formArr.push(group);
            this.imageUrl[i] = WORK.items[i].urlAtt;
            WORK.items[i].fileExtension = "";
            this.formArr.at(i).get("fileExtension").clearValidators();
          }
          for (let i = 0; i < WORK.intervenedItems.length; i++) {
            this.selectedItem[i] = {
              resume: WORK.intervenedItems[i].resume,
              nameEquipment: WORK.intervenedItems[i].nameEquipment,
            }            
            WORK.intervenedItems[i].date += "T05:00:00.000Z";
            let group = this.initItemIntervenedRows();
            group.addControl('SK', new FormControl('', Validators.required));
            this.formArrIntervened.push(group);
            this.partsSelected.push([])

            this.workService.getResume({type: 'E', resume: this.selectedItem[i].resume}).subscribe(data => {
              this.parts[i] = data["body"]; 

              for (let j = 0; j < WORK.intervenedItems[i].parts.length; j++) {
                let part = this.parts[i].find(part => part.SK === WORK.intervenedItems[i].parts[j].resumePart)
                this.partsSelected[i].push(part)
                this.formArrParts(i).push(this.initPartsRows());
                this.formControl.patchValue(WORK);
              }
            })
          }          
          this.url = WORK.urlAtt;
          WORK.fileExtension = "";
          delete WORK.documentReceive;
          delete WORK.nameReceive;
          WORK.startDate += "T05:00:00.000Z";
          this.formControl.patchValue(WORK);
          this.formControl.get("enterprise").disable({ emitEvent: false });
          this.isInitializing = false;                    
        },
        (error) => {
          this.globalError =
            "Se ha presentado un error, por favor vuelva a intentarlo";
          this.isInitializing = false;
        }
      );
    } else {
      if(this.path == '/dashboard/informs') {
        forkJoin([enterprisesRq]).subscribe(
          ([enterprisesRs]) => {
            this.enterprises = enterprisesRs["body"];
            this.isInitializing = false;
          },
          (error) => {
            this.globalError =
              "Se ha presentado un error, por favor vuelva a intentarlo";
            this.isInitializing = false;
          }
        );
      }
      else {
        this.getResume('S', this.enterprise);
        this.getResume('I', this.enterprise);
        this.isInitializing = false;      
      }
    }

    if(this.path == '/dashboard/informs') {
      this.formControl.get("enterprise").setValidators([Validators.required, Validators.pattern("^ENTR[0-9]+$"), Validators.maxLength(11)]);
      this.formControl.get("enterprise").updateValueAndValidity({ emitEvent: false });

      this.formControl.get("contact").setValidators([Validators.required, Validators.pattern("^CONV[0-9]+$"), Validators.maxLength(11)]);
      this.formControl.get("contact").updateValueAndValidity({ emitEvent: false });

      this.formControl.get("service").setValidators([Validators.required]);
      this.formControl.get("service").updateValueAndValidity({ emitEvent: false });

      this.formControl.get("placeService").setValidators([Validators.required]);
      this.formControl.get("placeService").updateValueAndValidity({ emitEvent: false });
    }
  }

  ngAfterViewInit() { 
    setTimeout(() => {
      this.signPad = new SignaturePad(this.signaturePadElement.nativeElement);      
    }, 3000);
  }

  startSignPadDrawing(event: Event) {
  }

  /*It's work in devices*/
  movedFinger(event: Event) {
  }

  /*Undo last step from the signature*/
  undoSign() {
    const data = this.signPad.toData();
    if (data) {
      data.pop(); // remove the last step
      this.signPad.fromData(data);
    }
  }

  /*Clean whole the signature*/
  clearSignPad() {
    this.signPad.clear();
  }

  get formArr() {
    return this.formControl.get("items") as FormArray;
  }

  initItemRows() {
    return this.formBuilder.group({
      SK: ["", [Validators.required]],
      description: ["", [Validators.required, Validators.maxLength(510)]],
      fileExtension: ["", [Validators.required]],
    });
  }

  addNewRow(event) {
    event.stopPropagation();
    event.preventDefault();
    if (this.formArr.length < 20) {
      this.formArr.push(this.initItemRows());
    }
    console.log(this.formControl.value);
  }

  deleteRow(index: number) {
    this.formArr.removeAt(index);
    this.image.splice(index, 1);
    this.imageName.splice(index, 1);
    this.sizeImage.splice(index, 1);
    this.extensionImage.splice(index, 1);
    this.imageAlert.splice(index, 1);
    this.imageUrl.splice(index, 1);
  } 

  get formArrIntervened() {
    return this.formControl.get("intervenedItems") as FormArray;
  }

  formArrParts(intervenedItemsIndex) {
    return this.formArrIntervened.at(intervenedItemsIndex).get("parts") as FormArray;
  }

  initItemIntervenedRows() {
    return this.formBuilder.group({
      resume: [],
      partsSelected: [[]],
      date: ["", [Validators.required]],
      description : ["", [Validators.required, Validators.maxLength(4000)]],
      parts: this.formBuilder.array([])
    });
  }

  initPartsRows() {
    return this.formBuilder.group({
      resumePart: ["", [Validators.required]],
      name: ["", [Validators.required]],
      value: ["", [Validators.required]],
      description : ["", [Validators.required, Validators.maxLength(4000)]]
    });
  }

  addNewIntervenedRow(event, item) {    
    event.stopPropagation();
    event.preventDefault();
    if (this.selectedItem.find(element => element.resume === item.PK)) this.itemMessage = true;
    else {
      this.formArrIntervened.push(this.initItemIntervenedRows());            
      this.selectedItem[this.formArrIntervened.length - 1] = {
        resume: item.PK,
        nameEquipment: item.nameEquipment,
      };
      this.formArrIntervened
        .at(this.formArrIntervened.length - 1)
        .get("resume")
        .setValue(item.PK);

      this.workService.getResume({type: 'E', resume: item.PK}).subscribe(data => {
        this.parts[this.formArrIntervened.length - 1] = data["body"];             
      })

      this.partsSelected.push([])
      this.itemMessage = false;
    }
    this.search = ""; 
  }

  addNewPartRow(part, intervenedItemsIndex) {
    console.log(part)
    let findPart = this.formArrParts(intervenedItemsIndex).value.find(element => element.resumePart === part.SK);

    if(!findPart) {      
      this.formArrParts(intervenedItemsIndex).push(this.initPartsRows());
      this.formArrParts(intervenedItemsIndex)
        .at(this.formArrParts(intervenedItemsIndex).length - 1)
        .get("resumePart")
        .setValue(part.SK);  
        this.formArrParts(intervenedItemsIndex)
        .at(this.formArrParts(intervenedItemsIndex).length - 1)
        .get("name")
        .setValue(`${part.typeEquipmentName} - ${part.markName} - ${part.modelName}`);  
      this.partsSelected[intervenedItemsIndex].push(part)  
    } else {
      let partIndex = this.formArrParts(intervenedItemsIndex).value.findIndex(element => element.resumePart === part.SK)
      this.formArrParts(intervenedItemsIndex).removeAt(partIndex)
      this.partsSelected[intervenedItemsIndex].splice(partIndex, 1)
    }
  }

  deleteIntervenedRow(index: number) {
    this.formArrIntervened.removeAt(index);
    this.selectedItem.splice(index, 1);
    this.parts.splice(index, 1);
    this.partsSelected.splice(index, 1);
  }

  sendInform(e) {
    this.sendReport = e.checked;    
  }

  controlsStatus() {
    this.formControl.enable({ emitEvent: false });
    this.formControl.get("enterprise").disable({ emitEvent: false });    
    this.isUploading = false;
  }

  editRecomendation(i) {
    console.log(i)
  }

  send() {
    if((this.formControl.get('nameReceive').value || this.formControl.get('documentReceive').value || !this.signPad.isEmpty()) &&
    (!this.formControl.get('nameReceive').value || !this.formControl.get('documentReceive').value || this.signPad.isEmpty())) {
      this.uploadError = "Si va a firmar debe diligenciar los campos: Nombre, Cédula y realizar la firma";
      return;
    }
    this.uploadError = "";
    this.isUploading = true;
    this.globalError = "";
    this.successMessage = "";
    this.attachedError = "";
    this.formControl.disable({ emitEvent: false });
    let values = this.formControl.getRawValue();    
    if(!this.signPad.isEmpty()) {
      const base64ImageData = this.signPad.toDataURL();
      this.signImage = base64ImageData;
      values.signReceive = this.signImage;
    }
    if (!values.enterprise) delete values.enterprise;
    if (!values.contact) delete values.contact;
    if (!values.service) delete values.service;
    if (!values.placeService) delete values.placeService;
    if (!this.isUpdateOperation) {
      if(this.path == '/dashboard/informs') delete values.order;
      else values.order = this.PK;
      values.send = this.sendReport;
      if(!values.fileExtension) delete values.fileExtension;
      this.workService.saveWorkInform(values).subscribe(
        (data) => {
          if (data["body"].url || data["body"].urls[0]) {
            if (data["body"].url) {
              this.shareService
                .uploadFile(data["body"].url, this.file)
                .subscribe(
                  (data) => {},
                  (error) => {
                    this.errorCounter++;
                  }
                );
            }
            if (data["body"].urls[0]) {
              for (let item of this.image) {
                const image = data["body"].urls.find(
                  (element) => item.SK === element.uuid
                );
                this.shareService.uploadFile(image.url, item.file).subscribe(
                  (data) => {},
                  (error) => {
                    this.errorCounter++;
                  }
                );
              }
            }
            if (this.errorCounter > 0) {
              this.attachedError =
                "El registro se guardó correctamente pero se ha presentado un error al subir adjunto(s)";
              this.fileName = "Aún no se ha subido ningún archivo.";
              this.imageName = [];
            } else {
              this.successMessage = "Operación realizada con exito";
              this.fileName = "Aún no se ha subido ningún archivo.";
              this.imageName = [];
            }
          } else {
            this.successMessage = "Operación realizada con exito";
          }
          this.formControl.enable({ emitEvent: false });
          this.formControl.reset();
          this.clearSignPad();
          this.formControl.updateValueAndValidity({
            emitEvent: false,
          });
          this.isUploading = false;
          this.formArr.clear();
          this.formArrIntervened.clear();
          this.selectedItem = [];
          this.parts = [];
          this.partsSelected = [];
          this.recommendations = [];
        },
        (error) => {
          this.formControl.enable({ emitEvent: false });
          this.isUploading = false;
          this.uploadError = error.error.status.message;          
        }
      );
    } else {
      let label =
        "¿Está seguro de actualizar este informe de servicio?";
      const dialogRef = this.dialog.open(AlertComponent, {
        width: "500px",
        data: {
          label,
          updateInform: true,
          checkboxText: this.profile1 ? 'Enviar al cliente' : 'Enviar para aprobación'
        },
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          values.PK = this.PK;
          values.send = result.send;
          this.workService.updateWorkInform(values).subscribe(
            (data) => {
              if (data["body"].url || data["body"].urls[0]) {
                if (data["body"].url) {
                  this.shareService
                    .uploadFile(data["body"].url, this.file)
                    .subscribe(
                      (data) => {},
                      (error) => {
                        this.errorCounter++;
                      }
                    );
                }
                if (data["body"].urls[0]) {
                  for (let item of this.image) {
                    if (item) {
                      const image = data["body"].urls.find(
                        (element) => item.SK.includes("ITIO") ? item.SK === element.SK : item.SK === element.uuid                    
                      );
                      this.shareService.uploadFile(image.url, item.file).subscribe(
                        (data) => {},
                        (error) => {
                          this.errorCounter++;
                        }
                      );
                    }
                  }
                }
                if (this.errorCounter > 0) {
                  this.controlsStatus()
                  this.attachedError =
                    "El registro se guardó correctamente pero se ha presentado un error al subir adjunto(s)";
                } else {
                  this.controlsStatus()
                  this.successMessage = "Operación realizada con exito";
                }
              } else {
                this.controlsStatus()
                this.successMessage = "Operación realizada con exito";
              }
              this.modify = true;
            },
            (error) => {
              this.controlsStatus()
              this.uploadError = error.error.status.message;
            }
          );
        } else {
          this.controlsStatus();
        }
      });      
    }
  }

  getButtonText() {
    return this.isUploading
      ? this.PK.includes("WOIN")
        ? "Actualizando informe..."
        : "Creando nuevo informe..."
      : this.PK.includes("WOIN")
      ? "Actualizar informe"
      : "Crear nuevo informe";
  }

  close() {
    this.action.emit();
    this.message.emit(this.modify);
  }

  save(event, entity, index = this.recommendations.length) {
    event.stopPropagation();
    event.preventDefault();
    console.log(this.recommendations)
    const dialogRef = this.dialog.open(SaveListComponent, {
      width: "500px",
      data: {
        current: this.recommendations,
        entity,
        name: this.recommendations[index] || null,
        label: "Recomendaciones y observaciones",
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if(!this.recommendations.find(x => x === result)) {
          this.recommendations[index] = result;
          this.formControl.get("recommendation").value.push(result);
          this.formControl.get("recommendation").updateValueAndValidity();
        }        
      }
    });
  }
}
