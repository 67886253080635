<div *ngIf="globalError" class="alert alert-danger text-center">
    {{ globalError }}
  </div>
  
  <div
    *ngIf="isInitializing && !globalError"
    class="d-flex justify-content-center flex-column align-items-center mt-5"
  >
    <h4 class="my-4">Cargando todo...</h4>
    <mat-spinner></mat-spinner>
  </div>
  
  <div *ngIf="!isInitializing && !globalError" class="container-fluid">
    <form
      (keydown.enter)="$event.preventDefault()"
      autocomplete="off"
      [formGroup]="formControl"
    >
      <br />
      <div class="row">
        <div class="col-md-6">
          <mat-form-field appearance="outline" class="d-block w-100 mb-2">
            <mat-label>Entidad *</mat-label>
            <mat-select formControlName="entity">
              <mat-option>Elige una</mat-option>
              <mat-option *ngFor="let entity of entities" [value]="entity.entity">
                {{ entity.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <mat-form-field appearance="outline" class="d-block w-100 mb-2">
            <mat-label>Lista *</mat-label>
            <mat-select formControlName="PK">
              <mat-option>Elige una</mat-option>
              <mat-option *ngFor="let item of list" [value]="item.PK">
                {{ item.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-md-12" *ngIf="update">
          <mat-form-field appearance="outline" class="d-block w-100 mb-2">
            <mat-label>Nombre *</mat-label>
            <input formControlName="name" type="text" matInput />
          </mat-form-field>
        </div>
      </div>

      <div *ngIf="uploadError" class="alert alert-danger text-center">
        {{ uploadError }}
      </div>
  
      <div *ngIf="successMessage" class="alert alert-success text-center">
        {{ successMessage }}
      </div>
      
      <button
        class="d-block w-100 mt-2"
        mat-raised-button
        color="primary"
        [disabled]="formControl.invalid || isUploading"
        (click)="$event.preventDefault()"
        (click)="send()"
      >
        {{ getButtonText() }}
      </button>
    </form>
  </div>
  <br />
  
