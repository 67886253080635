import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TimeService } from '@app/time/services/time.service';

@Component({
  selector: 'app-check',
  templateUrl: './check.component.html',
  styleUrls: ['./check.component.scss']
})
export class CheckComponent implements OnInit {
  isInitializing = true;
  isUploading = false;
  globalError: string;
  uploadError: string;
  successMessage: String;
  showTable = false;
  result;

  tableNames = {
    personName: "Persona",
    type: "Tipo",
    categoryName: "Categoría",
    date: "Fecha|date",
    time: "Hora",
    status: "Estado",
    changeStatus: 'Cambiar estado',
  };

  constructor(
    private timeService: TimeService,
    private formBuilder: FormBuilder,
  ) { }
  public formControl: FormGroup;

  ngOnInit(): void {
    this.formControl = this.formBuilder.group({
      items: this.formBuilder.array([]),
    });
  
    this.timeService.getPendingCheck().subscribe(
      (data) => {
        this.result = data["body"];
        this.isInitializing = false;

        if (!this.result.length) {
          this.globalError = "No se encontraron resultados";
          this.showTable = false;
          return;
        }

        this.showTable = true;
      },
      (error) => {
        this.globalError =
          "Se ha presentado un error, por favor vuelva a intentarlo";
        this.isInitializing = false;
      }
    );
  }

  get formArr() {
    return this.formControl.get("items") as FormArray;
  }

  initItemRows() {
    return this.formBuilder.group({
      PK: ["", [Validators.required]],
      status: ["", [Validators.required]],
      person: ["", [Validators.required, Validators.pattern("^PERR[0-9]+$"), Validators.maxLength(11),]],
      date: ["", [Validators.required]],
    });
  }

  addNewRow(element) {
    this.formArr.push(this.initItemRows());
    this.formArr.at(this.formArr.length -1).get("PK").setValue(element.PK);    
    this.formArr.at(this.formArr.length -1).get("person").setValue(element.person);
    this.formArr.at(this.formArr.length -1).get("date").setValue(element.date);
  }

  action(event) { 
    let index = this.formArr.value.findIndex(x => x.PK === event.element.PK);
    if (event.action == 'Aprobado') {
      if(index === -1) this.addNewRow(event.element);
      this.formArr.at(this.formArr.length -1).get("status").setValue('Pendiente aprobación');
    } else if (event.action == 'Rechazado') {
      if(index === -1) this.addNewRow(event.element);
      this.formArr.at(this.formArr.length -1).get("status").setValue('Rechazado');
    } else if (event.action == 'Seleccione') {
      if(index !== -1) this.formArr.removeAt(index);
    } 
  }

  send() {
    this.isUploading = true;
      this.globalError = "";
      this.uploadError = "";
      this.formControl.disable({ emitEvent: false });
      let values = this.formControl.value;
      this.timeService.updateStatus(this.formControl.value).subscribe(
        (data) => {
          this.successMessage = "Operación realizada con exito";
          this.showTable = false;
        },
        (error) => {
          this.uploadError = "Se ha presentado un error, vuelva a intentarlo";
        }
      ); 
  }

  getButtonText() {
    return this.isUploading
      ? "Revisando..."
      : "Revisar";
  }
}
