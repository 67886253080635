import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { PermissionsService } from "@app/permissions/services/permissions.service";
import { ShareService } from "@app/shared/services/share.service";
import { Auth } from "aws-amplify";
import { forkJoin } from "rxjs";

@Component({
  selector: "app-permissions-form",
  templateUrl: "./form.component.html",
  styleUrls: ["./form.component.scss"],
})
export class FormComponent implements OnInit {
  @Input()
  isUpdateOperation;
  @Input()
  PK;
  @Output()
  action = new EventEmitter();
  isInitializing = true;
  isUploading = false;
  globalError: string;
  uploadError: string;
  successMessage: String;
  attachedError: string;

  fileName = "";
  file: File;
  extensionFile;
  url;
  fileAlert = "";
  fileSize;

  constructor(
    private formBuilder: FormBuilder,
    private permissionsService: PermissionsService,
    private shareService: ShareService
  ) {}
  public formControl: FormGroup;

  ngOnInit(): void {
    this.formControl = this.formBuilder.group({
      permissionDate: ["", [Validators.required]],
      startTime: [
        "",
        [
          Validators.required,
          Validators.pattern("^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$"),
        ],
      ],
      finishTime: [
        "",
        [
          Validators.required,
          Validators.pattern("^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$"),
        ],
      ],
      motive: ["", [Validators.required, Validators.maxLength(255)]],
      support: ["", [Validators.required]],
      approvedBoss: ["", [Validators.required]],
      fileExtension: ["", []],
    });

    Auth.currentSession().then((credentials) => {
      if (this.isUpdateOperation) {
        let permissionRq = this.permissionsService.get({ PK: this.PK });
        forkJoin([permissionRq]).subscribe(
          ([permissionRs]) => {
            const PERMISSION = permissionRs["body"][0];
            PERMISSION.permissionDate += "T05:00:00.000Z";
            this.url = PERMISSION.urlAtt;
            this.formControl.patchValue(PERMISSION);
            this.isInitializing = false;
          },
          (error) => {
            this.globalError =
              "Se ha presentado un error, por favor vuelva a intentarlo";
            this.isInitializing = false;
          }
        );
      } else {
        this.isInitializing = false;
      }
    });
  }

  onFileSelected(event) {
    this.file = event.target.files[0];
    if (this.file) {
      this.fileName = this.file.name;
      this.fileSize = this.file.size / 1024;
      this.extensionFile = this.fileName.split(".").pop();
      if (
        (this.extensionFile == "jpg" ||
          this.extensionFile == "png" ||
          this.extensionFile == "jpeg") &&
          this.fileSize <= 300
      ) {
          this.fileAlert = "";
          this.formControl.get("fileExtension").setValue(this.extensionFile);
          this.url = "";
      } else {
        this.fileAlert =
          "El archivo debe ser .png, .jpg o .jpeg e inferior a 300KB";
          this.extensionFile = undefined;
          this.fileName = undefined;
          this.fileSize = undefined;
      }     
    }
  }

  send() {
    this.isUploading = true;
    this.globalError = "";
    this.uploadError = "";
    this.successMessage = "";
    this.formControl.disable({ emitEvent: false });
    if (this.isUpdateOperation) {
      let data = this.formControl.getRawValue();
      data.PK = this.PK;
      this.permissionsService.updatePermissions(data).subscribe(
        (data) => {          
          if (data["body"].url) {            
            this.shareService.uploadFile(data["body"].url, this.file).subscribe(
              (data) => {
                this.successMessage = "Operación realizada con exito";
                this.fileName = "Aún no se ha subido ningún archivo.";
              },
              (error) => {
                this.attachedError =
                  "El registro se guardó correctamente pero se ha presentado un error al subir el archivo";
                this.fileName = "Aún no se ha subido ningún archivo.";
              }
            );
          } else {
            this.successMessage = "Operación realizada con exito";
          }
          this.formControl.enable({ emitEvent: false });
          this.isUploading = false;
        },
        (error) => {
          this.formControl.enable({ emitEvent: false });
          this.uploadError = error.error.status.message;
          this.isUploading = false;
        }
      );
    } else {
      let data = this.formControl.getRawValue();
      if (!data.nextDate) delete data.nextDate;
      this.permissionsService.savePermissions(data).subscribe(
        (data) => {          
          if (data["body"].url) {
            this.shareService.uploadFile(data["body"].url, this.file).subscribe(
              (data) => {
                this.successMessage = "Operación realizada con exito";
                this.fileName = "Aún no se ha subido ningún archivo.";
              },
              (error) => {
                this.attachedError =
                  "El registro se guardó correctamente pero se ha presentado un error al subir el archivo";
                this.fileName = "Aún no se ha subido ningún archivo.";
              }
            );
          } else {
            this.successMessage = "Operación realizada con exito";
          }
          this.formControl.enable({ emitEvent: false });
          this.formControl.reset();
          this.formControl.updateValueAndValidity({ emitEvent: false });
          this.isUploading = false;
        },
        (error) => {
          this.formControl.enable({ emitEvent: false });
          this.uploadError = error.error.status.message;
          this.isUploading = false;
        }
      );
    }
  }

  getButtonText() {
    return this.isUploading
      ? this.isUpdateOperation
        ? "Actualizando permiso..."
        : "Creando permiso..."
      : this.isUpdateOperation
      ? "Actualizar permiso"
      : "Crear permiso";
  }

  close() {
    this.action.emit();
  }
}
