import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';

@Injectable({
  providedIn: 'root'
})
export class PermissionsService {

  constructor(private http: HttpClient) { }

  savePermissions(data) {
    return this.http.put(
      `${environment.apiHost}/v1/permission`,
      data
    );
  }

  updatePermissions(data) {
    return this.http.patch(
      `${environment.apiHost}/v1/permission`,
      data
    );
  }

  get(data) {
    return this.http.post(
      `${environment.apiHost}/v1/permission`,
      data
    );
  }

  updateStatusPermission(data) {
    return this.http.patch(
      `${environment.apiHost}/v1/permission/update-status`,
      data
    );
  }

  getUsers(data) {
    return this.http.post(
      `${environment.apiHost}/v1/personal-registration`,
      data
    );
  }
}
