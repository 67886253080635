<div mat-dialog-content>
  <p>{{ label }}</p>
  <ng-container *ngIf="!hideClose && approve && !order">
    <mat-form-field appearance="outline" class="d-block w-100 mb-2">
      <mat-label>Estado</mat-label>
      <mat-select [(ngModel)]="status" [required]="true">
        <mat-option>Elige una</mat-option>
        <mat-option (click)="changeValue()" value="Aprobado"
          >Aprobar</mat-option
        >
        <mat-option (click)="changeValue()" value="Rechazado"
          >Rechazar</mat-option
        >
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="outline" class="d-block w-100 mb-2">
      <mat-label>Observaciones</mat-label>
      <textarea
        [(ngModel)]="reason"
        [required]="obligatorie"
        type="text"
        matInput
        rows="4"
      ></textarea>
    </mat-form-field>
  </ng-container>
  <ng-container *ngIf="!hideClose && approve && order">
    <mat-form-field appearance="outline" class="d-block w-100 mb-2">
      <mat-label>Estado</mat-label>
      <mat-select [(ngModel)]="status" [required]="true">
        <mat-option>Elige una</mat-option>
        <mat-option (click)="changeValue()" value="Aprobada"
          >Aprobar</mat-option
        >
        <mat-option (click)="changeValue()" value="Rechazada"
          >Rechazar</mat-option
        >
        <mat-option (click)="changeValue()" value="Anulada"
          >Anular</mat-option
        >
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="outline" class="d-block w-100 mb-2">
      <mat-label>Observaciones</mat-label>
      <textarea
        [(ngModel)]="reason"
        [required]="obligatorie"
        type="text"
        matInput
        rows="4"
      ></textarea>
    </mat-form-field>
    <div class="col-md-12">
      <mat-checkbox
        id="userCheckbox"
        style="position: relative; float: right"
        (change)="sendOrder($event)"
        >
        <span>Enviar al cliente</span>
        </mat-checkbox
      >
    </div>
  </ng-container>
  <ng-container *ngIf="!hideClose && email">
    <mat-form-field appearance="outline" class="d-block w-100 mb-2">
      <mat-label>Enviar a:</mat-label>
      <mat-select multiple [(ngModel)]="sendemail"
        ><mat-option *ngFor="let contact of contacts" [value]="contact.SK">
          {{ contact.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </ng-container>
  <ng-container *ngIf="!hideClose && showCloseItems">
    <mat-form-field appearance="outline" class="d-block w-100 mb-2">
      <mat-label>Equipos intervenidos:</mat-label>
      <mat-select multiple [(ngModel)]="closedItems"
        ><mat-option *ngFor="let item of closeItems" [value]="item.SK">
          {{ item.partName }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </ng-container>

  <ng-container *ngIf="!hideClose && enterpriseOc">
    <mat-form-field appearance="outline" class="d-block w-100 mb-2">
      <mat-label>OC cliente</mat-label>
      <input [(ngModel)]="enterpriseOcValue" [required]="obligatorie" type="text" matInput />
    </mat-form-field>
  </ng-container>

  <ng-container *ngIf="!hideClose && updateInform">
    <mat-checkbox
        id="userCheckbox"
        (change)="sendOrder($event)"
        >
        <span>{{ chexboxText }}</span>
      </mat-checkbox>
  </ng-container>

  <ng-container *ngIf="!hideClose && updateStatus">
    <mat-form-field appearance="outline" class="d-block w-100 mb-2">
      <mat-label>Fecha *</mat-label>
      <input
        readonly
        [(ngModel)]="date"
        matInput
        [matDatepicker]="picker"
      />
      <mat-datepicker-toggle        
        matSuffix
        [for]="picker"
      ></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
    <mat-form-field appearance="outline" class="d-block w-100 mb-2">
      <mat-label>Observaciones *</mat-label>
      <textarea
        [(ngModel)]="reason"
        type="text"
        matInput
        rows="4"
      ></textarea>
    </mat-form-field>
  </ng-container>
</div>

<div *ngIf="uploadError" class="alert alert-danger text-center">
  {{ uploadError }}
</div>

<div mat-dialog-actions>
  <button *ngIf="!hideClose" mat-button mat-dialog-close>Cancelar</button>
  <button mat-button (click)="send()">Aceptar</button>
</div>
