<form [formGroup]="loginFormGroup" (submit)="login()" autocomplete="off">
  <!-- openDialog($event)-->
  <h4 class="mb-4">Inicio de sesión</h4>

  <div *ngIf="globalError" class="alert alert-danger text-center">
    {{ globalError }}
  </div>

  <!-- Correo Electronico -->
  <mat-form-field appearance="outline" class="w-100">
    <mat-label>Usuario</mat-label>

    <input
      class="w-100"
      formControlName="username"
      type="text"
      name="user"
      matInput
    />

    <mat-icon matSuffix>account_circle</mat-icon>
    <mat-error *ngIf="loginFormGroup.controls.username.getError('required')">
      El usuario es obligatorio
    </mat-error>
  </mat-form-field>

  <!-- Contraseña -->
  <mat-form-field appearance="outline" class="w-100">
    <mat-label>Contraseña</mat-label>

    <input
      matInput
      formControlName="password"
      class="w-100"
      type="password"
      name="user"
      placeholder="Contraseña"
    />
    <mat-icon matSuffix>vpn_key</mat-icon>

    <mat-error *ngIf="loginFormGroup.controls.password.getError('required')">
      La contraseña es obligatoria
    </mat-error>

    <mat-error *ngIf="loginFormGroup.controls.password.getError('minlength')">
      La contraseña debe contener mínimo 8 caracteres
    </mat-error>
  </mat-form-field>

  <button
    [disabled]="isLoading"
    class="w-100"
    mat-raised-button
    color="primary"
  >
    Ingresar
  </button>
</form>
