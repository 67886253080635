import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { FormComponent } from './pages/form/form.component';
import { SearchComponent } from './pages/search/search.component';
import { ReportComponent } from './pages/report/report.component';
import { SharedModule } from '@app/shared/shared.module';
import { httpInterceptorProviders } from '@app/auth/interceptors';
import { ListTravelExpensesComponent } from './pages/list-travel-expenses/list-travel-expenses.component';
import { NgxCurrencyInputModule } from 'ngx-currency-input';



@NgModule({
  declarations: [FormComponent, ListTravelExpensesComponent, SearchComponent, ReportComponent],
  imports: [CommonModule, SharedModule, NgxCurrencyInputModule],
  exports: [],
  providers: [httpInterceptorProviders, CurrencyPipe],
})
export class TravelExpensesModule { }
