import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';

@Injectable({
  providedIn: 'root'
})
export class ListsService {

  constructor(private http: HttpClient) { }

  updateList(data) {
    return this.http.patch(
      `${environment.apiHost}/v1/lists`,
      data
    );
  }

  deleteList(data) {
    return this.http.patch(
      `${environment.apiHost}/v1/lists`,
      data
    );
  }
}
