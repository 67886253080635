import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from '@angular/material/dialog';
import { EnterprisesService } from '@app/enterprises/services/enterprises.service';
import { SaveListComponent } from '@app/shared/components/save-list/save-list.component';
import { ShareService } from '@app/shared/services/share.service';
import { Auth } from 'aws-amplify';
import { forkJoin } from "rxjs";

@Component({
  selector: 'app-enterprises-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class FormComponent implements OnInit {
  @Input()
  isUpdateOperation;
  @Input()
  PK;
  @Output()
  action = new EventEmitter();
  isInitializing = true;
  isUploading = false;
  globalError: string;
  uploadError: string;
  successMessage: String;
  positions;
  countries;
  cities;
  sectors;
  idMessage = false;
  showCreateUser = false;
  user;
  
  constructor(
    private formBuilder: FormBuilder,
    private enterprisesService: EnterprisesService,
    private shareService: ShareService,
    public dialog: MatDialog
  ) {}
  public formControl: FormGroup;

  ngOnInit(): void {
    this.formControl = this.formBuilder.group({
      personType: ["", [Validators.required]],
      name: ["", [Validators.required, Validators.maxLength(255)]],
      idType: ["", [Validators.required]],
      id: ["", [Validators.required, Validators.pattern("^[0-9]+$"), Validators.maxLength(15)]],
      dv: ["", [Validators.pattern("^[0-9]+$"), Validators.maxLength(2)]],
      type: ["", [Validators.required]],
      country: ["", [Validators.required, Validators.pattern("^COTR[0-9]+$"), Validators.maxLength(11)]],
      city: ["", [Validators.required, Validators.pattern("^CITY[0-9]+$"), Validators.maxLength(11)]],
      idSector: ["", [Validators.required, Validators.pattern("^SECT[0-9]+$"), Validators.maxLength(11)]],
      phone: ["", [Validators.required, Validators.pattern("^[a-zA-Z0-9- ]+$"), Validators.maxLength(50)]],
      address: ["", [Validators.required, Validators.pattern("^[a-zA-Z0-9-#áéíóúÁÉÍÓÚ ]+$"), Validators.maxLength(255)]],
      email: ["", [Validators.required, Validators.pattern("^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$"), Validators.maxLength(100)]],
      web: ["", [Validators.pattern("^[a-zA-Z0-9-_. ]+$"), Validators.maxLength(100)]],
      items: this.formBuilder.array([]),
      username: ["", []],
      password: ["", []],
      status: ["", []]
    });

    Auth.currentSession().then((credentials) => {
      let countryRq = this.shareService.getList("COTR");
    let cityRq = this.shareService.getList("CITY");
    let sectorRq = this.shareService.getList("SECT");
    let positionRq = this.shareService.getList("POST");
    if (this.isUpdateOperation) {
      let enterpriseRq = this.enterprisesService.get({ PK: this.PK });
      forkJoin([countryRq, cityRq, sectorRq, positionRq, enterpriseRq]).subscribe(
        ([countryRs, cityRs, sectorRs, positionRs, enterpriseRs]) => {
          this.countries = countryRs["body"];
          this.cities = cityRs["body"];
          this.sectors = sectorRs["body"];
          this.positions = positionRs["body"];
          const ENTERPRISE = enterpriseRs["body"].filter(item => item.SK === this.PK)[0];          
          ENTERPRISE.items = enterpriseRs["body"].filter(item => item.SK !== this.PK);
          ENTERPRISE.web = ENTERPRISE.web ? ENTERPRISE.web : "";          
          for (let i = 0; i < ENTERPRISE.items.length; i++) {
            let group = this.initItemRows();
            group.addControl('SK', new FormControl('', Validators.required));
            this.formArr.push(group)
          }
          this.formControl.patchValue(ENTERPRISE);
          if (this.formControl.get("username").value) {
            this.formControl.get("username").disable({ emitEvent: false });
            this.formControl
              .get("status")
              .setValidators([Validators.required]);
            this.formControl
              .get("status")
              .updateValueAndValidity({ emitEvent: false });
          } else {
            this.showCreateUser = true;
            this.formControl.get("username").disable({ emitEvent: false });
            this.formControl.get("password").disable({ emitEvent: false });
            this.formControl.get("status").disable({ emitEvent: false });
          }
          this.isInitializing = false;
        },
        (error) => {
          this.globalError =
            "Se ha presentado un error, por favor vuelva a intentarlo";
          this.isInitializing = false;
        }
      );
    } else {
      this.formArr.push(this.initItemRows())
      forkJoin([countryRq, cityRq, sectorRq, positionRq]).subscribe(
        ([countryRs, cityRs, sectorRs, positionRs]) => {
          this.countries = countryRs["body"];
          this.cities = cityRs["body"];
          this.sectors = sectorRs["body"];
          this.positions = positionRs["body"];
          this.isInitializing = false;
        },
        (error) => {
          this.globalError =
            "Se ha presentado un error, por favor vuelva a intentarlo";
          this.isInitializing = false;
        }
      );
      this.showCreateUser = true;
      this.formControl.get("username").disable({ emitEvent: false });
      this.formControl.get("password").disable({ emitEvent: false });
      this.formControl.get("status").disable({ emitEvent: false });
    }
    });
  }

  get formArr() {
    return this.formControl.get('items') as FormArray;
  }

  initItemRows() {
    return this.formBuilder.group({
    name: ["", [Validators.required, Validators.pattern("^[a-zA-ZáéíóúÁÉÍÓÚñÑ ]+$"), Validators.maxLength(255)]],
    position: ["", [Validators.required, Validators.pattern("^POST[0-9]+$"), Validators.maxLength(11)]],
    cellphone: ["", [Validators.required, Validators.pattern("^[a-zA-Z0-9- ]+$"), Validators.maxLength(50)]],
    email: ["", [Validators.required, Validators.pattern("^(([^<>()[]\\.,;:s@]+(.[^<>()[]\\.,;:s@]+)*)|(.+))@(([[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}])|(([a-zA-Z-0-9]+.)+[a-zA-Z]{2,}))$"), Validators.maxLength(100)]]
    });
  }
  addNewRow(event) {
    event.stopPropagation();
    event.preventDefault();
    this.formArr.push(this.initItemRows());
  }

  deleteRow(index: number) {
    if (this.formArr.length > 1) this.formArr.removeAt(index);    
  }

  createUser(e) {
    this.user = e.checked;

    if (this.user) {
      this.formControl.get("username").setValidators([Validators.required, Validators.pattern("^[a-z0-9]+$"),
      Validators.minLength(6), Validators.maxLength(14),]);
      this.formControl.get("username").enable({ emitEvent: false });
      this.formControl
        .get("username")
        .updateValueAndValidity({ emitEvent: false });
      this.formControl.get("password").setValidators([Validators.required, Validators.pattern(
        "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\\^$*.\\[\\]{}\\(\\)?\\-“!@#%&/,><\\’:;|_~`])\\S{8,14}$"
      ),]);
      this.formControl.get("password").enable({ emitEvent: false });
      this.formControl
        .get("password")
        .updateValueAndValidity({ emitEvent: false });
      this.formControl.get("status").setValidators([Validators.required]);
      this.formControl.get("status").enable({ emitEvent: false });
      this.formControl
        .get("status")
        .updateValueAndValidity({ emitEvent: false });
    } else {
      this.formControl.get("username").clearValidators();
      this.formControl.get("username").reset();
      this.formControl.get("username").disable({ emitEvent: false });
      this.formControl
        .get("username")
        .updateValueAndValidity({ emitEvent: false });
      this.formControl.get("password").clearValidators();
      this.formControl.get("password").reset();
      this.formControl.get("password").disable({ emitEvent: false });
      this.formControl
        .get("password")
        .updateValueAndValidity({ emitEvent: false });
      this.formControl.get("status").clearValidators();
      this.formControl.get("status").reset();
      this.formControl.get("status").disable({ emitEvent: false });
      this.formControl
        .get("status")
        .updateValueAndValidity({ emitEvent: false });
    }
  }

  searchId(event) {
    if (this.idMessage) this.idMessage = false;
    if(event) {
      this.enterprisesService.get({ id: event }).subscribe(
        (data) => {
          if ((data["body"].length !== 0 && !this.isUpdateOperation)  || 
          (data["body"].length !== 0 && this.isUpdateOperation && data["body"][0].PK !== this.PK)) this.idMessage = true;
        },
        (error) => {
        }
      );
    }    
  }

  send() { 
    this.isUploading = true;
    this.globalError = "";
    this.uploadError = "";
    this.successMessage = "";
    this.formControl.disable({ emitEvent: false });
    if (this.isUpdateOperation) {
      let values = this.formControl.getRawValue();
      values.PK = this.PK;
      if (!values.password) {
        delete values.password;
      }
      this.enterprisesService.updateEnterprises(values).subscribe(
        (data) => {
          this.formControl.enable({ emitEvent: false });
          this.successMessage = "Operación realizada con exito";
          this.isUploading = false;
          if (values.username) {
            this.showCreateUser = false;
            this.formControl.get("username").disable({ emitEvent: false });
            this.formControl
              .get("status")
              .setValidators([Validators.required]);
            this.formControl
              .get("status")
              .updateValueAndValidity({ emitEvent: false });
          }
          else {
            this.showCreateUser = true;
            this.formControl.get("username").disable({ emitEvent: false });
            this.formControl.get("password").disable({ emitEvent: false });
            this.formControl.get("status").disable({ emitEvent: false });
          }
        },
        (error) => {
          this.formControl.enable({ emitEvent: false });
          if(this.formControl.get("username").value) {
            this.formControl.get("username").disable({ emitEvent: false });
          } else {
            this.formControl.get("username").disable({ emitEvent: false });
            this.formControl.get("password").disable({ emitEvent: false });
            this.formControl.get("status").disable({ emitEvent: false });
          }
          this.uploadError = error.error.status.message;
          this.isUploading = false;
        }
      );
    } else {
      let values = this.formControl.getRawValue();
      if (!values.username) {
        delete values.username;
        delete values.password;
        delete values.status;
      }
      if(!values.dv) delete values.dv;
      if(!values.web) delete values.web;
      this.enterprisesService.saveEnterprises(values).subscribe(
        (data) => {
          this.formControl.enable({ emitEvent: false });
          this.formControl.reset();
          this.formControl.updateValueAndValidity({emitEvent: false});
          this.successMessage = "Operación realizada con exito";
          this.isUploading = false;
          this.formArr.clear();
          this.formArr.push(this.initItemRows());
          if (!this.user) {
            this.formControl.get("username").disable({ emitEvent: false });
            this.formControl.get("password").disable({ emitEvent: false });
            this.formControl.get("status").disable({ emitEvent: false });            
          }  
        },
        (error) => {
          this.formControl.enable({ emitEvent: false });
          this.uploadError = error.error.status.message;
          this.isUploading = false;
          if (!this.user) {
            this.formControl.get("username").disable({ emitEvent: false });
            this.formControl.get("password").disable({ emitEvent: false });
            this.formControl.get("status").disable({ emitEvent: false });            
          }
        }
      );
    }
  }

  save(event, entity, i = null) {
    event.stopPropagation();
    event.preventDefault();
    let list;
    let control;
    let label;
    if (entity === 'POST') {
      label = "Cargo";
      list = this.positions;
      control = this.formArr.controls[i].get('position');
    } else {
      if (entity === 'COTR') {
        label = "País";
        list = this.countries;
        control = this.formControl.get('country');
      } else if (entity === 'CITY') {
        label = "Ciudad";
        list = this.cities;
        control = this.formControl.get('city');
      } else if (entity === 'SECT') {
        label = "Sector";
        list = this.sectors;
        control = this.formControl.get('idSector');
      }
    }
    const dialogRef = this.dialog.open(SaveListComponent, {
      width: "500px",
      data: {
        current: list,
        entity,
        label
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        list.push(result);
        control.setValue(result["PK"]);
      }
    });
  }

  getButtonText() {
    return this.isUploading
      ? this.isUpdateOperation
        ? "Actualizando empresa..."
        : "Creando empresa..."
      : this.isUpdateOperation
      ? "Actualizar empresa"
      : "Crear empresa";
  }

  close() {
    this.action.emit();
  }
}
