import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ShareService } from '@app/shared/services/share.service';
import { FormatsService } from '@app/work/services/formats.service';
import { WorkService } from '@app/work/services/work.service';

@Component({
  selector: 'app-work-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})
export class ReportComponent implements OnInit {
  isInitializing = false;
  isUploading = false;
  globalError: string;
  uploadError: string;
  enterprises;
  data;
  path;

  formControl = this.formBuilder.group({
    typeDocument: ["", [Validators.required]],
    enterprise: ["", []],
    status: ["", []],
    service: ["", []],
    startDate: ["", []],
    finishDate: ["", []],
  });

  constructor(
    private formBuilder: FormBuilder,
    private workService: WorkService,
    private formatsService: FormatsService,
    private shareService: ShareService,
    private router: Router,
  ) {
    this.path = router.url == '/dashboard/informs' ? true : false;

    this.workService.getEnterprises({ all: "ALL" }).subscribe(
      (data) => {
        this.enterprises = data["body"];
        this.isInitializing = false;
      },
      (error) => {
        this.globalError =
          "Se ha presentado un error, por favor vuelva a intentarlo";
        this.isInitializing = false;
      }
    );
   }

  ngOnInit(): void {
  }

  send() {
    this.globalError = "";
    this.uploadError = ""; 
    if((this.formControl.get("startDate").value && !this.formControl.get("finishDate").value) ||
    (!this.formControl.get("startDate").value && this.formControl.get("finishDate").value)) {
      this.uploadError = "Debe ingresar fecha inicial y fecha final";
      return;
    }
    else if (!this.formControl.get("startDate").value && !this.formControl.get("finishDate").value && 
    !this.formControl.get("enterprise").value &&
    !this.formControl.get("status").value && !this.formControl.get("service").value) {
      this.uploadError = "Debe ingresar al menos un criterio de búsqueda";
      return;
    }
    else {
      let values = this.formControl.value;
      const enterprise = values.enterprise ? values.enterprise: undefined;
      const status = values.status ? values.status: undefined;
      const service = values.service ? values.service: undefined;
      const startDate = values.startDate ? values.startDate: undefined;
      const finishDate = values.finishDate ? values.finishDate: undefined;

      const SEARCHCRITERIA = {type: 'R', enterprise, status, service, startDate, finishDate}

      let serviceToCall = this.path ? this.workService.getInform(SEARCHCRITERIA) : this.workService.get(SEARCHCRITERIA)

      serviceToCall.subscribe(
        (data) => {
          console.log(data);
          this.data = data["body"];
          this.isInitializing = false;
          if (!this.data.length) {
            this.uploadError = "No se encontraron resultados";
            return;
          }
          let content =
          this.formControl.value.typeDocument === "PDF"
            ? (this.path ? this.formatsService.generateReportInforms(this.data) : this.formatsService.generateReport(this.data))
            : this.shareService.getTitles(this.path ? this.formatsService.TRANSLATETWO : this.formatsService.TRANSLATE, this.data);
            this.shareService.export(
            this.formControl.value.typeDocument,
            content,
            this.path ? "Informe de trabajo" : "Orden de trabajo",
            { pageSize: "FOLIO", pageOrientation: "landscape", title: this.path ? "Informes de trabajo" : "Informe de órdenes de trabajo" }
          );
        },
        (error) => {
          this.uploadError =
            "Se ha presentado un error, por favor vuelva a intentarlo";
          this.isInitializing = false;
        }
      );
    } 
  }

}
