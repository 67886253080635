import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AuthenticationService } from '@app/auth/services/authentication.service';
import { ShareService } from '@app/shared/services/share.service';
import { FormatsService } from '@app/warranties/services/formats.service';
import { WarrantiesService } from '@app/warranties/services/warranties.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
  isUpdateOperation = false;
  isInitializing = true;
  isUploading = false;
  globalError: string;
  uploadError: string;
  successMessage: String;
  showTable = false;
  enterprises;
  result;
  PK;

  tableNames = {
    order: "Orden",
    enterpriseName: "Empresa",
    service: "Servicio",    
    date: "Fecha garantía|date",
    edit: "Editar",
    print: "Imprimir",
    interveneEquipment: "Equipo(s) a intervenir",
  };

  formControl = this.formBuilder.group({
    order: ["", [Validators.pattern("^[0-9]+$")]],
    enterprise: ["", []],
    startDate: ["", []],
    finishDate: ["", []],
  });

  constructor(
    private formBuilder: FormBuilder,
    private warrantiesService: WarrantiesService,
    private authService: AuthenticationService,
    private formatsService: FormatsService,
    private shareService: ShareService,
  ) { }

  ngOnInit(): void {
    if (!this.authService.checkActionsPermission("WARR", ["UPDATE"])) {
      delete this.tableNames.edit;
    }

    if (!this.authService.checkActionsPermission("WARR", ["PRINT"])) {
      delete this.tableNames.print;
    }

    this.warrantiesService.getEnterprises({ all: "ALL" }).subscribe(
      (data) => {
        this.enterprises = data["body"];
        this.isInitializing = false;
      },
      (error) => {
        this.globalError =
          "Se ha presentado un error, por favor vuelva a intentarlo";
        this.isInitializing = false;
      }
    );
  }

  action(event) {
    if (event.action == "PRINT") {
      const PK = event.element.PK;
      this.warrantiesService.get({ PK }).subscribe(
        (data) => {
          const content = this.formatsService.generatePrint(data["body"][0]);
          this.shareService.export("PDF", content, null, {title: "Garantía de orden de trabajo"});
        },
        (error) => {}
      );
    } else if (event.action == "UPDATE") {
      this.isUpdateOperation = true;
      this.PK = event.element.PK;
    }
  }

  send() {
    if (
      (this.formControl.get("startDate").value &&
        !this.formControl.get("finishDate").value) ||
      (!this.formControl.get("startDate").value &&
        this.formControl.get("finishDate").value)
    ) {
      this.uploadError = "Debe ingresar fecha inicial y fecha final";
      return;
    } else if (
      !this.formControl.get("startDate").value &&
      !this.formControl.get("finishDate").value &&
      !this.formControl.get("order").value &&
      !this.formControl.get("enterprise").value
    ) {
      this.uploadError = "Debe ingresar al menos un criterio de búsqueda";
      return;
    } else {
      this.isUploading = true;
      this.globalError = "";
      this.uploadError = "";
      this.formControl.disable({ emitEvent: false });
      let values = this.formControl.getRawValue();
      const order = values.order ? values.order : undefined;
      const enterprise = values.enterprise ? values.enterprise : undefined;
      const startDate = values.startDate ? values.startDate : undefined;
      const finishDate = values.finishDate ? values.finishDate : undefined;
      this.warrantiesService
        .get({
          type: "S",
          order,
          enterprise,
          startDate,
          finishDate,
        })
        .subscribe(
          (data) => {
            this.formControl.enable({ emitEvent: false });
            this.result = data["body"];
            console.log(this.result);
            this.isUploading = false;
            if (!this.result.length) {
              this.uploadError = "No se encontraron resultados";
              this.showTable = false;
              return;
            }
            this.showTable = true;
          },
          (error) => {
            this.formControl.enable({ emitEvent: false });
            this.uploadError = "Se ha presentado un error, vuelva a intentarlo";
            this.isUploading = false;
          }
        );
    }   
  }

  close() {
    this.isUpdateOperation = false;
  }
}
