import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DocumentsService } from '@app/documents/services/documents.service';
import { SaveListComponent } from '@app/shared/components/save-list/save-list.component';
import { ShareService } from '@app/shared/services/share.service';
import { Auth } from 'aws-amplify';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class FormComponent implements OnInit {
  @Input()
  isUpdateOperation;
  @Input()
  PK;
  @Output()
  action = new EventEmitter();
  isInitializing = true;
  isUploading = false;
  globalError: string;
  uploadError: string;
  successMessage: String;
  attachedError: string;
  typeDocuments;
  marks;
  models;
  typeEquipments;

  fileName = "";
  file: File;
  extensionFile;
  url;

  constructor(
    private formBuilder: FormBuilder,
    private shareService: ShareService,
    private documentsService: DocumentsService,
    public dialog: MatDialog
  ) { }

  public formControl: FormGroup;

  ngOnInit(): void {
    this.formControl = this.formBuilder.group({
      typeDocument: ["", [Validators.required, Validators.pattern("^TYDO[0-9]+$")]],
      mark: ["", [Validators.required, Validators.pattern("^MARK[0-9]+$")]],
      model: ["", [Validators.required, Validators.pattern("^MODE[0-9]+$")]],
      typeEquipment: ["", [Validators.required, Validators.pattern("^TYEQ[0-9]+$")]],
      fileExtension: ["", [Validators.required]],
    });

    Auth.currentSession().then((credentials) => {
      let typeDocumentsRq = this.shareService.getList("TYDO");
      let marksRq = this.shareService.getList("MARK");
      let modelsRq = this.shareService.getList("MODE");
      let typeEquipmentsRq = this.shareService.getList("TYEQ");
      if (this.isUpdateOperation) {
        let documentRq = this.documentsService.get({ PK: this.PK });
        forkJoin([typeDocumentsRq, marksRq, modelsRq, typeEquipmentsRq, documentRq]).subscribe(
          ([typeDocumentsRs, marksRs, modelsRs, typeEquipmentsRs, documentRs]) => {
            this.typeDocuments = typeDocumentsRs["body"];
            this.marks = marksRs["body"];
            this.models = modelsRs["body"];
            this.typeEquipments = typeEquipmentsRs["body"];
            const DOCUMENT = documentRs["body"][0]; 
            this.url = DOCUMENT.urlAtt;
            this.formControl.patchValue(DOCUMENT);
            this.isInitializing = false;
          },
          (error) => {
            this.globalError =
              "Se ha presentado un error, por favor vuelva a intentarlo";
            this.isInitializing = false;
          }
        );
      } else {
        forkJoin([typeDocumentsRq, marksRq, modelsRq, typeEquipmentsRq]).subscribe(
          ([typeDocumentsRs, marksRs, modelsRs, typeEquipmentsRs]) => {
            this.typeDocuments = typeDocumentsRs["body"];
            this.marks = marksRs["body"];
            this.models = modelsRs["body"];
            this.typeEquipments = typeEquipmentsRs["body"];
            this.isInitializing = false;
          },
          (error) => {
            this.globalError =
              "Se ha presentado un error, por favor vuelva a intentarlo";
            this.isInitializing = false;
          }
        );
      }
    });
  }

  onFileSelected(event) {
    this.file = event.target.files[0];
    if (this.file) {
      this.fileName = this.file.name;
      this.extensionFile = this.fileName.split(".").pop();
      this.formControl.get("fileExtension").setValue(this.extensionFile);
      this.url = "";
    }
  }

  send() {
    this.isUploading = true;
    this.globalError = "";
    this.uploadError = "";
    this.successMessage = "";
    this.attachedError = "";
    let values = this.formControl.getRawValue();
    this.formControl.disable({ emitEvent: false });
    if (this.isUpdateOperation) {
      values.PK = this.PK;     
      this.documentsService.updateDocument(values).subscribe(
        (data) => {
          if(data["body"].url) {
              this.shareService.uploadFile(data["body"].url, this.file).subscribe(data=>{;
              this.successMessage = "Operación realizada con exito";
              this.fileName = 'Aún no se ha subido ningún archivo.';
            },
            (error) => {
              this.attachedError = "El registro se guardó correctamente pero se ha presentado un error al subir el archivo";
              this.fileName = 'Aún no se ha subido ningún archivo.';
            })
          }
          else {            
            this.successMessage = "Operación realizada con exito";            
          }  
          this.formControl.enable({ emitEvent: false });
          this.isUploading = false;
        },
        (error) => {
          this.formControl.enable({ emitEvent: false });
          this.uploadError = error.error.status.message;
          this.isUploading = false;
        }
      );
    } else {
      this.documentsService.saveDocument(values).subscribe(
        (data) => {          
          if(data["body"].url) {
              this.shareService.uploadFile(data["body"].url, this.file).subscribe(data=>{
              this.successMessage = "Operación realizada con exito";
              this.fileName = 'Aún no se ha subido ningún archivo.';
            },
            (error) => {
              this.attachedError = "El registro se guardó correctamente pero se ha presentado un error al subir el archivo";
              this.fileName = 'Aún no se ha subido ningún archivo.';
            })
          }
          else {            
            this.successMessage = "Operación realizada con exito";            
          }   
          this.formControl.enable({ emitEvent: false });
          this.formControl.reset();
          this.formControl.updateValueAndValidity({emitEvent: false});
          this.isUploading = false;       
        },
        (error) => {
          this.formControl.enable({ emitEvent: false });
          this.uploadError = error.error.status.message;
          this.isUploading = false;
        }
      );
    }
  }

  save(event, entity) {
    event.stopPropagation();
    event.preventDefault();
    let list;
    let control;
    let label;
    if (entity === "TYDO") {
      label = "Nombre";
      list = this.typeDocuments;
      control = this.formControl.get("typeDocument");
    } else if (entity === "MARK") {
      label = "Marca";
      list = this.marks;
      control = this.formControl.get("mark");
    } else if (entity === "MODE") {
      label = "Modelo";
      list = this.models;
      control = this.formControl.get("model");
    } else if (entity === "TYEQ") {
      label = "Tipo de equipo";
      list = this.typeEquipments;
      control = this.formControl.get("typeEquipment");
    }
    
    const dialogRef = this.dialog.open(SaveListComponent, {
      width: "500px",
      data: {
        current: list,
        entity,
        label,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        list.push(result);
        control.setValue(result["PK"]);
      }
    });
  }

  getButtonText() {
    return this.isUploading
      ? this.isUpdateOperation
        ? "Actualizando documento..."
        : "Creando documento..."
      : this.isUpdateOperation
      ? "Actualizar documento"
      : "Crear documento";
  }

  close() {
    this.action.emit();
  }
}
