<div *ngIf="globalError" class="alert alert-danger text-center">
  {{ globalError }}
</div>

<div
  *ngIf="isInitializing && !globalError"
  class="d-flex justify-content-center flex-column align-items-center mt-5"
>
  <h4 class="my-4">Cargando todo...</h4>
  <mat-spinner></mat-spinner>
</div>

<div *ngIf="!isInitializing && !globalError" class="container-fluid">
  <ng-container *ngIf="isUpdateOperation">
    <button
      class="d-block mt-2"
      mat-raised-button
      color="primary"
      (click)="close()"
    >
      <mat-icon>keyboard_backspace</mat-icon> Regresar
    </button>
  </ng-container>
  <form (keydown.enter)="$event.preventDefault()" autocomplete="off" [formGroup]="formControl">  
    <br>  
  <p>Los campos con asterisco &#40;&#42;&#41; son obligatorios</p>
    <div class="row">
      <div class="col-12">
        <mat-form-field appearance="outline" class="d-block w-100 mb-2">
          <mat-label>Nombre *</mat-label>
          <input formControlName="name" type="text" matInput />
        </mat-form-field>
      </div>
      <div class="col-md-4">
        <mat-form-field appearance="outline" class="d-block w-100 mb-2">
          <mat-label>Fecha de nacimiento</mat-label>
          <input
            readonly
            formControlName="birthdate"
            matInput
            [matDatepicker]="picker"
          />
          <mat-datepicker-toggle
          (click)="picker.open()"
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-datepicker touchUi #picker></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="col-md-4">
        <mat-form-field appearance="outline" class="d-block w-100 mb-2">
          <mat-label>Número de identificación *</mat-label>
          <input formControlName="identification" (blur)="searchId($event.target.value)" type="text" matInput />
          <p class="text-danger idMessage" *ngIf="idMessage">El usuario ya existe</p>
        </mat-form-field>
      </div>
      <div class="col-md-4">
        <mat-form-field appearance="outline" class="d-block w-100 mb-2">
          <mat-label>Cargo *</mat-label>
          <mat-select formControlName="position">
            <mat-option>Elige una</mat-option>
            <mat-option
              *ngFor="let position of positions"
              [value]="position.PK"
            >
              {{ position.name }}
            </mat-option>
          </mat-select>
          <mat-icon (click)="save($event, 'POST')" matSuffix
            >playlist_add</mat-icon
          >
        </mat-form-field>
      </div>
      <div class="col-md-4">
        <mat-form-field appearance="outline" class="d-block w-100 mb-2">
          <mat-label>Dirección</mat-label>
          <input formControlName="address" type="text" matInput />
        </mat-form-field>
      </div>
      <div class="col-md-4">
        <mat-form-field appearance="outline" class="d-block w-100 mb-2">
          <mat-label>Correo electrónico *</mat-label>
          <input formControlName="email" type="email" matInput />
        </mat-form-field>
      </div>
      <div class="col-md-4">
        <mat-form-field appearance="outline" class="d-block w-100 mb-2">
          <mat-label>Teléfono</mat-label>
          <input formControlName="phone" type="tel" matInput />
        </mat-form-field>
      </div>
      <div class="col-md-4">
        <mat-form-field appearance="outline" class="d-block w-100 mb-2">
          <mat-label>Celular *</mat-label>
          <input formControlName="cellphone" type="tel" matInput />
        </mat-form-field>
      </div>
      <div class="col-md-4">
        <mat-form-field appearance="outline" class="d-block w-100 mb-2">
          <mat-label>Estado *</mat-label>
          <mat-select formControlName="status">
            <mat-option>Elige una</mat-option>
            <mat-option value="ACTIVE">Activo</mat-option>
            <mat-option value="INACTIVE">Inactivo</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-md-4">
        <mat-form-field
          appearance="outline"
          class="d-block w-100 mb-2"
          (keydown.enter)="$event.preventDefault()"
        >
          <mat-label>Salario *</mat-label>
          <input formControlName="salary" [min]="1" ngxCurrencyInput matInput />
        </mat-form-field>
      </div>
      <ng-container *ngIf="showCreateUser">
        <div class="col-md-12">
          <mat-checkbox
            id="userCheckbox"
            style="position: relative; float: left"
            (change)="createUser($event)"
            >Crear usuario</mat-checkbox
          >
        </div>
      </ng-container>      
      <div class="col-md-4">
        <mat-form-field appearance="outline" class="d-block w-100 mb-2">
          <mat-label>Usuario</mat-label>
          <input formControlName="username" type="text" autocomplete="new-password" matInput />
        </mat-form-field>
      </div>
      <div class="col-md-4">
        <mat-form-field appearance="outline" class="d-block w-100 mb-2">
          <mat-label>Contraseña</mat-label>
          <input formControlName="password" type="password" autocomplete="new-password" matInput />
        </mat-form-field>
      </div>
      <div class="col-md-4">
        <mat-form-field appearance="outline" class="d-block w-100 mb-2">
          <mat-label>Perfil</mat-label>
          <mat-select formControlName="profile">
            <mat-option>Elige una</mat-option>
            <mat-option *ngFor="let profile of profiles" [value]="profile.PK">
              {{ profile.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>      
    </div>

    <p>La contraseña debe contener al menos un caracter especial, minúsculas, mayúsculas, números y una longitud entre 8 y 14 caracteres.</p>

    <div *ngIf="uploadError" class="alert alert-danger text-center">
      {{ uploadError }}
    </div>

    <div *ngIf="successMessage" class="alert alert-success text-center">
      {{ successMessage }}
    </div>

    <button
      class="d-block w-100 mt-2"
      mat-raised-button
      color="primary"
      [disabled]="formControl.invalid || isUploading"
      (click)="$event.preventDefault()"
      (click)="send()"
    >
      {{ getButtonText() }}
    </button>
  </form>
</div>
<br />
