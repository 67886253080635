import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class FormatsService {
  TRANSLATE = {
    name: 'Nombre',
    identification: 'Cédula',
    positionName: 'Cargo',
    birthdate: 'Fecha de nacimiento',
    address: 'Dirección',
    phone: 'Teléfono',
    cellphone: 'Celular',
    email: 'Email'
  }

  constructor() {}

  generatePrint(data) {
    return [
      {
        table: {
          headerRows: 1,
          widths: ["*", "*", "*", "*", "*", "*", "*", "*", "*", "*", "*", "*"],
          body: [
            [{ text: "DATOS PERSONALES", alignment: "center",  bold: true, colSpan: 12 }, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}],
            [
              { text: [{text: `Nombre: `, bold: true}, {text: `${data.name}`}], colSpan: 6 }, {}, {}, {}, {}, {},
              { text: [{text: `Cédula: `, bold: true}, {text: `${data.identification}`}], colSpan: 6 }, {}, {}, {}, {}, {},              
            ],
            [
              { text: [{text: `Cargo: `, bold: true}, {text: `${data.positionName}`}], colSpan: 6 }, {}, {}, {}, {}, {},
              { text: [{text: `Fecha de nacimiento: `, bold: true}, {text: `${data.birthdate || ""}`}], colSpan: 6 }, {}, {}, {}, {}, {},              
            ],
            [
              { text: [{text: `Teléfono: `, bold: true}, {text: `${data.phone || ""}`}], colSpan: 6 }, {}, {}, {}, {}, {},
              { text: [{text: `Dirección: `, bold: true}, {text: `${data.address || ""}`}], colSpan: 6 }, {}, {}, {}, {}, {},
            ],
            [
              { text: [{text: `Celular: `, bold: true}, {text: `${data.cellphone}`}], colSpan: 6 }, {}, {}, {}, {}, {},
              { text: [{text: `E-mail: `, bold: true}, {text: `${data.email}`}], colSpan: 6 }, {}, {}, {}, {}, {},
            ],
          ],
        },
      },
    ];
  }

  generateReport(data) {
    return [
      {
        table: {
          headerRows: 1,
          widths: ["20%", "10%", "8%", "20%", "10%", "10%", "22%"],
          body: [
            [{ text: 'Nombre', bold: true, alignment: "center" }, { text: 'Cédula', bold: true, alignment: "center" }, { text: 'Fecha nacimiento', bold: true, alignment: "center" }, { text: 'Dirección', bold: true, alignment: "center" }, { text: 'Teléfono', bold: true, alignment: "center" }, { text: 'Celular', bold: true, alignment: "center" }, { text: 'Email', bold: true, alignment: "center" }],
            ...data.map(d => ([d.name, d.identification, d.birthdate ? d.birthdate : " ", d.address || " ", d.phone || " ", d.cellphone, d.email])),
          ],
        },
      },
    ];
  }
}
