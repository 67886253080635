import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { FormatsService } from "@app/remission/services/formats.service";
import { RemissionService } from "@app/remission/services/remission.service";
import { AlertComponent } from "@app/shared/components/alert/alert.component";
import { ShareService } from "@app/shared/services/share.service";
import { Auth } from "aws-amplify";
import { forkJoin } from "rxjs";

@Component({
  selector: "app-remission-form",
  templateUrl: "./form.component.html",
  styleUrls: ["./form.component.scss"],
})
export class FormComponent implements OnInit {
  @Input()
  isUpdateOperation;
  @Input()
  PK;
  @Output()
  action = new EventEmitter();
  isInitializing = true;
  isUploading = false;
  globalError: string;
  uploadError: string;
  successMessage: String;
  enterprises;
  remissionNumber;
  contacts = [];

  constructor(
    private formBuilder: FormBuilder,
    private remissionService: RemissionService,
    private shareService: ShareService,
    public dialog: MatDialog,
    private formatsService: FormatsService
  ) {}

  public formControl: FormGroup;

  ngOnInit(): void {
    this.formControl = this.formBuilder.group({
      outDate: ["", [Validators.required]],
      enterprise: [
        "",
        [
          Validators.required,
          Validators.pattern("^ENTR[0-9]+$"),
          Validators.maxLength(11),
        ],
      ],
      contact: [
        "",
        [
          Validators.required,
          Validators.pattern("^CONV[0-9]+$"),
          Validators.maxLength(11),
        ],
      ],
      guide: ["", [Validators.required, Validators.maxLength(50)]],
      transporter: ["", [Validators.required, Validators.maxLength(255)]],
      observations: ["", [Validators.maxLength(4000)]],
      items: this.formBuilder.array([]),
    });

    this.formControl.get("enterprise").valueChanges.subscribe((value) => {
      if (value) {
        this.remissionService
          .getEnterprises({ contacts: value })
          .subscribe((data) => {
            this.contacts = data["body"];
          });
      } else {
        this.contacts = [];
      }
    });

    Auth.currentSession().then((credentials) => {
      let enterpriseRq = this.remissionService.getEnterprises({ all: "ALL" });
    if (this.isUpdateOperation) {
      let quotationRq = this.remissionService.get({ PK: this.PK });
      forkJoin([quotationRq, enterpriseRq]).subscribe(
        ([quotationRs, enterpriseRs]) => {
          const REMISSION = quotationRs["body"].filter(
            (item) => item.SK === this.PK
          )[0];
          this.enterprises = enterpriseRs["body"];
          REMISSION.items = quotationRs["body"].filter(
            (item) => item.SK !== this.PK
          );
          REMISSION.outDate += "T05:00:00.000Z";
          for (let i = 0; i < REMISSION.items.length; i++) {
            let group = this.initItemRows();
            group.addControl("SK", new FormControl("", Validators.required));
            this.formArr.push(group);
          }
          this.formControl.patchValue(REMISSION);
          this.isInitializing = false;
        },
        (error) => {
          this.globalError =
            "Se ha presentado un error, por favor vuelva a intentarlo";
          this.isInitializing = false;
        }
      );
    } else {
      this.formArr.push(this.initItemRows());
      forkJoin([enterpriseRq]).subscribe(
        ([enterpriseRs]) => {
          this.enterprises = enterpriseRs["body"];
          this.isInitializing = false;
        },
        (error) => {
          this.globalError =
            "Se ha presentado un error, por favor vuelva a intentarlo";
          this.isInitializing = false;
        }
      );
    }
    });
  }

  get formArr() {
    return this.formControl.get("items") as FormArray;
  }

  initItemRows() {
    return this.formBuilder.group({
      product: ["", [Validators.required, Validators.maxLength(510)]],
      quantity: ["", [Validators.required, Validators.min(0.01)]],
    });
  }

  addNewRow(event) {
    event.stopPropagation();
    event.preventDefault();
    this.formArr.push(this.initItemRows());
  }

  deleteRow(index: number) {
    if (this.formArr.length > 1) this.formArr.removeAt(index);   
  }

  print(data) {
    let label = "¿Desea imprimir esta remisión?";
    const dialogRef = this.dialog.open(AlertComponent, {
      width: "500px",
      data: {
        label,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        let PK = data["body"].PK;
        this.remissionNumber = PK.substr(4);
        this.remissionService.get({ PK }).subscribe(
          (data) => {
            const REMISSION = data["body"].filter((item) => item.SK === PK)[0];
            REMISSION.items = data["body"].filter((item) => item.SK !== PK);
            console.log(REMISSION);
            const content = this.formatsService.generatePrint(REMISSION);
            this.shareService.export("PDF", content, null, {
              title: "Remisión No. " + this.remissionNumber,
            });
          },
          (error) => {}
        );
      }
    });
  }

  send() {
    this.isUploading = true;
    this.globalError = "";
    this.uploadError = "";
    this.successMessage = "";
    if (this.isUpdateOperation) {
      let data = this.formControl.getRawValue();
      data.PK = this.PK;
      this.remissionService.updateRemission(data).subscribe(
        (data) => {
          this.formControl.enable({ emitEvent: false });
          this.successMessage = "Operación realizada con exito";
          this.isUploading = false;
          this.print(data);
        },
        (error) => {
          this.formControl.enable({ emitEvent: false });
          this.uploadError = error.error.status.message;
          this.isUploading = false;
        }
      );
    } else {
      let values = this.formControl.getRawValue();
      if(!values.observations) delete values.observations;
      this.remissionService.saveRemission(values).subscribe(
        (data) => {
          this.formControl.enable({ emitEvent: false });
          this.formControl.reset();
          this.formControl.updateValueAndValidity({ emitEvent: false });
          this.successMessage = "Operación realizada con exito";
          this.isUploading = false;
          this.formArr.clear();
          this.formArr.push(this.initItemRows());
          this.print(data);
        },
        (error) => {
          this.formControl.enable({ emitEvent: false });
          this.uploadError = error.error.status.message;
          this.isUploading = false;
        }
      );
    }
  }

  getButtonText() {
    return this.isUploading
      ? this.isUpdateOperation
        ? "Actualizando remisión..."
        : "Creando remisión..."
      : this.isUpdateOperation
      ? "Actualizar remisión"
      : "Crear remisión";
  }

  close() {
    this.action.emit();
  }
}
