import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { ReactiveFormsModule } from "@angular/forms";
import { SharedModule } from "@app/shared/shared.module";
import { LoginComponent } from "./pages/login/login.component";
import { AuthLayoutComponent } from "./pages/auth-layout/auth-layout.component";
import { AuthenticationTokenGuard } from "./guards/authentication-token.guard";

@NgModule({
  declarations: [LoginComponent, AuthLayoutComponent],
  imports: [CommonModule, SharedModule, ReactiveFormsModule, RouterModule],
  exports: [AuthLayoutComponent],
  providers: [AuthenticationTokenGuard],
})
export class AuthModule {}
