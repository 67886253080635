<div class="container-fluid">
  <mat-tab-group mat-stretch-tabs>
    <mat-tab
      *ngIf="authService.checkActionsPermission('TIME', ['CREATE'])"
      label="Crear"
      ><ng-template matTabContent
        ><app-time-form
          [isUpdateOperation]="isUpdateOperation"
          PK="null"
        ></app-time-form></ng-template
    ></mat-tab>
    <mat-tab
      *ngIf="authService.checkActionsPermission('TIME', ['CHECK'])"
      label="Revisar"
      ><ng-template matTabContent><app-check></app-check></ng-template
    ></mat-tab>
    <mat-tab
      *ngIf="authService.checkActionsPermission('TIME', ['APPROVE'])"
      label="Aprobar"
      ><ng-template matTabContent><app-approve></app-approve></ng-template
    ></mat-tab>
    <mat-tab
      *ngIf="authService.checkActionsPermission('TIME', ['UPDATE'])"
      label="Buscar"
      ><ng-template matTabContent
        ><app-time-search></app-time-search></ng-template
    ></mat-tab>
    <mat-tab
      *ngIf="authService.checkActionsPermission('TIME', ['REPORT'])"
      label="Informe"
      ><ng-template matTabContent><app-report></app-report></ng-template
    ></mat-tab>
  </mat-tab-group>
</div>
