import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListListsComponent } from './pages/list-lists/list-lists.component';
import { FormComponent } from './pages/form/form.component';
import { SharedModule } from '@app/shared/shared.module';
import { httpInterceptorProviders } from '@app/auth/interceptors';



@NgModule({
  declarations: [ListListsComponent, FormComponent],
  imports: [CommonModule,  SharedModule],
  exports: [],
  providers: [httpInterceptorProviders],
})
export class ListsModule { }
