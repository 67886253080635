import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { AuthenticationService } from "@app/auth/services/authentication.service";
import { MaterialRequestService } from "@app/material-request/services/material-request.service";
import { AlertComponent } from "@app/shared/components/alert/alert.component";
import { ShareService } from "@app/shared/services/share.service";
import { Auth } from "aws-amplify";
import { forkJoin } from "rxjs";

@Component({
  selector: "app-form",
  templateUrl: "./form.component.html",
  styleUrls: ["./form.component.scss"],
})
export class FormComponent implements OnInit {
  @Input()
  isUpdateOperation;
  @Input()
  PK;
  @Output()
  action = new EventEmitter();
  isInitializing = true;
  isUploading = false;
  globalError: string;
  uploadError: string;
  successMessage: String;
  categories;

  constructor(
    private formBuilder: FormBuilder,
    private materialRequestService: MaterialRequestService,
    public dialog: MatDialog,
    private shareService: ShareService,
    private authService: AuthenticationService,
  ) {}

  public formControl: FormGroup;
  profile = this.authService.cognitoUser["profile"];

  ngOnInit(): void {
    this.formControl = this.formBuilder.group({
      order: ["", [Validators.required, Validators.pattern("^[0-9]+$")]],
      enterpriseOrder: [{value: "", disabled: true}],
      startDateOrder: [{value: "", disabled: true}],
      finishDateOrder: [{value: "", disabled: true}],
      descriptionOrder: [{value: "", disabled: true}],
      departureDate: ["", [Validators.required]],
      motive: ["", [Validators.required, Validators.maxLength(512)]],
      refer: ["", [Validators.required]], 
      numberRefer: ["", []], 
      bill: ["", [Validators.required]], 
      numberBill: ["", []],
      transporter: ["", [Validators.required, Validators.maxLength(255)]],
      guide: ["", [Validators.required, Validators.maxLength(255)]],
      observations: ["", [Validators.maxLength(4000)]],
      send: [false, []],
      items: this.formBuilder.array([]),
    });

    Auth.currentSession().then((credentials) => {
      let categoryRq = this.shareService.getList("CAMR");
      if (this.isUpdateOperation) {
        let materialRequestRq = this.materialRequestService.get({ PK: this.PK });
        forkJoin([materialRequestRq, categoryRq]).subscribe(
          ([materialRequestRs, categoryRs]) => {
            const MATERIAL_REQUEST = materialRequestRs["body"].filter(item => item.SK === this.PK)[0];
            this.categories = categoryRs["body"];
            MATERIAL_REQUEST.items = materialRequestRs["body"].filter(item => item.SK !== this.PK);
            MATERIAL_REQUEST.departureDate += "T05:00:00.000Z";
            if(MATERIAL_REQUEST.order) this.searchId(MATERIAL_REQUEST.order)
            for (let i = 0; i < MATERIAL_REQUEST.items.length; i++) {
              let group = this.initItemRows();
              group.addControl('SK', new FormControl('', Validators.required));
              this.formArr.push(group)    
            }
            this.formControl.patchValue(MATERIAL_REQUEST);          
            this.isInitializing = false;
          },
          (error) => {
            this.globalError =
              "Se ha presentado un error, por favor vuelva a intentarlo";
            this.isInitializing = false;
          }
        );      
      }else {      
        this.formArr.push(this.initItemRows());
        forkJoin([categoryRq]).subscribe(
          ([categoryRs]) => {
            this.categories = categoryRs["body"];
            this.isInitializing = false;
          },
          (error) => {
            this.globalError =
              "Se ha presentado un error, por favor vuelva a intentarlo";
            this.isInitializing = false;
          }
        );
      }
    });

    this.formControl.get("refer").valueChanges.subscribe((value) => {
      if(value === 'Si') {
        this.formControl.get("numberRefer").setValidators([Validators.required, Validators.maxLength(255)]);
      } else {
        this.formControl.get("numberRefer").clearValidators();
        this.formControl.get("numberRefer").reset();
      }
      this.formControl.get("numberRefer").updateValueAndValidity({ emitEvent: false });
    });
    
    this.formControl.get("bill").valueChanges.subscribe((value) => {
      if(value === 'Si') {
        this.formControl.get("numberBill").setValidators([Validators.required, Validators.maxLength(255)]);
      } else {
        this.formControl.get("numberBill").clearValidators();
        this.formControl.get("numberBill").reset();
      }
      this.formControl.get("numberBill").updateValueAndValidity({ emitEvent: false });
    });  
  }

  get formArr() {
    return this.formControl.get("items") as FormArray;
  }

  initItemRows() {
    return this.formBuilder.group({
      category: ["", [Validators.required, Validators.pattern("^CAMR[0-9]+$")]],
      description: ["", [Validators.required, Validators.maxLength(255)]],
      unity: ["", [Validators.required, Validators.maxLength(255)]],
      quantity: ["", [Validators.required, Validators.min(1)]],
    });
  }

  addNewRow(event) {
    event.stopPropagation();
    event.preventDefault();
    this.formArr.push(this.initItemRows());
  }

  deleteRow(index: number) {
    if (this.formArr.length > 1) this.formArr.removeAt(index); 
  }

  searchId(event) {
    let PK = `WORD${event}`;
    let orderData = {
      enterpriseOrder: "",
      startDateOrder: "",
      finishDateOrder: "",
      descriptionOrder: "",
    };
    this.materialRequestService.getWork({ PK }).subscribe(
      (data) => {
        if (data["body"].length !== 0) {
          orderData.enterpriseOrder = data["body"][0].enterpriseName;
          orderData.startDateOrder = data["body"][0].startDate;
          orderData.finishDateOrder = data["body"][0].finishDate;
          orderData.descriptionOrder = data["body"][0].description;

          this.formControl.patchValue(orderData);
        } else {
          this.noOrderFound("No existe la orden de trabajo digitada");
        }
      },
      (error) => {
        this.noOrderFound(
          "Se presentó un error al consultar la orden, por favor intente nuevamente"
        );
      }
    );
  }

  noOrderFound(message) {
    this.formControl.get("enterpriseOrder").reset();
    this.formControl.get("startDateOrder").reset();
    this.formControl.get("finishDateOrder").reset();
    this.formControl.get("descriptionOrder").reset();
    this.formControl.get("order").reset();

    let action = true;
    let label = message;
    this.dialog.open(AlertComponent, {
      width: "500px",
      data: {
        label,
        action,
      },
    });
  }

  disabledInputs() {
    this.formControl.get("enterpriseOrder").disable({ emitEvent: false });
    this.formControl.get("startDateOrder").disable({ emitEvent: false });
    this.formControl.get("finishDateOrder").disable({ emitEvent: false });
    this.formControl.get("descriptionOrder").disable({ emitEvent: false });
  }

  send() {
    if(this.formControl.get('send').value) {
      let label =
        "¿Está seguro que desea enviar el documento para aprobación? Ya no podrá ingresar a modificar el registro";
      const dialogRef = this.dialog.open(AlertComponent, {
        width: "500px",
        data: {
          label,
        },
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) this.callSaveUpdateServices()
      });
    } else this.callSaveUpdateServices()
  }

  callSaveUpdateServices() {
    this.isUploading = true;
    this.globalError = "";
    this.uploadError = "";
    this.successMessage = "";
    let values = this.formControl.value;
    if(!values.observations) delete values.observations;
    if(!values.numberRefer) delete values.numberRefer;
    if(!values.numberBill) delete values.numberBill;
    this.formControl.disable({ emitEvent: false });
    if (this.isUpdateOperation) {
      values.PK = this.PK;
      this.materialRequestService.updateMaterialRequest(values).subscribe(
        (data) => {
          this.successMessage = "Operación realizada con exito";
          this.formControl.enable({ emitEvent: false });
          this.disabledInputs();
          this.formArr.updateValueAndValidity({ emitEvent: false });
          this.isUploading = false;
        },
        (error) => {
          this.formControl.enable({ emitEvent: false });
          this.disabledInputs()
          this.uploadError = error.error.status.message;
          this.isUploading = false;
        }
      );
    } else {
      this.materialRequestService.saveMaterialRequest(values).subscribe(
        (data) => {
          this.successMessage = "Operación realizada con exito"; 
          this.formControl.enable({ emitEvent: false });
          this.disabledInputs()
          this.formControl.reset();
          this.formControl.updateValueAndValidity({ emitEvent: false });
          this.formArr.clear();
          this.formArr.push(this.initItemRows());
          this.isUploading = false;
        },
        (error) => {
          this.formControl.enable({ emitEvent: false });
          this.disabledInputs()
          this.uploadError = error.error.status.message;
          this.isUploading = false;
        }
      );
    }
  }

  getButtonText() {
    return this.isUploading
      ? this.isUpdateOperation
        ? "Actualizando solicitud de materiales..."
        : "Creando solicitud de materiales..."
      : this.isUpdateOperation
      ? "Actualizar solicitud de materiales"
      : "Crear solicitud de materiales";
  }

  close() {
    this.action.emit();
  }
}
