import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { AuthenticationService } from '@app/auth/services/authentication.service';
import { DocumentsService } from '@app/documents/services/documents.service';
import { ShareService } from '@app/shared/services/share.service';
import { Auth } from 'aws-amplify';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
  isUpdateOperation = false;
  isInitializing = true;
  isUploading = false;
  globalError: string;
  uploadError: string;
  successMessage: String;
  showTable = false;
  result;
  PK;
  typeDocuments;
  marks;
  models;
  typeEquipments;

  tableNames = {    
    typeEquipmentName: "Tipo de equipo",
    markName: "Marca",
    modelName: "Modelo",
    typeDocumentName: "Tipo de documento",
    edit: "Editar",
    download: "Adjunto"
  };

  formControl = this.formBuilder.group({
    typeDocument: ["", []],
    typeEquipment: ["", []],
    mark: ["", []],
    model: ["", []]
  });

  constructor(
    private formBuilder: FormBuilder,
    private documentsService: DocumentsService,
    private shareService: ShareService,
    private authService: AuthenticationService,
  ) { }

  ngOnInit(): void {
    if (!this.authService.checkActionsPermission("DOLI", ["UPDATE"])) {
      delete this.tableNames.edit;
    }

    Auth.currentSession().then((credentials) => {
      let typeDocumentsRq = this.shareService.getList("TYDO");
      let marksRq = this.shareService.getList("MARK");
      let modelsRq = this.shareService.getList("MODE");
      let typeEquipmentsRq = this.shareService.getList("TYEQ");
      forkJoin([typeDocumentsRq, marksRq, modelsRq, typeEquipmentsRq]).subscribe(
        ([typeDocumentsRs, marksRs, modelsRs, typeEquipmentsRs]) => {
          this.typeDocuments = typeDocumentsRs["body"];
          this.marks = marksRs["body"];
          this.models = modelsRs["body"];
          this.typeEquipments = typeEquipmentsRs["body"];
          this.isInitializing = false;
        },
        (error) => {
          this.globalError =
            "Se ha presentado un error, por favor vuelva a intentarlo";
          this.isInitializing = false;
        }
      );
    });
  }

  action(event) {
    if (event.action == "UPDATE") {
      this.isUpdateOperation = true;   
      this.PK = event.element.PK;
    }
  }

  send() {
    if (
      !this.formControl.get("typeDocument").value &&
      !this.formControl.get("typeEquipment").value &&
      !this.formControl.get("mark").value &&
      !this.formControl.get("model").value
    ) {
      this.uploadError = "Debe ingresar al menos un criterio de búsqueda";
      return;
    } else {
      this.isUploading = true;
      this.globalError = "";
      this.uploadError = "";
      this.formControl.disable({ emitEvent: false });
      let values = this.formControl.getRawValue();
      const typeDocument = values.typeDocument ? values.typeDocument : undefined;
      const typeEquipment = values.typeEquipment ? values.typeEquipment : undefined;
      const mark = values.mark ? values.mark : undefined;
      const model = values.model ? values.model : undefined;
      this.documentsService
        .get({
          type: "S",
          typeDocument,
          typeEquipment,
          mark,
          model,
        })
        .subscribe(
          (data) => {
            this.formControl.enable({ emitEvent: false });
            this.result = data["body"];
            this.isUploading = false;
            if (!this.result.length) {
              this.uploadError = "No se encontraron resultados";
              this.showTable = false;
              return;
            }
            this.showTable = true;
          },
          (error) => {
            this.formControl.enable({ emitEvent: false });
            this.uploadError = "Se ha presentado un error, vuelva a intentarlo";
            this.isUploading = false;
          }
        );
    }
  }

  close() {
    this.isUpdateOperation = false;
  }
}
