import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FormatsService {
  TRANSLATE = {
    enterpriseName: "Empresa",
    contactName: "Contacto",
    contactCellphone: "Celular",
    contactEmail: "Email",
    title: "Título",
    commercialManagement: "Gestión comercial",
  }

  constructor() { }

  generatePrint(data){
    return [
      {
        lineHeight: 1,
        text: ".", color: "white",
      },
      {
        alignment: 'left',
			  columns: [
          {
            text: [
              {text: 'Empresa: ', bold: true},
              {text: `${data.enterpriseName} \n`},
              {text: 'Celular: ', bold: true},
              {text: `${data.contactCellphone} \n`},
              {text: 'Título: ', bold: true},
              {text: `${data.title} \n`},
            ]
          },
          {
            text: [
              {text: 'Contacto: ', bold: true},
              {text: `${data.contactName} \n`},              
              {text: 'Email: ', bold: true},
              {text: `${data.contactEmail} \n`},
              {text: 'Gestión comercial: ', bold: true},
              {text: `${data.commercialManagement} \n`},
            ]
          }
        ]
      },
      {
        lineHeight: 2,
        text: ".", color: "white",
      },
      {
        table: {
          headerRows: 1,
          widths: ["*", "*", "*", "*", "*", "*", "*", "*", "*", "*", "*", "*"],
          body: [            
            [
              { text: `Fecha`, alignment: "center", bold: true, colSpan: 2 }, {},
              { text: `Hora`, alignment: "center", bold: true, colSpan: 2 }, {},
              { text: `Fecha próximo contacto`, alignment: "center", bold: true, colSpan: 2 }, {},
              { text: `Descripción`, alignment: "center", bold: true, colSpan: 6 }, {}, {}, {}, {}, {}
            ], 
            ...data.items.map(d => ([{text: d.date, colSpan: 2}, {}, {text: d.hour, colSpan: 2}, {}, {text: d.nextDate ? d.nextDate : " ", colSpan: 2}, {}, {text: d.description, colSpan: 6, alignment:'justify'}, {}, {}, {}, {}, {}]))           
          ],          
        },
      }
    ];
  }

  generateReport(data){
    return [
      {
        table: {
          headerRows: 1,
          widths: ["16%", "16%", "16%", "16%", "20%", "16%"],
          body: [
            [{ text: 'Empresa', bold: true, alignment: "center" }, { text: 'Contacto', bold: true, alignment: "center" }, { text: 'Celular', bold: true, alignment: "center" }, { text: 'Email', bold: true, alignment: "center" }, { text: 'Título', bold: true, alignment: "center" }, { text: 'Gestión comercial', bold: true, alignment: "center" }],
            ...data.map(d => ([d.enterpriseName, d.contactName, d.contactCellphone, d.contactEmail, d.title, d.commercialManagement])),
          ],
        },
      },      
    ];
  }
}
