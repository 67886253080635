import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormComponent } from './pages/form/form.component';
import { ListResumeComponent } from './pages/list-resume/list-resume.component';
import { SearchComponent } from './pages/search/search.component';
import { ReportComponent } from './pages/report/report.component';
import { SharedModule } from '@app/shared/shared.module';
import { httpInterceptorProviders } from '@app/auth/interceptors';
import { Ng2SearchPipeModule } from 'ng2-search-filter';



@NgModule({
  declarations: [FormComponent, ListResumeComponent, SearchComponent, ReportComponent],
  imports: [CommonModule, SharedModule, Ng2SearchPipeModule],
  exports: [],
  providers: [httpInterceptorProviders],
})
export class ResumeModule { }
