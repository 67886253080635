import { AfterViewInit, Component, OnInit } from '@angular/core';
import { AuthenticationService } from '@app/auth/services/authentication.service';

@Component({
  selector: 'app-list-documents',
  templateUrl: './list-documents.component.html',
  styleUrls: ['./list-documents.component.scss']
})

export class ListDocumentsComponent implements AfterViewInit {
  isUpdateOperation = false;
  constructor(public authService: AuthenticationService) {}

  ngAfterViewInit() {}
}
