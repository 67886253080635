<div class="container-fluid">
  <mat-tab-group mat-stretch-tabs>
    <mat-tab
      *ngIf="authService.checkActionsPermission('CTRA', ['CREATE'])"
      label="Crear"
      ><ng-template matTabContent
        ><app-commercial-form
          [isUpdateOperation]="isUpdateOperation"
          PK="null"
        ></app-commercial-form></ng-template
    ></mat-tab>
    <mat-tab
      *ngIf="
        authService.checkActionsPermission('CTRA', ['UPDATE', 'PRINT', 'CLOSE'])
      "
      label="Buscar"
      ><ng-template matTabContent
        ><app-commercial-search></app-commercial-search></ng-template
    ></mat-tab>
    <mat-tab
      *ngIf="authService.checkActionsPermission('CTRA', ['REPORT'])"
      label="Informe"
      ><ng-template matTabContent
        ><app-commercial-report></app-commercial-report></ng-template
    ></mat-tab>
  </mat-tab-group>
</div>
