import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-root",
  template: `<app-core></app-core>`,
})
export class AppComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
