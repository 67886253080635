import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormComponent } from './pages/form/form.component';
import { ListCommercialComponent } from './pages/list-commercial/list-commercial.component';
import { ReportComponent } from './pages/report/report.component';
import { SearchComponent } from './pages/search/search.component';
import { SharedModule } from '@app/shared/shared.module';
import { httpInterceptorProviders } from '@app/auth/interceptors';



@NgModule({
  declarations: [FormComponent, ListCommercialComponent, ReportComponent, SearchComponent],
  imports: [CommonModule, SharedModule],  
  providers: [httpInterceptorProviders],
})
export class CommercialModule { }
