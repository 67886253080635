
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from '@environment';

/* IMPORT AMPLIFY */
import Amplify from 'aws-amplify';

Amplify.configure({
  storage: sessionStorage,
  Auth: {
    region: environment.region,
    userPoolId: environment.userPoolId,
    userPoolWebClientId: environment.userPoolWebClientId,
    authenticationFlowType: environment.authenticationFlowType
  },
});

// You can get the current config object
// const currentConfig = Auth.configure();

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));

