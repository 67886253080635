<div *ngIf="globalError" class="alert alert-danger text-center">
    {{ globalError }}
</div>

<div
  *ngIf="isInitializing && !globalError"
  class="d-flex justify-content-center flex-column align-items-center mt-5"
>
  <h4 class="my-4">Cargando todo...</h4>
  <mat-spinner></mat-spinner>
</div>

<div *ngIf="!isInitializing && !globalError" class="container-fluid">
    <ng-container *ngIf="isUpdateOperation">
        <button
        class="d-inline mt-2"
        mat-raised-button
        color="primary"
        (click)="close()"
      >
        <mat-icon>keyboard_backspace</mat-icon> Regresar
      </button>     
    </ng-container>
    <form [formGroup]="formControl" autocomplete="off">
      <br>
      <p>Los campos con asterisco &#40;&#42;&#41; son obligatorios</p>
        <div class="row">             
          <div class="col-md-6">
            <mat-form-field appearance="outline" class="d-block w-100 mb-2" (keydown.enter)="$event.preventDefault()">
              <mat-label>Empresa *</mat-label>
              <mat-select formControlName="enterprise">
                <mat-option>Elige una</mat-option>
                <mat-option
                  *ngFor="let enterprise of enterprises"
                  [value]="enterprise.PK"
                >
                  {{ enterprise.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-md-6">
            <mat-form-field appearance="outline" class="d-block w-100 mb-2" (keydown.enter)="$event.preventDefault()">
              <mat-label>Contacto *</mat-label>
              <mat-select formControlName="contact">
                <mat-option>Elige una</mat-option>
                <mat-option
                  *ngFor="let contact of contacts"
                  [value]="contact.SK"
                >
                  {{ contact.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div> 
          <div class="col-md-6">
            <mat-form-field appearance="outline" class="d-block w-100 mb-2" (keydown.enter)="$event.preventDefault()">
              <mat-label>Fecha inicial *</mat-label>
              <input
                readonly
                formControlName="startDate"
                matInput
                [matDatepicker]="picker"
              />
              <mat-datepicker-toggle
              (click)="picker.open()"
                matSuffix
                [for]="picker"
              ></mat-datepicker-toggle>
              <mat-datepicker touchUi #picker></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="col-md-6">
            <mat-form-field appearance="outline" class="d-block w-100 mb-2" (keydown.enter)="$event.preventDefault()">
              <mat-label>Fecha final *</mat-label>
              <input
                readonly
                formControlName="finishDate"
                matInput
                [matDatepicker]="picker1"
              />
              <mat-datepicker-toggle
              (click)="picker.open()"
                matSuffix
                [for]="picker1"
              ></mat-datepicker-toggle>
              <mat-datepicker touchUi #picker1></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="col-md-6">
            <mat-form-field appearance="outline" class="d-block w-100 mb-2" (keydown.enter)="$event.preventDefault()">
              <mat-label>Servicio *</mat-label>
              <mat-select formControlName="service">
                <mat-option>Elige una</mat-option>
                <mat-option value="Calibración">Calibración</mat-option>
                <mat-option value="Mantenimiento preventivo">Mantenimiento preventivo</mat-option>
                <mat-option value="Mantenimiento correctivo">Mantenimiento correctivo</mat-option>
                <mat-option value="Montaje">Montaje</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-md-6">
            <mat-form-field appearance="outline" class="d-block w-100 mb-2" (keydown.enter)="$event.preventDefault()">
              <mat-label>Técnico *</mat-label>
              <mat-select formControlName="technician">
                <mat-option>Elige una</mat-option>
                <mat-option *ngFor="let user of users" [value]="user.PK">
                  {{ user.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-12">
            <mat-form-field appearance="outline" class="d-block w-100 mb-2">
              <mat-label>Lugar de prestación del servicio *</mat-label>
              <input formControlName="placeService" type="text" matInput />
            </mat-form-field>
          </div>
          <div class="col-12">
            <mat-form-field appearance="outline" class="d-block w-100 mb-2">
              <mat-label>Equipo(s) a intervenir *</mat-label>
              <input formControlName="interveneEquipment" type="text" matInput />
            </mat-form-field>
          </div>
          <div class="col-md-12">
            <mat-form-field appearance="outline" class="d-block w-100 mb-2">
              <mat-label>Descripción *</mat-label>
              <textarea formControlName="description" type="text" matInput rows="7"></textarea>
            </mat-form-field>
          </div>
          <div class="col-md-6 d-block w-100 mb-2">
            <input type="file" class="file-input" (change)="onFileSelected($event)" #fileUpload>
            <div class="file-upload">
              <mat-label>Materiales: {{fileName || "Aún no se ha subido ningún archivo."}} </mat-label>
              <button mat-mini-fab color="primary" class="upload-btn"
                (click)="$event.preventDefault()"
                (click)="fileUpload.click()">
                <mat-icon>attach_file</mat-icon>
              </button>
              <ng-container *ngIf="url">
                <a [href]="url" target="_blank"> Descargar archivo adjunto</a>
              </ng-container>                
            </div>
        </div>                        
      </div>
      <ng-container *ngIf="isUpdateOperation">
        <div class="row">
          <div class="col-md-6">
            <mat-form-field appearance="outline" class="d-block w-100 mb-2" (keydown.enter)="$event.preventDefault()">
              <mat-label>Estado *</mat-label>
              <mat-select formControlName="status">
                <mat-option>Elige una</mat-option>
                <mat-option value="Ejecución">Ejecución</mat-option>
                <mat-option value="Facturar">Facturar</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-md-6">
            <mat-form-field appearance="outline" class="d-block w-100 mb-2" (keydown.enter)="$event.preventDefault()">
              <mat-label>Recordatorio</mat-label>
              <mat-select formControlName="reminder">
                <mat-option>Elige una</mat-option>
                <mat-option value="3">3 meses</mat-option>
                <mat-option value="6">6 meses</mat-option>
                <mat-option value="9">9 meses</mat-option>
                <mat-option value="12">12 meses</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <hr>
      <h2>Items</h2>      
      <div formArrayName="items">
        <div *ngFor="let itemrow of formControl.controls.items['controls']; let i=index" [formGroupName]="i">
          <hr>
        <div class="row">
          <div  class="col-md-12">
            <button
            class="d-block mt-2 button-remove"
            mat-raised-button
            color="primary"
            (click)="$event.preventDefault()"
            (click)="deleteRow(i)"
          >
            <mat-icon>remove</mat-icon>
          </button>
          <br>
          </div>
        </div>
        <div class="row">        
          <div class="col-md-3">
            <mat-form-field appearance="outline" class="d-block w-100 mb-2" (keydown.enter)="$event.preventDefault()">
              <mat-label>Fecha *</mat-label>
              <input
                readonly
                formControlName="date"
                matInput
                [matDatepicker]="picker2"
              />
              <mat-datepicker-toggle
              (click)="picker.open()"
                matSuffix
                [for]="picker2"
              ></mat-datepicker-toggle>
              <mat-datepicker touchUi #picker2></mat-datepicker>
            </mat-form-field>
          </div> 
          <div class="col-md-3">
            <mat-form-field appearance="outline" class="d-block w-100 mb-2" (keydown.enter)="$event.preventDefault()">
              <mat-label>Técnico *</mat-label>
              <mat-select formControlName="technician">
                <mat-option>Elige una</mat-option>
                <mat-option *ngFor="let user of users" [value]="user.PK">
                  {{ user.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-md-3">
            <mat-form-field appearance="outline" class="d-block w-100 mb-2" (keydown.enter)="$event.preventDefault()">
              <mat-label>Hora inicio (HH:MM)</mat-label>
              <input formControlName="initialHour" type="text" matInput />
            </mat-form-field>
          </div><div class="col-md-3">
            <mat-form-field appearance="outline" class="d-block w-100 mb-2" (keydown.enter)="$event.preventDefault()">
              <mat-label>Hora final (HH:MM)</mat-label>
              <input formControlName="finalHour" type="text" matInput />
            </mat-form-field>
          </div>
        </div>
        </div>
      </div>   
      <hr>   
      <button
          class="d-block mt-2"
          mat-raised-button
          color="primary"
          style="margin: auto;"
          (click)="addNewRow($event)"
        >
          <mat-icon>add</mat-icon>
        </button>
        <br>
      </ng-container>
      <!---->
        
        <div *ngIf="uploadError" class="alert alert-danger text-center">
          {{ uploadError }}
        </div>
    
        <div *ngIf="successMessage" class="alert alert-success text-center">
          {{ successMessage }}
        </div>

        <div *ngIf="attachedError" class="alert alert-warning text-center">
          {{ attachedError }}
        </div>
    
        <button
          class="d-block w-100 mt-2"
          mat-raised-button
          color="primary"
          [disabled]="formControl.invalid || isUploading"
          (click)="$event.preventDefault()"
          (click)="send()"          
        >
          {{ getButtonText() }}
        </button>
    </form>
</div>
<br />

