<div *ngIf="globalError" class="alert alert-danger text-center">
  {{ globalError }}
</div>

<div
  *ngIf="isInitializing && !globalError"
  class="d-flex justify-content-center flex-column align-items-center mt-5"
>
  <h4 class="my-4">Cargando todo...</h4>
  <mat-spinner></mat-spinner>
</div>

<div *ngIf="!isInitializing && !globalError" class="container-fluid">
  <button
  *ngIf="path == '/dashboard/order' || (path != '/dashboard/order') && isUpdateOperation"
    class="d-inline mt-2"
    mat-raised-button
    color="primary"
    (click)="close()"
  >
    <mat-icon>keyboard_backspace</mat-icon> Regresar
  </button>
  <form [formGroup]="formControl" autocomplete="off">
    <br />
    <p>Los campos con asterisco &#40;&#42;&#41; son obligatorios</p>
    <div class="row">
      <ng-container *ngIf="path != '/dashboard/order'">
        <div class="col-md-6">
          <mat-form-field appearance="outline" class="d-block w-100 mb-2" (keydown.enter)="$event.preventDefault()">
            <mat-label>Empresa *</mat-label>
            <mat-select formControlName="enterprise">
              <mat-option>Elige una</mat-option>
              <mat-option
                *ngFor="let enterprise of enterprises"
                [value]="enterprise.PK"
              >
                {{ enterprise.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <mat-form-field appearance="outline" class="d-block w-100 mb-2" (keydown.enter)="$event.preventDefault()">
            <mat-label>Contacto *</mat-label>
            <mat-select formControlName="contact">
              <mat-option>Elige una</mat-option>
              <mat-option
                *ngFor="let contact of contacts"
                [value]="contact.SK"
              >
                {{ contact.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div> 
        <div class="col-md-6">
          <mat-form-field appearance="outline" class="d-block w-100 mb-2" (keydown.enter)="$event.preventDefault()">
            <mat-label>Servicio *</mat-label>
            <mat-select formControlName="service">
              <mat-option>Elige una</mat-option>
              <mat-option value="Calibración">Calibración</mat-option>
              <mat-option value="Mantenimiento preventivo">Mantenimiento preventivo</mat-option>
              <mat-option value="Mantenimiento correctivo">Mantenimiento correctivo</mat-option>
              <mat-option value="Montaje">Montaje</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <mat-form-field appearance="outline" class="d-block w-100 mb-2">
            <mat-label>Lugar de prestación del servicio *</mat-label>
            <input formControlName="placeService" type="text" matInput />
          </mat-form-field>
        </div>
      </ng-container>
      <div class="col-md-4">
        <mat-form-field
          appearance="outline"
          class="d-block w-100 mb-2"
          (keydown.enter)="$event.preventDefault()"
        >
          <mat-label>Fecha *</mat-label>
          <input
            readonly
            formControlName="startDate"
            matInput
            [matDatepicker]="picker"
          />
          <mat-datepicker-toggle
            (click)="picker.open()"
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-datepicker touchUi #picker></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="col-md-4">
        <mat-form-field
          appearance="outline"
          class="d-block w-100 mb-2"
          (keydown.enter)="$event.preventDefault()"
        >
          <mat-label>Tipo de equipo *</mat-label>
          <input formControlName="typeDevice" type="text" matInput />
        </mat-form-field>
      </div>
      <div class="col-md-4">
        <mat-form-field
          appearance="outline"
          class="d-block w-100 mb-2"
          (keydown.enter)="$event.preventDefault()"
        >
          <mat-label>Marca *</mat-label>
          <input formControlName="mark" type="text" matInput />
        </mat-form-field>
      </div>
      <div class="col-md-4">
        <mat-form-field
          appearance="outline"
          class="d-block w-100 mb-2"
          (keydown.enter)="$event.preventDefault()"
        >
          <mat-label>Potencia &#40;BTU/HR - BHP&#41; *</mat-label>
          <input formControlName="power" type="text" matInput />
        </mat-form-field>
      </div>
      <div class="col-md-4">
        <mat-form-field
          appearance="outline"
          class="d-block w-100 mb-2"
          (keydown.enter)="$event.preventDefault()"
        >
          <mat-label>Sistema modulación *</mat-label>
          <input formControlName="modulationSystem" type="text" matInput />
        </mat-form-field>
      </div>
      <div class="col-md-4">
        <mat-form-field
          appearance="outline"
          class="d-block w-100 mb-2"
          (keydown.enter)="$event.preventDefault()"
        >
          <mat-label>Título del informe *</mat-label>
          <input formControlName="description" type="text" matInput />
        </mat-form-field>
      </div>
      <div class="col-md-6">
        <mat-form-field
          appearance="outline"
          class="d-block w-100 mb-2"
          (keydown.enter)="$event.preventDefault()"
        >
          <mat-label>Voltaje circuito control/potencia *</mat-label>
          <input formControlName="voltage" type="text" matInput />
        </mat-form-field>
      </div>
      <div class="col-md-6">
        <mat-form-field
          appearance="outline"
          class="d-block w-100 mb-2"
          (keydown.enter)="$event.preventDefault()"
        >
          <mat-label>Revisión seguridades caldera/quemador/equipo *</mat-label>
          <mat-select formControlName="securityCheck">
            <mat-option>Elige una</mat-option>
            <mat-option value="Si">Sí</mat-option>
            <mat-option value="No">No</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-md-6">
        <mat-form-field
          appearance="outline"
          class="d-block w-100 mb-2"
          (keydown.enter)="$event.preventDefault()"
        >
          <mat-label>Presión de aire *</mat-label>
          <input formControlName="airPressure" type="text" matInput />
        </mat-form-field>
      </div>
      <div class="col-md-6">
        <mat-form-field
          appearance="outline"
          class="d-block w-100 mb-2"
          (keydown.enter)="$event.preventDefault()"
        >
          <mat-label>Revisión quemador *</mat-label>
          <mat-select formControlName="burnerCheck">
            <mat-option>Elige una</mat-option>
            <mat-option value="Si">Sí</mat-option>
            <mat-option value="No">No</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-md-6">
        <mat-form-field
          appearance="outline"
          class="d-block w-100 mb-2"
          (keydown.enter)="$event.preventDefault()"
        >
          <mat-label
            >Presión gas reg. primera etapa &#40;BAR-PSI&#41; *</mat-label
          >
          <input formControlName="gasPressure1" type="text" matInput />
        </mat-form-field>
      </div>
      <div class="col-md-6">
        <mat-form-field
          appearance="outline"
          class="d-block w-100 mb-2"
          (keydown.enter)="$event.preventDefault()"
        >
          <mat-label>Revisión suiche quemador *</mat-label>
          <mat-select formControlName="switchCheck">
            <mat-option>Elige una</mat-option>
            <mat-option value="Si">Sí</mat-option>
            <mat-option value="No">No</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-md-6">
        <mat-form-field
          appearance="outline"
          class="d-block w-100 mb-2"
          (keydown.enter)="$event.preventDefault()"
        >
          <mat-label
            >Presión gas reg. segunda etapa &#40;MBAR-INH20&#41; *</mat-label
          >
          <input formControlName="gasPressure2" type="text" matInput />
        </mat-form-field>
      </div>
      <div class="col-md-6">
        <mat-form-field
          appearance="outline"
          class="d-block w-100 mb-2"
          (keydown.enter)="$event.preventDefault()"
        >
          <mat-label>Revisión circuito eléctrico *</mat-label>
          <mat-select formControlName="circuitCheck">
            <mat-option>Elige una</mat-option>
            <mat-option value="Si">Sí</mat-option>
            <mat-option value="No">No</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-md-6">
        <mat-form-field
          appearance="outline"
          class="d-block w-100 mb-2"
          (keydown.enter)="$event.preventDefault()"
        >
          <mat-label>Set valvula de seguridad *</mat-label>
          <input formControlName="setValve" type="text" matInput />
        </mat-form-field>
      </div>
      <div class="col-md-6">
        <mat-form-field
          appearance="outline"
          class="d-block w-100 mb-2"
          (keydown.enter)="$event.preventDefault()"
        >
          <mat-label>Analisis de combustión *</mat-label>
          <mat-select formControlName="combustionAnalysis">
            <mat-option>Elige una</mat-option>
            <mat-option value="Si">Sí</mat-option>
            <mat-option value="No">No</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-md-12">
        <mat-form-field appearance="outline" class="d-block w-100 mb-2">
          <mat-label
            >Describa de manera detallada como encontró la máquina e
            instrumentación &#40;Antes y depués de la intervanción&#41;
            *</mat-label
          >
          <textarea
            formControlName="detail"
            type="text"
            matInput
            rows="7"
          ></textarea>
        </mat-form-field>
      </div>
      <div class="col-md-12" *ngIf="isUpdateOperation && recommendations.length === 0; else recommendationList">
        <mat-form-field appearance="outline" class="d-block w-100 mb-2">
          <mat-label>Recomendaciones y observaciones *</mat-label>
          <textarea
            formControlName="recommendation"
            type="text"
            matInput
            rows="7"
          ></textarea>
        </mat-form-field>
      </div>
      <ng-template #recommendationList>
        <div class="col-12">
          <mat-form-field appearance="outline" class="d-block w-100 mb-2" (keydown.enter)="$event.preventDefault()">
            <mat-label>Recomendaciones y observaciones *</mat-label>
            <mat-select multiple formControlName="recommendation">
              <div *ngFor="let recommendation of recommendations; index as i" class="d-flex justify-content-between" >
                <mat-option class="flex-grow-1" [value]="recommendation">
                  {{ recommendation }}                
                </mat-option>
                <button
                mat-flat-button
                (click)="save($event, 'OPLI', i)"
                mat-button>
                  <mat-icon>edit</mat-icon>
                </button>
              </div>              
            </mat-select>
            <mat-icon (click)="save($event, 'OPLI')" matSuffix
              >playlist_add</mat-icon
            >
          </mat-form-field>
        </div>
      </ng-template>      
      <div class="col-md-12 d-block w-100 mb-2">
        <hr />
        <h2>Registro fotográfico</h2>
        <div formArrayName="items">
          <div
            *ngFor="
              let itemrow of formControl.controls.items['controls'];
              let i = index
            "
            [formGroupName]="i"
          >
            <hr />
            <div class="row">
              <div class="col-md-12">
                <button
                  class="d-block mt-2 button-remove"
                  mat-raised-button
                  color="primary"
                  (click)="$event.preventDefault()"
                  (click)="deleteRow(i)"
                >
                  <mat-icon>remove</mat-icon>
                </button>
                <br />
              </div>
            </div>
            <div class="row">              
              <div class="col-md-6">
                <mat-form-field
                  appearance="outline"
                  class="d-block w-100 mb-2"
                  (keydown.enter)="$event.preventDefault()"
                >
                  <mat-label>Descripción</mat-label>
                  <input formControlName="description" type="text" matInput />
                </mat-form-field>
              </div>
              <div class="col-md-6 d-block w-100 mb-2">
                <input
                  type="file"
                  accept="image/*"
                  maxFileSize="512000"
                  class="file-input"
                  (change)="onImageSelected($event, i)"
                  #fileUpload
                />
                <div class="file-upload">
                  <mat-label
                    >Adjunto:
                    {{ imageName[i] || "Aún no se ha subido ningún archivo."}}
                  </mat-label>
                  <button
                    mat-mini-fab
                    color="primary"
                    class="upload-btn"
                    (click)="$event.preventDefault()"
                    (click)="fileUpload.click()"
                  >
                    <mat-icon>attach_file</mat-icon>
                  </button>
                  <ng-container *ngIf="imageUrl[i]">
                    <a [href]="imageUrl[i]" target="_blank">
                      Descargar archivo adjunto</a
                    >
                  </ng-container>
                  <p class="text-danger">{{ imageAlert[i] || ""}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <button
          class="d-block mt-2"
          mat-raised-button
          color="primary"
          style="margin: auto"
          (click)="addNewRow($event)"
        >
          <mat-icon>add</mat-icon>
        </button>
        <br />
      </div>
      <div class="col-md-12 d-block w-100 mb-2">
        <hr />
        <h2>Equipos intervenidos</h2>
        <div class="col-md-12">
          <mat-form-field appearance="outline" class="d-block w-100 mb-2">
            <mat-label>Equipos</mat-label>
            <input
              type="search"
              [(ngModel)]="search"
              [ngModelOptions]="{ standalone: true }"
              autocomplete="off"
              matInput
            />
            <p class="text-danger itemMessage" *ngIf="itemMessage">El equipo seleccionado ya fue agregado</p>
          </mat-form-field>
          <div class="mat-elevation-z2" style="position: absolute; top: 45px; right: 0; z-index: 1; background-color: white; width:inherit" *ngIf="search.length > 2">
            <mat-option
              *ngFor="let equipment of equipments | filter: search"
              (click)="addNewIntervenedRow($event, equipment)"
            >
              {{ equipment.nameEquipment }} - {{equipment.code}}
            </mat-option>
          </div>
        </div>
        <div formArrayName="intervenedItems">
          <div
            *ngFor="
              let itemrow of formControl.controls.intervenedItems['controls'];
              let i = index
            "
            [formGroupName]="i"
          >
            <hr />
            <div class="row">
              <div class="col-md-12">
                <button
                  class="d-block mt-2 button-remove"
                  mat-raised-button
                  color="primary"
                  (click)="$event.preventDefault()"
                  (click)="deleteIntervenedRow(i)"
                >
                  <mat-icon>remove</mat-icon>
                </button>
                <br />
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <mat-form-field appearance="outline" class="d-block w-100 mb-2">
                  <mat-label>Equipo</mat-label>
                  <input 
                    [value]="this.selectedItem[i].nameEquipment"
                    [disabled]="true"
                    type="text"
                    matInput
                  />
                </mat-form-field>
              </div>
              <div class="col-md-6">
                <mat-form-field appearance="outline" class="d-block w-100 mb-2" (keydown.enter)="$event.preventDefault()">
                  <mat-label>Partes</mat-label>
                  <mat-select multiple formControlName="partsSelected">
                    <mat-option *ngFor="let part of parts[i]" [value]="part.SK"
                    (click)="addNewPartRow(part, i)">
                      {{part.typeEquipmentName}} - {{part.markName}} - {{part.modelName}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-md-2">
                <mat-form-field appearance="outline" class="d-block w-100 mb-2" (keydown.enter)="$event.preventDefault()">
                  <mat-label>Fecha *</mat-label>
                  <input
                    readonly
                    formControlName="date"
                    matInput
                    [matDatepicker]="picker2"
                  />
                  <mat-datepicker-toggle
                  (click)="picker.open()"
                    matSuffix
                    [for]="picker2"
                  ></mat-datepicker-toggle>
                  <mat-datepicker touchUi #picker2></mat-datepicker>
                </mat-form-field>
              </div>
              <div class="col-12">
                <mat-form-field appearance="outline" class="d-block w-100 mb-2" (keydown.enter)="$event.preventDefault()">
                  <mat-label>Descripción *</mat-label>
                  <input formControlName="description" type="text" matInput />
                </mat-form-field>
              </div>
              <div class="col-12">
                <div formArrayName="parts">
                  <div
                    *ngFor="
                      let itemrow of formControl.controls.intervenedItems['controls'][i].controls.parts['controls'];
                      let j = index
                    "
                    [formGroupName]="j"
                  >
                    <div class="row px-3">
                      <p class="col-12"><b>{{this.partsSelected[i][j].typeEquipmentName}} - {{this.partsSelected[i][j].markName}} - {{this.partsSelected[i][j].modelName}}</b></p>
                      <div class="col-md-3">
                        <mat-form-field appearance="outline" class="d-block w-100 mb-2" (keydown.enter)="$event.preventDefault()">
                          <mat-label>Valor *</mat-label>
                          <mat-select formControlName="value">
                            <mat-option value="10">10%</mat-option>
                            <mat-option value="20">20%</mat-option>
                            <mat-option value="30">30%</mat-option>
                            <mat-option value="40">40%</mat-option>
                            <mat-option value="50">50%</mat-option>
                            <mat-option value="60">60%</mat-option>
                            <mat-option value="70">70%</mat-option>
                            <mat-option value="80">80%</mat-option>
                            <mat-option value="90">90%</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                      <div class="col-md-9">
                        <mat-form-field appearance="outline" class="d-block w-100 mb-2" (keydown.enter)="$event.preventDefault()">
                          <mat-label>Descripción *</mat-label>
                          <input formControlName="description" type="text" matInput />
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr />
        </div>
      </div>
      <div class="col-md-12 d-block w-100 mb-2">        
        <div class="container-fluid" [hidden]="!showTable">
          <br>
          <h2>Equipos con pendientes</h2>
          <div *ngIf="statusError" class="alert alert-danger text-center">
              {{ statusError }}
          </div>
          <div *ngIf="successStatus" class="alert alert-success text-center">
            {{ successStatus }}
          </div>
          <div class="row">
            <div class="col">
              <app-data-table
                [names]="tableNames"
                [data]="pendingEquipments"
                (action)="tableAction($event)"
              ></app-data-table>
            </div>
          </div>
          <br>
        </div>
      </div>
      <div class="col-md-12">
        <mat-form-field appearance="outline" class="d-block mb-1 mx-auto" style="width:300px">
          <mat-label>Nombre</mat-label>
          <input formControlName="nameReceive" type="text" matInput />
        </mat-form-field>
    </div>
    <div class="col-md-12">
      <mat-form-field appearance="outline" class="d-block mb-1 mx-auto" style="width:300px">
        <mat-label>Cédula</mat-label>
        <input formControlName="documentReceive" type="number" matInput />
      </mat-form-field>
    </div>
      <div class="col-12">
        <canvas class="mx-auto" #signPadCanvas (touchstart)="startSignPadDrawing($event)" (touchmove)="movedFinger($event)"></canvas>
        <div class="text-center mt-1 mb-3">
            <button class="mx-1"(click)="$event.preventDefault()" mat-raised-button color="primary" (click)="clearSignPad()"><mat-icon>delete</mat-icon></button>
            <button class="mx-1"(click)="$event.preventDefault()" mat-raised-button color="primary" (click)="undoSign()"><mat-icon>undo</mat-icon></button>
        </div>
      </div>
      <div class="col-md-6 d-block w-100 mb-2">
        <input
          type="file"
          class="file-input"
          (change)="onFileSelected($event)"
          #fileUpload
        />
        <div class="file-upload">
          <mat-label
            >Adjunto: {{ fileName || "Aún no se ha subido ningún archivo." }}
          </mat-label>
          <button
            mat-mini-fab
            color="primary"
            class="upload-btn"
            (click)="$event.preventDefault()"
            (click)="fileUpload.click()"
          >
            <mat-icon>attach_file</mat-icon>
          </button>
          <ng-container *ngIf="url">
            <a [href]="url" target="_blank"> Descargar archivo adjunto</a>
          </ng-container>
        </div>
      </div>
      <div *ngIf="!isUpdateOperation" class="col-md-6">
        <mat-checkbox
          id="userCheckbox"
          style="position: relative; float: right"
          (change)="sendInform($event)"
          >
          <span *ngIf="profile1; else otherProfile">Enviar al cliente</span>
          <ng-template #otherProfile>Enviar para aprobación</ng-template>
          </mat-checkbox
        >
      </div>
      <div class="col-12">
      </div>
    </div>
    <div *ngIf="uploadError" class="alert alert-danger text-center">
      {{ uploadError }}
    </div>

    <div *ngIf="successMessage" class="alert alert-success text-center">
      {{ successMessage }}
    </div>

    <div *ngIf="attachedError" class="alert alert-warning text-center">
      {{ attachedError }}
    </div>

    <button
      class="d-block w-100 mt-2"
      mat-raised-button
      color="primary"
      [disabled]="formControl.invalid || isUploading"
      (click)="$event.preventDefault()"
      (click)="send()"
    >
      {{ getButtonText() }}
    </button>
    <br />
  </form>
</div>
