import { CurrencyPipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '@app/auth/services/authentication.service';
import { InventoryService } from '@app/inventory/services/inventory.service';

@Component({
  selector: 'app-inventory-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
  isInitializing = true;
  globalError: string;
  showTable = false;
  result;
  total;

  tableNames = {
    code: "Código",    
    description: "Descripción",
    quantity: "Cantidad",
    status: "Estado",
    price: "Precio",
  };

  constructor(
    private invenotryService: InventoryService,
    private authService: AuthenticationService,
    private currencyPipe: CurrencyPipe
  ) { }

  profile = this.authService.cognitoUser["profile"];  

  ngOnInit(): void {
    this.invenotryService.get({ type: "S" }).subscribe(
      (data) => {
        this.result = data["body"];
        this.isInitializing = false;

        if (!this.result.length) {
          this.globalError = "No se encontraron resultados";
          this.showTable = false;
          return;
        }

        this.showTable = true;
        this.total = this.currencyPipe.transform(this.result.reduce((a, b) => a + b.price, 0), 'USD', 'symbol-narrow', '1.2-2');
      },
      (error) => {
        this.globalError =
          "Se ha presentado un error, por favor vuelva a intentarlo";
        this.isInitializing = false;
      }
    );
  }

}
