import { Component, OnInit } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { AuthenticationService } from "@app/auth/services/authentication.service";
import { Router, ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
})
export class LoginComponent implements OnInit {
  loginFormGroup = this.formBuilder.group({
    username: ["", [Validators.required]],
    password: ["", [Validators.required, Validators.minLength(8)]],
  });

  isLoading = false;
  globalError: string;

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthenticationService,
    private router: Router,
  ) {}  

  ngOnInit() {}

  login() {
    if (this.loginFormGroup.invalid) {
      return;
    }

    this.isLoading = true;
    const { username, password } = this.loginFormGroup.value;

    this.authService
      .login(username, password)
      .then(() => {
        let profile = this.authService.cognitoUser["profile"];
        let link = profile === 'PROF6' ? ["dashboard"] : ["dashboard", "notifications"];
        this.router.navigate(link);
      })
      .catch((error) => {
        console.log("error", error);
        switch (error.code) {
          case "ResourceNotFoundException":
          case "UserNotFoundException":
          case "NotAuthorizedException":
            this.globalError = "Usuario o contraseña incorrectos";
            break;
          default:
            this.globalError = "¡Ups! Algo salió mal";
            break;
        }
      })
      .finally(() => {
        this.isLoading = false;
      });
  }
}
