import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { AuthenticationService } from '@app/auth/services/authentication.service';
import { FormatsService } from '@app/remission/services/formats.service';
import { RemissionService } from '@app/remission/services/remission.service';
import { ShareService } from '@app/shared/services/share.service';

@Component({
  selector: 'app-remission-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
  isUpdateOperation = false;
  isInitializing = true;
  isUploading = false;
  globalError: string;
  uploadError: string;
  showTable = false;
  enterprises;
  result;
  PK;
  remissionNumber;

  tableNames = {
    PK: "Consecutivo",
    enterpriseName: "Empresa",
    outDate: "Fecha|date",
    edit: "Editar",
    print: "Imprimir",
  };

  formControl = this.formBuilder.group({
    id: ["", []],
    enterprise: ["", []],
    startDate: ["", []],
    finishDate: ["", []],
  });

  constructor(
    private formBuilder: FormBuilder,
    private remissionService: RemissionService,
    private formatsService: FormatsService,
    private shareService: ShareService,
    private authService: AuthenticationService,
  ) {
    this.remissionService.getEnterprises({ all: "ALL" }).subscribe(
      (data) => {
        this.enterprises = data["body"];
        this.isInitializing = false;
      },
      (error) => {
        this.globalError =
          "Se ha presentado un error, por favor vuelva a intentarlo";
        this.isInitializing = false;
      }
    );

    this.formControl.get("id").valueChanges.subscribe((value) => {
      if (value) {
        this.formControl.get("enterprise").clearValidators();
        this.formControl.get("enterprise").reset();
        this.formControl.get("enterprise").disable({ emitEvent: false });
        this.formControl.get("enterprise").updateValueAndValidity({ emitEvent: false });

        this.formControl.get("startDate").clearValidators();
        this.formControl.get("startDate").reset();
        this.formControl.get("startDate").disable({ emitEvent: false });
        this.formControl.get("startDate").updateValueAndValidity({ emitEvent: false });

        this.formControl.get("finishDate").clearValidators();
        this.formControl.get("finishDate").reset();
        this.formControl.get("finishDate").disable({ emitEvent: false });
        this.formControl.get("finishDate").updateValueAndValidity({ emitEvent: false });

      } else {
        this.formControl.get("enterprise").enable({ emitEvent: false });
        this.formControl.get("enterprise").updateValueAndValidity({ emitEvent: false });

        this.formControl.get("startDate").enable({ emitEvent: false });
        this.formControl.get("startDate").updateValueAndValidity({ emitEvent: false });

        this.formControl.get("finishDate").enable({ emitEvent: false });
        this.formControl.get("finishDate").updateValueAndValidity({ emitEvent: false });
      }
    });
   }

  ngOnInit(): void {
    if (!this.authService.checkActionsPermission("REMI", ["UPDATE"])) {
      delete this.tableNames.edit;
    }

    if (!this.authService.checkActionsPermission("REMI", ["PRINT"])) {
      delete this.tableNames.print;
    }
  }

  action(event) {
    if (event.action == "PRINT") {
      const PK = event.element.PK;
      this.remissionNumber = PK.substr(4);
      this.remissionService.get({ PK }).subscribe(
        (data) => {
          const REMISSION = data["body"].filter(item => item.SK === PK)[0];
          REMISSION.items = data["body"].filter(item => item.SK !== PK);
          REMISSION.items.sort((a, b) => a.SK.slice(4) - b.SK.slice(4));
          console.log(REMISSION);
          const content = this.formatsService.generatePrint(REMISSION);
          this.shareService.export("PDF", content, null, {title: "Remisión No. " + this.remissionNumber});
        },
        (error) => {}
      );
    } else if (event.action == "UPDATE") {
      this.isUpdateOperation = true;
      this.PK = event.element.PK;
    }
  }

  send() {
    if((this.formControl.get("startDate").value && !this.formControl.get("finishDate").value) ||
    (!this.formControl.get("startDate").value && this.formControl.get("finishDate").value)) {
      this.uploadError = "Debe ingresar fecha inicial y fecha final";
      return;
    }
    else if (!this.formControl.get("startDate").value && !this.formControl.get("finishDate").value && 
    !this.formControl.get("id").value && !this.formControl.get("enterprise").value) {
      this.uploadError = "Debe ingresar al menos un criterio de búsqueda";
      return;
    }
    else {
      this.isUploading = true;
      this.globalError = "";
      this.uploadError = "";
      this.formControl.disable({ emitEvent: false });
      let values = this.formControl.getRawValue();
      const id = values.id ?  values.id: undefined;
      const enterprise = values.enterprise ? values.enterprise: undefined;
      const startDate = values.startDate ? values.startDate: undefined;
      const finishDate = values.finishDate ? values.finishDate: undefined;
      this.remissionService.get({typeSearch: "S",id, enterprise, startDate, finishDate}).subscribe(
        (data) => {
          if (this.formControl.get("id").value) {
            this.formControl.get("id").enable({ emitEvent: false });
            this.formControl.get("enterprise").disable({ emitEvent: false });
            this.formControl.get("startDate").disable({ emitEvent: false });
            this.formControl.get("finishDate").disable({ emitEvent: false });
          }
          else { this.formControl.enable({ emitEvent: false }); }
          this.result = data["body"];
          this.isUploading = false;
          if (!this.result.length) {
            this.uploadError = "No se encontraron resultados";
            this.showTable = false;
            return;
          }            
          this.showTable = true;
        },
        (error) => {
          if (this.formControl.get("id").value) {
            this.formControl.get("id").enable({ emitEvent: false });
            this.formControl.get("enterprise").disable({ emitEvent: false });
            this.formControl.get("startDate").disable({ emitEvent: false });
            this.formControl.get("finishDate").disable({ emitEvent: false });
          }
          else { this.formControl.enable({ emitEvent: false }); }
          this.uploadError = "Se ha presentado un error, vuelva a intentarlo";
          this.isUploading = false;
        }
      );
    }
  }

  close() {
    this.isUpdateOperation = false;
  }
}
