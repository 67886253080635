import { Component, AfterViewInit } from '@angular/core';
import { AuthenticationService } from '@app/auth/services/authentication.service';

@Component({
  selector: 'app-list-material-request',
  templateUrl: './list-material-request.component.html',
  styleUrls: ['./list-material-request.component.scss']
})
export class ListMaterialRequestComponent implements AfterViewInit {
isUpdateOperation = false;
  constructor(public authService: AuthenticationService) {}

  ngAfterViewInit() {}
}
