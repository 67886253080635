import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { OrdersService } from '@app/order/services/orders.service';
import { Auth } from 'aws-amplify';
import { forkJoin } from 'rxjs';
import { WorkService } from '@app/work/services/work.service';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class FormComponent implements OnInit {
  @Input()
  isUpdateOperation;
  @Input()
  PK;
  @Output()
  action = new EventEmitter();
  isInitializing = true;
  isUploading = false;
  globalError: string;
  uploadError: string;
  successMessage: String;

  enterprises;
  contacts = [];
  users;

  selectedIVA = "1";

  itemValue = [];
  totalItem = [];
  itemIVA = [];
  subtotal = 0;
  totalIVA = 0;
  retefuente = 0;
  reteica = 0;
  discount = 0;
  total = 0;
  valuesChange;

  constructor(
    private FormBuilder: FormBuilder,
    private ordersService: OrdersService,
    private workService: WorkService,
  ) { }

  public formControl: FormGroup;

  ngOnInit(): void {
    this.formControl = this.FormBuilder.group({
      enterprise: [
        "",
        [Validators.required, Validators.pattern("ENTR[0-9]+$"), Validators.maxLength(11)],
      ],
      contact: [
        "",
        [Validators.required, Validators.pattern("^CONV[0-9]+$"), Validators.maxLength(11)],
      ],
      requester: ["", [Validators.required, Validators.pattern("^PERR[0-9]+$"), Validators.maxLength(11)]],
      deliveryAddress: ["", [Validators.required, Validators.maxLength(500)]],
      deliveryCity: ["", [Validators.required, Validators.maxLength(500)]],
      deliveryReceive: ["", [Validators.required, Validators.maxLength(500)]],
      deliveryCellphone: ["", [Validators.required, Validators.maxLength(500)]],
      deliveryTime: ["", [Validators.required, Validators.maxLength(500)]],
      paymentForm: ["", [Validators.required, Validators.maxLength(500)]],
      observations: ["", [Validators.maxLength(5000)]],
      discount: ["", [Validators.min(0)]],
      retefuente: ["", [Validators.min(0), Validators.max(100)]],
      reteica: ["", [Validators.min(0), Validators.max(100)]],
      items: this.FormBuilder.array([]),
    });

    this.formControl.get("enterprise").valueChanges.subscribe((value) => {
      if (value) {
        this.ordersService
          .getEnterprises({ contacts: value })
          .subscribe((data) => {
            this.contacts = data["body"];
          });
      } else {
        this.contacts = [];
      }
    });

    Auth.currentSession().then((credentials) => {
      let enterpriseRq = this.ordersService.getEnterprises({ type: "Proveedor" });
      let userRq = this.workService.getUsers({ type: "L", status: "ACTIVE" });
      if (this.isUpdateOperation) {
        let orderRq = this.ordersService.get({ PK: this.PK });
        forkJoin([orderRq, enterpriseRq, userRq]).subscribe(
          ([orderRs, enterpriseRs, userRs]) => {
            this.enterprises = enterpriseRs["body"];
            this.users = userRs["body"];
            const ORDER = orderRs["body"].filter(
              (item) => item.SK === this.PK
            )[0];
            this.discount = ORDER.discount;
            ORDER.items = orderRs["body"].filter((item) => item.SK !== this.PK);
            ORDER.items.sort((a, b) => a.SK.slice(4) - b.SK.slice(4));
            for (let i = 0; i < ORDER.items.length; i++) {
              this.itemValue[i] = ORDER.items[i].value;
              ORDER.items[i].IVA = String(ORDER.items[i].IVA);
              let group = this.initItemRows();
              group.addControl("SK", new FormControl("", Validators.required));
              this.formArr.push(group);
            }
            this.formControl.patchValue(ORDER);
            this.isInitializing = false;
          },
          (error) => {
            this.globalError =
              "Se ha presentado un error, por favor vuelva a intentarlo";
            this.isInitializing = false;
          }
        );
      } else {
        this.formArr.push(this.initItemRows());
        forkJoin([
          enterpriseRq, userRq
        ]).subscribe(
          ([
            enterpriseRs, userRs
          ]) => {
            this.enterprises = enterpriseRs["body"];
            this.users = userRs["body"];
            this.isInitializing = false;
          },
          (error) => {
            this.globalError =
              "Se ha presentado un error, por favor vuelva a intentarlo";
            this.isInitializing = false;
          }
        );
      }
    });

    this.valuesChange = this.formControl.controls["items"].valueChanges;
    this.valuesChange.subscribe((items) => this.updateTotalUnitPrice(items));
  }

  get formArr() {
    return this.formControl.get("items") as FormArray;
  }

  initItemRows() {
    return this.FormBuilder.group({
      item: ["", [Validators.required, Validators.maxLength(500)]],
      quantity: ["", [Validators.required, Validators.min(0.01)]],
      value: ["", [Validators.required, Validators.min(1)]],
      IVA: ["", [Validators.required, Validators.min(0), Validators.max(100)]],
    });
  }

  addNewRow(event) {
    event.stopPropagation();
    event.preventDefault();
    this.formArr.push(this.initItemRows());
    this.changeIVA(this.formArr.length - 1);
  }

  deleteRow(index: number) {
    this.itemValue.splice(index, 1);
    if (this.formArr.length > 1) {
      this.formArr.removeAt(index);
    }
  }

  allItemsWithIVA(value) {
    this.selectedIVA = value;
    for (let i = 0; i < this.formArr.length; i++) {
      this.changeIVA(i);
    }
  }

  changeIVA(indice) {
    if (this.selectedIVA == "0" || this.selectedIVA == "5" || this.selectedIVA == "19") {
      this.formArr
        .at(indice)
        .get("IVA")
        .setValue(this.selectedIVA);
    } else this.formArr
      .at(indice)
      .get("IVA").reset("")   
  }

  updateTotalUnitPrice(items: any) {
    this.subtotal = 0;
    this.totalIVA = 0;
    this.total = 0;
    for (let i in items) {
      if (items[i].value && items[i].quantity) {
        this.totalItem[i] = items[i].quantity * items[i].value;
        this.subtotal += this.totalItem[i];
        if (items[i].IVA) {
          this.itemIVA[i] = (this.totalItem[i] * Number(items[i].IVA)) / 100;
        } else this.itemIVA[i] = 0;
        this.totalIVA += this.itemIVA[i];
      }
    }
    this.updateAllValues();
  }

  updateAllValues() {
    this.retefuente = (this.subtotal * this.formControl.get("retefuente").value) / 100;
    this.reteica = (this.subtotal * this.formControl.get("reteica").value) / 1000; 
    this.total = this.subtotal + this.totalIVA - this.retefuente - this.reteica - this.discount;   
}

  send() {
    let data = this.formControl.getRawValue();
    for (let i = 0; i < this.formArr.length; i++) {
      if (data.items[i].IVA) { data.items[i].IVA = Number(data.items[i].IVA) }
      else { delete data.items[i].IVA }
    }
    if (!data.observations) delete data.observations;
    if (!data.discount) data.discount = 0;
    if (!data.retefuente) data.retefuente = 0;
    if (!data.reteica) data.reteica = 0;
    this.isUploading = true;
    this.globalError = "";
    this.uploadError = "";
    this.successMessage = "";
    this.formControl.disable({ emitEvent: false });
    if (this.isUpdateOperation) {
      data.PK = this.PK;
      this.ordersService.updateOrders(data).subscribe(
        (data) => {
          this.formControl.enable({ emitEvent: false });
          this.successMessage = "Operación realizada con exito";
        },
        (error) => {
          this.formControl.enable({ emitEvent: false });
          this.uploadError = error.error.status.message;
          this.isUploading = false;
        }
      );
    } else {
      this.ordersService.saveOrders(data).subscribe(
        (data) => {
            this.successMessage = "Operación realizada con exito";
            this.isUploading = false;
            this.formControl.enable({ emitEvent: false });
            this.formArr.clear();
            this.formControl.reset();
            this.formArr.updateValueAndValidity({ emitEvent: false });
            this.itemValue = [];
            this.totalItem = [];
            this.itemIVA = [];
            this.subtotal = 0;
            this.totalIVA = 0;
            this.retefuente = 0;
            this.reteica = 0;
            this.discount = 0;
            this.total = 0;
        },
        (error) => {
          this.formControl.enable({ emitEvent: false });
          this.uploadError = "error.error.status.message";
          this.isUploading = false;
        }
      );
    }
  }

  getButtonText() {
    return this.isUploading
      ? this.isUpdateOperation
        ? "Actualizando orden de compra..."
        : "Creando orden de compra..."
      : this.isUpdateOperation
        ? "Actualizar orden de compra"
        : "Crear orden de compra";
  }

  close() {
    this.action.emit();
  }
}
