<div *ngIf="globalError" class="alert alert-danger text-center">
  {{ globalError }}
</div>

<div
  *ngIf="isInitializing && !globalError"
  class="d-flex justify-content-center flex-column align-items-center mt-5"
>
  <h4 class="my-4">Cargando todo...</h4>
  <mat-spinner></mat-spinner>
</div>

<div *ngIf="!isInitializing && !globalError" class="container-fluid">
  <ng-container *ngIf="isUpdateOperation">
    <button
      class="d-block mt-2"
      mat-raised-button
      color="primary"
      (click)="close()"
    >
      <mat-icon>keyboard_backspace</mat-icon> Regresar
    </button>
  </ng-container>
  <form [formGroup]="formControl" autocomplete="off">
    <br />
    <p>Los campos con asterisco &#40;&#42;&#41; son obligatorios</p>
    <div class="row">
      <div class="col-md-4">
        <mat-form-field
          appearance="outline"
          class="d-block w-100 mb-2"
          (keydown.enter)="$event.preventDefault()"
        >
          <mat-label>Fecha de permiso *</mat-label>
          <input
            readonly
            formControlName="permissionDate"
            matInput
            [matDatepicker]="picker"
          />
          <mat-datepicker-toggle
            (click)="picker.open()"
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-datepicker touchUi #picker></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="col-md-4">
        <mat-form-field
          appearance="outline"
          class="d-block w-100 mb-2"
          (keydown.enter)="$event.preventDefault()"
        >
          <mat-label>Hora inicial *</mat-label>
          <input formControlName="startTime" type="text" matInput />
        </mat-form-field>
      </div>
      <div class="col-md-4">
        <mat-form-field
          appearance="outline"
          class="d-block w-100 mb-2"
          (keydown.enter)="$event.preventDefault()"
        >
          <mat-label>Hora final *</mat-label>
          <input formControlName="finishTime" type="text" matInput />
        </mat-form-field>
      </div>
      <div class="col-md-12">
        <mat-form-field appearance="outline" class="d-block w-100 mb-2">
          <mat-label>Motivo *</mat-label>
          <textarea
            formControlName="motive"
            type="text"
            matInput
            rows="7"
          ></textarea>
        </mat-form-field>
      </div>      
      <div class="col-md-6">
        <mat-form-field
          appearance="outline"
          class="d-block w-100 mb-2"
          (keydown.enter)="$event.preventDefault()"
        >
          <mat-label>Entrega soporte *</mat-label>
          <mat-select formControlName="support">
            <mat-option>Elige una</mat-option>
            <mat-option value="Si">Sí</mat-option>
            <mat-option value="No">No</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-md-6">
        <mat-form-field
          appearance="outline"
          class="d-block w-100 mb-2"
          (keydown.enter)="$event.preventDefault()"
        >
          <mat-label>Aprobado por jefe *</mat-label>
          <mat-select formControlName="approvedBoss">
            <mat-option>Elige una</mat-option>
            <mat-option value="Si">Sí</mat-option>
            <mat-option value="No">No</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-12 d-block w-100 mb-2">
        <input
          type="file"
          accept="image/*"
          class="file-input"
          (change)="onFileSelected($event)"
          #fileUpload
        />
        <div class="file-upload">
          <mat-label
            >Soporte: {{ fileName || "Aún no se ha subido ningún archivo." }}
          </mat-label>
          <button
            mat-mini-fab
            color="primary"
            class="upload-btn"
            (click)="$event.preventDefault()"
            (click)="fileUpload.click()"
          >
            <mat-icon>attach_file</mat-icon>
          </button>
          <ng-container *ngIf="url">
            <a [href]="url" target="_blank"> Descargar archivo adjunto</a>
          </ng-container>
          <p class="text-danger">{{ fileAlert || ""}}</p>
        </div>
      </div>
    </div>

    <div *ngIf="uploadError" class="alert alert-danger text-center">
      {{ uploadError }}
    </div>

    <div *ngIf="successMessage" class="alert alert-success text-center">
      {{ successMessage }}
    </div>

    <div *ngIf="attachedError" class="alert alert-warning text-center">
      {{ attachedError }}
    </div>

    <button
      class="d-block w-100 mt-2"
      mat-raised-button
      color="primary"
      [disabled]="formControl.invalid || isUploading"
      (click)="$event.preventDefault()"
      (click)="send()"
    >
      {{ getButtonText() }}
    </button>
  </form>
</div>
<br />
