import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CoreComponent } from "./core.component";
import { CoreRoutingModule } from "./core-routing.module";
import { AuthModule } from "@app/auth/auth.module";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { AuthenticationTokenGuard } from "@app/auth/guards/authentication-token.guard";
import { SharedModule } from "@app/shared/shared.module";
import { DashboardOutletComponent } from "./dashboard-outlet/dashboard-outlet.component";
import { UsersModule } from "@app/users/users.module";
import { EnterprisesModule } from "@app/enterprises/enterprises.module";
import { QuotationsModule } from "@app/quotations/quotations.module";
import { CommercialModule } from "@app/commercial/commercial.module";
import { WorkModule } from "@app/work/work.module";
import { RemissionModule } from "@app/remission/remission.module";
import { NotificationsComponent } from './notifications/notifications.component';
import { ListsModule } from "@app/lists/lists.module";
import { OrderModule } from "@app/order/order.module";
import { WarrantiesModule } from "@app/warranties/warranties.module";
import { TravelExpensesModule } from "@app/travel-expenses/travel-expenses.module";
import { DocumentsModule } from "@app/documents/documents.module";
import { ResumeModule } from "@app/resume/resume.module";
import { PermissionsModule } from "@app/permissions/permissions.module";
import { InventoryModule } from "@app/inventory/inventory.module";
import { TimeModule } from "@app/time/time.module";
import { MaterialRequestModule } from "@app/material-request/material-request.module";

@NgModule({
  declarations: [CoreComponent, DashboardComponent, DashboardOutletComponent, NotificationsComponent],
  imports: [
    CommonModule,
    CoreRoutingModule,
    AuthModule,
    BrowserAnimationsModule,
    SharedModule,
    UsersModule,
    EnterprisesModule,
    QuotationsModule,
    CommercialModule,
    WorkModule,
    RemissionModule,
    ListsModule,
    OrderModule,
    WarrantiesModule,
    TravelExpensesModule,
    DocumentsModule,
    ResumeModule,
    PermissionsModule,
    InventoryModule,
    TimeModule,
    MaterialRequestModule
  ],
  exports: [CoreComponent],
  bootstrap: [CoreComponent],
  providers: [AuthenticationTokenGuard],
})
export class CoreModule {}
