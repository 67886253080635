<div class="container-fluid">
  <mat-tab-group mat-stretch-tabs>
    <mat-tab
      *ngIf="authService.checkActionsPermission('ENTR', ['CREATE'])"
      label="Crear"
      ><ng-template matTabContent
        ><app-enterprises-form
          [isUpdateOperation]="isUpdateOperation"
          PK="null"
        ></app-enterprises-form></ng-template
    ></mat-tab>
    <mat-tab
      *ngIf="authService.checkActionsPermission('ENTR', ['UPDATE', 'PRINT'])"
      label="Buscar"
      ><ng-template matTabContent
        ><app-enterprises-search></app-enterprises-search></ng-template
    ></mat-tab>
    <mat-tab
      *ngIf="authService.checkActionsPermission('ENTR', ['REPORT'])"
      label="Informe"
      ><ng-template matTabContent
        ><app-enterprises-report></app-enterprises-report></ng-template
    ></mat-tab>
  </mat-tab-group>
</div>
