import { AfterViewInit, Component, OnInit } from '@angular/core';
import { AuthenticationService } from '@app/auth/services/authentication.service';

@Component({
  selector: 'app-list-commercial',
  templateUrl: './list-commercial.component.html',
  styleUrls: ['./list-commercial.component.scss']
})
export class ListCommercialComponent implements AfterViewInit {
  isUpdateOperation = false;

  constructor(public authService: AuthenticationService) { }

  ngAfterViewInit() {}

}
