import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { AuthenticationService } from '@app/auth/services/authentication.service';
import { FormatsService } from '@app/resume/services/formats.service';
import { ResumeService } from '@app/resume/services/resume.service';
import { ShareService } from '@app/shared/services/share.service';
import { Auth } from 'aws-amplify';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
  isUpdateOperation = false;
  isInitializing = true;
  isUploading = false;
  globalError: string;
  uploadError: string;
  successMessage: String;
  showTable = false;
  result;
  PK;
  enterprises;
  marks;
  //models;
  typeEquipments;

  tableNames = { 
    PK: "Consecutivo", 
    nameEquipment: "Nombre del equipo",  
    enterpriseName: "Empresa",
    typeEquipmentName: "Tipo de equipo",
    markName: "Marca",
    model: "Modelo",
    edit: "Editar",
    print: "Imprimir",
  };

  formControl = this.formBuilder.group({
    enterprise: ["", []],
    typeEquipment: ["", []],
    mark: ["", []],
    //model: ["", []]
  });

  constructor(
    private formBuilder: FormBuilder,
    private resumesService: ResumeService,
    private shareService: ShareService,
    private authService: AuthenticationService,
    private formatsService: FormatsService,
  ) { }

  profile = this.authService.cognitoUser["profile"];

  ngOnInit(): void {
    if (!this.authService.checkActionsPermission("RESU", ["UPDATE"])) {
      delete this.tableNames.edit;
    }

    if (!this.authService.checkActionsPermission("RESU", ["PRINT"])) {
      delete this.tableNames.print;
    }

    Auth.currentSession().then((credentials) => {
      let enterprisesRq = this.resumesService.getEnterprises({ all: "ALL" });
      let marksRq = this.shareService.getList("MARK");
      //let modelsRq = this.shareService.getList("MODE");
      let typeEquipmentsRq = this.shareService.getList("TYEQ");
      forkJoin([enterprisesRq, marksRq, typeEquipmentsRq]).subscribe(
        ([enterprisesRs, marksRs, typeEquipmentsRs]) => {
          this.enterprises = enterprisesRs["body"];
          this.marks = marksRs["body"];
          //this.models = modelsRs["body"];
          this.typeEquipments = typeEquipmentsRs["body"];
          this.isInitializing = false;
        },
        (error) => {
          this.globalError =
            "Se ha presentado un error, por favor vuelva a intentarlo";
          this.isInitializing = false;
        }
      );
    });
  }

  action(event) {
    if (event.action == "UPDATE") {
      this.isUpdateOperation = true;   
      this.PK = event.element.PK;
    } else if (event.action == "PRINT") {
      const PK = event.element.PK;
      let images;
      this.resumesService.get({ PK }).subscribe(
        (data) => {
          const RESUME = data["body"].filter(
            (item) => item.entity === "RESU"
          )[0];
          RESUME.items = data["body"].filter(
            (item) => item.entity === "RESU|ITRE"
          );          
          RESUME.equipmentsIntervened = data["body"].filter(
            (item) => item.entity === "RESU|REIN"
          );

          RESUME.partsIntervened = data["body"].filter(
            (item) => item.entity === "RESU|REIP"
          );

          if(this.profile === 'PROF6') RESUME.equipments = [];
          else RESUME.equipments = data["body"].filter(
            (item) => item.entity === "RESU|ITPR"
          );

          images = {'image1': data["body"][0].urlAtt || ""}
          const content = this.formatsService.generatePrint(RESUME);
          this.shareService.export("PDF", content, null, {
            title: "Hoja de vida equipo",
            images,
          });
        },
        (error) => {}
      );
    }
  }

  send() {
    if (
      !this.formControl.get("enterprise").value &&
      !this.formControl.get("typeEquipment").value &&
      !this.formControl.get("mark").value
    ) {
      this.uploadError = "Debe ingresar al menos un criterio de búsqueda";
      return;
    } else {
      this.isUploading = true;
      this.globalError = "";
      this.uploadError = "";
      this.formControl.disable({ emitEvent: false });
      let values = this.formControl.getRawValue();
      const enterprise = values.enterprise ? values.enterprise : undefined;
      const typeEquipment = values.typeEquipment ? values.typeEquipment : undefined;
      const mark = values.mark ? values.mark : undefined;
      //const model = values.model ? values.model : undefined;
      this.resumesService
        .get({
          type: "S",
          enterprise,
          typeEquipment,
          mark,
          //model,
        })
        .subscribe(
          (data) => {
            this.formControl.enable({ emitEvent: false });
            this.result = data["body"];
            this.isUploading = false;
            if (!this.result.length) {
              this.uploadError = "No se encontraron resultados";
              this.showTable = false;
              return;
            }
            this.showTable = true;
          },
          (error) => {
            this.formControl.enable({ emitEvent: false });
            this.uploadError = "Se ha presentado un error, vuelva a intentarlo";
            this.isUploading = false;
          }
        );
    }
  }

  close() {
    this.isUpdateOperation = false;
  }
}
