import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MaterialModule } from "@app/material/material.module";
import { HttpClientModule } from "@angular/common/http";
import { RouterModule } from "@angular/router";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { SaveListComponent } from "./components/save-list/save-list.component";
import { DataTableComponent } from "./components/data-table/data-table.component";
import { AlertComponent } from "./components/alert/alert.component";

@NgModule({
  declarations: [SaveListComponent, DataTableComponent, AlertComponent],
  imports: [CommonModule, MaterialModule, BrowserAnimationsModule, FormsModule],
  exports: [
    MaterialModule,
    HttpClientModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    DataTableComponent
  ],
})
export class SharedModule {}
