import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormComponent } from './pages/form/form.component';
import { ListWorkComponent } from './pages/list-work/list-work.component';
import { ReportComponent } from './pages/report/report.component';
import { SearchComponent } from './pages/search/search.component';
import { SharedModule } from '@app/shared/shared.module';
import { httpInterceptorProviders } from '@app/auth/interceptors';
import { NewReportComponent } from './pages/new-report/new-report.component';
import { Ng2SearchPipeModule } from 'ng2-search-filter';



@NgModule({
  declarations: [FormComponent, ListWorkComponent, ReportComponent, SearchComponent, NewReportComponent],
  imports: [CommonModule, SharedModule, Ng2SearchPipeModule],
  providers: [httpInterceptorProviders],
})
export class WorkModule { }
