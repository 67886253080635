import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  constructor(private http: HttpClient) { }

  get() {
    return this.http.get(
      `${environment.apiHost}/v1/notifications`,      
    );
  }
}
