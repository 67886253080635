<mat-sidenav-container class="h-100 d-flex flex-column">
  <mat-sidenav #dashboardDrawer mode="over" position="end">
    <div class="p-4 h-100 d-flex justify-content-between flex-column">
      <div class="d-flex flex-column flex-sm-row align-items-center w-100 border-bottom pb-4">
        <div
          class="bg-image-fixed rounded-circle shadow mb-1"
          style="
            background-image: url(./assets/images/logo/logo.png);
            width: 100px;
            height: 100px;
          "
        ></div>
        <div class="ml-4 d-flex flex-column justify-content-between">
          <div>
            <h5 class="mb-0">{{ name }}</h5>
            <p>
              <small class="text-muted">{{ email }}</small
              ><br />
              <small class="text-muted">{{ phone }}<br />KBT Solutions</small>
            </p>
          </div>
        </div>
      </div>
      <div
        class="w-100 pt-4 d-flex flex-column justify-content-between"
        style="flex: 1"
      >
        <div>
          <a  (click)="redirectComponent(['dashboard'])" (click)="dashboardDrawer.toggle()" class="links"><mat-icon>content_copy</mat-icon> Formatos</a>
          <a *ngIf="profile !== 'PROF6'" (click)="redirectComponent(['dashboard','notifications'])" (click)="dashboardDrawer.toggle()" class="links"><mat-icon>notifications_active</mat-icon> Notificaciones</a>
        </div>
        <button
          class="w-100"
          mat-raised-button
          (click)="logout()"
          style="background-color: #2ec1ac; color: white"
        >
          Cerrar sesión
        </button>
      </div>
    </div>
  </mat-sidenav>
  <mat-sidenav-content class="d-flex flex-column">
    <header
      class="navbar navbar-dark d-flex flex-column flex-sm-row"
      style="background: linear-gradient(45deg, #ffffff, #0f5249)"
    >
      <a [routerLink]="['./']" class="navbar-brand" style="padding: 0;">
        <img
          style="height: 50px; width: auto;"
          src="./assets/images/logo/logotipo.png"
          alt=""
          class="img-fluid"
        /><br />

      </a> 
      <h5 class="mb-0">{{authenticationService.title}}</h5>
      <button
        (click)="dashboardDrawer.toggle()"
        mat-icon-button
        aria-label="Mostrar menú"
        style="color: white"
      >
        <mat-icon>menu</mat-icon>
      </button>
    </header>

    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
