import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FormatsService {

  constructor() { }

  TRANSLATE = {
    enterpriseName: "Empresa",
    code: "Código",
    nameEquipment: "Nombre del equipo",
    typeEquipmentName: "Tipo de equipo",
    markName: "Marca",
    model: "Modelo",
    serial: "Serial",
    capacity: "Capacidad",
    consuption: "Consumo",
  }

  TRANSLATETWO = {
    enterpriseName: "Empresa",
    nameEquipment: "Nombre del equipo",
    date: "Fecha",
    status: "Estado",
    description: "Descripción",
  }

  generatePrint(data) {
    let document: Array<any> = [
      {
        lineHeight: 1,
        text: ".", color: "white",
      },            
    ];

    if(data.urlAtt) {      
      document.push(
        {
          layout: 'noBorders',
          table: {
            headerRows: 1,
            widths: ["100%"],
            body: [
              [{ text: "Foto del equipo", alignment: "center",  bold: true}],
              [{ image: `image1`, fit: [350, 350], alignment: "center"}],
            ],
          },
        },
        {
          lineHeight: 1,
          text: ".", color: "white",
        },  
      )
    }

    document.push(
      {
        table: {
          headerRows: 1,
          widths: ["50%", "50%"],
          body: [            
            [
              { text: [{text: `Empresa: `, bold: true}, {text: `${data.enterpriseName}`}]},
              { text: [{text: `Código: `, bold: true}, {text: `${data.code}`}]},          
            ],
            [
              { text: [{text: `Nombre del equipo: `, bold: true}, {text: `${data.nameEquipment}`}]},
              { text: [{text: `Tipo de equipo: `, bold: true}, {text: `${data.typeEquipmentName}`}]},              
            ],
            [
              { text: [{text: `Marca: `, bold: true}, {text: `${data.markName}`}]},
              { text: [{text: `Modelo: `, bold: true}, {text: `${data.model}`}]},              
            ],
            [
              { text: [{text: `Serial: `, bold: true}, {text: `${data.serial}`}]},
              { text: [{text: `Capacidad: `, bold: true}, {text: `${data.capacity}`}]},              
            ],
            [
              { text: [{text: `Combustible: `, bold: true}, {text: `${data.fuelName || ''}`}]},
              { text: [{text: `Presión de trabajo: `, bold: true}, {text: `${data.workPressure || ''}`}]},              
            ],
            [
              { text: [{text: `Presión de diseño: `, bold: true}, {text: `${data.designPressure || ''}`}]},
              { text: [{text: `Consumo: `, bold: true}, {text: `${data.consuption}`}]},              
            ],
            [
              { text: [{text: `Producción de vapor: `, bold: true}, {text: `${data.steamProduction || ''}`}]},
              { text: [{text: `Voltaje: `, bold: true}, {text: `${data.voltage || ''}`}]},              
            ],
          ],
        },
      },
    );

    if(data.aditionalDescription) {
      document.push(
        {
          lineHeight: 1,
          text: ".", color: "white",
        },
        {
          table: {
            headerRows: 1,
            widths: ["100%"],
            body: [ 
              [{ text: "Descripción adicional:", bold: true}],
              [{ text: `${data.aditionalDescription || ""}`, alignment:'justify' }],
            ],
          },
        },
      )
    }
    
    if(data.items.length > 0){
      document.push(
        {
          lineHeight: 1,
          text: ".", color: "white",
        },
        {
          table: {
            headerRows: 1,
            widths: ["40%", "30%", "30%"],
            body: [
              [{ text: "Características adicionales", alignment: "center",  bold: true, colSpan: 3}, {}, {}],
              [
                { text: `Nombre`, alignment: "center", bold: true },
                { text: `Valor`, alignment: "center", bold: true },
                { text: `Unidad de medida`, alignment: "center", bold: true },
              ],
              ...data.items.map(d => ([{text: d.name}, {text: d.value}, {text: d.measureUnit || ''}]))
            ],
          },
        },        
      )
    }

    if(data.equipments.length > 0){
      document.push(
        {
          lineHeight: 1,
          text: ".", color: "white",
        },
        {
          table: {
            headerRows: 1,
            widths: ["17%", "17%", "17%", "17%", "32%"],
            body: [
              [{ text: "Listado de partes", alignment: "center",  bold: true, colSpan: 5}, {}, {}, {}, {}],
              [
                { text: `Tipo equipo`, alignment: "center", bold: true },
                { text: `Marca`, alignment: "center", bold: true },
                { text: `Modelo`, alignment: "center", bold: true },
                { text: `Tipo documento`, alignment: "center", bold: true },
                { text: `Información adicional`, alignment: "center", bold: true },
              ],
              ...data.equipments.map(d => ([{text: d.typeEquipmentName}, {text: d.markName}, {text: d.modelName}, {text: d.typeDocumentName}, {text: d.aditionalInformation}]))
            ],
          },
        },        
      )
    }

    if(data.equipmentsIntervened.length > 0){
      document.push(
        {
          lineHeight: 1,
          text: ".", color: "white",
        },
        {
          table: {
            headerRows: 1,
            widths: ["100%"],
            body: [ 
              [{ text: "Intervenciones", alignment: "center", bold: true}],
            ],
          },
        },
      )
      
      for(let i = 0; i < data.equipmentsIntervened.length; i++) {
        let parts = data.partsIntervened.filter(element => element.relation6 === data.equipmentsIntervened[i].SK);
        
        document.push(
          {
            lineHeight: 1,
            text: ".", color: "white",
          },
          {
            table: {
              headerRows: 1,
              widths: ["50%", "50%"],
              body: [            
                [
                  { text: [{text: `Fecha: `, bold: true}, {text: `${data.equipmentsIntervened[i].date}`}]},
                  { text: [{text: `Confiabilidad: `, bold: true}, {text: `${data.equipmentsIntervened[i].reliability * 100}%`}]},          
                ],
                [
                  { text: [{text: `Descripción: `, bold: true}, {text: `${data.equipmentsIntervened[i].description}`}], colSpan: 2}              
                ],
              ],
            },
          }
        )

        if(parts.length > 0) {
          document.push(          
            {
              table: {
                headerRows: 1,
                widths: ["24%", "11%", "11%", "22%", "11%", "21%"],
                body: [
                  [
                    { text: `Parte`, alignment: "center", bold: true },
                    { text: `Fecha`, alignment: "center", bold: true },
                    { text: `Valor`, alignment: "center", bold: true },
                    { text: `Descripción`, alignment: "center", bold: true },
                    { text: `Fecha corrección`, alignment: "center", bold: true },
                    { text: `Descripción corrección`, alignment: "center", bold: true },
                  ],
                  ...parts.map(d => ([{text: `${d.typeEquipmentName} - ${d.markName} - ${d.modelName}`}, {text: d.date}, {text: `${d.value}%`, alignment: "center"}, {text: d.description}, {text: d.dateCorrection || ''}, {text: d.descriptionCorrection || ''}]))
                ],
              },
            },        
          ) 
        }                       
      }      
    }

    return document;
  }

  generateReport(data) {
    let j = 1;
    return [
      {
        table: {
          headerRows: 1,
          widths: ["13%", "8%", "13%", "10%", "8%", "9%", "9%", "9%", "9%", "12%"],
          body: [
            [             
            { text: 'Empresa', bold: true, alignment: "center" }, 
            { text: 'Código', bold: true, alignment: "center" },
            { text: 'Nombre del equipo', bold: true, alignment: "center" },
            { text: 'Tipo de equipo', bold: true, alignment: "center" }, 
            { text: 'Marca', bold: true, alignment: "center" }, 
            { text: 'Modelo', bold: true, alignment: "center" },
            { text: 'Serial', bold: true, alignment: "center" },
            { text: 'Capacidad', bold: true, alignment: "center" },
            { text: 'Consumo', bold: true, alignment: "center" },
            { text: 'Imagen', bold: true, alignment: "center" },
          ],
            ...data.map(d => {
              let index = j;
              j++
              return[
                d.enterpriseName,
                d.code,
                d.nameEquipment,
                d.typeEquipmentName, 
                d.markName, 
                d.model,
                d.serial || '',
                d.capacity,
                d.consuption || '', 
                { image: `image${index-1}`, fit: [90, 90], alignment: "center"}
              ]}),
          ],
        },
      },
    ];
  }

  generateReportEquipment(data) {
    return [
      {
        table: {
          headerRows: 1,
          widths: ["20%", "20%", "15%", "15%", "30%"],
          body: [
            [             
            { text: 'Empresa', bold: true, alignment: "center" },             
            { text: 'Nombre del equipo', bold: true, alignment: "center" },
            { text: 'Fecha', bold: true, alignment: "center" },
            { text: 'Estado', bold: true, alignment: "center" }, 
            { text: 'Descripción', bold: true, alignment: "center" }, 
          ],
            ...data.map(d => (
              [
                d.enterpriseName,
                d.nameEquipment,
                d.date, 
                d.status === 'needIntervention' ? 'Necesita intervención' : d.status === 'interventionUrgent' ? 'Intervención urgente' : d.status, 
                d.description,
              ])),
          ],
        },
      },
    ];
  }
}
