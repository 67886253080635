import { Component, OnInit } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { AuthenticationService } from "@app/auth/services/authentication.service";
import { FormatsService } from "@app/quotations/services/formats.service";
import { QuotationsService } from "@app/quotations/services/quotations.service";
import { AlertComponent } from "@app/shared/components/alert/alert.component";
import { ShareService } from "@app/shared/services/share.service";

@Component({
  selector: "app-quotations-search",
  templateUrl: "./search.component.html",
  styleUrls: ["./search.component.scss"],
})
export class SearchComponent implements OnInit {
  isUpdateOperation = false;
  isInitializing = true;
  isUploading = false;
  globalError: string;
  uploadError: string;
  successMessage: String;
  showTable = false;
  enterprises;
  result;
  PK;
  quoteNumber;
  tableNames = {
    PK: "Consecutivo",
    enterpriseName: "Empresa",
    date: "Fecha|date",
    subject: "Asunto",
    edit: "Editar",
    print: "Imprimir",
    sendQuotation: "Enviar Cotización",
    approve: "Enviada",
    reject: "Enviada",
  };

  formControl = this.formBuilder.group({
    consecutive: ["", []],
    enterprise: ["", []],
    status: ["", []],
    startDate: ["", []],
    finishDate: ["", []],
  });
  constructor(
    private formBuilder: FormBuilder,
    private quotationsService: QuotationsService,
    private formatsService: FormatsService,
    private shareService: ShareService,
    private authService: AuthenticationService,
    public dialog: MatDialog
  ) {
    this.quotationsService.getEnterprises({ all: "ALL" }).subscribe(
      (data) => {
        this.enterprises = data["body"];
        this.isInitializing = false;
      },
      (error) => {
        this.globalError =
          "Se ha presentado un error, por favor vuelva a intentarlo";
        this.isInitializing = false;
      }
    );

    this.formControl.get("consecutive").valueChanges.subscribe((value) => {
      if (value) {
        this.formControl.get("enterprise").clearValidators();
        this.formControl.get("enterprise").reset();
        this.formControl.get("enterprise").disable({ emitEvent: false });
        this.formControl
          .get("enterprise")
          .updateValueAndValidity({ emitEvent: false });

        this.formControl.get("startDate").clearValidators();
        this.formControl.get("startDate").reset();
        this.formControl.get("startDate").disable({ emitEvent: false });
        this.formControl
          .get("startDate")
          .updateValueAndValidity({ emitEvent: false });

        this.formControl.get("finishDate").clearValidators();
        this.formControl.get("finishDate").reset();
        this.formControl.get("finishDate").disable({ emitEvent: false });
        this.formControl
          .get("finishDate")
          .updateValueAndValidity({ emitEvent: false });
      } else {
        this.formControl.get("enterprise").enable({ emitEvent: false });
        this.formControl
          .get("enterprise")
          .updateValueAndValidity({ emitEvent: false });

        this.formControl.get("startDate").enable({ emitEvent: false });
        this.formControl
          .get("startDate")
          .updateValueAndValidity({ emitEvent: false });

        this.formControl.get("finishDate").enable({ emitEvent: false });
        this.formControl
          .get("finishDate")
          .updateValueAndValidity({ emitEvent: false });
      }
    });
  }

  ngOnInit(): void {
    if (!this.authService.checkActionsPermission("QUOT", ["UPDATE"])) {
      delete this.tableNames.edit;
    }

    if (!this.authService.checkActionsPermission("QUOT", ["PRINT"])) {
      delete this.tableNames.print;
    }

    if (!this.authService.checkActionsPermission("QUOT", ["SEND_FORM"])) {
      delete this.tableNames.sendQuotation;
    }

    // Se valida permisos para la acción de cerrar (Aprobar - Rechazar)
    if (!this.authService.checkActionsPermission("QUOT", ["CLOSE"])) {
      delete this.tableNames.approve;
      delete this.tableNames.reject;
    }
  }

  action(event) {
    if (event.action == "PRINT") {
      const PK = event.element.PK;
      const imgValidation = element => element.urlAtt;
      let images = {};
      let itemsWithImages = []
      this.quotationsService.get({ PK }).subscribe(
        (data) => {
          const QUOTATION = data["body"].filter((item) => item.SK === PK)[0];
          QUOTATION.items = data["body"].filter(
            (item) => item.SK !== PK && item.entity === "QUOT|ITQU"
          );
          QUOTATION.items.sort((a, b) => a.SK.slice(4) - b.SK.slice(4));
          for(let i = 0; i < QUOTATION.items.length; i++) {
            QUOTATION.items[i].index = i + 1;
          }
          QUOTATION.optionalItems = data["body"].filter(
            (item) => item.SK !== PK && item.entity === "QUOT|IQOP"
          );
          QUOTATION.optionalItems.sort((a, b) => a.SK.slice(4) - b.SK.slice(4));
          for(let i = 0; i < QUOTATION.optionalItems.length; i++) {
            QUOTATION.optionalItems[i].index = i + 1;
          }
          
          QUOTATION.itemsImgs = []
          QUOTATION.optionalItemsImgs = []
          if(QUOTATION.items.find(imgValidation) || QUOTATION.optionalItems.find(imgValidation)) {
            QUOTATION.itemsImgs = QUOTATION.items.filter(imgValidation)
            QUOTATION.optionalItemsImgs = QUOTATION.optionalItems.filter(imgValidation)
            itemsWithImages = QUOTATION.itemsImgs.concat(QUOTATION.optionalItemsImgs)
            for (let i = 0; i < itemsWithImages.length; i++) {
              images[`image${i}`] = itemsWithImages[i].urlAtt;
            }       
          }         
          const content = this.formatsService.generatePrint(QUOTATION);
          this.quoteNumber =
            QUOTATION.org === undefined
              ? PK.substr(4)
              : QUOTATION.org.substr(4) + "-" + QUOTATION.rev;
          this.shareService.export("PDF", content, null, {
            title: "Cotización No. " + this.quoteNumber,
            images,
            pageMargins: [50, 90, 50, 30],
          });
        },
        (error) => {}
      );
    } else if (event.action == "UPDATE") {
      this.isUpdateOperation = true;
      this.PK = event.element.PK;
    } else if (event.action == "REJECT" || event.action == "APPROVE") {
      let status = event.action == "REJECT" ? "Rechazada" : "Aprobada";
      this.PK = event.element.PK;
      let index = this.result.findIndex(element => element.PK === this.PK);
      this.quotationsService
        .closeQuotations({ PK: event.element.PK, status })
        .subscribe(
          (data) => {
            let label =
              `La cotización fue ${status} exitosamente`;
              let action = true;
            const dialogRef = this.dialog.open(AlertComponent, {
              width: "500px",
              data: {
                label,
                action
              },
            });
            this.result[index].status = "modificado";
          },
          (error) => {
            let label =
              `"Se ha presentado un error, vuelva a intentarlo"`;
              let action = true;
            const dialogRef = this.dialog.open(AlertComponent, {
              width: "500px",
              data: {
                label,
                action
              },
            });
          }
        );
    } else if (event.action == "SEND_FORM") {
      this.uploadError = "";
      this.successMessage ="";
      let label = "Enviar Informe";
      let sendEmail = true;
      let enterprisePK = event.element.enterprise;
      this.PK = event.element.PK;
      let index = this.result.findIndex(element => element.PK === this.PK);
      const dialogRef = this.dialog.open(AlertComponent, {
        width: "500px",
        data: {
          label,
          sendEmail,
          enterprisePK
        },
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          result.PK = event.element.PK;
          this.quotationsService.sendEmail(result).subscribe(
            (data) => {
              this.successMessage = "Operación realizada con exito";
            },
            (error) => {
              this.uploadError = "Se ha presentado un error, vuelva a intentarlo";
            }
          );
        }        
      });
    }
  }

  send() {
    if (
      (this.formControl.get("startDate").value &&
        !this.formControl.get("finishDate").value) ||
      (!this.formControl.get("startDate").value &&
        this.formControl.get("finishDate").value)
    ) {
      this.uploadError = "Debe ingresar fecha inicial y fecha final";
      return;
    } else if (
      !this.formControl.get("startDate").value &&
      !this.formControl.get("finishDate").value &&
      !this.formControl.get("consecutive").value &&
      !this.formControl.get("enterprise").value &&
      !this.formControl.get("status").value
    ) {
      this.uploadError = "Debe ingresar al menos un criterio de búsqueda";
      return;
    } else {
      this.isUploading = true;
      this.globalError = "";
      this.uploadError = "";
      this.formControl.disable({ emitEvent: false });
      let values = this.formControl.getRawValue();
      const consecutive = values.consecutive ? values.consecutive : undefined;
      const enterprise = values.enterprise ? values.enterprise : undefined;
      const startDate = values.startDate ? values.startDate : undefined;
      const finishDate = values.finishDate ? values.finishDate : undefined;
      this.quotationsService
        .get({ type: "S", consecutive, enterprise, startDate, finishDate })
        .subscribe(
          (data) => {
            if (this.formControl.get("consecutive").value) {
              this.formControl.get("consecutive").enable({ emitEvent: false });
              this.formControl.get("enterprise").disable({ emitEvent: false });
              this.formControl.get("startDate").disable({ emitEvent: false });
              this.formControl.get("finishDate").disable({ emitEvent: false });
            } else {
              this.formControl.enable({ emitEvent: false });
            }
            this.result = data["body"];
            this.isUploading = false;
            if (!this.result.length) {
              this.uploadError = "No se encontraron resultados";
              this.showTable = false;
              return;
            }

            this.showTable = true;
          },
          (error) => {
            if (this.formControl.get("consecutive").value) {
              this.formControl.get("consecutive").enable({ emitEvent: false });
              this.formControl.get("enterprise").disable({ emitEvent: false });
              this.formControl.get("startDate").disable({ emitEvent: false });
              this.formControl.get("finishDate").disable({ emitEvent: false });
            } else {
              this.formControl.enable({ emitEvent: false });
            }
            this.uploadError = "Se ha presentado un error, vuelva a intentarlo";
            this.isUploading = false;
          }
        );
    }
  }

  close() {
    this.isUpdateOperation = false;
  }
}
