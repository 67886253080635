import { Component, OnInit } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { AuthenticationService } from "@app/auth/services/authentication.service";
import { CommercialService } from "@app/commercial/services/commercial.service";
import { FormatsService } from "@app/commercial/services/formats.service";
import { AlertComponent } from "@app/shared/components/alert/alert.component";
import { ShareService } from "@app/shared/services/share.service";

@Component({
  selector: "app-commercial-search",
  templateUrl: "./search.component.html",
  styleUrls: ["./search.component.scss"],
})
export class SearchComponent implements OnInit {
  isUpdateOperation = false;
  isInitializing = true;
  isUploading = false;
  globalError: string;
  uploadError: string;
  showTable = false;
  enterprises;
  result;
  PK;
  commercialNumber;
  data;

  tableNames = {
    enterpriseName: "Empresa",
    commercialManagement: "Gestión comercial",
    title: "Título",
    edit: "Editar",
    print: "Imprimir",
    close: "Finalizar",
  };

  formControl = this.formBuilder.group({
    enterprise: ["", []],
    commercialManagement: ["", []],
    dateType: ["", []],
    startDate: ["", []],
    finishDate: ["", []],
  });

  constructor(
    private formBuilder: FormBuilder,
    private commercialService: CommercialService,
    private formatsService: FormatsService,
    private shareService: ShareService,
    private authService: AuthenticationService,
    public dialog: MatDialog
  ) {
    this.commercialService.getEnterprises({ all: "ALL" }).subscribe(
      (data) => {
        this.enterprises = data["body"];
        this.isInitializing = false;
      },
      (error) => {
        this.globalError =
          "Se ha presentado un error, por favor vuelva a intentarlo";
        this.isInitializing = false;
      }
    );
  }

  ngOnInit(): void {
    if (!this.authService.checkActionsPermission("CTRA", ["UPDATE"])) {
      delete this.tableNames.edit;
    }

    if (!this.authService.checkActionsPermission("CTRA", ["PRINT"])) {
      delete this.tableNames.print;
    }

    if (!this.authService.checkActionsPermission("CTRA", ["CLOSE"])) {
      delete this.tableNames.print;
    }
  }

  action(event) {
    if (event.action == "PRINT") {
      const PK = event.element.PK;
      this.commercialNumber = PK.substr(4);
      this.commercialService.get({ PK }).subscribe(
        (data) => {
          const COMMERCIAL = data["body"].filter((item) => item.SK === PK)[0];
          COMMERCIAL.items = data["body"].filter((item) => item.SK !== PK);
          console.log(COMMERCIAL);
          const content = this.formatsService.generatePrint(COMMERCIAL);
          this.shareService.export("PDF", content, null, {
            title: "Seguimiento comercial No. " + this.commercialNumber,
          });
        },
        (error) => {}
      );
    } else if (event.action == "UPDATE") {
      this.isUpdateOperation = true;
      this.PK = event.element.PK;
    } else if (event.action == "CLOSE") {
      let status = "Finalizado";
      this.commercialService
        .closeCommercial({ PK: event.element.PK })
        .subscribe(
          (data) => {
            let action = true;
            let label = `El seguimiento comercial fue ${status} exitosamente`;
            const dialogRef = this.dialog.open(AlertComponent, {
              width: "500px",
              data: {
                label,
                action
              },
            });
          },
          (error) => {
            let action = true;
            let label = `"Se ha presentado un error, vuelva a intentarlo"`;
            const dialogRef = this.dialog.open(AlertComponent, {
              width: "500px",
              data: {
                label,
                action
              },
            });
          }
        );
    }
  }

  send() {
    if (
      ((this.formControl.get("startDate").value ||
        this.formControl.get("finishDate").value) &&
        !this.formControl.get("dateType").value) ||
      ((!this.formControl.get("startDate").value ||
        !this.formControl.get("finishDate").value) &&
        this.formControl.get("dateType").value)
    ) {
      this.uploadError =
        "Debe ingresar tipo de fecha, fecha inicial y fecha final";
      return;
    } else if (
      !this.formControl.get("startDate").value &&
      !this.formControl.get("finishDate").value &&
      !this.formControl.get("enterprise").value &&
      !this.formControl.get("commercialManagement").value &&
      !this.formControl.get("dateType").value
    ) {
      this.uploadError = "Debe ingresar al menos un criterio de búsqueda";
      return;
    } else {
      this.isUploading = true;
      this.globalError = "";
      this.uploadError = "";
      this.formControl.disable({ emitEvent: false });
      let values = this.formControl.value;
      const enterprise = values.enterprise ? values.enterprise : undefined;
      const commercialManagement = values.commercialManagement
        ? values.commercialManagement
        : undefined;
      const dateType = values.dateType ? values.dateType : undefined;
      const startDate = values.startDate ? values.startDate : undefined;
      const finishDate = values.finishDate ? values.finishDate : undefined;
      this.commercialService
        .get({
          type: "S",
          enterprise,
          commercialManagement,
          dateType,
          startDate,
          finishDate,
        })
        .subscribe(
          (data) => {
            this.formControl.enable({ emitEvent: false });
            this.result = data["body"];
            this.isUploading = false;
            if (!this.result.length) {
              this.uploadError = "No se encontraron resultados";
              this.showTable = false;
              return;
            }
            this.showTable = true;
          },
          (error) => {
            this.formControl.enable({ emitEvent: false });
            this.uploadError = "Se ha presentado un error, vuelva a intentarlo";
            this.isUploading = false;
          }
        );
    }
  }

  close() {
    this.isUpdateOperation = false;
  }
}
