import { Component, OnInit } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { AuthenticationService } from "@app/auth/services/authentication.service";
import { AlertComponent } from "@app/shared/components/alert/alert.component";
import { ShareService } from "@app/shared/services/share.service";
import { FormatsService } from "@app/work/services/formats.service";
import { WorkService } from "@app/work/services/work.service";
import { forkJoin } from "rxjs";

@Component({
  selector: "app-work-search",
  templateUrl: "./search.component.html",
  styleUrls: ["./search.component.scss"],
})
export class SearchComponent implements OnInit {
  isUpdateOperation = false;
  isInitializing = true;
  isUploading = false;
  globalError: string;
  uploadError: string;
  successMessage: String;
  showTable = false;
  enterprises;
  result;
  PK;
  enterprise;
  workNumber;
  data;

  isNewReport = false;

  user = this.authService.cognitoUser;

  path;

  tableNames = {
    PK: "Consecutivo",
    enterpriseName: "Empresa",
    service: "Servicio",    
    startDate: "Fecha|date",
    status: "Estado",
    statusInform: "Estado Informe",
    editOrder: "Ejecución|Cancelada|Facturar|Finalizada",
    enterpriseOc: "OC cliente",
    crateInform: "Ejecución|Facturar",
    updateInform: this.user["username"],    
    approveInform: "Pendiente aprobación",
    sendForm: "Enviar Informe",
    print: "Imprimir",
    invoiced: "Facturar",
    closeOrder: "Facturada",
    interveneEquipment: "Equipo(s) a intervenir",
  };

  formControl = this.formBuilder.group({
    id: ["", []],
    enterprise: ["", []],
    status: ["", []],
    service: ["", []],
    startDate: ["", []],
    finishDate: ["", []],
  });

  constructor(
    private formBuilder: FormBuilder,
    private workService: WorkService,
    private formatsService: FormatsService,
    private shareService: ShareService,
    private authService: AuthenticationService,
    public dialog: MatDialog,
    private router: Router,
  ) {
    this.path = router.url == '/dashboard/informs' ? true : false;    

    this.formControl.get("id").valueChanges.subscribe((value) => {
      if (value) {
        this.formControl.get("enterprise").clearValidators();
        this.formControl.get("enterprise").reset();
        this.formControl.get("enterprise").disable({ emitEvent: false });
        this.formControl
          .get("enterprise")
          .updateValueAndValidity({ emitEvent: false });

        this.formControl.get("status").clearValidators();
        this.formControl.get("status").reset();
        this.formControl.get("status").disable({ emitEvent: false });
        this.formControl
          .get("status")
          .updateValueAndValidity({ emitEvent: false });

        this.formControl.get("service").clearValidators();
        this.formControl.get("service").reset();
        this.formControl.get("service").disable({ emitEvent: false });
        this.formControl
          .get("service")
          .updateValueAndValidity({ emitEvent: false });

        this.formControl.get("startDate").clearValidators();
        this.formControl.get("startDate").reset();
        this.formControl.get("startDate").disable({ emitEvent: false });
        this.formControl
          .get("startDate")
          .updateValueAndValidity({ emitEvent: false });

        this.formControl.get("finishDate").clearValidators();
        this.formControl.get("finishDate").reset();
        this.formControl.get("finishDate").disable({ emitEvent: false });
        this.formControl
          .get("finishDate")
          .updateValueAndValidity({ emitEvent: false });
      } else {
        this.formControl.get("enterprise").enable({ emitEvent: false });
        this.formControl
          .get("enterprise")
          .updateValueAndValidity({ emitEvent: false });

        this.formControl.get("status").enable({ emitEvent: false });
        this.formControl
          .get("status")
          .updateValueAndValidity({ emitEvent: false });

        this.formControl.get("service").enable({ emitEvent: false });
        this.formControl
          .get("service")
          .updateValueAndValidity({ emitEvent: false });

        this.formControl.get("startDate").enable({ emitEvent: false });
        this.formControl
          .get("startDate")
          .updateValueAndValidity({ emitEvent: false });

        this.formControl.get("finishDate").enable({ emitEvent: false });
        this.formControl
          .get("finishDate")
          .updateValueAndValidity({ emitEvent: false });
      }
    });
  }

  ngOnInit(): void {
    if (!this.authService.checkActionsPermission("WORD", ["UPDATE"])) {
      delete this.tableNames.editOrder;
    }

    if (!this.authService.checkActionsPermission("WORD", ["UPDATE_OC"]) || this.path) {
      delete this.tableNames.enterpriseOc;
    }

    if (!this.authService.checkActionsPermission("WORD", ["PRINT"])) {
      delete this.tableNames.print;
    }

    if (!this.authService.checkActionsPermission("WORD", ["CREATE_INFORM"])) {
      delete this.tableNames.crateInform;
    }

    if (!this.authService.checkActionsPermission("WORD", ["UPDATE_INFORM"])) {
      delete this.tableNames.updateInform;
    }

    if (!this.authService.checkActionsPermission("WORD", ["SEND_FORM"])) {
      delete this.tableNames.sendForm;
    }

    if (!this.authService.checkActionsPermission("WORD", ["INVOICED"])) {
      delete this.tableNames.invoiced;
    }

    if (!this.authService.checkActionsPermission("WORD", ["CLOSE"])) {
      delete this.tableNames.closeOrder;
    }

    if(this.path) {
      delete this.tableNames.status;
      delete this.tableNames.editOrder;
      delete this.tableNames.crateInform;
      delete this.tableNames.invoiced;
      delete this.tableNames.closeOrder;
      delete this.tableNames.interveneEquipment;
    } else {
      delete this.tableNames.statusInform;
    }

    let workRq = this.workService.getInform({type: "S", enterprise: this.user["custom:id"]})
    let enterprisesRq = this.workService.getEnterprises({ all: "ALL" })
    if(this.user["profile"] === 'PROF6' && this.path) {
      forkJoin([workRq, enterprisesRq]).subscribe(
        ([worksq, enterprisesRs]) => {
          this.result = worksq["body"].sort((a, b) => {
            let date1 = a.creationDate;
            let date2 = b.creationDate;
            return date1 > date2 ? -1 : date1 < date2 ? 1 : 0;
          }).slice(0, 10);
          this.enterprises = enterprisesRs["body"];
          if (this.result.length > 0) this.showTable = true;          
          this.isInitializing = false;
        },
        (error) => {
          this.globalError =
            "Se ha presentado un error, por favor vuelva a intentarlo";
          this.isInitializing = false;
        }
      );
    } else {
      forkJoin([enterprisesRq]).subscribe(
        ([enterprisesRs]) => {
          this.enterprises = enterprisesRs["body"];
          this.isInitializing = false;
        },
        (error) => {
          this.globalError =
            "Se ha presentado un error, por favor vuelva a intentarlo";
          this.isInitializing = false;
        }
      );
    }
  }

  action(event) {
    if (event.action == "PRINT") {
      let images;
      const PK = event.element.PK;
      this.workNumber = PK.substr(4);
      this.workService.get({ PK }).subscribe(
        (data) => {
          let content;
          if (event.element.PK.includes("WORD")) {
            const WORK = data["body"].filter((item) => item.SK === PK)[0];
            WORK.items = data["body"].filter((item) => item.SK !== PK);
            content = this.formatsService.generatePrint(WORK);
          } else {
            const WORK = data["body"].filter(
              (item) => item.entity === "WOIN"
            )[0];
            WORK.items = data["body"].filter(
              (item) => item.entity === "WOIN|ITIO"
            );
            WORK.equipments = data["body"].filter(
              (item) => item.entity === "WOIN|ITIN"
            );
            WORK.items.sort((a, b) => a.SK.slice(4) - b.SK.slice(4));
            content = this.formatsService.generateInform(WORK);
            this.workNumber = WORK.org ? "Orden de trabajo No. " + WORK.org.substr(4) + "-" + WORK.rev : "Informe de servicio No. " + this.workNumber + "- IND";
            images = {};
            for (let i = 0; i < WORK.items.length; i++) {
              images[`image${i}`] = WORK.items[i].urlAtt;
            }
          }
          this.shareService.export("PDF", content, null, {
            title: this.workNumber,
            images,
          });
        },
        (error) => {}
      );
    } else if (event.action == "UPDATE") {
      this.isUpdateOperation = true;
      this.isNewReport = false;      
      this.PK = event.element.PK;
    } else if (event.action == "UPDATE_OC") {
      this.uploadError = "";
      this.successMessage ="";
      let label = "OC cliente";
      let enterpriseOc = true;
      let enterpriseOcValue = event.element.enterpriseOc;
      this.PK = event.element.PK;
      let index = this.result.findIndex(element => element.PK === this.PK);
      const dialogRef = this.dialog.open(AlertComponent, {
        width: "500px",
        data: {
          label,
          enterpriseOc,
          enterpriseOcValue
        },
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          result.PK = this.PK;
          this.workService.editEnterpriseOc(result).subscribe(
            (data) => {
              this.successMessage = "Operación realizada con exito";
              this.result[index].enterpriseOc = result.enterpriseOc;
            },
            (error) => {
              this.uploadError = "Se ha presentado un error, vuelva a intentarlo";
            }
          );
        }        
      });
    } else if (event.action == "CREATE_INFORM") {
      this.isUpdateOperation = false;
      this.isNewReport = true;
      this.PK = event.element.PK;
      this.enterprise = event.element.enterprise;
    } else if (event.action == "UPDATE_INFORM") {
      this.isUpdateOperation = true;
      this.isNewReport = true;
      this.PK = event.element.PK;
    } else if (event.action == "APPROVE_INFORM") {
      this.uploadError = "";
      this.successMessage ="";
      let label = "Estado Informe";
      let statusInform = true;
      let sendEmail = true;
      let enterprisePK = event.element.enterprise;
      let closeItems = event.element.closeItems;
      this.PK = event.element.PK;
      let index = this.result.findIndex(element => element.PK === this.PK);
      const dialogRef = this.dialog.open(AlertComponent, {
        width: "800px",
        data: {
          label,
          sendEmail,
          statusInform,
          enterprisePK,
          closeItems
        },
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          result.PK = event.element.PK;
          this.workService.updateStatusInform(result).subscribe(
            (data) => {
              this.successMessage = "Operación realizada con exito";
              this.result[index].statusInform = result.status;
            },
            (error) => {
              this.uploadError = "Se ha presentado un error, vuelva a intentarlo";
            }
          );
        }        
      });
    } else if (event.action == "SEND_FORM") {
      this.uploadError = "";
      this.successMessage ="";
      let label = "Enviar Informe";
      let sendEmail = true;
      let enterprisePK = event.element.enterprise;
      this.PK = event.element.PK;
      let index = this.result.findIndex(element => element.PK === this.PK);
      const dialogRef = this.dialog.open(AlertComponent, {
        width: "500px",
        data: {
          label,
          sendEmail,
          enterprisePK
        },
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          result.PK = event.element.PK;
          this.workService.sendEmail(result).subscribe(
            (data) => {
              this.successMessage = "Operación realizada con exito";
            },
            (error) => {
              this.uploadError = "Se ha presentado un error, vuelva a intentarlo";
            }
          );
        }        
      });
    } else if (event.action == "CLOSE") {
      let status = "Finalizada";
      this.PK = event.element.PK;
      let index = this.result.findIndex(element => element.PK === this.PK);
      this.workService.closeWork({ PK: event.element.PK, status }).subscribe(
        (data) => {
          let label = `La orden de trabajo fue ${status} exitosamente`;
          let action = true;
          const dialogRef = this.dialog.open(AlertComponent, {
            width: "500px",
            data: {
              label,
              action
            },
          });
          this.result[index].status = status;
        },
        (error) => {
          let label = "Se ha presentado un error, vuelva a intentarlo";
          let action = true;
          const dialogRef = this.dialog.open(AlertComponent, {
            width: "500px",
            data: {
              label,
              action
            },
          });
        }
      );
    } else if (event.action == "INVOICED") {
      let status = "Facturada";
      this.PK = event.element.PK;
      let index = this.result.findIndex(element => element.PK === this.PK);
      let action = true;
      this.workService.closeWork({ PK: event.element.PK, status }).subscribe(
        (data) => {
          let label = `La orden de trabajo fue ${status} exitosamente`;
          const dialogRef = this.dialog.open(AlertComponent, {
            width: "500px",
            data: {
              label,
              action
            },
          });
          this.result[index].status = status;
        },
        (error) => {
          let action = true;
          let label = "Se ha presentado un error, vuelva a intentarlo";
          const dialogRef = this.dialog.open(AlertComponent, {
            width: "500px",
            data: {
              label,
              action
            },
          });
        }
      );
    }
  }

  send() {
    if (
      (this.formControl.get("startDate").value &&
        !this.formControl.get("finishDate").value) ||
      (!this.formControl.get("startDate").value &&
        this.formControl.get("finishDate").value)
    ) {
      this.uploadError = "Debe ingresar fecha inicial y fecha final";
      return;
    } else if (
      !this.formControl.get("startDate").value &&
      !this.formControl.get("finishDate").value &&
      !this.formControl.get("id").value &&
      !this.formControl.get("enterprise").value &&
      !this.formControl.get("status").value &&
      !this.formControl.get("service").value
    ) {
      this.uploadError = "Debe ingresar al menos un criterio de búsqueda";
      return;
    } else {
      this.isUploading = true;
      this.globalError = "";
      this.uploadError = "";
      this.formControl.disable({ emitEvent: false });
      let values = this.formControl.getRawValue();
      const id = values.id ? values.id : undefined;
      const enterprise = values.enterprise ? values.enterprise : undefined;
      const startDate = values.startDate ? values.startDate : undefined;
      const finishDate = values.finishDate ? values.finishDate : undefined;
      const status = values.status ? values.status : undefined;
      const service = values.service ? values.service : undefined;

      const SEARCHCRITERIA = {
        type: "S",
        id,
        enterprise,
        startDate,
        finishDate,
        service,
        status,
      }

      let serviceToCall = this.path ? this.workService.getInform(SEARCHCRITERIA) : this.workService.get(SEARCHCRITERIA)
      
      serviceToCall.subscribe(
          (data) => {
            if (this.formControl.get("id").value) {
              this.formControl.get("id").enable({ emitEvent: false });
              this.formControl.get("enterprise").disable({ emitEvent: false });
              this.formControl.get("startDate").disable({ emitEvent: false });
              this.formControl.get("finishDate").disable({ emitEvent: false });
              this.formControl.get("status").disable({ emitEvent: false });
              this.formControl.get("service").disable({ emitEvent: false });
            } else {
              this.formControl.enable({ emitEvent: false });
            }
            this.result = data["body"];
            console.log(this.result);
            this.isUploading = false;
            if (!this.result.length) {
              this.uploadError = "No se encontraron resultados";
              this.showTable = false;
              return;
            }
            this.showTable = true;
          },
          (error) => {
            if (this.formControl.get("id").value) {
              this.formControl.get("id").enable({ emitEvent: false });
              this.formControl.get("enterprise").disable({ emitEvent: false });
              this.formControl.get("startDate").disable({ emitEvent: false });
              this.formControl.get("finishDate").disable({ emitEvent: false });
              this.formControl.get("status").disable({ emitEvent: false });
              this.formControl.get("service").disable({ emitEvent: false });
            } else {
              this.formControl.enable({ emitEvent: false });
            }
            this.uploadError = "Se ha presentado un error, vuelva a intentarlo";
            this.isUploading = false;
          }
        );
    }
  }

  deleteAction(event) {
    let index = this.result.findIndex(element => element.PK === this.PK);
    if (event) this.result[index].statusInform = "modificado";
  }

  close() {
    this.isUpdateOperation = false;
    this.isNewReport = false;
  }
}
