import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { FormatsService } from "@app/quotations/services/formats.service";
import { QuotationsService } from "@app/quotations/services/quotations.service";
import { AlertComponent } from "@app/shared/components/alert/alert.component";
import { SaveListComponent } from "@app/shared/components/save-list/save-list.component";
import { ShareService } from "@app/shared/services/share.service";
import { Auth } from "aws-amplify";
import { forkJoin } from "rxjs";
import { v4 as uuid } from "uuid";

@Component({
  selector: "app-quotations-form",
  templateUrl: "./form.component.html",
  styleUrls: ["./form.component.scss"],
})
export class FormComponent implements OnInit {
  @Input()
  isUpdateOperation;
  @Input()
  PK;
  @Output()
  action = new EventEmitter();
  isInitializing = true;
  isUploading = false;
  globalError: string;
  uploadError: string;
  successMessage: String;
  attachedError: string;

  enterprises;
  contacts = [];

  valuesChange;
  optionalValuesChange;
  totalSum: number = 0;
  unitValue = [];
  totalValue = [];
  optionalUnitValue = [];
  optionalTotalValue = [];
  itemsSum = 0;

  fileName = "";
  file: File;
  extensionFile;
  url;

  offers;
  instalations;
  currencies;
  optionalNotes;

  imageName: Array<string> = [];
  imageAlert: Array<string> = [];
  image = [];
  extensionImage: Array<string> = [];
  sizeImage: Array<number> = [];
  imageUrl: Array<string> = [];
  errorCounter = 0;

  imageNameOptionals: Array<string> = [];
  imageAlertOptionals: Array<string> = [];
  imageOptionals = [];
  extensionImageOptionals: Array<string> = [];
  sizeImageOptionals: Array<number> = [];
  imageUrlOptionals: Array<string> = [];
  errorCounterOptionals = 0;

  constructor(
    private formBuilder: FormBuilder,
    private quotationsService: QuotationsService,
    private shareService: ShareService,
    public dialog: MatDialog,
    private formatsService: FormatsService
  ) {}
  public formControl: FormGroup;

  onFileSelected(event) {
    this.file = event.target.files[0];
    if (this.file) {
      this.fileName = this.file.name;
      this.extensionFile = this.fileName.split(".").pop();
      this.formControl.get("fileExtension").setValue(this.extensionFile);
      this.url = "";
    }
  }

  ngOnInit(): void {
    this.formControl = this.formBuilder.group({
      enterprise: [
        "",
        [
          Validators.required,
          Validators.pattern("^ENTR[0-9]+$"),
          Validators.maxLength(11),
        ],
      ],
      contact: [
        "",
        [
          Validators.required,
          Validators.pattern("^CONV[0-9]+$"),
          Validators.maxLength(11),
        ],
      ],
      date: ["", [Validators.required]],
      subject: ["", [Validators.required, Validators.maxLength(255)]],
      scope: ["", [Validators.maxLength(10000)]],
      product: ["", [Validators.required, Validators.maxLength(255)]],
      installation: ["", [Validators.required, Validators.maxLength(255)]],
      deliveryTime: ["", [Validators.required, Validators.maxLength(255)]],
      currency: ["", [Validators.required, Validators.maxLength(255)]],
      offerValidity: ["", [Validators.required, Validators.maxLength(255)]],
      paymentMethod: ["", [Validators.required, Validators.maxLength(255)]],
      warranty: ["", [Validators.maxLength(255)]],
      notes: ["", [Validators.maxLength(4000)]],
      fileExtension: ["", []],
      sendList: [[], []],
      optionsList: [[], []],
      discount: [{ value: "", disabled: true }],
      discountDescription: ["", [Validators.maxLength(510)]],
      total: [{ value: "", disabled: true }],
      items: this.formBuilder.array([]),
      optionalItems: this.formBuilder.array([]),
    });

    this.formControl.get("enterprise").valueChanges.subscribe((value) => {
      if (value) {
        this.quotationsService
          .getEnterprises({ contacts: value })
          .subscribe((data) => {
            this.contacts = data["body"];
          });
      } else {
        this.contacts = [];
      }
    });

    Auth.currentSession().then((credentials) => {
      let enterpriseRq = this.quotationsService.getEnterprises({ all: "ALL" });
      let offersRq = this.shareService.getList("VALI");
      let instalationsRq = this.shareService.getList("INST");
      let currenciesRq = this.shareService.getList("CURR");
      let optionalNotesRq = this.shareService.getList("OPLI");
      if (this.isUpdateOperation) {
        let quotationRq = this.quotationsService.get({ PK: this.PK });
        forkJoin([
          quotationRq,
          enterpriseRq,
          offersRq,
          instalationsRq,
          currenciesRq,
          optionalNotesRq,
        ]).subscribe(
          ([
            quotationRs,
            enterpriseRs,
            offersRs,
            instalationsRs,
            currenciesRs,
            optionalNotesRs,
          ]) => {
            this.formControl.addControl(
              "status",
              new FormControl("", Validators.required)
            );
            const QUOTATION = quotationRs["body"].filter(
              (item) => item.SK === this.PK
            )[0];
            this.enterprises = enterpriseRs["body"];
            this.offers = offersRs["body"];
            this.instalations = instalationsRs["body"];
            this.currencies = currenciesRs["body"];
            this.optionalNotes = optionalNotesRs["body"].map(
              (item) => item.name
            );
            let options = QUOTATION.optionsList.filter(
              (option) => !this.optionalNotes.includes(option)
            );
            this.optionalNotes = [...this.optionalNotes, ...options];
            QUOTATION.total = 0;
            QUOTATION.items = quotationRs["body"].filter(
              (item) => item.SK !== this.PK && item.entity === "QUOT|ITQU"
            );
            QUOTATION.items.sort((a, b) => a.SK.slice(4) - b.SK.slice(4));
            QUOTATION.optionalItems = quotationRs["body"].filter(
              (item) => item.SK !== this.PK && item.entity === "QUOT|IQOP"
            );
            QUOTATION.optionalItems.sort(
              (a, b) => a.SK.slice(4) - b.SK.slice(4)
            );
            this.url = QUOTATION.urlAtt;
            QUOTATION.fileExtension = "";
            QUOTATION.date += "T05:00:00.000Z";
            for (let i = 0; i < QUOTATION.items.length; i++) {
              this.unitValue[i] = QUOTATION.items[i].unitValue;
              this.totalValue[i] = QUOTATION.items[i].totalValue;
              let group = this.initItemRows();
              group.addControl("SK", new FormControl(""));
              this.formArr.push(group);
              this.imageUrl[i] = QUOTATION.items[i].urlAtt;
              QUOTATION.items[i].fileExtension = "";
            }
            for (let i = 0; i < QUOTATION.optionalItems.length; i++) {
              this.optionalUnitValue[i] =
                QUOTATION.optionalItems[i].optionalUnitValue;
              this.optionalTotalValue[i] =
                QUOTATION.optionalItems[i].optionalTotalValue;
              let optionalGroup = this.initOptionalItemRows();
              optionalGroup.addControl("SK", new FormControl(""));
              this.optionalFormArr.push(optionalGroup);
              this.imageUrlOptionals[i] = QUOTATION.optionalItems[i].urlAtt;
              QUOTATION.optionalItems[i].fileExtension = "";
            }
            this.formControl.patchValue(QUOTATION);
            this.isInitializing = false;
          },
          (error) => {
            this.globalError =
              "Se ha presentado un error, por favor vuelva a intentarlo";
            this.isInitializing = false;
          }
        );
      } else {
        this.formArr.push(this.initItemRows());
        forkJoin([
          enterpriseRq,
          offersRq,
          instalationsRq,
          currenciesRq,
          optionalNotesRq,
        ]).subscribe(
          ([
            enterpriseRs,
            offersRs,
            instalationsRs,
            currenciesRs,
            optionalNotesRs,
          ]) => {
            this.enterprises = enterpriseRs["body"];
            this.offers = offersRs["body"];
            this.instalations = instalationsRs["body"];
            this.currencies = currenciesRs["body"];
            this.optionalNotes = optionalNotesRs["body"].map(
              (item) => item.name
            );
            this.formControl.get("optionsList").setValue(this.optionalNotes);
            this.isInitializing = false;
          },
          (error) => {
            this.globalError =
              "Se ha presentado un error, por favor vuelva a intentarlo";
            this.isInitializing = false;
          }
        );
      }
    });

    this.valuesChange = this.formControl.controls["items"].valueChanges;
    this.valuesChange.subscribe((items) => this.updateTotalUnitPrice(items));
    this.optionalValuesChange =
      this.formControl.controls["optionalItems"].valueChanges;
    this.optionalValuesChange.subscribe((Optionalitems) =>
      this.updateOptionalTotalUnitPrice(Optionalitems)
    );
  }

  onImageSelected(event, actualPosition: number) {
    const uuidValue = uuid();
    this.image[actualPosition] = {
      uuid: uuidValue,
      file: event.target.files[0],
    };
    if (this.image[actualPosition].file) {
      this.imageName[actualPosition] = this.image[actualPosition].file.name;
      this.sizeImage[actualPosition] =
        this.image[actualPosition].file.size / 1024; //Convert bytes to kilobytes
      this.extensionImage[actualPosition] = this.imageName[actualPosition]
        .split(".")
        .pop();
      if (
        (this.extensionImage[actualPosition] == "jpg" ||
          this.extensionImage[actualPosition] == "png" ||
          this.extensionImage[actualPosition] == "jpeg") &&
        this.sizeImage[actualPosition] <= 300
      ) {
        this.imageAlert[actualPosition] = "";
        if (this.imageUrl[actualPosition])
          this.imageUrl[actualPosition] = undefined;
        this.formArr.at(actualPosition).get("uuid").setValue(uuidValue);
        this.formArr
          .at(actualPosition)
          .get("fileExtension")
          .setValue(this.extensionImage[actualPosition]);
      } else {
        this.imageAlert[actualPosition] =
          "El archivo debe ser .png, .jpg o .jpeg e inferior a 300KB";
        this.image.splice(actualPosition, 1);
        this.imageName.splice(actualPosition, 1);
        this.sizeImage.splice(actualPosition, 1);
        this.extensionImage.splice(actualPosition, 1);
      }
    }
  }

  onImageSelectedOptionals(event, actualPosition: number) {
    const uuidValue = uuid();
    this.imageOptionals[actualPosition] = {
      uuid: uuidValue,
      file: event.target.files[0],
    };
    if (this.imageOptionals[actualPosition].file) {
      this.imageNameOptionals[actualPosition] =
        this.imageOptionals[actualPosition].file.name;
      this.sizeImageOptionals[actualPosition] =
        this.imageOptionals[actualPosition].file.size / 1024; //Convert bytes to kilobytes
      this.extensionImageOptionals[actualPosition] = this.imageNameOptionals[
        actualPosition
      ]
        .split(".")
        .pop();
      if (
        (this.extensionImageOptionals[actualPosition] == "jpg" ||
          this.extensionImageOptionals[actualPosition] == "png" ||
          this.extensionImageOptionals[actualPosition] == "jpeg") &&
        this.sizeImageOptionals[actualPosition] <= 300
      ) {
        this.imageAlertOptionals[actualPosition] = "";
        if (this.imageUrlOptionals[actualPosition])
          this.imageUrlOptionals[actualPosition] = undefined;
        this.optionalFormArr.at(actualPosition).get("uuid").setValue(uuidValue);
        this.optionalFormArr
          .at(actualPosition)
          .get("fileExtension")
          .setValue(this.extensionImageOptionals[actualPosition]);
      } else {
        this.imageAlertOptionals[actualPosition] =
          "El archivo debe ser .png, .jpg o .jpeg e inferior a 300KB";
        this.imageOptionals.splice(actualPosition, 1);
        this.imageNameOptionals.splice(actualPosition, 1);
        this.sizeImageOptionals.splice(actualPosition, 1);
        this.extensionImageOptionals.splice(actualPosition, 1);
      }
    }
  }

  validateDiscount() {
    if (this.formControl.get("discountDescription").value) {
      this.formControl.get("discount").setValidators([Validators.required]);
      this.formControl.get("discount").enable({ emitEvent: false });
      this.formControl
        .get("discount")
        .updateValueAndValidity({ emitEvent: false });
    } else {
      this.formControl.get("discount").clearValidators();
      this.formControl.get("discount").reset();
      this.formControl.get("discount").disable({ emitEvent: false });
      this.formControl
        .get("discount")
        .updateValueAndValidity({ emitEvent: false });
      this.restDiscount();
    }
  }

  restDiscount() {
    this.totalSum = this.itemsSum;
    this.totalSum -= this.formControl.get("discount").value;
  }

  get formArr() {
    return this.formControl.get("items") as FormArray;
  }

  get optionalFormArr() {
    return this.formControl.get("optionalItems") as FormArray;
  }

  initItemRows() {
    return this.formBuilder.group({
      description: ["", [Validators.required, Validators.maxLength(510)]],
      quantity: ["", [Validators.required, Validators.min(0.01)]],
      unitValue: ["", [Validators.required, Validators.min(1)]],
      totalValue: [{ value: "", disabled: true }],
      uuid: ["", []],
      fileExtension: ["", []],
    });
  }

  initOptionalItemRows() {
    return this.formBuilder.group({
      optionalDescription: [
        "",
        [Validators.required, Validators.maxLength(510)],
      ],
      optionalQuantity: ["", [Validators.required, Validators.min(0.01)]],
      optionalUnitValue: ["", [Validators.required, Validators.min(1)]],
      optionalTotalValue: [{ value: "", disabled: true }],
      uuid: ["", []],
      fileExtension: ["", []],
    });
  }

  addNewRow(event) {
    event.stopPropagation();
    event.preventDefault();
    this.formArr.push(this.initItemRows());
  }

  deleteRow(index: number) {
    if (this.formArr.length > 1) {
      this.unitValue.splice(index, 1);
      this.totalValue.splice(index, 1);
      this.formArr.removeAt(index);
      this.image.splice(index, 1);
      this.imageName.splice(index, 1);
      this.sizeImage.splice(index, 1);
      this.extensionImage.splice(index, 1);
      this.imageAlert.splice(index, 1);
      this.imageUrl.splice(index, 1);
    }
  }

  addNewOptionalRow(event) {
    event.stopPropagation();
    event.preventDefault();
    this.optionalFormArr.push(this.initOptionalItemRows());
  }

  deleteOptionalRow(index: number) {
    this.optionalFormArr.removeAt(index);
    this.optionalUnitValue.splice(index, 1);
    this.optionalTotalValue.splice(index, 1);
    this.imageOptionals.splice(index, 1);
    this.imageNameOptionals.splice(index, 1);
    this.sizeImageOptionals.splice(index, 1);
    this.extensionImageOptionals.splice(index, 1);
    this.imageAlertOptionals.splice(index, 1);
    this.imageUrlOptionals.splice(index, 1);
  }

  private updateTotalUnitPrice(items: any) {
    const CONTROL = <FormArray>this.formControl.controls["items"];
    this.totalSum = 0;
    for (let i in items) {
      if (items[i].unitValue) {
        let totalUnitPrice = items[i].quantity * items[i].unitValue;
        CONTROL.at(+i)
          .get("totalValue")
          .setValue(totalUnitPrice, { onlySelf: true, emitEvent: false });
        this.totalSum += totalUnitPrice;
      }
    }
    this.itemsSum = this.totalSum;
    this.restDiscount();
    this.formControl.controls["total"].setValue(this.totalSum, {
      onlySelf: true,
      emitEvent: false,
    });
  }

  private updateOptionalTotalUnitPrice(items: any) {
    const CONTROL = <FormArray>this.formControl.controls["optionalItems"];
    for (let i in items) {
      if (items[i].optionalUnitValue) {
        let totalUnitPrice =
          items[i].optionalQuantity * items[i].optionalUnitValue;
        CONTROL.at(+i)
          .get("optionalTotalValue")
          .setValue(totalUnitPrice, { onlySelf: true, emitEvent: false });
      }
    }
  }

  controlsDisable() {
    if (!this.formControl.get("discountDescription").value)
      this.formControl.get("discount").disable({ emitEvent: false });
    this.formControl.get("total").disable({ emitEvent: false });
    for (let i = 0; i < this.formArr.length; i++) {
      this.formArr.at(i).get("totalValue").disable({ emitEvent: false });
    }
    for (let i = 0; i < this.optionalFormArr.length; i++) {
      this.optionalFormArr
        .at(i)
        .get("optionalTotalValue")
        .disable({ emitEvent: false });
    }
  }

  send() {
    this.isUploading = true;
    this.globalError = "";
    this.uploadError = "";
    this.successMessage = "";
    this.attachedError = "";
    this.formControl.disable({ emitEvent: false });
    if (this.isUpdateOperation) {
      let label =
        "Al actualizar la cotización se creará una nueva revisión. ¿Está seguro de actualizar la cotización?";
      const dialogRef = this.dialog.open(AlertComponent, {
        width: "500px",
        data: {
          label,
        },
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          let data = this.formControl.getRawValue();
          data.PK = this.PK;
          delete data.status;
          this.quotationsService.updateQuotations(data).subscribe(
            (data) => {
              if (
                data["body"].url ||
                data["body"].urls[0] ||
                data["body"].urlsOptionals[0]
              ) {
                if (data["body"].url) {
                  this.shareService
                    .uploadFile(data["body"].url, this.file)
                    .subscribe(
                      (data) => {},
                      (error) => {
                        this.errorCounter++;
                      }
                    );
                }
                if (data["body"].urls[0]) {
                  for (let item of this.image) {
                    if (item) {
                      const image = data["body"].urls.find(
                        (element) => item.uuid === element.uuid
                      );
                      this.shareService
                        .uploadFile(image.url, item.file)
                        .subscribe(
                          (data) => {},
                          (error) => {
                            this.errorCounter++;
                          }
                        );
                    }
                  }
                }
                if (data["body"].urlsOptionals[0]) {
                  for (let item of this.imageOptionals) {
                    if (item) {
                      const image = data["body"].urlsOptionals.find(
                        (element) => item.uuid === element.uuid
                      );
                      this.shareService
                        .uploadFile(image.url, item.file)
                        .subscribe(
                          (data) => {},
                          (error) => {
                            this.errorCounter++;
                          }
                        );
                    }
                  }
                }
                if (this.errorCounter > 0) {
                  this.attachedError =
                    "El registro se guardó correctamente pero se ha presentado un error al subir adjunto(s)";
                } else {
                  this.successMessage = "Operación realizada con exito";
                }
              } else {
                this.successMessage = "Operación realizada con exito";
              }
              this.formControl.enable({ emitEvent: false });
              this.controlsDisable();
              this.isUploading = false;
            },
            (error) => {
              this.formControl.enable({ emitEvent: false });
              this.controlsDisable();
              this.uploadError = error.error.status.message;
              this.isUploading = false;
            }
          );
        } else {
          this.isUploading = false;
          this.formControl.enable({ emitEvent: false });
          this.controlsDisable();
        }
      });
    } else {
      let label = "¿Está seguro que desea guardar la cotización?";
      const dialogRef = this.dialog.open(AlertComponent, {
        width: "500px",
        data: {
          label,
        },
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          let values = this.formControl.getRawValue();
          if (!values.discountDescription) {
            delete values.discountDescription;
            delete values.discount;
          }
          if (!values.warranty) delete values.warranty;
          if (!values.notes) delete values.notes;
          if (!values.scope) delete values.scope;
          if (!values.fileExtension) delete values.fileExtension;
          if (!values.sendList) delete values.sendList;
          if (!values.optionsList) delete values.optionsList;
          this.quotationsService.saveQuotations(values).subscribe(
            (data) => {
              if (
                data["body"].url ||
                data["body"].urls[0] ||
                data["body"].urlsOptionals[0]
              ) {
                if (data["body"].url) {
                  this.shareService
                    .uploadFile(data["body"].url, this.file)
                    .subscribe(
                      (data) => {},
                      (error) => {
                        this.errorCounter++;
                      }
                    );
                }
                if (data["body"].urls[0]) {
                  for (let item of this.image) {
                    if (item) {
                      const image = data["body"].urls.find(
                        (element) => item.uuid === element.uuid
                      );
                      this.shareService
                        .uploadFile(image.url, item.file)
                        .subscribe(
                          (data) => {},
                          (error) => {
                            this.errorCounter++;
                          }
                        );
                    }
                  }
                }
                if (data["body"].urlsOptionals[0]) {
                  for (let item of this.imageOptionals) {
                    if (item) {
                      const image = data["body"].urlsOptionals.find(
                        (element) => item.uuid === element.uuid
                      );
                      this.shareService
                        .uploadFile(image.url, item.file)
                        .subscribe(
                          (data) => {},
                          (error) => {
                            this.errorCounter++;
                          }
                        );
                    }
                  }
                }
                if (this.errorCounter > 0) {
                  this.attachedError =
                    "El registro se guardó correctamente pero se ha presentado un error al subir adjunto(s)";
                  this.fileName = "Aún no se ha subido ningún archivo.";
                  this.imageName = [];
                  this.imageNameOptionals = [];
                } else {
                  this.successMessage = "Operación realizada con exito";
                  this.fileName = "Aún no se ha subido ningún archivo.";
                  this.imageName = [];
                  this.imageNameOptionals = [];
                }
              } else {
                this.successMessage = "Operación realizada con exito";
              }
              this.isUploading = false;
              this.formControl.enable({ emitEvent: false });
              this.formControl.reset();
              this.formArr.clear();
              this.optionalFormArr.clear();
              this.formArr.updateValueAndValidity({ emitEvent: false });
              this.totalSum = 0;
              this.unitValue = [];
              this.totalValue = [];
              this.optionalUnitValue = [];
              this.optionalTotalValue = [];
              this.formControl.get("total").disable({ emitEvent: false });
              if (!this.formControl.get("discountDescription").value)
                this.formControl.get("discount").disable({ emitEvent: false });
            },
            (error) => {
              this.formControl.enable({ emitEvent: false });
              this.controlsDisable();
              this.uploadError = error.error.status.message;
              this.isUploading = false;
            }
          );
        } else {
          this.isUploading = false;
          this.formControl.enable({ emitEvent: false });
          this.controlsDisable();
        }
      });
    }
  }

  previewQuotation() {
    let values = this.formControl.getRawValue();  
    values.itemsImgs = [];
    values.optionalItemsImgs = [];

    let enterprise = this.enterprises.filter(
      (item) => item.PK === values.enterprise
    );
    let nameEnterprise = enterprise[0].name;
    values.enterpriseName = nameEnterprise;

    if (!this.isUpdateOperation) {
      var dd = String(values.date.getDate()).padStart(2, "0");
      var mm = String(values.date.getMonth() + 1).padStart(2, "0");
      var yyyy = values.date.getFullYear();
      values.date = yyyy + "-" + mm + "-" + dd;
    }

    const content = this.formatsService.generatePrint(values);
    this.shareService.export("PDF", content, null, {
      title: "Cotización No. ",
      pageMargins: [50, 90, 50, 30],
    });
  }

  getButtonText() {
    return this.isUploading
      ? this.isUpdateOperation
        ? "Actualizando cotización..."
        : "Creando cotización..."
      : this.isUpdateOperation
      ? "Actualizar cotización"
      : "Crear cotización";
  }

  save(event, entity) {
    event.stopPropagation();
    event.preventDefault();
    let list;
    let control;
    let label;
    if (entity === "VALI") {
      label = "Validez oferta";
      list = this.offers;
      control = this.formControl.get("offerValidity");
    } else if (entity === "OPLI") {
      label = "Notas";
      list = this.optionalNotes;
      control = this.formControl.get("optionsList");
    }

    console.log(list)
    console.log(this.formControl.value)
    const dialogRef = this.dialog.open(SaveListComponent, {
      width: "500px",
      data: {
        current: list,
        entity,
        label,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result && entity !== "OPLI") {
        list.push(result);
        control.setValue(result["PK"]);
      } else if (result) {
        let items = control.value;
        items.push(result);
        list.push(result);
        control.setValue(items);
      }
    });
  }

  close() {
    this.action.emit();
  }

  openAlert() {}
}
