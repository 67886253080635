import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AuthenticationService } from '@app/auth/services/authentication.service';
import { EnterprisesService } from '@app/enterprises/services/enterprises.service';
import { FormatsService } from '@app/enterprises/services/formats.service';
import { ShareService } from '@app/shared/services/share.service';

@Component({
  selector: 'app-enterprises-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
  isUpdateOperation = false;
  isInitializing = true;
  isUploading = false;
  globalError: string;
  uploadError: string;
  showTable = false;
  enterprises;
  result;
  PK;
  tableNames = {
    name: "Nombre",
    edit: "Editar",
    print: "Imprimir",
  };

  formControl = this.formBuilder.group({
    PK: ["", [Validators.required]],
    id: ["", [Validators.required]],
  });

  constructor(
    private formBuilder: FormBuilder,
    private entrepriseService: EnterprisesService,
    private formatsService: FormatsService,
    private shareService: ShareService,
    private authService: AuthenticationService
  ) { 
    this.formControl.get("PK").valueChanges.subscribe((value) => {
      if (value) {
        this.formControl.get("id").clearValidators();
        this.formControl.get("id").reset();
        this.formControl.get("id").disable({ emitEvent: false });
        this.formControl
          .get("id")
          .updateValueAndValidity({ emitEvent: false });
      } else {
        this.formControl.get("id").enable({ emitEvent: false });
        this.formControl
          .get("id")
          .setValidators([Validators.required]);
        this.formControl
          .get("id")
          .updateValueAndValidity({ emitEvent: false });
      }
    });

    this.formControl.get("id").valueChanges.subscribe((value) => {
      if (value) {
        this.formControl.get("PK").clearValidators();
        this.formControl.get("PK").reset();
        this.formControl.get("PK").disable({ emitEvent: false });
        this.formControl.get("PK").updateValueAndValidity({ emitEvent: false });
      } else {
        this.formControl.get("PK").setValidators([Validators.required]);
        this.formControl.get("PK").enable({ emitEvent: false });
        this.formControl.get("PK").updateValueAndValidity({ emitEvent: false });
      }
    });
  }

  ngOnInit(): void {
    if (!this.authService.checkActionsPermission("ENTR", ["UPDATE"])) {
      delete this.tableNames.edit;
    }

    if (!this.authService.checkActionsPermission("ENTR", ["PRINT"])) {
      delete this.tableNames.print;
    }

    this.entrepriseService.get({ all: "ALL" }).subscribe(
      (data) => {
        this.enterprises = data["body"];
        this.isInitializing = false;
      },
      (error) => {
        this.globalError =
          "Se ha presentado un error, por favor vuelva a intentarlo";
        this.isInitializing = false;
      }
    );
  }
  action(event) {
    if (event.action == "PRINT") {
      const PK = event.element.PK;
      this.entrepriseService.get({ PK }).subscribe(
        (data) => {
          const ENTERPRISE = data["body"].filter(item => item.SK === PK)[0];
          ENTERPRISE.items = data["body"].filter(item => item.SK !== PK);
          console.log(ENTERPRISE);
          const content = this.formatsService.generatePrint(ENTERPRISE);
          this.shareService.export("PDF", content, null, {title: "Registro de empresa"});
        },
        (error) => {}
      );
    } else if (event.action == "UPDATE") {
      this.isUpdateOperation = true;
      this.PK = event.element.PK;
    }
  }

  send() {
    this.isUploading = true;
    this.globalError = "";
    this.uploadError = "";
    this.formControl.disable({ emitEvent: false });
    const PK = this.formControl.value.PK;
    const id = this.formControl.value.id;
    let data = PK ? { PK, typeSearch: "L" } : { id };
    this.entrepriseService.get(data).subscribe(
      (data) => {
        this.formControl.enable({ emitEvent: false });
        this.result = data["body"];  
        this.isUploading = false;
        if (!this.result.length) {
          this.uploadError = "No se encontraron resultados";
          this.showTable = false;
          return;
        }
        this.showTable = true;
      },
      (error) => {
        this.formControl.enable({ emitEvent: false });
        this.uploadError = "Se ha presentado un error, vuelva a intentarlo";
        this.isUploading = false;
      }
    );
  }

  close() {
    this.isUpdateOperation = false;
  }
}
