import { Component, OnInit } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { ShareService } from "@app/shared/services/share.service";
import { FormatsService } from "@app/time/services/formats.service";
import { TimeService } from "@app/time/services/time.service";

@Component({
  selector: "app-report",
  templateUrl: "./report.component.html",
  styleUrls: ["./report.component.scss"],
})
export class ReportComponent implements OnInit {
  isInitializing = false;
  isUploading = false;
  globalError: string;
  uploadError: string;
  users;
  data;

  formControl = this.formBuilder.group({
    typeDocument: ["", [Validators.required]],
    typeInform: ["", [Validators.required]],
    person: ["", []],
    status: ["", []],
    startDate: ["", [Validators.required]],
    finishDate: ["", [Validators.required]],
  });

  constructor(
    private formBuilder: FormBuilder,
    private timeService: TimeService,
    private formatsService: FormatsService,
    private shareService: ShareService
  ) {}

  ngOnInit(): void {
    this.timeService.getUsers({ all: "ALL" }).subscribe(
      (data) => {
        this.users = data["body"];
        this.isInitializing = false;
      },
      (error) => {
        this.globalError =
          "Se ha presentado un error, por favor vuelva a intentarlo";
        this.isInitializing = false;
      }
    );
  }

  send() {
    this.globalError = "";
    this.uploadError = "";
    let values = this.formControl.value;
    const typeInform = values.typeInform;
    const person = values.person ? values.person : undefined;
    const status = values.status ? values.status : undefined;
    const startDate = values.startDate;
    const finishDate = values.finishDate;
    this.timeService
      .get({ type: "R", typeInform, person, status, startDate, finishDate })
      .subscribe(
        (data) => {
          this.data = data["body"];
          console.log(this.data)
          this.isInitializing = false;
          if (!this.data.length) {
            this.uploadError = "No se encontraron resultados";
            return;
          }
          if (typeInform == "General") {
            let content =
              this.formControl.value.typeDocument === "PDF"
                ? this.formatsService.generateReportGeneral(this.data)
                : this.shareService.getTitles(
                    this.formatsService.TRANSLATE_GENERAL,
                    this.data
                  );
            this.shareService.export(
              this.formControl.value.typeDocument,
              content,
              "Tiempos general",
              {
                pageSize: "FOLIO",
                pageOrientation: "landscape",
                title: "Informe Hoja de tiempos - General",
              }
            );
          } else if (typeInform == "Consolidado") {
            let content =
              this.formControl.value.typeDocument === "PDF"
                ? this.formatsService.generateReportConsolidado(this.data)
                : this.shareService.getTitles(
                    this.formatsService.TRANSLATE_CONSOLIDADO,
                    this.data
                  );
            this.shareService.export(
              this.formControl.value.typeDocument,
              content,
              "Tiempos consolidado",
              {
                pageSize: "FOLIO",
                pageOrientation: "landscape",
                title: "Informe Hoja de tiempos - Consolidado",
              }
            );
          } else if (typeInform == "Liquidación") {
            let content =
              this.formControl.value.typeDocument === "PDF"
                ? this.formatsService.generateReportLiquidacion(this.data)
                : this.shareService.getTitles(
                    this.formatsService.TRANSLATE_LIQUIDACION,
                    this.data
                  );
            this.shareService.export(
              this.formControl.value.typeDocument,
              content,
              "Tiempos liquidación",
              {
                pageSize: "FOLIO",
                pageOrientation: "landscape",
                title: "Informe Hoja de tiempos - Liquidación",
              }
            );
          }
        },
        (error) => {
          this.uploadError =
            "Se ha presentado un error, por favor vuelva a intentarlo";
          this.isInitializing = false;
        }
      );
  }
}
