<div class="container-fluid">
  <mat-tab-group mat-stretch-tabs>
    <mat-tab label="Actualizar"
      ><ng-template matTabContent><app-form [update]="true"></app-form></ng-template
    ></mat-tab>
    <mat-tab label="Eliminar"
      ><ng-template matTabContent><app-form [delete]="true"></app-form></ng-template
    ></mat-tab>
  </mat-tab-group>
</div>
