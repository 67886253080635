import { Component, AfterViewInit } from '@angular/core';
import { AuthenticationService } from '@app/auth/services/authentication.service';

@Component({
  selector: 'app-list-inventory',
  templateUrl: './list-inventory.component.html',
  styleUrls: ['./list-inventory.component.scss']
})
export class ListInventoryComponent implements AfterViewInit {
  isUpdateOperation = false;
  constructor(public authService: AuthenticationService) { }

  ngAfterViewInit() {}

}
