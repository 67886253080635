import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ResumeService } from '@app/resume/services/resume.service';
import { SaveListComponent } from '@app/shared/components/save-list/save-list.component';
import { ShareService } from '@app/shared/services/share.service';
import { Auth } from 'aws-amplify';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class FormComponent implements OnInit {
  @Input()
  isUpdateOperation;
  @Input()
  PK;
  @Output()
  action = new EventEmitter();
  isInitializing = true;
  isUploading = false;
  globalError: string;
  uploadError: string;
  successMessage: String;
  attachedError: string;
  enterprises;
  marks;
  //models;
  typeEquipments;
  fuels;
  parts;

  fileName = "";
  file: File;
  extensionFile;
  fileSize;
  fileAlert = "";
  url;

  search = "";
  selectedItem = [];
  itemMessage = false;  

  constructor(
    private formBuilder: FormBuilder,
    private shareService: ShareService,
    private resumesService: ResumeService,
    public dialog: MatDialog
  ) { }

  public formControl: FormGroup;

  ngOnInit(): void {
    this.formControl = this.formBuilder.group({
      enterprise: ["", [Validators.required, Validators.pattern("^ENTR[0-9]+$")]],
      code: ["", [Validators.required, Validators.maxLength(20)]],
      nameEquipment: ["", [Validators.required, Validators.maxLength(512)]],
      typeEquipment: ["", [Validators.required, Validators.pattern("^TYEQ[0-9]+$")]],
      mark: ["", [Validators.required, Validators.pattern("^MARK[0-9]+$")]],
      model: ["", [Validators.required, Validators.maxLength(100)]],
      serial: ["", [Validators.maxLength(50)]],
      capacity: ["", [Validators.required, Validators.pattern("^[0-9]+$"), Validators.maxLength(100)]],
      measureUnitCapacity : ["", [Validators.required, Validators.maxLength(100)]],
      fuel: ["", [Validators.pattern("^FUEL[0-9]+$")]],
      workPressure: ["", [Validators.maxLength(100)]],
      designPressure: ["", [Validators.maxLength(100)]],
      consuption: ["", [Validators.maxLength(100)]],
      steamProduction: ["", [Validators.maxLength(100)]],
      voltage: ["", [Validators.pattern("^[0-9]+$"), Validators.maxLength(100)]],
      amperage: ["", [Validators.pattern("^[0-9]+$"), Validators.maxLength(100)]],
      aditionalDescription: ["", [Validators.maxLength(4000)]],
      fileExtension: ["", []],
      items: this.formBuilder.array([]),
      partsItems: this.formBuilder.array([]),
    });

    Auth.currentSession().then((credentials) => {
      let enterprisesRq = this.resumesService.getEnterprises({ all: "ALL" });
      let marksRq = this.shareService.getList("MARK");
      //let modelsRq = this.shareService.getList("MODE");
      let typeEquipmentsRq = this.shareService.getList("TYEQ");
      let fuelsRq = this.shareService.getList("FUEL");
      let partsRq = this.resumesService.getDocuments({ all: "ALL" });
      if (this.isUpdateOperation) {
        let resumeRq = this.resumesService.get({ PK: this.PK });
        forkJoin([enterprisesRq, marksRq, typeEquipmentsRq, fuelsRq, partsRq, resumeRq]).subscribe(
          ([enterprisesRs, marksRs, typeEquipmentsRs, fuelsRs, partsRs, resumeRs]) => {
            this.enterprises = enterprisesRs["body"];
            this.marks = marksRs["body"];
            //this.models = modelsRs["body"];
            this.typeEquipments = typeEquipmentsRs["body"];
            this.fuels = fuelsRs["body"];
            this.parts = partsRs["body"];
            const RESUME = resumeRs["body"].filter((item) => item.SK === this.PK )[0]; 
            RESUME.items = resumeRs["body"].filter((item) => item.SK !== this.PK && item.entity === "RESU|ITRE");
            RESUME.partsItems = resumeRs["body"].filter((item) => item.SK !== this.PK && item.entity === "RESU|ITPR");
            this.url = RESUME.urlAtt;
            for (let i = 0; i < RESUME.items.length; i++) {
              let group = this.initItemRows();
              this.formArr.push(group);
            }
            for (let i = 0; i < RESUME.partsItems.length; i++) {
              this.selectedItem[i] = {
                document: RESUME.partsItems[i].document,
                typeEquipment: RESUME.partsItems[i].typeEquipmentName,
                mark: RESUME.partsItems[i].markName,
                model: RESUME.partsItems[i].modelName,
                typeDocument: RESUME.partsItems[i].typeDocumentName,
              };
              let group = this.initItemPartsRows();
              this.formArrParts.push(group);
            }
            this.formControl.patchValue(RESUME);
            this.isInitializing = false;
          },
          (error) => {
            this.globalError =
              "Se ha presentado un error, por favor vuelva a intentarlo";
            this.isInitializing = false;
          }
        );
      } else {
        forkJoin([enterprisesRq, marksRq, typeEquipmentsRq, fuelsRq, partsRq]).subscribe(
          ([enterprisesRs, marksRs, typeEquipmentsRs, fuelsRs, partsRs]) => {
            this.enterprises = enterprisesRs["body"];
            this.marks = marksRs["body"];
            //this.models = modelsRs["body"];
            this.typeEquipments = typeEquipmentsRs["body"];
            this.fuels = fuelsRs["body"];
            this.parts = partsRs["body"];
            this.isInitializing = false;
          },
          (error) => {
            this.globalError =
              "Se ha presentado un error, por favor vuelva a intentarlo";
            this.isInitializing = false;
          }
        );
      }
    });
  }

  onFileSelected(event) {
    this.file = event.target.files[0];
    if (this.file) {
      this.fileName = this.file.name;
      this.fileSize = this.file.size / 1024;
      this.extensionFile = this.fileName.split(".").pop();
      if (
        (this.extensionFile == "jpg" ||
          this.extensionFile == "png" ||
          this.extensionFile == "jpeg") &&
          this.fileSize <= 300
      ) {
          this.fileAlert = "";
          this.formControl.get("fileExtension").setValue(this.extensionFile);
          this.url = "";
      } else {
        this.fileAlert =
          "El archivo debe ser .png, .jpg o .jpeg e inferior a 300KB";
          this.extensionFile = undefined;
          this.fileName = undefined;
          this.fileSize = undefined;
      }     
    }
  }

  get formArr() {
    return this.formControl.get("items") as FormArray;
  }

  initItemRows() {
    return this.formBuilder.group({
      name : ["", [Validators.required, Validators.maxLength(100)]],
      value : ["", [Validators.required, Validators.maxLength(100)]],
      measureUnit : ["", [Validators.maxLength(100)]],
    });
  }

  addNewRow(event) {
    event.stopPropagation();
    event.preventDefault();
    this.formArr.push(this.initItemRows());
  }

  deleteRow(index: number) {
    this.formArr.removeAt(index); 
  } 

  get formArrParts() {
    return this.formControl.get("partsItems") as FormArray;
  }

  initItemPartsRows() {
    return this.formBuilder.group({
      document: [],
      aditionalInformation: ["", [Validators.required, Validators.maxLength(4000)]],
    });
  }

  addNewPartsRow(event, item) {    
    event.stopPropagation();
    event.preventDefault();
    if (this.selectedItem.find(element => element.document === item.PK)) this.itemMessage = true;
    else {
      this.formArrParts.push(this.initItemPartsRows());            
      this.selectedItem[this.formArrParts.length - 1] = {
        document: item.PK,
        typeEquipment: item.typeEquipmentName,
        mark: item.markName,
        model: item.modelName,
        typeDocument: item.typeDocumentName
      };
        this.formArrParts
        .at(this.formArrParts.length - 1)
        .get("document")
        .setValue(item.PK);
      this.itemMessage = false;
    }
    this.search = "";    
  }

  deletePartsRow(index: number) {
    this.formArrParts.removeAt(index);
    this.selectedItem.splice(index, 1);
  }

  send() {
    this.isUploading = true;
    this.globalError = "";
    this.uploadError = "";
    this.successMessage = "";
    this.attachedError = "";
    let values = this.formControl.getRawValue();
    if(!this.extensionFile) delete values.fileExtension;
    this.formControl.disable({ emitEvent: false });
    if (this.isUpdateOperation) {
      values.PK = this.PK;          
      this.resumesService.updateResume(values).subscribe(
        (data) => {
          if(data["body"].url) {
              this.shareService.uploadFile(data["body"].url, this.file).subscribe(data=>{;
              this.successMessage = "Operación realizada con exito";
              this.fileName = 'Aún no se ha subido ningún archivo.';
            },
            (error) => {
              this.attachedError = "El registro se guardó correctamente pero se ha presentado un error al subir el archivo";
              this.fileName = 'Aún no se ha subido ningún archivo.';
            })
          }
          else {            
            this.successMessage = "Operación realizada con exito";            
          }  
          this.formControl.enable({ emitEvent: false });
          this.isUploading = false;
        },
        (error) => {
          this.formControl.enable({ emitEvent: false });
          this.uploadError = error.error.status.message;
          this.isUploading = false;
        }
      );
    } else {
      this.resumesService.saveResume(values).subscribe(
        (data) => {          
          if(data["body"].url) {
              this.shareService.uploadFile(data["body"].url, this.file).subscribe(data=>{
              this.successMessage = "Operación realizada con exito";
              this.fileName = 'Aún no se ha subido ningún archivo.';
            },
            (error) => {
              this.attachedError = "El registro se guardó correctamente pero se ha presentado un error al subir el archivo";
              this.fileName = 'Aún no se ha subido ningún archivo.';
            })
          }
          else {            
            this.successMessage = "Operación realizada con exito";            
          }   
          this.formControl.enable({ emitEvent: false });
          this.formControl.reset();
          this.formArr.clear();
          this.formArrParts.clear();
          this.selectedItem = [];
          this.itemMessage = false; 
          this.fileAlert = "";
          this.formControl.updateValueAndValidity({emitEvent: false});
          this.isUploading = false;       
        },
        (error) => {
          this.formControl.enable({ emitEvent: false });
          this.uploadError = error.error.status.message;
          this.isUploading = false;
        }
      );
    }
  }

  save(event, entity) {
    event.stopPropagation();
    event.preventDefault();
    let list;
    let control;
    let label;
    if (entity === "MARK") {
      label = "Marca";
      list = this.marks;
      control = this.formControl.get("mark");
    } 
    // else if (entity === "MODE") {
    //   label = "Modelo";
    //   list = this.models;
    //   control = this.formControl.get("model");
    // } 
    else if (entity === "TYEQ") {
      label = "Tipo de equipo";
      list = this.typeEquipments;
      control = this.formControl.get("typeEquipment");
    } else if (entity === "FUEL") {
      label = "Combustible";
      list = this.fuels;
      control = this.formControl.get("fuel");
    }
    
    const dialogRef = this.dialog.open(SaveListComponent, {
      width: "500px",
      data: {
        current: list,
        entity,
        label,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        list.push(result);
        control.setValue(result["PK"]);
      }
    });
  }

  getButtonText() {
    return this.isUploading
      ? this.isUpdateOperation
        ? "Actualizando hoja de vida..."
        : "Creando hoja de vida..."
      : this.isUpdateOperation
      ? "Actualizar hoja de vida"
      : "Crear hoja de vida";
  }

  close() {
    this.action.emit();
  }
}