import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';

@Injectable({
  providedIn: 'root'
})
export class WarrantiesService {

  constructor(private http: HttpClient) { }

  saveWarranty(data) {
    return this.http.put(
      `${environment.apiHost}/v1/warranty`,
      data
    );
  }

  updateWarranty(data) {
    return this.http.patch(
      `${environment.apiHost}/v1/warranty`,
      data
    );
  }

  get(data) {
    return this.http.post(
      `${environment.apiHost}/v1/warranty`,
      data
    );
  }

  getEnterprises(data) {
    return this.http.post(
      `${environment.apiHost}/v1/vendor`,
      data
    );
  }

  getUsers(data) {
    return this.http.post(
      `${environment.apiHost}/v1/personal-registration`,
      data
    );
  }

  getWork(data) {
    return this.http.post(
      `${environment.apiHost}/v1/work-order`,
      data
    );
  }
}
