import { WHITE_ON_BLACK_CSS_CLASS } from '@angular/cdk/a11y/high-contrast-mode/high-contrast-mode-detector';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FormatsService {
  TRANSLATE = {
    personType: 'Tipo de persona',
    name: 'Nombre/Razón social',    
    id: 'Cédula/NIT',
    countryName: 'País',
    cityName: 'Ciudad',
    address: 'Dirección',
    phone: 'Teléfono',
    email: 'Email',
    web : 'Página web'
  }

  constructor() { }

  generatePrint(data){
    return [
      {
        table: {
          headerRows: 1,
          widths: ["*", "*", "*", "*", "*", "*", "*", "*", "*", "*", "*", "*"],
          body: [
            [{ text: "DATOS EMPRESA", alignment: "center",  bold: true, colSpan: 12 }, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}],
            [
              { text: [{text: `Nombre/Razón social: `, bold: true}, {text: `${data.name}`}], colSpan: 12 }, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {},
            ],
            [
              { text: [{text: `Tipo de persona: `, bold: true}, {text: `${data.personType}`}], colSpan: 6 }, {}, {}, {}, {}, {},
              { text: [{text: `Cédula/NIT: `, bold: true}, {text: `${data.id}`}], colSpan: 6 }, {}, {}, {}, {}, {},              
            ],
            [              
              { text: [{text: `País: `, bold: true}, {text: `${data.countryName}`}], colSpan: 4 }, {}, {}, {},  
              { text: [{text: `Ciudad: `, bold: true}, {text: `${data.cityName}`}], colSpan: 4 }, {}, {}, {},
              { text: [{text: `Sector: `, bold: true}, {text: `${data.sectorName}`}], colSpan: 4 }, {}, {}, {},         
            ],
            [
              { text: [{text: `Dirección: `, bold: true}, {text: `${data.address}`}], colSpan: 6 }, {}, {}, {}, {}, {},
              { text: [{text: `Teléfono: `, bold: true}, {text: `${data.phone}`}], colSpan: 6 }, {}, {}, {}, {}, {},
            ],
            [
              { text: [{text: `E-mail: `, bold: true}, {text: `${data.email}`}], colSpan: 6 }, {}, {}, {}, {}, {},
              { text: [{text: `Página web: `, bold: true}, {text: `${data.web || ""}`}], colSpan: 6 }, {}, {}, {}, {}, {},
            ],
          ],
        },
      },
      {
        lineHeight: 2,
        text: ".", color: "white",
      },
      {
        table: {
          headerRows: 1,
          widths: ["*", "*", "*", "*", "*", "*", "*", "*", "*", "*", "*", "*"],
          body: [
            [{ text: "CONTACTOS", alignment: "center",  bold: true, colSpan: 12 }, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}],
            [
              { text: `Nombre`, alignment: "center", bold: true, colSpan: 3 }, {}, {},
              { text: `Cargo`, alignment: "center", bold: true, colSpan: 3 }, {}, {},
              { text: `Celular`, alignment: "center", bold: true, colSpan: 3 }, {}, {},
              { text: `Email`, alignment: "center", bold: true, colSpan: 3 }, {}, {},
            ],
            ...data.items.map(d => ([{text: d.name, colSpan: 3}, {}, {}, {text: d.positionName, colSpan: 3}, {}, {}, {text: d.cellphone, colSpan: 3}, {}, {}, {text: d.email, colSpan: 3}, {}, {},]))
          ],
        },
      }
    ];
  }

  generateReport(data) {
    return [
      {
        table: {
          headerRows: 1,
          widths: ["9%", "14%", "9%", "9%", "9%", "9%", "9%", "16%", "16%"],
          body: [
            [{ text: 'Tipo de persona', bold: true, alignment: "center" }, { text: 'Nombre/Razón social', bold: true, alignment: "center" }, { text: 'Cédula/NIT', bold: true, alignment: "center" }, { text: 'País', bold: true, alignment: "center" }, { text: 'Ciudad', bold: true, alignment: "center" }, { text: 'Dirección', bold: true, alignment: "center" }, { text: 'Teléfono', bold: true, alignment: "center" }, { text: 'Email', bold: true, alignment: "center" }, { text: 'Página Web', bold: true, alignment: "center" }],
            ...data.map(d => ([d.personType, d.name, d.id, d.countryName, d.cityName, d.address, d.phone, d.email, d.web || ' '])),
          ],
        },
      },
    ];
  }
}