import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-list-lists',
  templateUrl: './list-lists.component.html',
})
export class ListListsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
