import { Component, OnInit } from "@angular/core";
import { AuthenticationService } from "@app/auth/services/authentication.service";
import { Router, Event, NavigationEnd } from "@angular/router";
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { MatDialog } from "@angular/material/dialog";
import { AlertComponent } from "@app/shared/components/alert/alert.component";

@Component({
  selector: "app-dashboard",
  templateUrl: "dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
})
export class DashboardComponent implements OnInit {
  name: string;
  email = "";
  phone = "";

  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;
  count = 0;

  profile = this.authenticationService.cognitoUser["profile"];

  constructor(
    public authenticationService: AuthenticationService,
    private router: Router,
    private idle: Idle,
    private keepalive: Keepalive,
    public dialog: MatDialog
  ) {
    const cognitoUser = authenticationService.cognitoUser;
    this.name = cognitoUser['name'];
    this.email = cognitoUser['email'];
    this.phone = cognitoUser['phone'];

    idle.setIdle(10800);
    idle.setTimeout(300);
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    /*idle.onIdleStart.subscribe(async () => {
      this.idleState = "You've gone idle!";
      console.log(this.idleState);
      this.idle.stop();
      this.idle.onTimeout.observers.length = 0;
      this.idle.onIdleStart.observers.length = 0;
      this.idle.onIdleEnd.observers.length = 0;
      this.logout();
      let logout = true;
      let label = "Sesión cerrada por inactividad";
      const dialogRef = this.dialog.open(AlertComponent, {
        width: "500px",
        data: {
          label,
          logout,
        },
      });
    });*/

    idle.onIdleStart.subscribe(() => {
      this.idleState = "IDLE";
      console.log(this.idleState);
      
    });

    idle.onIdleEnd.subscribe(() => {
      this.idleState = "NOT_IDLE";
      console.log(this.idleState);
    });

    idle.onTimeout.subscribe(() => {
      this.idleState = "TIMED_OUT";
      console.log(this.idleState);
      this.logout();
      this.count ++;
      if(this.count === 1) {
        let action = true;
        let label = "Sesión cerrada por inactividad";
        const dialogRef = this.dialog.open(AlertComponent, {
          width: "500px",
          data: {
            label,
            action,
          },
        });
      }
    });

    this.idle.watch();
  }

  ngOnInit() {
    this.authenticationService.setTitle(this.router.url);
    this.router.events.subscribe( (event: Event) => {
      if (event instanceof NavigationEnd) {
        this.authenticationService.setTitle(event.url);
      }
    });
  }

  logout() {
    this.authenticationService
      .signOut()
      .then(() => this.router.navigate(["auth", "login"]));
  }

  redirectComponent(url) {
    this.router.navigate(url)
  }

}
