import { Component, AfterViewInit } from '@angular/core';
import { AuthenticationService } from '@app/auth/services/authentication.service';

@Component({
  selector: 'app-list-permissions',
  templateUrl: './list-permissions.component.html',
  styleUrls: ['./list-permissions.component.scss']
})
export class ListPermissionsComponent implements AfterViewInit {
  isUpdateOperation = false;
  constructor(public authService: AuthenticationService) { }

  ngAfterViewInit() {}

}
