import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/shared/shared.module';
import { httpInterceptorProviders } from '@app/auth/interceptors';
import { FormComponent } from './pages/form/form.component';
import { ListQuotationsComponent } from './pages/list-quotations/list-quotations.component';
import { ReportComponent } from './pages/report/report.component';
import { SearchComponent } from './pages/search/search.component';
import { CurrencyPipe } from "@angular/common";
import { NgxCurrencyInputModule } from 'ngx-currency-input';

@NgModule({
  declarations: [FormComponent, ListQuotationsComponent, ReportComponent, SearchComponent],
  imports: [CommonModule, SharedModule, NgxCurrencyInputModule],
  providers: [httpInterceptorProviders, CurrencyPipe],
})
export class QuotationsModule { }
