<div class="container-fluid">
  <mat-tab-group mat-stretch-tabs>
    <mat-tab
      *ngIf="authService.checkActionsPermission('QUOT', ['CREATE'])"
      label="Crear"
      ><ng-template matTabContent
        ><app-quotations-form
          [isUpdateOperation]="isUpdateOperation"
          PK="null"
        ></app-quotations-form></ng-template
    ></mat-tab>
    <mat-tab
      *ngIf="authService.checkActionsPermission('QUOT', ['UPDATE', 'PRINT'])"
      label="Buscar"
      ><ng-template matTabContent
        ><app-quotations-search></app-quotations-search></ng-template
    ></mat-tab>
    <mat-tab
      *ngIf="authService.checkActionsPermission('QUOT', ['REPORT'])"
      label="Informe"
      ><ng-template matTabContent><app-report></app-report></ng-template
    ></mat-tab>
  </mat-tab-group>
</div>
