import { CurrencyPipe } from '@angular/common';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FormatsService {
  TRANSLATE = {
    PK: 'Cons',
    enterpriseName: 'Empresa',
    contactName: 'Contacto',
    observations: 'Observaciones',
    creationDate: 'Fecha',
    status: 'Estado'
  }

  constructor(private currencyPipe: CurrencyPipe) { }

  generatePrint(data) {
    let i = 1;
    let document: Array<any> = [
      {
        text: [
          {text: 'NIT 901.362.544-6 \n', alignment: "center"},
          {text: 'BRR EL RECREO CL 31 D 80 B 57 \n', alignment: "center"},
          {text: 'Cel: 3127718388 \n', alignment: "center"},
          {text: 'Cartagena – Bolívar \n', alignment: "center"},
        ]
      },
      {
        lineHeight: 1,
        text: ".", color: "white",
      },
      {
        text: [
          {text: `Orden de compra N° ${data.PK.substr(4)} \n`, alignment: "center", bold: true},
          {text: 'Fecha: ', alignment: "center", bold: true},
          {text: `${data.creationDate} \n`, alignment: "center"},
        ]
      },
      {
        lineHeight: 2,
        text: ".", color: "white",
      },
      {
        layout: 'noBorders',
        table: {
          headerRows: 1,
          widths: ["10%","35%", "10%", "10%", "35%"],
          body: [
            [{ text: 'PROVEEDOR', bold: true, colSpan: 2 }, {}, { text: ''}, { text: 'DIRECCIÓN DE ENTREGA', bold: true, colSpan: 2  }, {}],
            [{ text: `${data.enterpriseName}`, colSpan: 2 }, {}, { text: ''}, { text: 'Dirección', bold: true }, {text: `${data.deliveryAddress}`}],
            [{ text: `NIT`, bold: true }, {text: `${data.enterpriseDv ? `${data.enterpriseId} - ${data.enterpriseDv}` : data.enterpriseId}`}, { text: '' }, { text: 'Ciudad', bold: true }, {text: `${data.deliveryCity}`}],
            [{ text: `Dirección`, bold: true }, {text: `${data.enterpriseAddress}`}, {  text: ''}, { text: 'Recibe', bold: true }, {text: `${data.deliveryReceive}`}],
            [{ text: `Celular`, bold: true }, {text: `${data.enterprisePhone}`}, {  text: ''}, { text: 'Celular', bold: true }, {text: `${data.deliveryCellphone}`}],
            [{ text: `Ciudad`, bold: true }, {text: `${data.enterpriseCity}`}, {  text: ''}, { text: '' }, {text: '' }],
          ],
        },
      },
      {
        lineHeight: 1,
        text: ".", color: "white",
      },
      {
        table: {
          headerRows: 1,
          widths: ["30%","30%", "40%"],
          body: [
            [{ text: 'Tiempo de entrega', bold: true, alignment: "center" }, { text: 'Términos de pago', bold: true, alignment: "center" }, { text: 'Solicitado por', bold: true, alignment: "center" }],
            [{ text: `${data.deliveryTime}`, alignment: "center" }, { text: `${data.paymentForm}`, alignment: "center" }, { text: `${data.requesterName}`, alignment: "center" }],
          ],
        },
      },
      {
        lineHeight: 1,
        text: ".", color: "white",
      },
      {
        table: {
          headerRows: 1,
          widths: ["10%","40%", "10%", "10%", "15%", "15%"],
          body: [
            [{ text: 'Item', bold: true, alignment: "center" }, { text: 'Descripción', bold: true, alignment: "center" }, { text: 'Cantidad', bold: true, alignment: "center" }, { text: 'IVA', bold: true, alignment: "center" }, { text: 'Precio unitario', bold: true, alignment: "center" }, { text: 'TOTAL', bold: true, alignment: "center" }],
            ...data.items.map(d => ([i++, d.item, d.quantity, { text: `${d.IVA}%` }, { text: `${this.currencyPipe.transform(d.value, 'USD', 'symbol-narrow', '1.2-2')}`, alignment: 'right'}, { text: `${this.currencyPipe.transform(d.quantity*d.value, 'USD', 'symbol-narrow', '1.2-2')}`, alignment: 'right'}])),
          ],
        },
      },
      {
        lineHeight: 1,
        text: ".", color: "white",
      },
      {
        table: {
          headerRows: 7,
          widths: ["70%", "15%", "15%"],
          body: [
            [{ text: `Observaciones: \n ${data.observations || ""}`, rowSpan: 7 }, {text: 'Valor', bold: true}, {text: `${this.currencyPipe.transform(data.valueSubtotal, 'USD', 'symbol-narrow', '1.2-2')}`, alignment: 'right'}],
            [{}, {text: 'Descuento', bold: true}, {text: `${this.currencyPipe.transform(data.discount, 'USD', 'symbol-narrow', '1.2-2')}`, alignment: 'right'}],
            [{}, {text: 'Sub-total', bold: true}, {text: `${this.currencyPipe.transform(data.valueSubtotal-data.discount, 'USD', 'symbol-narrow', '1.2-2')}`, alignment: 'right'}],
            [{}, {text: 'IVA ', bold: true}, {text: `${this.currencyPipe.transform(data.valueIVA, 'USD', 'symbol-narrow', '1.2-2')}`, alignment: 'right'}],
            [{}, {text: 'Retefuente', bold: true}, {text: `${this.currencyPipe.transform(data.valueRetefuente, 'USD', 'symbol-narrow', '1.2-2')}`, alignment: 'right'}],
            [{}, {text: 'ReteICA', bold: true}, {text: `${this.currencyPipe.transform(data.valueReteica, 'USD', 'symbol-narrow', '1.2-2')}`, alignment: 'right'}],
            [{text: `Autorizado por: ${data.status == "Aprobada" ? data.approverName : ""}`}, {text: 'TOTAL', bold: true}, {text: `${this.currencyPipe.transform(data.valueTotal, 'USD', 'symbol-narrow', '1.2-2')}`, alignment: 'right'}],
          ],
        },
      },
    ];

    return document;
  }

  generateReport(data) {
    return [
      {
        table: {
          headerRows: 1,
          widths: ["5%","20%", "20%", "25%", "15%", "15%"],
          body: [
            [{ text: 'Cons.', bold: true, alignment: "center" }, { text: 'Empresa', bold: true, alignment: "center" }, { text: 'Contacto', bold: true, alignment: "center" }, { text: 'Observaciones', bold: true, alignment: "center" }, { text: 'Fecha', bold: true, alignment: "center" }, { text: 'Estado', bold: true, alignment: "center" }],
            ...data.map(d => ([d.PK.substr(4), d.enterpriseName, d.contactName, d.observations || "", d.creationDate, d.status])),
          ],
        },
      },
    ];
  }
}
