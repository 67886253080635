<ng-container>
  <div *ngIf="globalError" class="alert alert-danger text-center">
    {{ globalError }}
  </div>

  <div
    *ngIf="isInitializing && !globalError"
    class="d-flex justify-content-center flex-column align-items-center mt-5"
  >
    <h4 class="my-4">Cargando todo...</h4>
    <mat-spinner></mat-spinner>
  </div>

  <div *ngIf="!isInitializing && !globalError" class="container-fluid">
    <div class="container-fluid" [hidden]="!showTable">
      <h4 *ngIf="profile === 'PROF1' || profile === 'PROF3'" class="text-right mt-4">Total: {{ total }}</h4>
      <div class="row">
        <div class="col">
          <app-data-table
            [names]="tableNames"
            [data]="result"
          ></app-data-table>
        </div>
      </div>
    </div>
  </div>

  <br />
</ng-container>