import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { Auth } from "aws-amplify";
import { AuthenticationService } from "../services/authentication.service";
import { Router } from "@angular/router";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(
    private authService: AuthenticationService,
    private router: Router
  ) {}


  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return new Observable((subscriber) => {
      Auth.currentSession().then((credentials) => {
        let authReq = req;
        let contentType = req.headers.get("Content-Type");
        if (contentType !== "binary/octet-stream") {
          if (req.body) {
            for(let field of Object.keys(req.body)){
              if (req.body[field] === "") {
                delete req.body[field];
              }
            }
          }
          authReq = req.clone({
            headers: req.headers.set(
              "Authorization",
              credentials.getIdToken().getJwtToken()
            ),
          });
        }
        next.handle(authReq).subscribe(subscriber);
      }).catch(error => {
        if (error && error.code === "NotAuthorizedException") {
          this.authService.signOut()
          .then(() => this.router.navigate(["auth", "login"]));
        }
      });
    });
  }
}
