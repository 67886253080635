import { CurrencyPipe } from '@angular/common';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FormatsService {

  TRANSLATE_GENERAL = {
    personName: 'Persona',
    personSalary: 'Salario',
    categoryName: 'Categoría',
    type: 'Tipo',
    date: 'Fecha',
    time: 'Hora',
    status: 'Estado',
    location: 'Ubicación',
    address: 'Dirección'
  }

  TRANSLATE_CONSOLIDADO = {
    name: 'Persona',
    startDate: 'Fecha inicial',
    startTime: 'Hora inicial',
    finishDate: 'Fecha final',
    finishTime: 'Hora final',
  }

  TRANSLATE_LIQUIDACION = {
    name: 'Nombre',
    salary: 'Salario',
    timeShouldWork: 'Horas periodo',
    timeWorked: 'Horas ordinarias',
    valueTimeWorked: 'Valor horas ordinarias',
    timeDayExtra: 'HED',
    valueTimeDayExtra: 'Valor HED',
    timeNightExtra: 'HEN',
    valueTimeNightExtra: 'Valor HEN',
    timeHolyDayExtra: 'HEDF',
    valueTimeHolyDayExtra: 'Valor HEDF',
    timeHolyNightExtra: 'HENF',
    valueTimeHolyNightExtra: 'Valor HENF',
    total: 'Total'
  } 

  constructor(private currencyPipe: CurrencyPipe) { }

  generateReportGeneral(data) {
    return [
      {
        table: {
          headerRows: 1,
          widths: ["15%", "10%", "10%", "8%", "8%", "8%", "8%", "13%", "20%"],
          body: [
            [{ text: 'Persona', bold: true, alignment: "center" }, { text: 'Salario', bold: true, alignment: "center" }, { text: 'Categoría', bold: true, alignment: "center" }, { text: 'Tipo', bold: true, alignment: "center" }, { text: 'Fecha', bold: true, alignment: "center" }, { text: 'Hora', bold: true, alignment: "center" }, { text: 'Estado', bold: true, alignment: "center" }, { text: 'Ubicación', bold: true, alignment: "center" }, { text: 'Dirección', bold: true, alignment: "center" }],
            ...data.map(d => ([d.personName, {text: `${d.personSalary ? this.currencyPipe.transform(d.personSalary, 'USD', 'symbol-narrow', '1.2-2') : ''}`, alignment: 'right'}, d.categoryName, d.type, d.date, d.time, d.status, d.location, d.address])),
          ],
        },
      },
    ];
  }

  generateReportConsolidado(data) {
    return [
      {
        table: {
          headerRows: 1,
          widths: ["40%", "15%", "15%", "15%", "15%"],
          body: [
            [{ text: 'Persona', bold: true, alignment: "center" }, { text: 'Fecha inicial', bold: true, alignment: "center" }, { text: 'Hora inicial', bold: true, alignment: "center" }, { text: 'Fecha final', bold: true, alignment: "center" }, { text: 'Hora final', bold: true, alignment: "center" }],
            ...data.map(d => ([d.name, d.startDate || '', d.startTime || '', d.finishDate || '', d.finishTime || ''])),
          ],
        },
      },
    ];
  }

  generateReportLiquidacion(data) {
    return [
      {
        table: {
          headerRows: 1,
          widths: ["11%", "10%", "4%", "4%", "9%", "4%", "9%", "4%", "9%", "4%", "9%", "4%", "9%", "10%",],
          body: [
            [{ text: 'Nombre', bold: true, alignment: "center" }, { text: 'Salario', bold: true, alignment: "center" }, { text: 'Horas per.', bold: true, alignment: "center" }, { text: 'Horas ord.', bold: true, alignment: "center" }, { text: 'Valor horas ord.', bold: true, alignment: "center" }, { text: 'HED', bold: true, alignment: "center" },{ text: 'Valor HED', bold: true, alignment: "center" },{ text: 'HEN', bold: true, alignment: "center" },{ text: 'Valor HEN', bold: true, alignment: "center" },{ text: 'HEDF', bold: true, alignment: "center" },{ text: 'Valor HEDF', bold: true, alignment: "center" },{ text: 'HENF', bold: true, alignment: "center" },{ text: 'Valor HENF', bold: true, alignment: "center" },{ text: 'Total', bold: true, alignment: "center" },],
            ...data.map(d => ([d.name, {text: `${this.currencyPipe.transform(d.salary, 'USD', 'symbol-narrow', '1.2-2')}`}, d.timeShouldWork, d.timeWorked, {text: `${this.currencyPipe.transform(d.valueTimeWorked, 'USD', 'symbol-narrow', '1.2-2')}`}, d.timeDayExtra, {text: `${this.currencyPipe.transform(d.valueTimeDayExtra, 'USD', 'symbol-narrow', '1.2-2')}`}, d.timeNightExtra, {text: `${this.currencyPipe.transform(d.valueTimeNightExtra, 'USD', 'symbol-narrow', '1.2-2')}`}, d.timeHolyDayExtra, {text: `${this.currencyPipe.transform(d.valueTimeHolyDayExtra, 'USD', 'symbol-narrow', '1.2-2')}`}, d.timeHolyNightExtra, {text: `${this.currencyPipe.transform(d.valueTimeHolyNightExtra, 'USD', 'symbol-narrow', '1.2-2')}`}, {text: `${this.currencyPipe.transform(d.total, 'USD', 'symbol-narrow', '1.2-2')}`}])),
          ],
        },
      },
    ];
  }
}
