<div class="container-fluid">
  <mat-tab-group mat-stretch-tabs>
    <mat-tab
      *ngIf="authService.checkActionsPermission('PERM', ['CREATE'])"
      label="Crear"
      ><ng-template matTabContent
        ><app-permissions-form
        [isUpdateOperation]="isUpdateOperation"
        PK="null"
      ></app-permissions-form></ng-template
    ></mat-tab>
    <mat-tab
      *ngIf="authService.checkActionsPermission('PERM', ['UPDATE', 'PRINT'])"
      label="Buscar"
      ><ng-template matTabContent
        ><app-permissions-search></app-permissions-search></ng-template
    ></mat-tab>
    <mat-tab
      *ngIf="authService.checkActionsPermission('PERM', ['REPORT'])"
      label="Informe"
      ><ng-template matTabContent><app-report></app-report></ng-template
    ></mat-tab>
  </mat-tab-group>
</div>
