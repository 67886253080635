import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { AuthenticationService } from "@app/auth/services/authentication.service";
import { AlertComponent } from "@app/shared/components/alert/alert.component";
import { SaveListComponent } from "@app/shared/components/save-list/save-list.component";
import { GeolocationService } from "@app/shared/services/geolocation.service";
import { ShareService } from "@app/shared/services/share.service";
import { TimeService } from "@app/time/services/time.service";
import { Auth } from "aws-amplify";
import { forkJoin } from "rxjs";

@Component({
  selector: "app-time-form",
  templateUrl: "./form.component.html",
  styleUrls: ["./form.component.scss"],
})
export class FormComponent implements OnInit {
  @Input()
  isUpdateOperation;
  @Input()
  PK;
  @Output()
  action = new EventEmitter();
  isInitializing = true;
  isUploading = false;
  globalError: string;
  uploadError: string;
  successMessage: String;
  categories;
  users;
  manual;

  constructor(
    private formBuilder: FormBuilder,
    private timeService: TimeService,
    private authService: AuthenticationService,
    private shareService: ShareService,
    private geolocationService: GeolocationService,
    public dialog: MatDialog
  ) {}
  public formControl: FormGroup;
  profile = this.authService.cognitoUser["profile"];

  ngOnInit(): void {
    this.formControl = this.formBuilder.group({
      type: ["", [Validators.required]],
      category: [
        "",
        [
          Validators.required,
          Validators.pattern("^CATE[0-9]+$"),
          Validators.maxLength(11),
        ],
      ],
      location: ["", [Validators.required, Validators.maxLength(512)]],
      address: ["", [Validators.required, Validators.maxLength(512)]],
      order: ["", [Validators.pattern("^[0-9]+$")]],
      isManual: ["", []],
      date: ["", []],
      time: ["", [Validators.pattern("^([01][0-9]|2[0-3]):([0-5][0-9]):([0-5][0-9])$")]],
      person: [
        "",
        [Validators.pattern("^PERR[0-9]+$"), Validators.maxLength(11)],
      ],
      enterpriseOrder: [{ value: "", disabled: true }],
      startDateOrder: [{ value: "", disabled: true }],
      finishDateOrder: [{ value: "", disabled: true }],
      descriptionOrder: [{ value: "", disabled: true }],
    });

    Auth.currentSession().then((credentials) => {
      let categoryRq = this.shareService.getList("CATE");
      let userRq = this.timeService.getUsers({ type: "L", status: "ACTIVE" });
      if (this.isUpdateOperation) {
        let timeRq = this.timeService.get({ PK: this.PK });
        forkJoin([timeRq, categoryRq, userRq]).subscribe(
          ([timeRs, categoryRs, userRs]) => {
            const TIME = timeRs["body"][0];
            TIME.date += "T05:00:00.000Z";
            console.log(TIME)
            this.categories = categoryRs["body"];
            this.users = userRs["body"];
            this.formControl.patchValue(TIME);
            this.enableManualInputs();            
            this.isInitializing = false;
          },
          (error) => {
            this.globalError =
              "Se ha presentado un error, por favor vuelva a intentarlo";
            this.isInitializing = false;
          }
        );
      } else {
        forkJoin([categoryRq, userRq]).subscribe(
          ([categoryRs, userRs]) => {
            this.categories = categoryRs["body"];
            this.users = userRs["body"];
            this.disableManualInputs();
            this.getGeoLocation();
            this.isInitializing = false;
          },
          (error) => {
            this.globalError =
              "Se ha presentado un error, por favor vuelva a intentarlo";
            this.isInitializing = false;
          }
        );
      }
    });
  }

  getGeoLocation() {    
    this.geolocationService.getCurrentPosition().subscribe({
      next: (position) => {        
        this.formControl.get('location').setValue(`${position.coords.latitude}, ${position.coords.longitude}`);
        this.geolocationService.getAddress(position.coords.latitude, position.coords.longitude).subscribe(
          (data) => {
            this.formControl.get('address').setValue(data[0].display_name)
          },
          (error) => {},
        )
      },
      error: (error) => {
        this.alertMessage('No se obtuvo su ubicación. Verifique que esté habilitada la opción de ubicación y recarge la página.')
      },
    });
  }

  disableManualInputs() {
    this.formControl.get("person").clearValidators();
    this.formControl.get("person").reset();
    this.formControl.get("person").disable({ emitEvent: false });
    this.formControl.get("person").updateValueAndValidity({ emitEvent: false });

    this.formControl.get("date").clearValidators();
    this.formControl.get("date").reset();
    this.formControl.get("date").disable({ emitEvent: false });
    this.formControl.get("date").updateValueAndValidity({ emitEvent: false });

    this.formControl.get("time").clearValidators();
    this.formControl.get("time").reset();
    this.formControl.get("time").disable({ emitEvent: false });
    this.formControl.get("time").updateValueAndValidity({ emitEvent: false });
  }

  enableManualInputs() {
    this.formControl
      .get("person")
      .setValidators([
        Validators.required,
        Validators.pattern("^PERR[0-9]+$"),
        Validators.maxLength(11),
      ]);
    this.formControl.get("person").enable({ emitEvent: false });
    this.formControl.get("person").updateValueAndValidity({ emitEvent: false });

    this.formControl.get("date").setValidators([Validators.required]);
    this.formControl.get("date").enable({ emitEvent: false });
    this.formControl.get("date").updateValueAndValidity({ emitEvent: false });

    this.formControl
      .get("time")
      .setValidators([
        Validators.required,
        Validators.pattern("^([01][0-9]|2[0-3]):([0-5][0-9]):([0-5][0-9])$"),
      ]);
    this.formControl.get("time").enable({ emitEvent: false });
    this.formControl.get("time").updateValueAndValidity({ emitEvent: false });
  }

  isManual() {  
    if (this.manual) this.enableManualInputs();
    else this.disableManualInputs();
  }

  searchId(event) {
    let PK = `WORD${event}`;
    let orderData = {
      enterpriseOrder: "",
      startDateOrder: "",
      finishDateOrder: "",
      descriptionOrder: "",
    };
    this.timeService.getWork({ PK }).subscribe(
      (data) => {
        if (data["body"].length !== 0) {
          orderData.enterpriseOrder = data["body"][0].enterpriseName;
          orderData.startDateOrder = data["body"][0].startDate;
          orderData.finishDateOrder = data["body"][0].finishDate;
          orderData.descriptionOrder = data["body"][0].description;

          this.formControl.patchValue(orderData);
        } else {
          this.noOrderFound("No existe la orden de trabajo digitada");
        }
      },
      (error) => {
        this.noOrderFound(
          "Se presentó un error al consultar la orden, por favor intente nuevamente"
        );
      }
    );
  }

  noOrderFound(message) {
    this.formControl.get("enterpriseOrder").reset();
    this.formControl.get("startDateOrder").reset();
    this.formControl.get("finishDateOrder").reset();
    this.formControl.get("descriptionOrder").reset();
    this.formControl.get("order").reset();  
    
    this.alertMessage(message);
  }

  alertMessage(message) {
    let action = true;
    let label = message;
    this.dialog.open(AlertComponent, {
      width: "500px",
      data: {
        label,
        action,
      },
    });
  }

  disabledOrderInputs() {
    this.formControl.get("enterpriseOrder").disable({ emitEvent: false });
    this.formControl.get("startDateOrder").disable({ emitEvent: false });
    this.formControl.get("finishDateOrder").disable({ emitEvent: false });
    this.formControl.get("descriptionOrder").disable({ emitEvent: false });
  }

  send() {
    this.isUploading = true;
    this.globalError = "";
    this.uploadError = "";
    this.successMessage = "";
    let values = this.formControl.value;
    if (!values.order) delete values.order;
    values.isManual = this.manual || false;
    this.formControl.disable({ emitEvent: false });
    if (this.isUpdateOperation) {
      values.PK = this.PK;
      delete values.location;
      delete values.address;
      delete values.isManual;
      this.timeService.updateTime(values).subscribe(
        (data) => {
          this.successMessage = "Operación realizada con exito";
          this.formControl.enable({ emitEvent: false });
          this.disabledOrderInputs();         
          this.isUploading = false;
        },
        (error) => {
          this.formControl.enable({ emitEvent: false });
          this.disabledOrderInputs();
          this.uploadError = error.error.status.message;
          this.isUploading = false;
        }
      );
    } else {
      this.timeService.saveTime(values).subscribe(
        (data) => {
          this.successMessage = "Operación realizada con exito";
          this.formControl.enable({ emitEvent: false });
          this.disabledOrderInputs();
          this.disableManualInputs();
          this.manual = false;
          this.formControl.reset();
          this.getGeoLocation();
          this.formControl.updateValueAndValidity({ emitEvent: false });
          this.isUploading = false;
        },
        (error) => {
          this.formControl.enable({ emitEvent: false });
          this.disabledOrderInputs();
          this.isManual();
          this.uploadError = error.error.status.message;
          this.isUploading = false;
        }
      );
    }
  }

  save(event, entity) {
    event.stopPropagation();
    event.preventDefault();
    let list;
    let control;
    let label;
    if (entity === "CATE") {
      label = "Categoría";
      list = this.categories;
      control = this.formControl.get("category");
    }

    const dialogRef = this.dialog.open(SaveListComponent, {
      width: "500px",
      data: {
        current: list,
        entity,
        label,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        list.push(result);
        control.setValue(result["PK"]);
      }
    });
  }

  getButtonText() {
    return this.isUploading
      ? this.isUpdateOperation
        ? "Actualizando hoja de tiempos..."
        : "Creando hoja de tiempos..."
      : this.isUpdateOperation
      ? "Actualizar hoja de tiempos"
      : "Crear hoja de tiempos";
  }

  close() {
    this.action.emit();
  }
}
