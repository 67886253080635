<div class="hero">
  <!-- <mat-progress-bar mode="query" color="#B4288A"></mat-progress-bar> -->
  <div class="row mx-0 h-100">
    <div
      class="col-md p-5 text-light d-flex flex-column justify-content-center align-items-center"
      style="background: linear-gradient(45deg, #0f5249, #ffffff)"
    >
      <img
        src="./assets/images/logo/logotipo.png"
        alt=""
        style="max-width: 250px;"        
        class="my-4"
      />
      <h3 style="color: black; text-align: center;"  class="my-4">Bienvenido al sistema de gestión de KBT Solutions</h3>
    </div>

    <div class="col-md-5 p-5 mt-5">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
