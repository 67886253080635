import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { CoreModule } from "./core/core.module";
import { AppComponent } from "./app.component";
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { MomentModule } from 'angular2-moment';
import { Ng2SearchPipeModule } from "ng2-search-filter";

@NgModule({
  declarations: [AppComponent],
  imports: [BrowserModule, CoreModule,
    NgIdleKeepaliveModule.forRoot(),
    MomentModule, Ng2SearchPipeModule],
  bootstrap: [AppComponent],
})
export class AppModule {}
