<div class="container-fluid">
    <mat-tab-group mat-stretch-tabs>
      <mat-tab
        *ngIf="authService.checkActionsPermission('TREX', ['CREATE'])"
        label="Crear"
        ><ng-template matTabContent
          ><app-form
            [isUpdateOperation]="isUpdateOperation"
            PK="null"
          ></app-form></ng-template
      ></mat-tab>
      <mat-tab
        *ngIf="authService.checkActionsPermission('TREX', ['UPDATE', 'PRINT'])"
        label="Buscar"
        ><ng-template matTabContent><app-search></app-search></ng-template
      ></mat-tab>
      <mat-tab
        *ngIf="authService.checkActionsPermission('TREX', ['REPORT'])"
        label="Informe"
        ><ng-template matTabContent><app-report></app-report></ng-template
      ></mat-tab>
    </mat-tab-group>
  </div>