import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FormatsService {

  constructor() { }

  TRANSLATE = {
    typeEquipmentName: "Tipo de equipo",
    markName: "Marca",
    modelName: "Modelo",
    typeDocumentName: "Tipo de documento"
  }

  generateReport(data) {
    return [
      {
        table: {
          headerRows: 1,
          widths: ["22%", "22%", "22%", "22%", "12%"],
          body: [
            [{ text: 'Tipo de equipo', bold: true, alignment: "center" }, { text: 'Marca', bold: true, alignment: "center" }, { text: 'Modelo', bold: true, alignment: "center" }, { text: 'Tipo de documento', bold: true, alignment: "center" }, { text: 'Adjunto', bold: true, alignment: "center" }],
            ...data.map(d => ([d.typeEquipmentName, d.markName, d.modelName, d.typeDocumentName, { text: `${d.urlAtt ? 'Descargar archivo' : ''}`, link: `${d.urlAtt}`, decoration: 'underline'}])),
          ],
        },
      },
    ];
  }
}
