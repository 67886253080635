import { Component, OnInit } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { AuthenticationService } from "@app/auth/services/authentication.service";
import { ShareService } from "@app/shared/services/share.service";
import { FormatsService } from "@app/users/services/formats.service";
import { UsersService } from "@app/users/services/users.service";

@Component({
  selector: "app-search",
  templateUrl: "./search.component.html",
  styleUrls: ["./search.component.scss"],
})
export class SearchComponent implements OnInit {
  isUpdateOperation = false;
  isInitializing = true;
  isUploading = false;
  globalError: string;
  uploadError: string;
  showTable = false;
  users;
  result;
  PK;
  tableNames = {
    name: "Nombre",
    edit: "Editar",
    print: "Imprimir",
  };

  formControl = this.formBuilder.group({
    PK: ["", [Validators.required]],
    identification: ["", [Validators.required]],
  });

  constructor(
    private formBuilder: FormBuilder,
    private userService: UsersService,
    private formatsService: FormatsService,
    private shareService: ShareService,
    private authService: AuthenticationService
  ) {
    this.formControl.get("PK").valueChanges.subscribe((value) => {
      if (value) {
        this.formControl.get("identification").clearValidators();
        this.formControl.get("identification").reset();
        this.formControl.get("identification").disable({ emitEvent: false });
        this.formControl
          .get("identification")
          .updateValueAndValidity({ emitEvent: false });
      } else {
        this.formControl.get("identification").enable({ emitEvent: false });
        this.formControl
          .get("identification")
          .setValidators([Validators.required]);
        this.formControl
          .get("identification")
          .updateValueAndValidity({ emitEvent: false });
      }
    });

    this.formControl.get("identification").valueChanges.subscribe((value) => {
      if (value) {
        this.formControl.get("PK").clearValidators();
        this.formControl.get("PK").reset();
        this.formControl.get("PK").disable({ emitEvent: false });
        this.formControl.get("PK").updateValueAndValidity({ emitEvent: false });
      } else {
        this.formControl.get("PK").setValidators([Validators.required]);
        this.formControl.get("PK").enable({ emitEvent: false });
        this.formControl.get("PK").updateValueAndValidity({ emitEvent: false });
      }
    });
  }

  ngOnInit(): void {
    if (!this.authService.checkActionsPermission("PERR", ["UPDATE"])) {
      delete this.tableNames.edit;
    }

    if (!this.authService.checkActionsPermission("PERR", ["PRINT"])) {
      delete this.tableNames.print;
    }

    this.userService.get({ all: "ALL" }).subscribe(
      (data) => {
        this.users = data["body"];
        this.isInitializing = false;
      },
      (error) => {
        this.globalError =
          "Se ha presentado un error, por favor vuelva a intentarlo";
        this.isInitializing = false;
      }
    );
  }

  action(event) {
    if (event.action == "PRINT") {
      const PK = event.element.PK;
      this.userService.get({ PK }).subscribe(
        (data) => {
          const content = this.formatsService.generatePrint(data["body"][0]);
          this.shareService.export("PDF", content, null, {title: "Registro de personal"});
        },
        (error) => {}
      );
    } else if (event.action == "UPDATE") {
      this.isUpdateOperation = true;
      this.PK = event.element.PK;
    }
  }

  send() {
    this.isUploading = true;
    this.globalError = "";
    this.uploadError = "";
    this.formControl.disable({ emitEvent: false });
    const PK = this.formControl.value.PK;
    const identification = this.formControl.value.identification;
    let data = PK ? { PK, type: "L" } : { identification };
    this.userService.get(data).subscribe(
      (data) => {
        this.formControl.enable({ emitEvent: false });
        this.result = data["body"];  
        this.isUploading = false;
        if (!this.result.length) {
          this.uploadError = "No se encontraron resultados";
          this.showTable = false;
          return;
        }
        this.showTable = true;
      },
      (error) => {
        this.formControl.enable({ emitEvent: false });
        this.uploadError = "Se ha presentado un error, vuelva a intentarlo";
        this.isUploading = false;
      }
    );
  }

  close() {
    this.isUpdateOperation = false;
  }
}
