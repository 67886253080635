import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AlertComponent } from '@app/shared/components/alert/alert.component';
import { ShareService } from '@app/shared/services/share.service';
import { WarrantiesService } from '@app/warranties/services/warranties.service';
import { Auth } from 'aws-amplify';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class FormComponent implements OnInit {
  @Input()
  isUpdateOperation;
  @Input()
  PK;
  @Output()
  action = new EventEmitter();
  isInitializing = true;
  isUploading = false;
  globalError: string;
  uploadError: string;
  successMessage: String;
  users;
  invalidOrder = true;

  constructor(
    private formBuilder: FormBuilder,
    private warrantiesService: WarrantiesService,
    private shareService: ShareService,
    public dialog: MatDialog
  ) { }

  public formControl: FormGroup;

  ngOnInit(): void {
    this.formControl = this.formBuilder.group({
      order: ["", [Validators.required, Validators.pattern("^[0-9]+$")]],
      enterprise: [{value: "", disabled: true}],
      startDate: [{value: "", disabled: true}],
      service: [{value: "", disabled: true}],
      interveneEquipment: [{value: "", disabled: true}],
      description: [{value: "", disabled: true}],   
      date: ["", [Validators.required]],
      technician: ["", [Validators.required, Validators.pattern("^PERR[0-9]+$"), Validators.maxLength(11)]],
      descriptionWarranty: ["", [Validators.required, Validators.maxLength(4000)]],
      observations: ["", [Validators.required, Validators.maxLength(4000)]],
    });

    Auth.currentSession().then((credentials) => {
      let userRq = this.warrantiesService.getUsers({all:"ALL"});
      if (this.isUpdateOperation) {
        let warrantyRq = this.warrantiesService.get({ PK: this.PK });
        forkJoin([warrantyRq, userRq]).subscribe(
          ([warrantyRs, userRs]) => {
            const WARRANTY = warrantyRs["body"][0];
            WARRANTY.enterprise = WARRANTY.enterpriseName;
            WARRANTY.date += "T05:00:00.000Z";
            this.users = userRs["body"];
            this.formControl.patchValue(WARRANTY);
            this.isInitializing = false;
            this.invalidOrder = false;
          },
          (error) => {
            this.globalError =
              "Se ha presentado un error, por favor vuelva a intentarlo";
            this.isInitializing = false;
          }
        );             
      } else {      
        forkJoin([userRq]).subscribe(
          ([userRs]) => {
            this.users = userRs["body"];
            this.isInitializing = false;
          },
          (error) => {
            this.globalError =
              "Se ha presentado un error, por favor vuelva a intentarlo";
            this.isInitializing = false;
          }
        );
      }
    });
  }

  searchId(event) {
    let PK = `WORD${event}`;
    let orderData = {
      enterprise: "",
      startDate: "",
      service: "",
      interveneEquipment: "",
      description: "",
    }
    this.warrantiesService.getWork({ PK }).subscribe(
      (data) => {
        if (data["body"].length !== 0) {
          orderData.enterprise = data["body"][0].enterpriseName;
          orderData.startDate = data["body"][0].startDate;
          orderData.service = data["body"][0].service;
          orderData.interveneEquipment = data["body"][0].interveneEquipment;
          orderData.description = data["body"][0].description;

          this.formControl.patchValue(orderData);
          this.invalidOrder = false;  
        } else {
          this.noOrderFound("No existe la orden de trabajo digitada")
        }
      },
      (error) => {
        this.noOrderFound("Se presentó un error al consultar la orden, por favor intente nuevamente")
      }
    );     
  }

  noOrderFound(message) {
    this.formControl.get('enterprise').reset();
    this.formControl.get('startDate').reset();
    this.formControl.get('service').reset();
    this.formControl.get('interveneEquipment').reset();
    this.formControl.get('description').reset();

    let action = true;
    let label = message;
    this.dialog.open(AlertComponent, {
      width: "500px",
      data: {
        label,
        action,
      },
    });
    
    this.invalidOrder = true;
  }

  disabledInputs() {
    this.formControl.get("enterprise").disable({ emitEvent: false });
    this.formControl.get("startDate").disable({ emitEvent: false });
    this.formControl.get("service").disable({ emitEvent: false }); 
    this.formControl.get("interveneEquipment").disable({ emitEvent: false });
    this.formControl.get("description").disable({ emitEvent: false });     
  }

  send() {
    this.isUploading = true;
    this.globalError = "";
    this.uploadError = "";
    this.successMessage = "";
    let values = this.formControl.value;
    this.formControl.disable({ emitEvent: false });
    if (this.isUpdateOperation) {
      values.PK = this.PK;
      this.warrantiesService.updateWarranty(values).subscribe(
        (data) => {
          this.formControl.enable({ emitEvent: false });
          this.disabledInputs()
          this.successMessage = "Operación realizada con exito";
          this.isUploading = false;
        },
        (error) => {
          this.formControl.enable({ emitEvent: false });
          this.disabledInputs()
          this.uploadError = error.error.status.message;
          this.isUploading = false;
        }
      );
    } else {
      this.warrantiesService.saveWarranty(values).subscribe(
        (data) => {
          this.formControl.enable({ emitEvent: false });
          this.disabledInputs()
          this.formControl.reset();
          this.formControl.updateValueAndValidity({ emitEvent: false });
          this.successMessage = "Operación realizada con exito";
          this.isUploading = false;
        },
        (error) => {
          this.formControl.enable({ emitEvent: false });
          this.disabledInputs()
          this.uploadError = error.error.status.message;
          this.isUploading = false;
        }
      );
    }
  }

  getButtonText() {
    return this.isUploading
      ? this.isUpdateOperation
        ? "Actualizando garantía..."
        : "Creando garantía..."
      : this.isUpdateOperation
      ? "Actualizar garantía"
      : "Crear garantía";
  }

  close() {
    this.action.emit();
  }
}
