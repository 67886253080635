<div *ngIf="globalError" class="alert alert-danger text-center">
    {{ globalError }}
</div>

<div
  *ngIf="isInitializing && !globalError"
  class="d-flex justify-content-center flex-column align-items-center mt-5"
>
  <h4 class="my-4">Cargando todo...</h4>
  <mat-spinner></mat-spinner>
</div>

<div *ngIf="!isInitializing && !globalError" class="container-fluid">
    <ng-container *ngIf="isUpdateOperation">
        <button
          class="d-block mt-2"
          mat-raised-button
          color="primary"
          (click)="close()"
        >
          <mat-icon>keyboard_backspace</mat-icon> Regresar
        </button>
    </ng-container>
    <form [formGroup]="formControl" autocomplete="off">
      <br>
      <p>Los campos con asterisco &#40;&#42;&#41; son obligatorios</p>
        <div class="row">
            <div class="col-md-4">
                <mat-form-field appearance="outline" class="d-block w-100 mb-2" (keydown.enter)="$event.preventDefault()">
                  <mat-label>Fecha de salida *</mat-label>
                  <input
                    readonly
                    formControlName="outDate"
                    matInput
                    [matDatepicker]="picker"
                  />
                  <mat-datepicker-toggle
                  (click)="picker.open()"
                    matSuffix
                    [for]="picker"
                  ></mat-datepicker-toggle>
                  <mat-datepicker touchUi #picker></mat-datepicker>
                </mat-form-field>
            </div>  
          <div class="col-md-4">
            <mat-form-field appearance="outline" class="d-block w-100 mb-2" (keydown.enter)="$event.preventDefault()">
              <mat-label>Empresa *</mat-label>
              <mat-select formControlName="enterprise">
                <mat-option>Elige una</mat-option>
                <mat-option
                  *ngFor="let enterprise of enterprises"
                  [value]="enterprise.PK"
                >
                  {{ enterprise.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-md-4">
            <mat-form-field appearance="outline" class="d-block w-100 mb-2" (keydown.enter)="$event.preventDefault()">
              <mat-label>Contacto *</mat-label>
              <mat-select formControlName="contact">
                <mat-option>Elige una</mat-option>
                <mat-option
                  *ngFor="let contact of contacts"
                  [value]="contact.SK"
                >
                  {{ contact.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div> 
          <div class="col-md-6">
            <mat-form-field appearance="outline" class="d-block w-100 mb-2" (keydown.enter)="$event.preventDefault()">
              <mat-label>Guía *</mat-label>
              <input formControlName="guide" type="text" matInput />
            </mat-form-field>
          </div> 
          <div class="col-md-6">
            <mat-form-field appearance="outline" class="d-block w-100 mb-2" (keydown.enter)="$event.preventDefault()">
              <mat-label>Transportadora *</mat-label>
              <input formControlName="transporter" type="text" matInput />
            </mat-form-field>
          </div> 
          <div class="col-md-12">
            <mat-form-field appearance="outline" class="d-block w-100 mb-2">
              <mat-label>Observaciones</mat-label>
              <textarea formControlName="observations" type="text" matInput rows="7"></textarea>
            </mat-form-field>
          </div>                
      </div>
      <hr>
      <h2>Items</h2>      
      <div formArrayName="items">
        <div *ngFor="let itemrow of formControl.controls.items['controls']; let i=index" [formGroupName]="i">
          <hr>
        <div class="row">
          <div  class="col-md-12">
            <button
            class="d-block mt-2 button-remove"
            mat-raised-button
            color="primary"
            (click)="$event.preventDefault()"
            (click)="deleteRow(i)"
          >
            <mat-icon>remove</mat-icon>
          </button>
          <br>
          </div>
        </div>
        <div class="row">        
          <div class="col-md-8">
            <mat-form-field appearance="outline" class="d-block w-100 mb-2" (keydown.enter)="$event.preventDefault()">
              <mat-label>Producto *</mat-label>
              <input formControlName="product" type="text" matInput />
            </mat-form-field>
          </div> 
          <div class="col-md-4">
            <mat-form-field appearance="outline" class="d-block w-100 mb-2" (keydown.enter)="$event.preventDefault()">
              <mat-label>Cantidad *</mat-label>
              <input formControlName="quantity" type="number" min='0' matInput />
            </mat-form-field>
          </div>
        </div>
        </div>
      </div>   
      <hr>   
      <button
          class="d-block mt-2"
          mat-raised-button
          color="primary"
          style="margin: auto;"
          (click)="addNewRow($event)"
        >
          <mat-icon>add</mat-icon>
        </button>
        <br>
        
        <div *ngIf="uploadError" class="alert alert-danger text-center">
          {{ uploadError }}
        </div>
    
        <div *ngIf="successMessage" class="alert alert-success text-center">
          {{ successMessage }}
        </div>
    
        <button
          class="d-block w-100 mt-2"
          mat-raised-button
          color="primary"
          [disabled]="formControl.invalid || isUploading || formArr.length === 0"
          (click)="$event.preventDefault()"
          (click)="send()"
        >
          {{ getButtonText() }}
        </button>
    </form>
</div>
<br />
