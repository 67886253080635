import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { AuthenticationService } from '@app/auth/services/authentication.service';
import { TimeService } from '@app/time/services/time.service';

@Component({
  selector: 'app-time-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
  isUpdateOperation = false;
  isInitializing = true;
  isUploading = false;
  globalError: string;
  uploadError: string;
  successMessage: String;
  showTable = false;
  users;
  result;
  PK;

  tableNames = {
    personName: "Persona",
    type: "Tipo",
    categoryName: "Categoría",
    date: "Fecha|date",
    time: "Hora",
    status: "Estado",
    editOrder: "Pendiente revisión|Rechazado",
  };

  formControl = this.formBuilder.group({
    consecutivo: ["", []],
    person: ["", []],
    status: ["", []],
    startDate: ["", []],
    finishDate: ["", []],
  });

  constructor(
    private formBuilder: FormBuilder,
    private timeService: TimeService,
    private authService: AuthenticationService,
  ) { }

  ngOnInit(): void {
    this.timeService.getUsers({ type: "L", status: "ACTIVE" }).subscribe(
      (data) => {
        this.users = data["body"];
        this.isInitializing = false;
      },
      (error) => {
        this.globalError =
          "Se ha presentado un error, por favor vuelva a intentarlo";
        this.isInitializing = false;
      }
    );

    if (!this.authService.checkActionsPermission("TIME", ["UPDATE"])) {
      delete this.tableNames.editOrder;
    }
  }

  action(event) {
    if (event.action == "UPDATE") {
      this.isUpdateOperation = true;
      this.PK = event.element.PK;
    }
  }

  send() {
    if (
      (this.formControl.get("startDate").value &&
        !this.formControl.get("finishDate").value) ||
      (!this.formControl.get("startDate").value &&
        this.formControl.get("finishDate").value)
    ) {
      this.uploadError = "Debe ingresar fecha inicial y fecha final";
      return;
    } else if (
      !this.formControl.get("startDate").value &&
      !this.formControl.get("finishDate").value &&
      !this.formControl.get("person").value &&
      !this.formControl.get("status").value
    ) {
      this.uploadError = "Debe ingresar al menos un criterio de búsqueda";
      return;
    } else {
      this.isUploading = true;
      this.globalError = "";
      this.uploadError = "";
      this.formControl.disable({ emitEvent: false });
      let values = this.formControl.getRawValue();
      const person = values.person ? values.person : undefined;
      const status = values.status ? values.status : undefined;
      const startDate = values.startDate ? values.startDate : undefined;
      const finishDate = values.finishDate ? values.finishDate : undefined;
      this.timeService
        .get({ type: "S", person, status, startDate, finishDate })
        .subscribe(
          (data) => {
            this.formControl.enable({ emitEvent: false });
            this.result = data["body"];
            this.isUploading = false;
            if (!this.result.length) {
              this.uploadError = "No se encontraron resultados";
              this.showTable = false;
              return;
            }

            this.showTable = true;
          },
          (error) => {
            this.formControl.enable({ emitEvent: false });
            this.uploadError = "Se ha presentado un error, vuelva a intentarlo";
            this.isUploading = false;
          }
        );
    }
  }

  close() {
    this.isUpdateOperation = false;
  }
}
