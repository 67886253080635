import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { DocumentsService } from '@app/documents/services/documents.service';
import { FormatsService } from '@app/documents/services/formats.service';
import { ShareService } from '@app/shared/services/share.service';
import { Auth } from 'aws-amplify';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})
export class ReportComponent implements OnInit {
  isInitializing = false;
  isUploading = false;
  globalError: string;
  uploadError: string;
  typeDocuments;
  marks;
  models;
  typeEquipments;
  data;

  formControl = this.formBuilder.group({
    typeFile: ["", [Validators.required]],
    typeDocument: ["", []],
    typeEquipment: ["", []],
    mark: ["", []],
    model: ["", []]
  });

  constructor(
    private formBuilder: FormBuilder,
    private documentsService: DocumentsService,
    private formatsService: FormatsService,
    private shareService: ShareService,
  ) { }

  ngOnInit(): void {
    Auth.currentSession().then((credentials) => {
      let typeDocumentsRq = this.shareService.getList("TYDO");
      let marksRq = this.shareService.getList("MARK");
      let modelsRq = this.shareService.getList("MODE");
      let typeEquipmentsRq = this.shareService.getList("TYEQ");
      forkJoin([typeDocumentsRq, marksRq, modelsRq, typeEquipmentsRq]).subscribe(
        ([typeDocumentsRs, marksRs, modelsRs, typeEquipmentsRs]) => {
          this.typeDocuments = typeDocumentsRs["body"];
          this.marks = marksRs["body"];
          this.models = modelsRs["body"];
          this.typeEquipments = typeEquipmentsRs["body"];
          this.isInitializing = false;
        },
        (error) => {
          this.globalError =
            "Se ha presentado un error, por favor vuelva a intentarlo";
          this.isInitializing = false;
        }
      );
    });
  }

  send() {
    this.globalError = "";
    this.uploadError = ""; 
    if (
      !this.formControl.get("typeDocument").value &&
      !this.formControl.get("typeEquipment").value &&
      !this.formControl.get("mark").value &&
      !this.formControl.get("model").value
    ) {
      this.uploadError = "Debe ingresar al menos un criterio de búsqueda";
      return;
    } else {
      let values = this.formControl.value;
      const typeDocument = values.typeDocument ? values.typeDocument : undefined;
      const typeEquipment = values.typeEquipment ? values.typeEquipment : undefined;
      const mark = values.mark ? values.mark : undefined;
      const model = values.model ? values.model : undefined;
      this.documentsService.get({type: 'R', typeDocument, typeEquipment, mark, model}).subscribe(
        (data) => {
          this.data = data["body"];
          this.isInitializing = false;
          if (!this.data.length) {
            this.uploadError = "No se encontraron resultados";
            return;
          }
          let content =
          this.formControl.value.typeFile === "PDF"
            ? this.formatsService.generateReport(this.data)
            : this.shareService.getTitles(this.formatsService.TRANSLATE, this.data);
            this.shareService.export(
            this.formControl.value.typeFile,
            content,
            "Biblioteca de documentos",
            { pageSize: "FOLIO", pageOrientation: "landscape", title: "Informe de biblioteca de documentos" }
          );
        },
        (error) => {
          this.uploadError =
            "Se ha presentado un error, por favor vuelva a intentarlo";
          this.isInitializing = false;
        }
      );
    }
  }
}
