<div *ngIf="globalError" class="alert alert-danger text-center">
    {{ globalError }}
</div>

<div
  *ngIf="isInitializing && !globalError"
  class="d-flex justify-content-center flex-column align-items-center mt-5"
>
  <h4 class="my-4">Cargando todo...</h4>
  <mat-spinner></mat-spinner>
</div>

<div *ngIf="!isInitializing && !globalError" class="container-fluid">
    <form [formGroup]="formControl" (submit)="send()" autocomplete="off">
      <br>
      <p>Los campos con asterisco &#40;&#42;&#41; son obligatorios</p>
      <div class="row">
        <div class="col-md-4">
          <mat-form-field appearance="outline" class="d-block w-100 mb-2">
            <mat-label>Tipo de archivo *</mat-label>
            <mat-select formControlName="typeDocument">
              <mat-option>Elige una</mat-option>
              <mat-option value="PDF">PDF</mat-option>
              <mat-option value="EXCEL">Excel</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-md-4">
            <mat-form-field appearance="outline" class="d-block w-100 mb-2">
              <mat-label>Empresa</mat-label>
              <mat-select formControlName="enterprise">
                <mat-option>Elige una</mat-option>
                <mat-option *ngFor="let enterprise of enterprises" [value]="enterprise.PK">
                    {{ enterprise.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-md-4">
            <mat-form-field appearance="outline" class="d-block w-100 mb-2">
              <mat-label>Estado</mat-label>
              <mat-select formControlName="status">
                <mat-option>Elige una</mat-option>
                <div *ngIf="!path; else informStatus">
                  <mat-option value="Ejecución">Ejecución</mat-option>
                  <mat-option value="Cancelada">Cancelada</mat-option>
                  <mat-option value="Facturar">Facturar</mat-option>
                  <mat-option value="Facturada">Facturada</mat-option>
                  <mat-option value="Finalizada">Finalizada</mat-option>
                </div>
                <ng-template #informStatus>
                  <mat-option value="Guardado">Guardado</mat-option>
                  <mat-option value="Pendiente aprobación">Pendiente aprobación</mat-option>
                  <mat-option value="Aprobado">Aprobado</mat-option>
                  <mat-option value="Rechazado">Rechazado</mat-option>
                </ng-template>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-md-4">
            <mat-form-field appearance="outline" class="d-block w-100 mb-2">
              <mat-label>Servicio</mat-label>
              <mat-select formControlName="service">
                <mat-option>Elige una</mat-option>
                <mat-option value="Calibración">Calibración</mat-option>
                <mat-option value="Mantenimiento preventivo">Mantenimiento preventivo</mat-option>
                <mat-option value="Mantenimiento correctivo">Mantenimiento correctivo</mat-option>
                <mat-option value="Montaje">Montaje</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-md-4">
            <mat-form-field appearance="outline" class="d-block w-100 mb-2">
              <mat-label>Fecha inicial</mat-label>
              <input
                readonly
                formControlName="startDate"
                matInput
                [matDatepicker]="picker"
              />
              <mat-datepicker-toggle
              (click)="picker.open()"
                matSuffix
                [for]="picker"
              ></mat-datepicker-toggle>
              <mat-datepicker touchUi #picker></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="col-md-4">
            <mat-form-field appearance="outline" class="d-block w-100 mb-2">
              <mat-label>Fecha final</mat-label>
              <input
                readonly
                formControlName="finishDate"
                matInput
                [matDatepicker]="picker1"
              />
              <mat-datepicker-toggle
              (click)="picker.open()"
                matSuffix
                [for]="picker1"
              ></mat-datepicker-toggle>
              <mat-datepicker touchUi #picker1></mat-datepicker>
            </mat-form-field>
          </div> 
      </div>
  
      <div *ngIf="uploadError" class="alert alert-danger text-center">
        {{ uploadError }}
      </div>
  
      <button
        class="d-block w-100 mt-2"
        mat-raised-button
        color="primary"        
        [disabled]="formControl.invalid || isUploading"
      >
        {{ isUploading ? "Generando..." : "Generar" }}
      </button>
    </form>
  </div>
  <br />
