import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ShareService } from '@app/shared/services/share.service';
import { WorkService } from '@app/work/services/work.service';
import { Auth } from 'aws-amplify';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-work-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class FormComponent implements OnInit {
  @Input()
  isUpdateOperation;  
  @Input()
  PK;
  @Output()
  action = new EventEmitter();
  isInitializing = true;
  isUploading = false;
  globalError: string;
  uploadError: string;
  successMessage: String;
  attachedError: string;
  enterprises;
  contacts = [];
  users;
  fileName = '';
  file:File;
  extensionFile;
  url;
  isNewReport;
  
  constructor(
    private formBuilder: FormBuilder,
    private workService: WorkService,    
    private shareService: ShareService,
    public dialog: MatDialog

  ) { }  

  onFileSelected(event) {
    this.file = event.target.files[0];
    if (this.file) {
        this.fileName = this.file.name;
        this.extensionFile =this.fileName.split('.').pop();
        this.formControl.get("fileExtension").setValue(this.extensionFile);
        this.url = "";
    }
  }

  public formControl: FormGroup;

  ngOnInit(): void {
    this.formControl = this.formBuilder.group({
      enterprise: ["", [Validators.required, Validators.pattern("^ENTR[0-9]+$"), Validators.maxLength(11)]],
      contact: ["", [Validators.required, Validators.pattern("^CONV[0-9]+$"), Validators.maxLength(11)]],
      startDate: ["", [Validators.required]],
      finishDate: ["", [Validators.required]],
      service: ["", [Validators.required]],
      technician: ["", [Validators.required, Validators.pattern("^PERR[0-9]+$"), Validators.maxLength(11)]],
      description: ["", [Validators.required, Validators.maxLength(4000)]],
      placeService: ["", [Validators.required]],
      interveneEquipment: ["", [Validators.required, Validators.maxLength(512)]],
      fileExtension: ["", []],      
    });

    this.formControl.get("enterprise").valueChanges.subscribe((value) => {
      if (value) {
        this.workService.getEnterprises({contacts:value}).subscribe(data=>{this.contacts = data["body"]});
      } else {
        this.contacts = [];
      }
    });

    Auth.currentSession().then((credentials) => {
    let enterpriseRq = this.workService.getEnterprises({all:"ALL"});
    let userRq = this.workService.getUsers({all:"ALL"});
    if (this.isUpdateOperation) {
      let workRq = this.workService.get({ PK: this.PK });
      forkJoin([workRq, enterpriseRq, userRq]).subscribe(
        ([workRs, enterpriseRs, userRs]) => {
          this.formControl.addControl("status", new FormControl('', Validators.required));
          this.formControl.addControl("reminder", new FormControl(''));          
          this.formControl.addControl("items", this.formBuilder.array([]));
          const WORK = workRs["body"].filter(item => item.SK === this.PK)[0];
          this.enterprises = enterpriseRs["body"];
          this.users = userRs["body"];
          WORK.reminder =  WORK.reminder ? WORK.reminder: ""; 
          WORK.work =  WORK.work ? WORK.work: "";
          WORK.observations =  WORK.observations ? WORK.observations: "";  
          console.log(WORK);    
          WORK.items = workRs["body"].filter(item => item.SK !== this.PK);
          this.url = WORK.urlAtt;  
          WORK.startDate += "T05:00:00.000Z";
          WORK.finishDate += "T05:00:00.000Z";
          for (let i = 0; i < WORK.items.length; i++) {
            WORK.items[i].date += "T05:00:00.000Z";
            let group = this.initItemRows();
            group.addControl('SK', new FormControl('', Validators.required));
            this.formArr.push(group)     
          }
          this.formControl.patchValue(WORK);          
          this.isInitializing = false;
        },
        (error) => {
          this.globalError =
            "Se ha presentado un error, por favor vuelva a intentarlo";
          this.isInitializing = false;
        }
      );      
    }else {      
      forkJoin([enterpriseRq, userRq]).subscribe(
        ([enterpriseRs, userRs]) => {
          this.enterprises = enterpriseRs["body"];
          this.users = userRs["body"];
          this.isInitializing = false;
        },
        (error) => {
          this.globalError =
            "Se ha presentado un error, por favor vuelva a intentarlo";
          this.isInitializing = false;
        }
      );
    }
    });
  }

  get formArr() {
    return this.formControl.get('items') as FormArray;
  }

  initItemRows() {
    return this.formBuilder.group({
    date: ["", [Validators.required]],
    technician: ["", [Validators.required, Validators.pattern("^PERR[0-9]+$"), Validators.maxLength(11)]],
    initialHour: ['', [Validators.required, Validators.pattern("^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$")]],
    finalHour: ['', [Validators.required, Validators.pattern("^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$")]],
    });
  }
  addNewRow(event) {
    event.stopPropagation();
    event.preventDefault();
    this.formArr.push(this.initItemRows());
  }

  deleteRow(index: number) {
    if (this.formArr.length > 1) this.formArr.removeAt(index); 
  } 
  
  send() {
    this.isUploading = true;
    this.globalError = "";
    this.uploadError = "";
    this.successMessage = "";
    this.attachedError = "";
    this.formControl.disable({ emitEvent: false });
    if (this.isUpdateOperation) {
      let data = this.formControl.getRawValue();
      data.PK = this.PK;      
      data.fileExtension = data.fileExtension ? data.fileExtension : undefined;
      this.workService.updateWork(data).subscribe(
        (data) => {
          if(data["body"].url) {
            this.shareService.uploadFile(data["body"].url, this.file).subscribe(data=>{;
              this.successMessage = "Operación realizada con exito";
              this.fileName = 'Aún no se ha subido ningún archivo.';
            },
            (error) => {
              this.attachedError = "El registro se guardó correctamente pero se ha presentado un error al subir el archivo";
              this.fileName = 'Aún no se ha subido ningún archivo.';
            })
          }
          else {            
            this.successMessage = "Operación realizada con exito";            
          }  
          this.formControl.enable({ emitEvent: false });
          this.isUploading = false;
        },
        (error) => {
          this.formControl.enable({ emitEvent: false });
          this.uploadError = error.error.status.message;
          this.isUploading = false;
        }
      );
    } else {
      let values = this.formControl.getRawValue();
      if(!values.fileExtension) delete values.fileExtension;
      this.workService.saveWork(values).subscribe(
        (data) => {          
          if(data["body"].url) {
            this.shareService.uploadFile(data["body"].url, this.file).subscribe(data=>{
              this.successMessage = "Operación realizada con exito";
              this.fileName = 'Aún no se ha subido ningún archivo.';
            },
            (error) => {
              this.attachedError = "El registro se guardó correctamente pero se ha presentado un error al subir el archivo";
              this.fileName = 'Aún no se ha subido ningún archivo.';
            })
          }
          else {            
            this.successMessage = "Operación realizada con exito";            
          }   
          this.formControl.enable({ emitEvent: false });
          this.formControl.reset();
          this.formControl.updateValueAndValidity({emitEvent: false});
          this.isUploading = false;       
        },
        (error) => {
          this.formControl.enable({ emitEvent: false });
          this.uploadError = error.error.status.message;
          this.isUploading = false;
        }
      );
    }
  }

  getButtonText() {
    return this.isUploading
      ? this.isUpdateOperation
        ? "Actualizando orden de trabajo..."
        : "Creando orden de trabajo..."
      : this.isUpdateOperation
      ? "Actualizar orden de trabajo"
      : "Crear orden de trabajo";
  }

  close() {
    this.action.emit();
  }
}
