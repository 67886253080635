import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AuthenticationService } from '@app/auth/services/authentication.service';
import { AlertComponent } from '@app/shared/components/alert/alert.component';
import { ShareService } from '@app/shared/services/share.service';
import { FormatsService } from '@app/travel-expenses/services/formats.service';
import { TravelExpensesService } from '@app/travel-expenses/services/travel-expenses.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
  isUpdateOperation = false;
  isInitializing = true;
  isUploading = false;
  globalError: string;
  uploadError: string;
  successMessage: String;
  showTable = false;
  users;
  result;
  PK;

  tableNames = {
    PK: "Consecutivo",
    order: "Orden",
    enterpriseName: "Empresa",
    personName: "Persona",    
    creationDate: "Fecha|date",
    status: "Estado",
    editOrder: "Guardado|Rechazado",
    approveItem: "Pendiente aprobación",
    print: "Imprimir",
  };

  formControl = this.formBuilder.group({
    consecutivo: ["", []],
    order: ["", [Validators.pattern("^[0-9]+$")]],
    person: ["", []],
    startDate: ["", []],
    finishDate: ["", []],
  });

  constructor(
    private formBuilder: FormBuilder,
    private travelExpensesService: TravelExpensesService,
    private authService: AuthenticationService,
    private formatsService: FormatsService,
    private shareService: ShareService,
    public dialog: MatDialog,
  ) {
    this.formControl.get("consecutivo").valueChanges.subscribe((value) => {
      if (value) {
        this.formControl.get("order").clearValidators();
        this.formControl.get("order").reset();
        this.formControl.get("order").disable({ emitEvent: false });
        this.formControl
          .get("order")
          .updateValueAndValidity({ emitEvent: false });

        this.formControl.get("person").clearValidators();
        this.formControl.get("person").reset();
        this.formControl.get("person").disable({ emitEvent: false });
        this.formControl
          .get("person")
          .updateValueAndValidity({ emitEvent: false });

        this.formControl.get("startDate").clearValidators();
        this.formControl.get("startDate").reset();
        this.formControl.get("startDate").disable({ emitEvent: false });
        this.formControl
          .get("startDate")
          .updateValueAndValidity({ emitEvent: false });

        this.formControl.get("finishDate").clearValidators();
        this.formControl.get("finishDate").reset();
        this.formControl.get("finishDate").disable({ emitEvent: false });
        this.formControl
          .get("finishDate")
          .updateValueAndValidity({ emitEvent: false });
      } else {
        this.formControl.get("order").enable({ emitEvent: false });
        this.formControl
          .get("order")
          .updateValueAndValidity({ emitEvent: false });

        this.formControl.get("person").enable({ emitEvent: false });
        this.formControl
          .get("person")
          .updateValueAndValidity({ emitEvent: false });

        this.formControl.get("startDate").enable({ emitEvent: false });
        this.formControl
          .get("startDate")
          .updateValueAndValidity({ emitEvent: false });

        this.formControl.get("finishDate").enable({ emitEvent: false });
        this.formControl
          .get("finishDate")
          .updateValueAndValidity({ emitEvent: false });
      }
    });
  }

  ngOnInit(): void {
    if (!this.authService.checkActionsPermission("TREX", ["UPDATE"])) {
      delete this.tableNames.editOrder;
    }

    if (!this.authService.checkActionsPermission("TREX", ["APPROVE"])) {
      delete this.tableNames.approveItem;
    }

    if (!this.authService.checkActionsPermission("TREX", ["PRINT"])) {
      delete this.tableNames.print;
    }

    this.travelExpensesService.getUsers({ all: "ALL" }).subscribe(
      (data) => {
        this.users = data["body"];
        this.isInitializing = false;
      },
      (error) => {
        this.globalError =
          "Se ha presentado un error, por favor vuelva a intentarlo";
        this.isInitializing = false;
      }
    );
  }

  action(event) {
    if (event.action == "PRINT") {
      const PK = event.element.PK;
      this.travelExpensesService.get({ PK }).subscribe(
        (data) => {
          const TRAVELEXPENSES = data["body"].filter((item) => item.SK === PK)[0];
          TRAVELEXPENSES.items = data["body"].filter((item) => item.SK !== PK);
          const content = this.formatsService.generatePrint(TRAVELEXPENSES);
          this.shareService.export("PDF", content, null, {title: `Legalización de viáticos No. ${PK.substr(4)}`});
        },
        (error) => {}
      );
    } else if (event.action == "UPDATE") {
      this.isUpdateOperation = true;
      this.PK = event.element.PK;
    } else if (event.action == "APPROVE_ITEM") {
      this.uploadError = "";
      this.successMessage ="";
      let label = "Estado Legalización de viatico";
      let statusInform = true;
      this.PK = event.element.PK;
      let index = this.result.findIndex(element => element.PK === this.PK);
      const dialogRef = this.dialog.open(AlertComponent, {
        width: "500px",
        data: {
          label,
          statusInform,
        },
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          result.PK = event.element.PK;
          this.travelExpensesService.updateStatus(result).subscribe(
            (data) => {
              this.successMessage = "Operación realizada con exito";
              this.result[index].status = result.status;
            },
            (error) => {
              this.uploadError = "Se ha presentado un error, vuelva a intentarlo";
            }
          );
        }        
      });
    }
  }

  send() {
    if (
      (this.formControl.get("startDate").value &&
        !this.formControl.get("finishDate").value) ||
      (!this.formControl.get("startDate").value &&
        this.formControl.get("finishDate").value)
    ) {
      this.uploadError = "Debe ingresar fecha inicial y fecha final";
      return;
    } else if (
      !this.formControl.get("startDate").value &&
      !this.formControl.get("consecutivo").value &&
      !this.formControl.get("finishDate").value &&
      !this.formControl.get("order").value &&
      !this.formControl.get("person").value
    ) {
      this.uploadError = "Debe ingresar al menos un criterio de búsqueda";
      return;
    } else {
      this.isUploading = true;
      this.globalError = "";
      this.uploadError = "";
      this.formControl.disable({ emitEvent: false });
      let values = this.formControl.getRawValue();
      const consecutivo = values.consecutivo ? values.consecutivo : undefined;
      const order = values.order ? values.order : undefined;
      const person = values.person ? values.person : undefined;
      const startDate = values.startDate ? values.startDate : undefined;
      const finishDate = values.finishDate ? values.finishDate : undefined;
      this.travelExpensesService
        .get({
          type: "S",
          order,
          person,
          startDate,
          finishDate,
          consecutivo
        })
        .subscribe(
          (data) => {
            if (this.formControl.get("consecutivo").value) {
              this.formControl.get("consecutivo").enable({ emitEvent: false });
              this.formControl.get("person").disable({ emitEvent: false });
              this.formControl.get("order").disable({ emitEvent: false });
              this.formControl.get("startDate").disable({ emitEvent: false });
              this.formControl.get("finishDate").disable({ emitEvent: false });
            } else {
              this.formControl.enable({ emitEvent: false });
            }
            this.result = data["body"];
            this.isUploading = false;
            if (!this.result.length) {
              this.uploadError = "No se encontraron resultados";
              this.showTable = false;
              return;
            }
            this.showTable = true;
          },
          (error) => {
            if (this.formControl.get("consecutivo").value) {
              this.formControl.get("consecutivo").enable({ emitEvent: false });
              this.formControl.get("person").disable({ emitEvent: false });
              this.formControl.get("order").disable({ emitEvent: false });
              this.formControl.get("startDate").disable({ emitEvent: false });
              this.formControl.get("finishDate").disable({ emitEvent: false });
            } else {
              this.formControl.enable({ emitEvent: false });
            }
            this.uploadError = "Se ha presentado un error, vuelva a intentarlo";
            this.isUploading = false;
          }
        );
    }   
  }

  close() {
    this.isUpdateOperation = false;
  }
}
