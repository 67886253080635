<div class="container-fluid">
  <mat-tab-group mat-stretch-tabs>
    <mat-tab
      *ngIf="authService.checkActionsPermission('WORD', ['CREATE']) && path == '/dashboard/order'"
      label="Crear"
      ><ng-template matTabContent
        ><app-work-form
          [isUpdateOperation]="isUpdateOperation"
          PK="null"
        ></app-work-form></ng-template
    ></mat-tab>
    <mat-tab
      *ngIf="authService.checkActionsPermission('WOIN', ['CREATE']) && path != '/dashboard/order'"
      label="Crear"
      ><ng-template matTabContent
        ><app-new-report
          [isUpdateOperation]="isUpdateOperation"
          PK="null"
        ></app-new-report></ng-template
    ></mat-tab>
    <mat-tab
      *ngIf="
        authService.checkActionsPermission('WORD', ['UPDATE', 'PRINT']) && path == '/dashboard/order'
      "
      label="Buscar"
      ><ng-template matTabContent
        ><app-work-search></app-work-search></ng-template
    ></mat-tab>
    <mat-tab
      *ngIf="
        authService.checkActionsPermission('WOIN', ['UPDATE', 'PRINT']) && path != '/dashboard/order'
      "
      label="Buscar"
      ><ng-template matTabContent
        ><app-work-search></app-work-search></ng-template
    ></mat-tab>
    <mat-tab
      *ngIf="authService.checkActionsPermission('WORD', ['REPORT']) && path == '/dashboard/order'"
      label="Informe"
      ><ng-template matTabContent
        ><app-work-report></app-work-report></ng-template
    ></mat-tab>
    <mat-tab
      *ngIf="authService.checkActionsPermission('WOIN', ['REPORT']) && path != '/dashboard/order'"
      label="Informe"
      ><ng-template matTabContent
        ><app-work-report></app-work-report></ng-template
    ></mat-tab>
  </mat-tab-group>
</div>
