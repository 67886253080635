import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListMaterialRequestComponent } from './pages/list-material-request/list-material-request.component';
import { FormComponent } from './pages/form/form.component';
import { ReportComponent } from './pages/report/report.component';
import { SearchComponent } from './pages/search/search.component';
import { SharedModule } from '@app/shared/shared.module';
import { httpInterceptorProviders } from '@app/auth/interceptors';



@NgModule({
  declarations: [ListMaterialRequestComponent, FormComponent, ReportComponent, SearchComponent],
  imports: [CommonModule, SharedModule],
  providers: [httpInterceptorProviders],
})
export class MaterialRequestModule { }
