import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ShareService } from '@app/shared/services/share.service';
import { FormatsService } from '@app/warranties/services/formats.service';
import { WarrantiesService } from '@app/warranties/services/warranties.service';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})
export class ReportComponent implements OnInit {
  isInitializing = false;
  isUploading = false;
  globalError: string;
  uploadError: string;
  enterprises;
  data;

  formControl = this.formBuilder.group({
    typeDocument: ["", [Validators.required]],
    enterprise: ["", []],
    startDate: ["", []],
    finishDate: ["", []],
  });

  constructor(
    private formBuilder: FormBuilder,
    private warrantiesService: WarrantiesService,
    private formatsService: FormatsService,
    private shareService: ShareService,
  ) {
    this.warrantiesService.getEnterprises({ all: "ALL" }).subscribe(
      (data) => {
        this.enterprises = data["body"];
        this.isInitializing = false;
      },
      (error) => {
        this.globalError =
          "Se ha presentado un error, por favor vuelva a intentarlo";
        this.isInitializing = false;
      }
    );
   }

  ngOnInit(): void {
  }

  send() {
    this.globalError = "";
    this.uploadError = ""; 
    if((this.formControl.get("startDate").value && !this.formControl.get("finishDate").value) ||
    (!this.formControl.get("startDate").value && this.formControl.get("finishDate").value)) {
      this.uploadError = "Debe ingresar fecha inicial y fecha final";
      return;
    } else if (!this.formControl.get("startDate").value && !this.formControl.get("finishDate").value && 
    !this.formControl.get("enterprise").value) {
      this.uploadError = "Debe ingresar al menos un criterio de búsqueda";
      return;
    } else {
      let values = this.formControl.value;
      const enterprise = values.enterprise ? values.enterprise: undefined;
      const startDate = values.startDate ? values.startDate: undefined;
      const finishDate = values.finishDate ? values.finishDate: undefined;
      this.warrantiesService.get({type: 'R', enterprise, startDate, finishDate}).subscribe(
        (data) => {
          console.log(data);
          this.data = data["body"];
          this.isInitializing = false;
          if (!this.data.length) {
            this.uploadError = "No se encontraron resultados";
            return;
          }
          let content =
          this.formControl.value.typeDocument === "PDF"
            ? this.formatsService.generateReport(this.data)
            : this.shareService.getTitles(this.formatsService.TRANSLATE, this.data);
            this.shareService.export(
            this.formControl.value.typeDocument,
            content,
            "Orden de trabajo",
            { pageSize: "FOLIO", pageOrientation: "landscape", title: "Informe de garantías de órdenes de trabajo" }
          );
        },
        (error) => {
          this.uploadError =
            "Se ha presentado un error, por favor vuelva a intentarlo";
          this.isInitializing = false;
        }
      );
    }
  }
}
