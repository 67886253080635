import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from "@angular/forms";
import { MatDialog } from '@angular/material/dialog';
import { AuthenticationService } from '@app/auth/services/authentication.service';
import { FormatsService } from '@app/order/services/formats.service';
import { OrdersService } from '@app/order/services/orders.service';
import { AlertComponent } from '@app/shared/components/alert/alert.component';
import { ShareService } from '@app/shared/services/share.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
  isUpdateOperation = false;
  isInitializing = true;
  isUploading = false;
  globalError: string;
  uploadError: string;
  successMessage: String;
  showTable = false;
  enterprises;
  orderNumber;
  result;
  PK;

  tableNames = {
    PK: "Consecutivo",
    enterpriseName: "Empresa",
    creationDate: "Fecha|date",
    editPurchaseOrder: "Pendiente aprobación|Rechazada|Aprobada",
    approve: "Pendiente aprobación",
    print: "Imprimir",
  };

  formControl = this.formBuilder.group({
    enterprise: ["", []],
    status: ["", []],
    startDate: ["", []],
    finishDate: ["", []],
  });

  constructor(
    private formBuilder: FormBuilder,
    private shareService: ShareService,
    private authService: AuthenticationService,
    private ordersService: OrdersService,
    private formatsService: FormatsService,
    public dialog: MatDialog,
  ) { 
    this.ordersService.getEnterprises({ type: "Proveedor" }).subscribe(
      (data) => {
        this.enterprises = data["body"];
        this.isInitializing = false;
      },
      (error) => {
        this.globalError =
          "Se ha presentado un error, por favor vuelva a intentarlo";
        this.isInitializing = false;
      }
    );
  }

  ngOnInit(): void {
    if (!this.authService.checkActionsPermission("PUOR", ["UPDATE"])) {
      delete this.tableNames.editPurchaseOrder;
    }

    if (!this.authService.checkActionsPermission("PUOR", ["PRINT"])) {
      delete this.tableNames.print;
    }

    if (!this.authService.checkActionsPermission("PUOR", ["APPROVE"])) {
      delete this.tableNames.approve;
    }
  }

  action(event) {
    if (event.action == "PRINT") {
      const PK = event.element.PK;
      this.ordersService.get({ PK }).subscribe(
        (data) => {
          const ORDER = data["body"].filter((item) => item.SK === PK)[0];
          ORDER.items = data["body"].filter(
            (item) => item.SK !== PK && item.entity === "PUOR|ITPO"
          );
          ORDER.items.sort((a, b) => a.SK.slice(4) - b.SK.slice(4));
          const content = this.formatsService.generatePrint(ORDER);
          this.shareService.export("PDF", content, null, {
            title: "KBT SOLUTIONS S.A.S",
            pageMargins: [50, 90, 50, 30],
          });
        },
        (error) => {}
      );
    } else if (event.action == "UPDATE") {
      this.isUpdateOperation = true;
      this.PK = event.element.PK;
    } else if (event.action == "APPROVE") {
      this.uploadError = "";
      this.successMessage ="";
      let label = "Estado Orden de compra";
      let statusInform = true;
      let order = true;
      this.PK = event.element.PK;
      let index = this.result.findIndex(element => element.PK === this.PK);
      const dialogRef = this.dialog.open(AlertComponent, {
        width: "500px",
        data: {
          label,
          statusInform,
          order
        },
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          result.PK = event.element.PK;
          this.ordersService.updateStatus(result).subscribe(
            (data) => {
              this.successMessage = "Operación realizada con exito";
              this.result[index].status = result.status;
            },
            (error) => {
              this.uploadError = "Se ha presentado un error, vuelva a intentarlo";
            }
          );
        }        
      });
    }
  }

  send() {
    if (
      (this.formControl.get("startDate").value &&
        !this.formControl.get("finishDate").value) ||
      (!this.formControl.get("startDate").value &&
        this.formControl.get("finishDate").value)
    ) {
      this.uploadError = "Debe ingresar fecha inicial y fecha final";
      return;
    } else if (
      !this.formControl.get("startDate").value &&
      !this.formControl.get("finishDate").value &&
      !this.formControl.get("enterprise").value &&
      !this.formControl.get("status").value
    ) {
      this.uploadError = "Debe ingresar al menos un criterio de búsqueda";
      return;
    } else {
      this.isUploading = true;
      this.globalError = "";
      this.uploadError = "";
      this.formControl.disable({ emitEvent: false });
      let values = this.formControl.getRawValue();
      const enterprise = values.enterprise ? values.enterprise : undefined;
      const status = values.status ? values.status : undefined;
      const startDate = values.startDate ? values.startDate : undefined;
      const finishDate = values.finishDate ? values.finishDate : undefined;
      this.ordersService
        .get({ type: "S", enterprise, status, startDate, finishDate })
        .subscribe(
          (data) => {
            this.formControl.enable({ emitEvent: false });
            this.result = data["body"];
            this.isUploading = false;
            if (!this.result.length) {
              this.uploadError = "No se encontraron resultados";
              this.showTable = false;
              return;
            }
            this.showTable = true;
          },
          (error) => {
            this.formControl.enable({ emitEvent: false });
            this.uploadError = "Se ha presentado un error, vuelva a intentarlo";
            this.isUploading = false;
          }
        );
    }
  }

  close() {
    this.isUpdateOperation = false;
  }
}
