import { Component, OnInit } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { AuthenticationService } from "@app/auth/services/authentication.service";
import { FormatsService } from "@app/permissions/services/formats.service";
import { PermissionsService } from "@app/permissions/services/permissions.service";
import { AlertComponent } from "@app/shared/components/alert/alert.component";
import { ShareService } from "@app/shared/services/share.service";

@Component({
  selector: "app-permissions-search",
  templateUrl: "./search.component.html",
  styleUrls: ["./search.component.scss"],
})
export class SearchComponent implements OnInit {
  isUpdateOperation = false;
  isInitializing = true;
  isUploading = false;
  globalError: string;
  uploadError: string;
  successMessage: String;
  showTable = false;
  users;
  result;
  PK; 

  tableNames = {
    PK: "Consecutivo",
    permissionDate: "Fecha de permiso|date",
    startTime: "Hora inicial",
    finishTime: "Hora final",
    motive: "Motivo",
    support: "Entrega soporte",
    approvedBoss: "Aprobado por jefe",
    personName: "Persona",
    status: "Estado",
    editOrder: "Pendiente revisión|Rechazado",
    print: "Imprimir",
    check: "Pendiente revisión|Pendiente aprobación"
  };

  formControl = this.formBuilder.group({
    consecutivo: ["", []],
    person: ["", []],
    status: ["", []],
    startDate: ["", []],
    finishDate: ["", []],
  });

  profile = this.authService.cognitoUser["profile"];

  constructor(
    private formBuilder: FormBuilder,
    private permissionsService: PermissionsService,
    private formatsService: FormatsService,
    private shareService: ShareService,
    private authService: AuthenticationService,
    public dialog: MatDialog,
  ) {
    this.permissionsService.getUsers({ type: "L", status: "ACTIVE" }).subscribe(
      (data) => {
        this.users = data["body"];
        this.isInitializing = false;
      },
      (error) => {
        this.globalError =
          "Se ha presentado un error, por favor vuelva a intentarlo";
        this.isInitializing = false;
      }
    );

    this.formControl.get("consecutivo").valueChanges.subscribe((value) => {
      if (value) {
        this.formControl.get("person").clearValidators();
        this.formControl.get("person").reset();
        this.formControl.get("person").disable({ emitEvent: false });
        this.formControl
          .get("person")
          .updateValueAndValidity({ emitEvent: false });

        this.formControl.get("status").clearValidators();
        this.formControl.get("status").reset();
        this.formControl.get("status").disable({ emitEvent: false });
        this.formControl
          .get("status")
          .updateValueAndValidity({ emitEvent: false });

        this.formControl.get("startDate").clearValidators();
        this.formControl.get("startDate").reset();
        this.formControl.get("startDate").disable({ emitEvent: false });
        this.formControl
          .get("startDate")
          .updateValueAndValidity({ emitEvent: false });

        this.formControl.get("finishDate").clearValidators();
        this.formControl.get("finishDate").reset();
        this.formControl.get("finishDate").disable({ emitEvent: false });
        this.formControl
          .get("finishDate")
          .updateValueAndValidity({ emitEvent: false });
      } else {
        this.formControl.get("person").enable({ emitEvent: false });
        this.formControl
          .get("person")
          .updateValueAndValidity({ emitEvent: false });

        this.formControl.get("status").enable({ emitEvent: false });
        this.formControl
          .get("status")
          .updateValueAndValidity({ emitEvent: false });

        this.formControl.get("startDate").enable({ emitEvent: false });
        this.formControl
          .get("startDate")
          .updateValueAndValidity({ emitEvent: false });

        this.formControl.get("finishDate").enable({ emitEvent: false });
        this.formControl
          .get("finishDate")
          .updateValueAndValidity({ emitEvent: false });
      }
    });
  }

  ngOnInit(): void {
    if (!this.authService.checkActionsPermission("PERM", ["UPDATE"])) {
      delete this.tableNames.editOrder;
    }

    if (!this.authService.checkActionsPermission("PERM", ["PRINT"])) {
      delete this.tableNames.print;
    }

    if (!this.authService.checkActionsPermission("PERM", ["APPROVE"])) {
      delete this.tableNames.check;
    }
  }

  action(event) {
    if (event.action == "PRINT") {
      const PK = event.element.PK;
      this.permissionsService.get({ PK }).subscribe(
        (data) => {
          const content = this.formatsService.generatePrint(data["body"][0]);
          console.log(data)
          this.shareService.export("PDF", content, null, {title: `Solicitud de permiso ${PK.substring(4)}`});
        },
        (error) => {}
      );
    } else if (event.action == "UPDATE") {
      this.isUpdateOperation = true;
      this.PK = event.element.PK;
    } else if (event.action == "APPROVE") {
      this.uploadError = "";
      this.successMessage = "";
      let label = "Estado Solicitud de permiso";
      this.PK = event.element.PK;  
      let statusInform = true; 
      let index = this.result.findIndex(element => element.PK === this.PK);
      const dialogRef = this.dialog.open(AlertComponent, {
        width: "500px",
        data: {
          label,
          statusInform
        },
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {       
          result.PK = event.element.PK;
          this.permissionsService.updateStatusPermission(result).subscribe(
            (data) => {
              this.successMessage = "Operación realizada con exito";
              this.result[index].status = (this.profile === 'PROF2' && result.status === 'Aprobado') ? 'Pendiente aprobación' : result.status;
            },
            (error) => {
              this.uploadError = error.error.status.message;
            }
          );
        }
      });
    }
  }

  send() {
    if (
      (this.formControl.get("startDate").value &&
        !this.formControl.get("finishDate").value) ||
      (!this.formControl.get("startDate").value &&
        this.formControl.get("finishDate").value)
    ) {
      this.uploadError = "Debe ingresar fecha inicial y fecha final";
      return;
    } else if (
      !this.formControl.get("startDate").value &&
      !this.formControl.get("finishDate").value &&
      !this.formControl.get("consecutivo").value &&
      !this.formControl.get("person").value &&
      !this.formControl.get("status").value
    ) {
      this.uploadError = "Debe ingresar al menos un criterio de búsqueda";
      return;
    } else {
      this.isUploading = true;
      this.globalError = "";
      this.uploadError = "";
      this.formControl.disable({ emitEvent: false });
      let values = this.formControl.getRawValue();
      const consecutivo = values.consecutivo ? values.consecutivo : undefined;
      const person = values.person ? values.person : undefined;
      const status = values.status ? values.status : undefined;
      const startDate = values.startDate ? values.startDate : undefined;
      const finishDate = values.finishDate ? values.finishDate : undefined;
      this.permissionsService
        .get({ type: "S", consecutivo, person, status, startDate, finishDate })
        .subscribe(
          (data) => {
            if (this.formControl.get("consecutivo").value) {
              this.formControl.get("consecutivo").enable({ emitEvent: false });
              this.formControl.get("person").disable({ emitEvent: false });
              this.formControl.get("status").disable({ emitEvent: false });
              this.formControl.get("startDate").disable({ emitEvent: false });
              this.formControl.get("finishDate").disable({ emitEvent: false });
            } else {
              this.formControl.enable({ emitEvent: false });
            }
            this.result = data["body"];
            console.log(this.result)
            this.isUploading = false;
            if (!this.result.length) {
              this.uploadError = "No se encontraron resultados";
              this.showTable = false;
              return;
            }

            this.showTable = true;
          },
          (error) => {
            if (this.formControl.get("consecutivo").value) {
              this.formControl.get("consecutivo").enable({ emitEvent: false });
              this.formControl.get("person").disable({ emitEvent: false });
              this.formControl.get("status").disable({ emitEvent: false });
              this.formControl.get("startDate").disable({ emitEvent: false });
              this.formControl.get("finishDate").disable({ emitEvent: false });
            } else {
              this.formControl.enable({ emitEvent: false });
            }
            this.uploadError = "Se ha presentado un error, vuelva a intentarlo";
            this.isUploading = false;
          }
        );
    }
  }

  close() {
    this.isUpdateOperation = false;
  }
}
