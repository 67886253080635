import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { LoginComponent } from "@app/auth/pages/login/login.component";
import { AuthLayoutComponent } from "@app/auth/pages/auth-layout/auth-layout.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { AuthenticationTokenGuard } from "@app/auth/guards/authentication-token.guard";
import { DashboardOutletComponent } from "./dashboard-outlet/dashboard-outlet.component";
import { AuthenticatedUserGuard } from "@app/auth/guards/authenticated-user.guard";
import { ListUsersComponent } from "@app/users/pages/list-users/list-users.component";
import { ListEnterprisesComponent } from "@app/enterprises/pages/list-enterprises/list-enterprises.component";
import { ListQuotationsComponent } from "@app/quotations/pages/list-quotations/list-quotations.component";
import { ListCommercialComponent } from "@app/commercial/pages/list-commercial/list-commercial.component";
import { ListWorkComponent } from "@app/work/pages/list-work/list-work.component";
import { ListRemissionComponent } from "@app/remission/pages/list-remission/list-remission.component";
import { NotificationsComponent } from "./notifications/notifications.component";
import { ListListsComponent } from "@app/lists/pages/list-lists/list-lists.component";
import { ListOrdersComponent } from "@app/order/pages/list-orders/list-orders.component";
import { ListWarrantiesComponent } from "@app/warranties/pages/list-warranties/list-warranties.component";
import { ListTravelExpensesComponent } from "@app/travel-expenses/pages/list-travel-expenses/list-travel-expenses.component";
import { ListDocumentsComponent } from "@app/documents/pages/list-documents/list-documents.component";
import { ListResumeComponent } from "@app/resume/pages/list-resume/list-resume.component";
import { ListPermissionsComponent } from "@app/permissions/pages/list-permissions/list-permissions.component";
import { ListInventoryComponent } from "@app/inventory/pages/list-inventory/list-inventory.component";
import { ListTimeComponent } from "@app/time/pages/list-time/list-time.component";
import { ListMaterialRequestComponent } from "@app/material-request/pages/list-material-request/list-material-request.component";

const routes: Routes = [
  {
    path: "auth",
    component: AuthLayoutComponent,
    canActivate: [AuthenticatedUserGuard],
    children: [
      {
        path: "login",
        component: LoginComponent,
      },
      {
        path: "**",
        pathMatch: "full",
        redirectTo: "login",
      },
    ],
  },
  {
    path: "dashboard",
    component: DashboardComponent,
    canActivate: [AuthenticationTokenGuard],
    children: [
      {
        path: "",
        component: DashboardOutletComponent,
      },
      {
        path: "notifications",
        component: NotificationsComponent,
        canActivate: [AuthenticationTokenGuard]
      },
      {
        path: "users",
        children: [
          {
            path: "",
            component: ListUsersComponent,
          },
          {
            path: "**",
            pathMatch: "full",
            redirectTo: "",
          },
        ],
      },
      {
        path: "enterprises",
        children: [
          {
            path: "",
            component: ListEnterprisesComponent,
          },
          {
            path: "**",
            pathMatch: "full",
            redirectTo: "",
          },
        ],
      },
      {
        path: "quotation",
        children: [
          {
            path: "",
            component: ListQuotationsComponent,
          },
          {
            path: "**",
            pathMatch: "full",
            redirectTo: "",
          },
        ],
      },
      {
        path: "commercial",
        children: [
          {
            path: "",
            component: ListCommercialComponent,
          },
          {
            path: "**",
            pathMatch: "full",
            redirectTo: "",
          },
        ],
      },
      {
        path: "order",
        children: [
          {
            path: "",
            component: ListWorkComponent,
          },
          {
            path: "**",
            pathMatch: "full",
            redirectTo: "",
          },
        ],
      },
      {
        path: "remission",
        children: [
          {
            path: "",
            component: ListRemissionComponent,
          },
          {
            path: "**",
            pathMatch: "full",
            redirectTo: "",
          },
        ],
      },
      {
        path: "lists",
        children: [
          {
            path: "",
            component: ListListsComponent,
          },
          {
            path: "**",
            pathMatch: "full",
            redirectTo: "",
          },
        ],
      },
      {
        path: "purchase",
        children: [
          {
            path: "",
            component: ListOrdersComponent,
          },
          {
            path: "**",
            pathMatch: "full",
            redirectTo: "",
          },
        ],
      },
      {
        path: "informs",
        children: [
          {
            path: "",
            component: ListWorkComponent,
          },
          {
            path: "**",
            pathMatch: "full",
            redirectTo: "",
          },
        ],
      },
      {
        path: "warranty",
        children: [
          {
            path: "",
            component: ListWarrantiesComponent,
          },
          {
            path: "**",
            pathMatch: "full",
            redirectTo: "",
          },
        ],
      },
      {
        path: "travel",
        children: [
          {
            path: "",
            component: ListTravelExpensesComponent,
          },
          {
            path: "**",
            pathMatch: "full",
            redirectTo: "",
          },
        ],
      },
      {
        path: "document",
        children: [
          {
            path: "",
            component: ListDocumentsComponent,
          },
          {
            path: "**",
            pathMatch: "full",
            redirectTo: "",
          },
        ],
      },
      {
        path: "resume",
        children: [
          {
            path: "",
            component: ListResumeComponent,
          },
          {
            path: "**",
            pathMatch: "full",
            redirectTo: "",
          },
        ],
      },
      {
        path: "permission",
        children: [
          {
            path: "",
            component: ListPermissionsComponent,
          },
          {
            path: "**",
            pathMatch: "full",
            redirectTo: "",
          },
        ],
      },
      {
        path: "inventory",
        children: [
          {
            path: "",
            component: ListInventoryComponent,
          },
          {
            path: "**",
            pathMatch: "full",
            redirectTo: "",
          },
        ],
      },
      {
        path: "time",
        children: [
          {
            path: "",
            component: ListTimeComponent,
          },
          {
            path: "**",
            pathMatch: "full",
            redirectTo: "",
          },
        ],
      },
      {
        path: "materials-request",
        children: [
          {
            path: "",
            component: ListMaterialRequestComponent,
          },
          {
            path: "**",
            pathMatch: "full",
            redirectTo: "",
          },
        ],
      },
    ],
  },

  { path: "**", pathMatch: "full", redirectTo: "auth" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class CoreRoutingModule {}
