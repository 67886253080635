<div *ngIf="globalError" class="alert alert-danger text-center">
    {{ globalError }}
</div>

<div
  *ngIf="isInitializing && !globalError"
  class="d-flex justify-content-center flex-column align-items-center mt-5"
>
  <h4 class="my-4">Cargando todo...</h4>
  <mat-spinner></mat-spinner>
</div>

<div *ngIf="!isInitializing && !globalError" class="container-fluid">
    <ng-container *ngIf="isUpdateOperation">
        <button
        class="d-inline mt-2"
        mat-raised-button
        color="primary"
        (click)="close()"
      >
        <mat-icon>keyboard_backspace</mat-icon> Regresar
      </button>     
    </ng-container>
    <form [formGroup]="formControl" autocomplete="off">
      <br>
      <p>Los campos con asterisco &#40;&#42;&#41; son obligatorios</p>
        <div class="row">   
            <div class="col-md-6">
                <mat-form-field appearance="outline" class="d-block w-100 mb-2">
                  <mat-label>Orden</mat-label>
                  <input formControlName="order" type="number" (blur)="searchId($event.target.value)" matInput />
                </mat-form-field>
            </div>  
            <div class="col-md-6">
                <mat-form-field appearance="outline" class="d-block w-100 mb-2">
                  <mat-label>Empresa</mat-label>
                  <input formControlName="enterpriseOrder" type="text" matInput />
                </mat-form-field>
            </div>
            <div class="col-md-6">
                <mat-form-field appearance="outline" class="d-block w-100 mb-2">
                  <mat-label>Fecha inicial</mat-label>
                  <input formControlName="startDateOrder" type="text" matInput />
                </mat-form-field>
            </div>
            <div class="col-md-6">
                <mat-form-field appearance="outline" class="d-block w-100 mb-2">
                  <mat-label>Fecha final</mat-label>
                  <input formControlName="finishDateOrder" type="text" matInput />
                </mat-form-field>
            </div>
            <div class="col-md-12">
                <mat-form-field appearance="outline" class="d-block w-100 mb-2">
                  <mat-label>Descripción</mat-label>
                  <textarea formControlName="descriptionOrder" type="text" matInput rows="7"></textarea>
                </mat-form-field>
            </div> 
            <div class="col-md-6">
              <mat-form-field appearance="outline" class="d-block w-100 mb-2" (keydown.enter)="$event.preventDefault()">
                <mat-label>Empresa *</mat-label>
                <mat-select formControlName="enterprise">
                  <mat-option>Elige una</mat-option>
                  <mat-option
                    *ngFor="let enterprise of enterprises"
                    [value]="enterprise.PK"
                  >
                    {{ enterprise.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field appearance="outline" class="d-block w-100 mb-2" (keydown.enter)="$event.preventDefault()">
                <mat-label>Fecha inicial *</mat-label>
                <input
                  readonly
                  formControlName="startDate"
                  matInput
                  [matDatepicker]="picker"
                />
                <mat-datepicker-toggle
                (click)="picker.open()"
                  matSuffix
                  [for]="picker"
                ></mat-datepicker-toggle>
                <mat-datepicker touchUi #picker></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field appearance="outline" class="d-block w-100 mb-2" (keydown.enter)="$event.preventDefault()">
                <mat-label>Fecha final *</mat-label>
                <input
                  readonly
                  formControlName="finishDate"
                  matInput
                  [matDatepicker]="picker1"
                />
                <mat-datepicker-toggle
                (click)="picker.open()"
                  matSuffix
                  [for]="picker1"
                ></mat-datepicker-toggle>
                <mat-datepicker touchUi #picker1></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field appearance="outline" class="d-block w-100 mb-2" (keydown.enter)="$event.preventDefault()">
                <mat-label>Persona *</mat-label>
                <mat-select formControlName="person">
                  <mat-option>Elige una</mat-option>
                  <mat-option *ngFor="let user of users" [value]="user.PK">
                    {{ user.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-12">
              <mat-form-field appearance="outline" class="d-block w-100 mb-2">
                <mat-label>Área *</mat-label>
                <mat-select formControlName="area">
                  <mat-option>Elige una</mat-option>
                  <mat-option
                    *ngFor="let area of areas"
                    [value]="area.PK"
                  >
                    {{ area.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-md-12">
              <mat-form-field appearance="outline" class="d-block w-100 mb-2">
                <mat-label>Motivo *</mat-label>
                <textarea formControlName="motive" type="text" matInput rows="7"></textarea>
              </mat-form-field>
            </div>             
        <div class="col-md-12">
          <mat-form-field appearance="outline" class="d-block w-100 mb-2">
            <mat-label>Observaciones</mat-label>
            <textarea formControlName="observations" type="text" matInput rows="7"></textarea>
          </mat-form-field>
        </div>
      </div>
      <hr>
      <h2>Items</h2>      
      <div formArrayName="items">
        <div *ngFor="let itemrow of formControl.controls.items['controls']; let i=index" [formGroupName]="i">
          <hr>
        <div class="row">
          <div  class="col-md-12">
            <button
            class="d-block mt-2 button-remove"
            mat-raised-button
            color="primary"
            (click)="$event.preventDefault()"
            (click)="deleteRow(i)"
          >
            <mat-icon>remove</mat-icon>
          </button>
          <br>
          </div>
        </div>
        <div class="row">        
          <div class="col-md-3">
            <mat-form-field appearance="outline" class="d-block w-100 mb-2" (keydown.enter)="$event.preventDefault()">
              <mat-label>Fecha *</mat-label>
              <input
                readonly
                formControlName="date"
                matInput
                [matDatepicker]="picker2"
              />
              <mat-datepicker-toggle
              (click)="picker.open()"
                matSuffix
                [for]="picker2"
              ></mat-datepicker-toggle>
              <mat-datepicker touchUi #picker2></mat-datepicker>
            </mat-form-field>
          </div> 
          <div class="col-md-3">
            <mat-form-field appearance="outline" class="d-block w-100 mb-2" (keydown.enter)="$event.preventDefault()">
              <mat-label>Concepto *</mat-label>
              <mat-select formControlName="concept" (selectionChange)="otherConcept(i)" >
                <mat-option>Elige una</mat-option>
                <mat-option *ngFor="let concept of concepts" [value]="concept.PK">
                  {{ concept.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-md-3">
            <mat-form-field appearance="outline" class="d-block w-100 mb-2" (keydown.enter)="$event.preventDefault()">
              <mat-label>Otro concepto</mat-label>
              <input formControlName="conceptDescription" type="text" matInput />
            </mat-form-field>
          </div>
          <div class="col-md-3">
            <mat-form-field appearance="outline" class="d-block w-100 mb-2" (keydown.enter)="$event.preventDefault()">
              <mat-label>Valor *</mat-label>
              <input formControlName="value" [min]="0" ngxCurrencyInput matInput />
            </mat-form-field>
          </div>
        </div>
        </div>
      </div>   
      <hr>   
      <button
        class="d-block mt-2"
        mat-raised-button
        color="primary"
        style="margin: auto;"
        (click)="addNewRow($event)"
      >
        <mat-icon>add</mat-icon>
      </button>
      <br>
    <div class="row justify-content-end">
      <div class="col-md-3 offset-md-9">
        <mat-form-field
          appearance="outline"
          class="d-block w-100 mb-2"
        >
          <mat-label>Total gastos</mat-label>
          <input formControlName="totalExpenses" [min]="0" ngxCurrencyInput matInput />
        </mat-form-field>
      </div>
      <div class="col-md-3 offset-md-9">
        <mat-form-field appearance="outline" class="d-block w-100 mb-2">
          <mat-label>Total anticipo transferencia *</mat-label>
          <input formControlName="advanceTransfer" [min]="0" ngxCurrencyInput matInput (blur)="calculateReturns()" />
        </mat-form-field>
      </div>
      <div class="col-md-3 offset-md-9">
        <mat-form-field appearance="outline" class="d-block w-100 mb-2">
          <mat-label>Total anticipo efectivo * </mat-label>
          <input formControlName="advanceCash" [min]="0" ngxCurrencyInput matInput (blur)="calculateReturns()" />
        </mat-form-field>
      </div>
      <div class="col-md-3 offset-md-9">
        <mat-form-field appearance="outline" class="d-block w-100 mb-2">
          <mat-label>Total anticipo tarjeta crédito *</mat-label>
          <input formControlName="advanceCard" [min]="0" ngxCurrencyInput matInput (blur)="calculateReturns()" />
        </mat-form-field>
      </div>       
      <div class="col-md-3 offset-md-9">
        <mat-form-field
          appearance="outline"
          class="d-block w-100 mb-2"
        >
          <mat-label>Devoluciones</mat-label>
          <input formControlName="returns" [min]="0" ngxCurrencyInput matInput />
        </mat-form-field>
      </div>
    </div>
    <br />
      <div class="row">
        <div class="col-md-6 d-block w-100 mb-2">
          <input
            type="file"
            accept="application/pdf"
            class="file-input"
            (change)="onFileSelected($event)"
            #fileUpload
          />
          <div class="file-upload">
            <mat-label
              >Adjunto: {{ fileName || "Aún no se ha subido ningún archivo." }}
            </mat-label>
            <button
              mat-mini-fab
              color="primary"
              class="upload-btn"
              (click)="$event.preventDefault()"
              (click)="fileUpload.click()"
            >
              <mat-icon>attach_file</mat-icon>
            </button>
            <ng-container *ngIf="url">
              <a [href]="url" target="_blank"> Descargar archivo adjunto</a>
            </ng-container>
            <p class="text-danger">{{ documentAlert || ""}}</p>
          </div>
        </div> 
        <div class="col-md-6">
          <mat-checkbox
            id="userCheckbox"
            style="position: relative; float: right"
            (change)="sendInform($event)"
            >
            <span *ngIf="profile1; else otherProfile">Cerrar</span>
            <ng-template #otherProfile>Enviar para aprobación</ng-template>
            </mat-checkbox
          >
        </div>
      </div>
        
        <div *ngIf="uploadError" class="alert alert-danger text-center">
          {{ uploadError }}
        </div>
    
        <div *ngIf="successMessage" class="alert alert-success text-center">
          {{ successMessage }}
        </div>

        <div *ngIf="attachedError" class="alert alert-warning text-center">
          {{ attachedError }}
        </div>
    
        <button
          class="d-block w-100 mt-2"
          mat-raised-button
          color="primary"
          [disabled]="formControl.invalid || isUploading"
          (click)="$event.preventDefault()"
          (click)="send()"          
        >
          {{ getButtonText() }}
        </button>
    </form>
</div>
<br />