<div *ngIf="globalError" class="alert alert-danger text-center">
    {{ globalError }}
</div>

<div
  *ngIf="isInitializing && !globalError"
  class="d-flex justify-content-center flex-column align-items-center mt-5"
>
  <h4 class="my-4">Cargando todo...</h4>
  <mat-spinner></mat-spinner>
</div>

<div *ngIf="!isInitializing && !globalError" class="container-fluid">
    <ng-container *ngIf="isUpdateOperation">
        <button
        class="d-inline mt-2"
        mat-raised-button
        color="primary"
        (click)="close()"
      >
        <mat-icon>keyboard_backspace</mat-icon> Regresar
      </button>     
    </ng-container>
    <form [formGroup]="formControl" autocomplete="off">
      <br>
      <p>Los campos con asterisco &#40;&#42;&#41; son obligatorios</p>
        <div class="row">   
            <div class="col-md-6">
                <mat-form-field appearance="outline" class="d-block w-100 mb-2">
                  <mat-label>Empresa *</mat-label>
                  <mat-select formControlName="enterprise">
                    <mat-option>Elige una</mat-option>
                    <mat-option
                      *ngFor="let enterprise of enterprises"
                      [value]="enterprise.PK"
                    >
                      {{ enterprise.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-6">
                <mat-form-field appearance="outline" class="d-block w-100 mb-2">
                  <mat-label>Código/Referencia Empresa *</mat-label>
                  <input formControlName="code" type="text" matInput />
                </mat-form-field>
            </div>
            <div class="col-md-6">
                <mat-form-field appearance="outline" class="d-block w-100 mb-2">
                  <mat-label>Nombre del equipo *</mat-label>
                  <input formControlName="nameEquipment" type="text" matInput />
                </mat-form-field>
            </div>  
            <div class="col-md-6">
                <mat-form-field appearance="outline" class="d-block w-100 mb-2">
                  <mat-label>Tipo de equipo *</mat-label>
                  <mat-select formControlName="typeEquipment">
                    <mat-option>Elige una</mat-option>
                    <mat-option
                      *ngFor="let typeEquipment of typeEquipments"
                      [value]="typeEquipment.PK"
                    >
                      {{ typeEquipment.name }}
                    </mat-option>
                  </mat-select>
                  <mat-icon (click)="save($event, 'TYEQ')" matSuffix
                    >playlist_add</mat-icon
                  >
                </mat-form-field>
              </div>                      
            <div class="col-md-6">
              <mat-form-field appearance="outline" class="d-block w-100 mb-2">
                <mat-label>Marca *</mat-label>
                <mat-select formControlName="mark">
                  <mat-option>Elige una</mat-option>
                  <mat-option
                    *ngFor="let mark of marks"
                    [value]="mark.PK"
                  >
                    {{ mark.name }}
                  </mat-option>
                </mat-select>
                <mat-icon (click)="save($event, 'MARK')" matSuffix
                  >playlist_add</mat-icon
                >
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field appearance="outline" class="d-block w-100 mb-2">
                <mat-label>Modelo *</mat-label>
                <input formControlName="model" type="text" matInput />
                <!-- <mat-select formControlName="model">
                  <mat-option>Elige una</mat-option>
                  <mat-option
                    *ngFor="let model of models"
                    [value]="model.PK"
                  >
                    {{ model.name }}
                  </mat-option>
                </mat-select>
                <mat-icon (click)="save($event, 'MODE')" matSuffix
                  >playlist_add</mat-icon
                > -->
              </mat-form-field>
            </div> 
            <div class="col-md-6">
                <mat-form-field appearance="outline" class="d-block w-100 mb-2">
                  <mat-label>Serial</mat-label>
                  <input formControlName="serial" type="text" matInput />
                </mat-form-field>
            </div>  
            <div class="col-md-6">
                <mat-form-field appearance="outline" class="d-block w-100 mb-2">
                  <mat-label>Capacidad *</mat-label>
                  <input formControlName="capacity" type="number" matInput />
                </mat-form-field>
            </div>   
            <div class="col-md-6">
              <mat-form-field appearance="outline" class="d-block w-100 mb-2">
                <mat-label>Unidad de medida *</mat-label>
                <input formControlName="measureUnitCapacity" type="text" matInput />
              </mat-form-field>
          </div>        
            <div class="col-md-6">
                <mat-form-field appearance="outline" class="d-block w-100 mb-2">
                  <mat-label>Combustible</mat-label>
                  <mat-select formControlName="fuel">
                    <mat-option>Elige una</mat-option>
                    <mat-option
                      *ngFor="let fuel of fuels"
                      [value]="fuel.PK"
                    >
                      {{ fuel.name }}
                    </mat-option>
                  </mat-select>
                  <mat-icon (click)="save($event, 'FUEL')" matSuffix
                    >playlist_add</mat-icon
                  >
                </mat-form-field>
            </div> 
            <div class="col-md-6">
                <mat-form-field appearance="outline" class="d-block w-100 mb-2">
                  <mat-label>Presión de trabajo</mat-label>
                  <input formControlName="workPressure" type="text" matInput />
                </mat-form-field>
            </div>
            <div class="col-md-6">
                <mat-form-field appearance="outline" class="d-block w-100 mb-2">
                  <mat-label>Presión de diseño</mat-label>
                  <input formControlName="designPressure" type="text" matInput />
                </mat-form-field>
            </div>
            <div class="col-md-6">
                <mat-form-field appearance="outline" class="d-block w-100 mb-2">
                  <mat-label>Consumo</mat-label>
                  <input formControlName="consuption" type="text" matInput />
                </mat-form-field>
            </div>
            <div class="col-md-6">
                <mat-form-field appearance="outline" class="d-block w-100 mb-2">
                  <mat-label>Producción de vapor</mat-label>
                  <input formControlName="steamProduction" type="text" matInput />
                </mat-form-field>
            </div>
            <div class="col-md-6">
                <mat-form-field appearance="outline" class="d-block w-100 mb-2">
                  <mat-label>Voltaje (V)</mat-label>
                  <input formControlName="voltage" type="number" matInput />
                </mat-form-field>
            </div> 
            <div class="col-md-6">
              <mat-form-field appearance="outline" class="d-block w-100 mb-2">
                <mat-label>Amperaje (A)</mat-label>
                <input formControlName="amperage" type="number" matInput />
              </mat-form-field>
          </div>
            <div class="col-md-12">
                <mat-form-field appearance="outline" class="d-block w-100 mb-2">
                  <mat-label>Descripción adicional</mat-label>
                  <textarea formControlName="aditionalDescription" type="text" matInput rows="7"></textarea>
                </mat-form-field>
              </div>        
      </div>
      <hr>
      <h2>Caracteristicas adicionales</h2>      
      <div formArrayName="items">
        <div *ngFor="let itemrow of formControl.controls.items['controls']; let i=index" [formGroupName]="i">
          <hr>
        <div class="row">
          <div  class="col-md-12">
            <button
            class="d-block mt-2 button-remove"
            mat-raised-button
            color="primary"
            (click)="$event.preventDefault()"
            (click)="deleteRow(i)"
          >
            <mat-icon>remove</mat-icon>
          </button>
          <br>
          </div>
        </div>
        <div class="row"> 
          <div class="col-md-4">
            <mat-form-field appearance="outline" class="d-block w-100 mb-2" (keydown.enter)="$event.preventDefault()">
              <mat-label>Nombre *</mat-label>
              <input formControlName="name" type="text" matInput />
            </mat-form-field>
          </div>
          <div class="col-md-4">
            <mat-form-field appearance="outline" class="d-block w-100 mb-2" (keydown.enter)="$event.preventDefault()">
              <mat-label>Valor *</mat-label>
              <input formControlName="value" type="text" matInput />
            </mat-form-field>
          </div>
          <div class="col-md-4">
            <mat-form-field appearance="outline" class="d-block w-100 mb-2" (keydown.enter)="$event.preventDefault()">
              <mat-label>Unidad de medida</mat-label>
              <input formControlName="measureUnit" type="text" matInput />
            </mat-form-field>
          </div>
        </div>
        </div>
      </div>   
      <hr>   
      <button
        class="d-block mt-2"
        mat-raised-button
        color="primary"
        style="margin: auto;"
        (click)="addNewRow($event)"
      >
        <mat-icon>add</mat-icon>
      </button>
      <br>
      <div class="col-md-12 d-block w-100 mb-2">
        <hr />
        <h2>Listado de partes</h2>
        <div class="col-md-12">
          <mat-form-field appearance="outline" class="d-block w-100 mb-2">
            <mat-label>Partes</mat-label>
            <input
              type="search"
              [(ngModel)]="search"
              [ngModelOptions]="{ standalone: true }"
              autocomplete="off"
              matInput
            />
            <p class="text-danger itemMessage" *ngIf="itemMessage">La parte seleccionada ya fue agregada</p>
          </mat-form-field>
          <div class="mat-elevation-z2" style="position: absolute; top: 45px; right: 0; z-index: 1; background-color: white; width:inherit" *ngIf="search.length > 2">
            <mat-option
              *ngFor="let part of parts | filter: search"
              (click)="addNewPartsRow($event, part)"
            >
              {{ part.typeEquipmentName }} - {{part.markName}} - {{part.modelName}} - {{part.typeDocumentName}}
            </mat-option>
          </div>
        </div>
        <div formArrayName="partsItems">
          <div
            *ngFor="
              let itemrow of formControl.controls.partsItems['controls'];
              let i = index
            "
            [formGroupName]="i"
          >
            <hr />
            <div class="row">
              <div class="col-md-12">
                <button
                  class="d-block mt-2 button-remove"
                  mat-raised-button
                  color="primary"
                  (click)="$event.preventDefault()"
                  (click)="deletePartsRow(i)"
                >
                  <mat-icon>remove</mat-icon>
                </button>
                <br />
              </div>
            </div>
            <div class="row">
              <div class="col-md-3">
                <mat-form-field appearance="outline" class="d-block w-100 mb-2">
                  <mat-label>Tipo de equipo</mat-label>
                  <input 
                    [value]="this.selectedItem[i].typeEquipment"
                    [disabled]="true"
                    type="text"
                    matInput
                  />
                </mat-form-field>
              </div>
              <div class="col-md-3">
                <mat-form-field appearance="outline" class="d-block w-100 mb-2">
                  <mat-label>Marca</mat-label>
                  <input
                    [value]="this.selectedItem[i].mark"
                    [disabled]="true"
                    type="text"
                    matInput
                  />
                </mat-form-field>
              </div>
              <div class="col-md-3">
                <mat-form-field appearance="outline" class="d-block w-100 mb-2">
                  <mat-label>Modelo</mat-label>
                  <input 
                    [value]="this.selectedItem[i].model"
                    [disabled]="true"
                    type="text"
                    matInput
                  />
                </mat-form-field>
              </div>
              <div class="col-md-3">
                <mat-form-field appearance="outline" class="d-block w-100 mb-2">
                  <mat-label>Tipo de documento</mat-label>
                  <input
                    [value]="this.selectedItem[i].typeDocument"
                    [disabled]="true"
                    type="text"
                    matInput
                  />
                </mat-form-field>
              </div>
              <div class="col-12">
                <mat-form-field appearance="outline" class="d-block w-100 mb-2" (keydown.enter)="$event.preventDefault()">
                  <mat-label>Información adicional *</mat-label>
                  <input formControlName="aditionalInformation" type="text" matInput />
                </mat-form-field>
              </div>
            </div>
          </div>
          <hr />
        </div>
      </div>
      <div class="row">
        <div class="col-12 d-block w-100 mb-2">
          <input
            type="file"
            accept="image/*"
            class="file-input"
            (change)="onFileSelected($event)"
            #fileUpload
          />
          <div class="file-upload">
            <mat-label
              >Adjunto: {{ fileName || "Aún no se ha subido ningún archivo." }}
            </mat-label>
            <button
              mat-mini-fab
              color="primary"
              class="upload-btn"
              (click)="$event.preventDefault()"
              (click)="fileUpload.click()"
            >
              <mat-icon>attach_file</mat-icon>
            </button>
            <ng-container *ngIf="url">
              <a [href]="url" target="_blank"> Descargar archivo adjunto</a>
            </ng-container>
            <p class="text-danger">{{ fileAlert || ""}}</p>
          </div>
        </div>
      </div>
        
        <div *ngIf="uploadError" class="alert alert-danger text-center">
          {{ uploadError }}
        </div>
    
        <div *ngIf="successMessage" class="alert alert-success text-center">
          {{ successMessage }}
        </div>

        <div *ngIf="attachedError" class="alert alert-warning text-center">
          {{ attachedError }}
        </div>
    
        <button
          class="d-block w-100 mt-2"
          mat-raised-button
          color="primary"
          [disabled]="formControl.invalid || isUploading"
          (click)="$event.preventDefault()"
          (click)="send()"          
        >
          {{ getButtonText() }}
        </button>
    </form>
</div>
<br />