import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FormatsService {
  TRANSLATE = {
    PK: 'Cons.',
    requestDate: 'Fecha de solicitud',
    permissionDate: 'Fecha de permiso',
    startTime: 'Hora inicial',
    finishTime: 'Hora final',
    diffHours: 'Cantidad de horas',
    motive: 'Motivo',
    support: 'Entrega soporte',
    approvedBoss: 'Aprobado por jefe',
    personName: 'Persona',
    status: 'Estado',
    checkerName: 'Revisa',
    approverName: 'Aprueba',
  }

  constructor() { }

  generatePrint(data) {
    let document: Array<any> = [
      {
        lineHeight: 1,
        text: ".", color: "white",
      },
      {
        table: {
          headerRows: 0,
          widths: ["50%", "50%"],
          body: [  
            [{ text: [{text: `DATOS DEL EMPLEADO`, bold: true, alignment: "center"}], colSpan: 2 }, {}], 
            [
              { text: [{text: `Nombres y apellidos: `, bold: true}, {text: `${data.personName}`}] },
              { text: [{text: `N° de identificación: `, bold: true}, {text: `${data.personIdentification}`} ]}
            ],  
            [
              { text: [{text: `Cargo: `, bold: true}, {text: `${data.personPosition}`}]},
              { text: [{text: `Estado: `, bold: true}, {text: `${data.status}`}]},              
            ],
            [
              { text: [{text: `Fecha de la solicitud: `, bold: true}, {text: `${data.creationDate}`}]},
              { text: [{text: `Fecha del permiso: `, bold: true}, {text: `${data.permissionDate}`}]},              
            ],
          ],
        },
      },
      {
        table: {
          headerRows: 1,
          widths: ["50%", "50%"],
          body: [ 
            [{ text: "MOTIVO DEL PERMISO", bold: true, alignment: "center", colSpan: 2 }, {}],
            [{ text: `${data.motive}`, alignment:'justify', colSpan: 2 }, {}],
            [{ text: [{text: `HORARIO`, bold: true, alignment: "center"}], colSpan: 2 }, {}], 
            [
              { text: [{text: `Desde (Señalar hora de inicio)`, bold: true, alignment: "center"}] },
              { text: [{text: `Hasta (Señalar hora de fin)`, bold: true, alignment: "center"}]}
            ],  
            [
              { text: [{text: `${data.startTime}`, alignment: "center"}]},
              { text: [{text: `${data.finishTime}`, alignment: "center"}]},              
            ],
            [
              { text: [{text: `Cantidad de horas: `, bold: true}, {text: `${data.diffHours}`}], alignment: "center", colSpan: 2}, {}              
            ],
            [
              { text: [{text: `Entrega soporte: `, bold: true}, {text: `${data.support}`}]},
              { text: [{text: `Aprobado por jefe: `, bold: true}, {text: `${data.approvedBoss}`}]},              
            ],
          ],
        },
      },
      {
        lineHeight: 2,
        text: ".", color: "white",
      },
      {
        layout: 'noBorders',
        table: {          
          widths: ["*", "*", "*"],
          body: [            
            [
              { text: 'Empleado \n', alignment: "center", bold: true },
              { text: 'Líder servicios \n', alignment: "center", bold: true},
              { text: 'Admin./Gerencia \n', alignment: "center", bold: true},
            ],
            [
              {text: [{text: 'Nombre: ', bold: true}, {text: `${data.personName}\n`}]},
              {text: [{text: 'Nombre: ', bold: true}, {text: `${data.checkerName || ''}\n`}]},
              {text: [{text: 'Nombre: ', bold: true}, {text: `${data.approverName || ''}\n`}]},
            ],
            [
              {text: [{text: 'CC: ', bold: true}, {text: `${data.personIdentification}\n`}]},
              {text: [{text: 'CC: ', bold: true}, {text: `${data.checkerIdentification || ''}\n`}]},
              {text: [{text: 'CC: ', bold: true}, {text: `${data.approverIdentification || ''}\n`}]},
            ],
            [
              {text: [{text: 'Fecha: ', bold: true}, {text: `${data.creationDate}\n`}]},
              {text: [{text: 'Fecha: ', bold: true}, {text: `${data.checkDate || ''}\n`}]},
              {text: [{text: 'Fecha: ', bold: true}, {text: `${data.approveDate || ''}\n`}]},
            ],
          ],
        },
      },
    ];
    return document;
  }

  generateReport(data) {
    return [
      {
        table: {
          headerRows: 1,
          widths: ["5%", "7%", "7%", "7%", "7%", "5%", "10%", "6%", "6%", "10%", "10%", "10%", "10%"],
          body: [
            [{ text: 'Cons.', bold: true, alignment: "center" }, { text: 'Fecha de solicitud', bold: true, alignment: "center" }, { text: 'Fecha de permiso', bold: true, alignment: "center" }, { text: 'Hora inicial', bold: true, alignment: "center" }, { text: 'Hora final', bold: true, alignment: "center" }, { text: 'Cantidad horas', bold: true, alignment: "center" }, { text: 'Motivo', bold: true, alignment: "center" }, { text: 'Entrega soporte', bold: true, alignment: "center" }, { text: 'Aprobado por jefe', bold: true, alignment: "center" }, { text: 'Persona', bold: true, alignment: "center" }, { text: 'Estado', bold: true, alignment: "center" }, { text: 'Revisa', bold: true, alignment: "center" }, { text: 'Aprueba', bold: true, alignment: "center" }],
            ...data.map(d => ([d.PK.substring(4), d.requestDate, d.permissionDate, d.startTime, d.finishTime, d.diffHours || "", d.motive, d.support, d.approvedBoss, d.personName, d.status, d.checkerName || '', d.approverName || ''])),
          ],
        },
      },
    ];
  }
}
