<div *ngIf="globalError" class="alert alert-danger text-center">
    {{ globalError }}
</div>

<div
  *ngIf="isInitializing && !globalError"
  class="d-flex justify-content-center flex-column align-items-center mt-5"
>
  <h4 class="my-4">Cargando todo...</h4>
  <mat-spinner></mat-spinner>
</div>

<div *ngIf="!isInitializing && !globalError" class="container-fluid">
    <ng-container *ngIf="isUpdateOperation">
        <button
        class="d-inline mt-2"
        mat-raised-button
        color="primary"
        (click)="close()"
      >
        <mat-icon>keyboard_backspace</mat-icon> Regresar
      </button>     
    </ng-container>
    <form [formGroup]="formControl" autocomplete="off">
      <br>
      <p>Los campos con asterisco &#40;&#42;&#41; son obligatorios</p>
        <div class="row">             
          <div class="col-md-6">
              <mat-form-field appearance="outline" class="d-block w-100 mb-2">
                <mat-label>Tipo de equipo *</mat-label>
                <mat-select formControlName="typeEquipment">
                  <mat-option>Elige una</mat-option>
                  <mat-option
                    *ngFor="let typeEquipment of typeEquipments"
                    [value]="typeEquipment.PK"
                  >
                    {{ typeEquipment.name }}
                  </mat-option>
                </mat-select>
                <mat-icon (click)="save($event, 'TYEQ')" matSuffix
                  >playlist_add</mat-icon
                >
              </mat-form-field>
            </div>              
            <div class="col-md-6">
              <mat-form-field appearance="outline" class="d-block w-100 mb-2">
                <mat-label>Marca *</mat-label>
                <mat-select formControlName="mark">
                  <mat-option>Elige una</mat-option>
                  <mat-option
                    *ngFor="let mark of marks"
                    [value]="mark.PK"
                  >
                    {{ mark.name }}
                  </mat-option>
                </mat-select>
                <mat-icon (click)="save($event, 'MARK')" matSuffix
                  >playlist_add</mat-icon
                >
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field appearance="outline" class="d-block w-100 mb-2">
                <mat-label>Modelo *</mat-label>
                <mat-select formControlName="model">
                  <mat-option>Elige una</mat-option>
                  <mat-option
                    *ngFor="let model of models"
                    [value]="model.PK"
                  >
                    {{ model.name }}
                  </mat-option>
                </mat-select>
                <mat-icon (click)="save($event, 'MODE')" matSuffix
                  >playlist_add</mat-icon
                >
              </mat-form-field>
            </div> 
            <div class="col-md-6">
              <mat-form-field appearance="outline" class="d-block w-100 mb-2">
                <mat-label>Tipo de documento *</mat-label>
                <mat-select formControlName="typeDocument">
                  <mat-option>Elige una</mat-option>
                  <mat-option
                    *ngFor="let typeDocument of typeDocuments"
                    [value]="typeDocument.PK"
                  >
                    {{ typeDocument.name }}
                  </mat-option>
                </mat-select>
                <mat-icon (click)="save($event, 'TYDO')" matSuffix
                  >playlist_add</mat-icon
                >
              </mat-form-field>
            </div>                       
            <div class="col-12 d-block w-100 mb-2">
              <input
                type="file"
                class="file-input"
                (change)="onFileSelected($event)"
                #fileUpload
              />
              <div class="file-upload">
                <mat-label
                  >* Adjunto: {{ fileName || "Aún no se ha subido ningún archivo." }}
                </mat-label>
                <button
                  mat-mini-fab
                  color="primary"
                  class="upload-btn"
                  (click)="$event.preventDefault()"
                  (click)="fileUpload.click()"
                >
                  <mat-icon>attach_file</mat-icon>
                </button>
                <ng-container *ngIf="url">
                  <a [href]="url" target="_blank"> Descargar archivo adjunto</a>
                </ng-container>
              </div>
            </div>               
      </div>
        
        <div *ngIf="uploadError" class="alert alert-danger text-center">
          {{ uploadError }}
        </div>
    
        <div *ngIf="successMessage" class="alert alert-success text-center">
          {{ successMessage }}
        </div>

        <div *ngIf="attachedError" class="alert alert-warning text-center">
          {{ attachedError }}
        </div>
    
        <button
          class="d-block w-100 mt-2"
          mat-raised-button
          color="primary"
          [disabled]="formControl.invalid || isUploading"
          (click)="$event.preventDefault()"
          (click)="send()"          
        >
          {{ getButtonText() }}
        </button>
    </form>
</div>
<br />