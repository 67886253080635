import { Injectable } from '@angular/core';
import { Auth } from 'aws-amplify';
import { CognitoUser  } from 'amazon-cognito-identity-js';
import { User } from '../models/user.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@environment';
import { map, shareReplay } from 'rxjs/operators';
import { PartialObserver, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  private static readonly PERMISSIONS_KEY = 'PERMISSIONS';
  private tempPw: string;
  public title = '';
  constructor(
    private http: HttpClient,
  ) { }

  cognitoUser = {};

  private updateUserData = (user: CognitoUser) => {
    const username = user.getUsername();
    return new Promise((resolve, reject) => user.getUserAttributes((error, userAttributes) => {
      if (!userAttributes) {
        return reject({code: 'UnactivatedUserException'});
      }

      this.cognitoUser = userAttributes.reduce((lastValue, cognitoAttr) => {
        const name = cognitoAttr.getName();
        const value = cognitoAttr.getValue();

        return {...lastValue, ...({[name]: value})};
      }, {username});

      resolve(user);
    }));
  }

  login(username: string, password: string): Promise<CognitoUser> {
    return Auth.signIn(username, password)
      .then(this.updateUserData)
      .then((user: CognitoUser) => user);
  }

  setTitle(route:string) {
    const cachedPermissions = JSON.parse(sessionStorage.getItem(AuthenticationService.PERMISSIONS_KEY));
    if(cachedPermissions) {
      const format = cachedPermissions.filter(p => route.includes(p.route[0]));
      if(format[0]) {
        this.title = format[0].name;
      }
      else {
        if(route.includes("/notifications")) {
          this.title = "Notificaciones";
        }
        else {
          this.title = "Formatos";
        }
      } 
    }  
    else {
      if(route.includes("/notifications")) {
        this.title = "Notificaciones";
      }
      else {
        this.title = "Formatos";
      }
    }  
  }

  signOut() {
    sessionStorage.removeItem(AuthenticationService.PERMISSIONS_KEY);
    return Auth.signOut();
  }

  verify(): Promise<boolean> {
    return Auth.currentAuthenticatedUser()
      .then(this.updateUserData)
      .then(() => true)
      .catch(() => false);
  }

  getUserModulesPermissions() {
    const cachedPermissions = JSON.parse(sessionStorage.getItem(AuthenticationService.PERMISSIONS_KEY));

    if (!cachedPermissions) {
      return this.http
        .get(`${environment.apiHost}/v1/menu`)
        .pipe(
          map((response: any) => {
            sessionStorage.setItem(AuthenticationService.PERMISSIONS_KEY, JSON.stringify(response.body));
            console.log(response)
            return response.body;
          }),
        );
    } else {
      return new Observable((subscriber) => {
        subscriber.next(cachedPermissions);
        subscriber.complete();
      });
    }
  }

  checkActionsPermission(entity: string, actions: any[]) {
    const cachedPermissions = JSON.parse(sessionStorage.getItem(AuthenticationService.PERMISSIONS_KEY));
    const entityObject = cachedPermissions.filter(p => p.entity === entity);
    if (!entityObject.length) return false;
    const allowedActions = entityObject[0].actions.filter(a => actions.includes(a.name));
    if (!allowedActions.length) return false;
    return true;
  }
}
