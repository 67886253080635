import { Component, Inject} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { QuotationsService } from '@app/quotations/services/quotations.service';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent{
  label: string;
  action: boolean;
  status: string;
  statusInform: string;
  reason: string;  
  date: string;
  enterprisePK: string;
  sendEmail: string;
  order: string;

  hideClose = false;
  approve = false;
  email = false;
  obligatorie = false;
  updateStatus = false;

  uploadError: string;

  contacts = [];
  sendemail = [];

  sendEmailOrder = false;

  updateInform = false;
  chexboxText = "";

  showCloseItems = false;
  closeItems;
  closedItems;

  enterpriseOc;
  enterpriseOcValue = "";

  constructor(
    private quotationsService: QuotationsService,
    public dialogRef: MatDialogRef<AlertComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.label = data.label;
    this.action = data.action;
    this.statusInform = data.statusInform;
    this.sendEmail = data.sendEmail;
    this.enterprisePK = data.enterprisePK;
    this.order = data.order;
    this.updateStatus = data.updateStatus;
    this.updateInform = data.updateInform;
    this.chexboxText = data.checkboxText;
    this.closeItems = data.closeItems;
    this.enterpriseOc = data.enterpriseOc;
    this.enterpriseOcValue = data.enterpriseOcValue;
  }

  ngOnInit() {    
    if (this.action) this.hideClose = true;
    else this.hideClose = false;

    if (this.statusInform) this.approve = true;
    else this.approve = false;
    
    if (this.sendEmail) this.email = true;
    else this.email = false;
    
    if(this.enterprisePK) {
      this.quotationsService
          .getEnterprises({ contacts: this.enterprisePK })
          .subscribe((data) => {
            this.contacts = data["body"];
          });
    }

    if(this.enterpriseOc) this.obligatorie = true;

    if(this.closeItems) {
      this.showCloseItems = true;
      this.closedItems = this.closeItems.map(item => item.SK);
    }
  }

  changeValue() {
    if (this.status === "Aprobada" || this.status === "Aprobado") this.obligatorie = false;
    else this.obligatorie = true;
  }

  sendOrder(e) {
    this.sendEmailOrder = e.checked;    
  }

  send() {
    this.uploadError = "";
    
    if(this.updateStatus) {
      if(!this.date || !this.reason) this.uploadError = "Debe diligenciar los campos fecha y observaciones";
      else this.dialogRef.close({reason: this.reason, date: this.date, status: 'Ok'})
    } 
    else if (this.order) {
      if (!this.hideClose && ((this.obligatorie && !this.reason) || (!this.status && !this.reason))) {
        this.uploadError = "Debe diligenciar los campos requeridos";
      } else {
        this.dialogRef.close({status: this.status, reason: this.reason, sendEmail: this.sendEmailOrder});
      }
    }
    else if (this.enterpriseOc) {
      if (!this.hideClose && ((this.obligatorie && !this.enterpriseOcValue))) {
        this.uploadError = "Debe diligenciar los campos requeridos";
      } else {
        this.dialogRef.close({enterpriseOc: this.enterpriseOcValue});
      }
    }
    else if(this.updateInform) {
      this.dialogRef.close({send: this.sendEmailOrder});
    }
    else if (!this.statusInform && !this.sendEmail) this.dialogRef.close(true);
    else if (!this.statusInform && this.sendEmail) {
      if (this.sendemail.length > 0) this.dialogRef.close({enterprise: this.enterprisePK, sendList: this.sendemail}); 
      else this.uploadError = "Debe seleccionar como mínimo un contacto";    
    }
    else {
      if (!this.hideClose && ((this.obligatorie && !this.reason) || (!this.status && !this.reason))) {
        this.uploadError = "Debe diligenciar los campos requeridos";
      }
      else if (!this.hideClose && (this.status || this.reason)) {  
        if (this.sendemail.length > 0) this.dialogRef.close({status: this.status, reason: this.reason, enterprise: this.enterprisePK, sendList: this.sendemail, closeItems: this.closedItems});      
        else this.dialogRef.close({status: this.status, reason: this.reason, closeItems: this.closedItems});
      }
      else this.dialogRef.close();
    }    
  }
}
