import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { InventoryService } from '@app/inventory/services/inventory.service';
import { ShareService } from '@app/shared/services/share.service';

@Component({
  selector: 'app-inventory-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class FormComponent implements OnInit {
  isUploading = false;
  uploadError: string;
  successMessage: String;
  attachedError: string;

  fileName = "";
  file: File;
  extensionFile;
  fileAlert;

  constructor(
    private formBuilder: FormBuilder,
    private invenotryService: InventoryService,
    private shareService: ShareService,
  ) { }

  public formControl: FormGroup;

  onFileSelected(event) {
    this.fileAlert = "";
    this.file = event.target.files[0];
    if (this.file) {
      this.fileName = this.file.name;
      this.extensionFile = this.fileName.split(".").pop();
      if(this.extensionFile === 'xlsx') this.formControl.get("fileExtension").setValue(this.extensionFile);
      else this.fileAlert = "Debe subir un archivo de Excel (.xlsx)";
    }
  }

  ngOnInit(): void {
    this.formControl = this.formBuilder.group({
      fileExtension: ["", [Validators.required,]],
    });
  }

  send() {
    this.isUploading = true;
    this.uploadError = "";
    this.successMessage = "";
    this.attachedError = "";
    this.formControl.disable({ emitEvent: false });
    let values = this.formControl.getRawValue();
    this.invenotryService.saveInventory(values).subscribe(
      (data) => {
        if (data["body"].url) {
          this.shareService
            .uploadFile(data["body"].url, this.file)
            .subscribe(
              (data) => {
                this.successMessage = "El archivo se guardó correctamente. Una vez sea procesado se notificará por correo electrónico el resultado de la operación.";
                this.formControl.reset();
              },
              (error) => {
                this.uploadError = 'Error al guardar el archivo. Intente nuevamente.';
              }
            );
        }
        this.isUploading = false;
        this.formControl.enable({ emitEvent: false });
        
      },
      (error) => {
        this.formControl.enable({ emitEvent: false });
        this.uploadError = error.error.status.message;
        this.isUploading = false;
      }
    );
  }

  getButtonText() {
    return this.isUploading
      ? "Creando inventario..."
      : "Crear inventario";
  }
}
