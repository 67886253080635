import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';

@Injectable({
  providedIn: 'root'
})
export class EnterprisesService {
  constructor(private http: HttpClient) {}

  saveEnterprises(data) {
    return this.http.put(
      `${environment.apiHost}/v1/vendor`,
      data
    );
  }

  updateEnterprises(data) {
    return this.http.patch(
      `${environment.apiHost}/v1/vendor`,
      data
    );
  }

  get(data) {
    return this.http.post(
      `${environment.apiHost}/v1/vendor`,
      data
    );
  }
}
