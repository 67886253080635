import { Component, OnInit, AfterViewInit } from '@angular/core';
import { AuthenticationService } from '@app/auth/services/authentication.service';

@Component({
  selector: 'app-list-enterprises',
  templateUrl: './list-enterprises.component.html',
  styleUrls: ['./list-enterprises.component.scss']
})
export class ListEnterprisesComponent implements AfterViewInit {

  isUpdateOperation = false;
  constructor(public authService: AuthenticationService) {}

  ngAfterViewInit() {}

}
