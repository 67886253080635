import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';

@Injectable({
  providedIn: 'root'
})
export class RemissionService {

  constructor(private http: HttpClient) { }

  saveRemission(data) {
    return this.http.put(
      `${environment.apiHost}/v1/remission`,
      data
    );
  }

  updateRemission(data) {
    return this.http.patch(
      `${environment.apiHost}/v1/remission`,
      data
    );
  }

  get(data) {
    return this.http.post(
      `${environment.apiHost}/v1/remission`,
      data
    );
  }

  getEnterprises(data) {
    return this.http.post(
      `${environment.apiHost}/v1/vendor`,
      data
    );
  }
}
