<div *ngIf="globalError" class="alert alert-danger text-center">
    {{ globalError }}
</div>

<div
  *ngIf="isInitializing && !globalError"
  class="d-flex justify-content-center flex-column align-items-center mt-5"
>
  <h4 class="my-4">Cargando todo...</h4>
  <mat-spinner></mat-spinner>
</div>

<div *ngIf="!isInitializing && !globalError" class="container-fluid">
    <ng-container *ngIf="isUpdateOperation">
        <button
          class="d-block mt-2"
          mat-raised-button
          color="primary"
          (click)="close()"
        >
          <mat-icon>keyboard_backspace</mat-icon> Regresar
        </button>
    </ng-container>
    <form (keydown.enter)="$event.preventDefault()" autocomplete="off" [formGroup]="formControl">
      <br>
      <p>Los campos con asterisco &#40;&#42;&#41; son obligatorios</p>
        <div class="row">
            <div class="col-md-2">
                <mat-form-field appearance="outline" class="d-block w-100 mb-2">
                  <mat-label>Tipo persona *</mat-label>
                  <mat-select formControlName="personType">
                    <mat-option>Elige una</mat-option>
                    <mat-option value="Natural">Natural</mat-option>
                    <mat-option value="Jurídica">Jurídica</mat-option>
                  </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-4">
              <mat-form-field appearance="outline" class="d-block w-100 mb-2">
                <mat-label>Nombre/Razón social *</mat-label>
                <input formControlName="name" type="text" matInput />
              </mat-form-field>
            </div>
            <div class="col-md-2">
              <mat-form-field appearance="outline" class="d-block w-100 mb-2">
                <mat-label>Tipo identificación *</mat-label>
                <mat-select formControlName="idType">
                  <mat-option>Elige una</mat-option>
                  <mat-option value="Cédula">Nit</mat-option>
                  <mat-option value="NIT">Cédula</mat-option>
                </mat-select>
              </mat-form-field>
          </div>
          <div class="col-md-3">
            <mat-form-field appearance="outline" class="d-block w-100 mb-2">
              <mat-label>Número *</mat-label>
              <input formControlName="id" (blur)="searchId($event.target.value)" type="text" matInput />
              <p class="text-danger idMessage" *ngIf="idMessage">La empresa ya existe</p>
            </mat-form-field>
          </div>
          <div class="col-md-1">
            <mat-form-field appearance="outline" class="d-block w-100 mb-2">
              <mat-label>DV</mat-label>
              <input formControlName="dv" type="text" matInput />
            </mat-form-field>
          </div>
          <div class="col-md-3">
            <mat-form-field appearance="outline" class="d-block w-100 mb-2">
              <mat-label>Tipo empresa *</mat-label>
              <mat-select formControlName="type">
                <mat-option>Elige una</mat-option>
                <mat-option value="Cliente">Cliente</mat-option>
                <mat-option value="Proveedor">Proveedor</mat-option>
              </mat-select>
            </mat-form-field>
        </div>
        <div class="col-md-3">
          <mat-form-field appearance="outline" class="d-block w-100 mb-2">
            <mat-label>Pais *</mat-label>
            <mat-select formControlName="country">
              <mat-option>Elige una</mat-option>
              <mat-option
                *ngFor="let country of countries"
                [value]="country.PK"
              >
                {{ country.name }}
              </mat-option>
            </mat-select>
            <mat-icon (click)="save($event, 'COTR')" matSuffix
              >playlist_add</mat-icon
            >
          </mat-form-field>
        </div>
        <div class="col-md-3">
          <mat-form-field appearance="outline" class="d-block w-100 mb-2">
            <mat-label>Ciudad *</mat-label>
            <mat-select formControlName="city">
              <mat-option>Elige una</mat-option>
              <mat-option
                *ngFor="let city of cities"
                [value]="city.PK"
              >
                {{ city.name }}
              </mat-option>
            </mat-select>
            <mat-icon (click)="save($event, 'CITY')" matSuffix
              >playlist_add</mat-icon
            >
          </mat-form-field>
        </div>
        <div class="col-md-3">
          <mat-form-field appearance="outline" class="d-block w-100 mb-2">
            <mat-label>Sector *</mat-label>
            <mat-select formControlName="idSector">
              <mat-option>Elige una</mat-option>
              <mat-option
                *ngFor="let sector of sectors"
                [value]="sector.PK"
              >
                {{ sector.name }}
              </mat-option>
            </mat-select>
            <mat-icon (click)="save($event, 'SECT')" matSuffix
              >playlist_add</mat-icon
            >
          </mat-form-field>
        </div>
        <div class="col-md-3">
          <mat-form-field appearance="outline" class="d-block w-100 mb-2">
            <mat-label>Teléfono *</mat-label>
            <input formControlName="phone" type="tel" matInput />
          </mat-form-field>
        </div>
        <div class="col-md-3">
          <mat-form-field appearance="outline" class="d-block w-100 mb-2">
            <mat-label>Dirección *</mat-label>
            <input formControlName="address" type="text" matInput />
          </mat-form-field>
        </div>
        <div class="col-md-3">
          <mat-form-field appearance="outline" class="d-block w-100 mb-2">
            <mat-label>Correo electrónico *</mat-label>
            <input formControlName="email" type="email" matInput />
          </mat-form-field>
        </div>
        <div class="col-md-3">
          <mat-form-field appearance="outline" class="d-block w-100 mb-2">
            <mat-label>Página web</mat-label>
            <input formControlName="web" type="text" matInput />
          </mat-form-field>
        </div>
        <ng-container *ngIf="showCreateUser">
          <div class="col-md-12">
            <mat-checkbox
              id="userCheckbox"
              style="position: relative; float: left"
              (change)="createUser($event)"
              >Crear usuario</mat-checkbox
            >
          </div>
        </ng-container>      
        <div class="col-md-4">
          <mat-form-field appearance="outline" class="d-block w-100 mb-2">
            <mat-label>Usuario</mat-label>
            <input formControlName="username" type="text" autocomplete="new-password" matInput />
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-form-field appearance="outline" class="d-block w-100 mb-2">
            <mat-label>Contraseña</mat-label>
            <input formControlName="password" type="password" autocomplete="new-password" matInput />
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-form-field appearance="outline" class="d-block w-100 mb-2">
            <mat-label>Estado</mat-label>
            <mat-select formControlName="status">
              <mat-option>Elige una</mat-option>
              <mat-option value="ACTIVE">Activo</mat-option>
              <mat-option value="INACTIVE">Inactivo</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <hr>
      <h2>Contactos</h2>      
      <div formArrayName="items">
        <div *ngFor="let itemrow of formControl.controls.items['controls']; let i=index" [formGroupName]="i">
          <hr>
        <div class="row">
          <div  class="col-md-12">
            <button
            class="d-block mt-2 button-remove"
            mat-raised-button
            color="primary"
            (click)="$event.preventDefault()"
            (click)="deleteRow(i)"
          >
            <mat-icon>remove</mat-icon>
          </button>
          <br>
          </div>
        </div>
        <div class="row">        
          <div class="col-md-3">
            <mat-form-field appearance="outline" class="d-block w-100 mb-2">
              <mat-label>Nombre *</mat-label>
              <input formControlName="name" type="text" matInput />
            </mat-form-field>
          </div>
          <div class="col-md-3">
            <mat-form-field appearance="outline" class="d-block w-100 mb-2">
              <mat-label>Cargo *</mat-label>
              <mat-select formControlName="position">
                <mat-option>Elige una</mat-option>
                <mat-option
                  *ngFor="let position of positions"
                  [value]="position.PK"
                >
                  {{ position.name }}
                </mat-option>
              </mat-select>
              <mat-icon (click)="save($event, 'POST', i)" matSuffix
                >playlist_add</mat-icon
              >
            </mat-form-field>
          </div>
          <div class="col-md-3">
            <mat-form-field appearance="outline" class="d-block w-100 mb-2">
              <mat-label>Celular *</mat-label>
              <input formControlName="cellphone" type="tel" matInput />
            </mat-form-field>
          </div>
          <div class="col-md-3">
            <mat-form-field appearance="outline" class="d-block w-100 mb-2">
              <mat-label>Correo electrónico *</mat-label>
              <input formControlName="email" type="email" matInput />
            </mat-form-field>
          </div>        
        </div>
        </div>
      </div>      
      <button
          class="d-block mt-2"
          mat-raised-button
          color="primary"
          style="margin: auto;"
          (click)="addNewRow($event)"
        >
          <mat-icon>add</mat-icon>
        </button>
        <br>

        <div *ngIf="uploadError" class="alert alert-danger text-center">
          {{ uploadError }}
        </div>
    
        <div *ngIf="successMessage" class="alert alert-success text-center">
          {{ successMessage }}
        </div>
    
        <button
          class="d-block w-100 mt-2"
          mat-raised-button
          color="primary"
          [disabled]="formControl.invalid || isUploading || formArr.length === 0"
          (click)="$event.preventDefault()"
          (click)="send()"
        >
          {{ getButtonText() }}
        </button>
    </form>
</div>
<br />