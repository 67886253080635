import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '@app/auth/services/authentication.service';

@Component({
  selector: 'app-list-work',
  templateUrl: './list-work.component.html',
  styleUrls: ['./list-work.component.scss']
})
export class ListWorkComponent implements AfterViewInit {
  isUpdateOperation = false;
  path;

  constructor(public authService: AuthenticationService,
    private router: Router) { 
      this.path = router.url; 
    }

  ngAfterViewInit() {}

}
