<div *ngIf="globalError" class="alert alert-danger text-center">
    {{ globalError }}
</div>

<div
  *ngIf="isInitializing && !globalError"
  class="d-flex justify-content-center flex-column align-items-center mt-5"
>
  <h4 class="my-4">Cargando todo...</h4>
  <mat-spinner></mat-spinner>
</div>

<div *ngIf="!isInitializing && !globalError" class="container-fluid"> 
  
    <ng-container *ngIf="isUpdateOperation">
        <button
          class="d-block mt-2"
          mat-raised-button
          color="primary"
          (click)="close()"
        >
          <mat-icon>keyboard_backspace</mat-icon> Regresar
        </button>
    </ng-container>
    <form [formGroup]="formControl" autocomplete="off">
      <br>
      <p>Los campos con asterisco &#40;&#42;&#41; son obligatorios</p>
        <div class="row">
          <div class="col-md-6">
            <mat-form-field appearance="outline" class="d-block w-100 mb-2" (keydown.enter)="$event.preventDefault()">
              <mat-label>Empresa *</mat-label>
              <mat-select formControlName="enterprise">
                <mat-option>Elige una</mat-option>
                <mat-option
                  *ngFor="let enterprise of enterprises"
                  [value]="enterprise.PK"
                >
                  {{ enterprise.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-md-6">
            <mat-form-field appearance="outline" class="d-block w-100 mb-2" (keydown.enter)="$event.preventDefault()">
              <mat-label>Contacto *</mat-label>
              <mat-select formControlName="contact">
                <mat-option>Elige una</mat-option>
                <mat-option
                  *ngFor="let contact of contacts"
                  [value]="contact.SK"
                >
                  {{ contact.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>  
          <div class="col-md-6">
            <mat-form-field appearance="outline" class="d-block w-100 mb-2" (keydown.enter)="$event.preventDefault()">
              <mat-label>Gestión comercial *</mat-label>
              <mat-select formControlName="commercialManagement">
                <mat-option>Elige una</mat-option>
                <mat-option value="Email">Email</mat-option>
                <mat-option value="Llamada">Llamada</mat-option>
                <mat-option value="Visita">Visita</mat-option>
              </mat-select>
            </mat-form-field>
          </div>         
          <div class="col-md-6">
            <mat-form-field appearance="outline" class="d-block w-100 mb-2" (keydown.enter)="$event.preventDefault()">
              <mat-label>Título *</mat-label>
              <input formControlName="title" type="text" matInput />
            </mat-form-field>
          </div>
          <div class="col-md-4">
            <mat-form-field appearance="outline" class="d-block w-100 mb-2" (keydown.enter)="$event.preventDefault()">
              <mat-label>Fecha *</mat-label>
              <input
                readonly
                formControlName="date"
                matInput
                [matDatepicker]="picker"
              />
              <mat-datepicker-toggle
              (click)="picker.open()"
                matSuffix
                [for]="picker"
              ></mat-datepicker-toggle>
              <mat-datepicker touchUi #picker></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="col-md-4">
            <mat-form-field appearance="outline" class="d-block w-100 mb-2" (keydown.enter)="$event.preventDefault()">
              <mat-label>Hora *</mat-label>
              <input formControlName="hour" type="text" matInput />
            </mat-form-field>
          </div>
          <div class="col-md-4">
            <mat-form-field appearance="outline" class="d-block w-100 mb-2" (keydown.enter)="$event.preventDefault()">
              <mat-label>Fecha próximo contacto</mat-label>
              <input
                readonly
                formControlName="nextDate"
                matInput
                [matDatepicker]="picker1"
              />
              <mat-datepicker-toggle
              (click)="picker.open()"
                matSuffix
                [for]="picker1"
              ></mat-datepicker-toggle>
              <mat-datepicker touchUi #picker1></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="col-md-12">
            <mat-form-field appearance="outline" class="d-block w-100 mb-2">
              <mat-label>Descripción *</mat-label>
              <textarea formControlName="description" type="text" matInput rows="7"></textarea>
            </mat-form-field>
          </div>
      </div>
        <div *ngIf="uploadError" class="alert alert-danger text-center">
          {{ uploadError }}
        </div>
    
        <div *ngIf="successMessage" class="alert alert-success text-center">
          {{ successMessage }}
        </div>
    
        <button
          class="d-block w-100 mt-2"
          mat-raised-button
          color="primary"
          [disabled]="formControl.invalid || isUploading"
          (click)="send()"
        >
          {{ getButtonText() }}
        </button>
    </form>
    <div *ngIf="showTable" class="container-fluid">
      <div class="row">
        <div class="col">
          <app-data-table
            [names]="tableNames"
            [data]="items"
          ></app-data-table>
        </div>
      </div>
    </div>
</div>
<br />