import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { CommercialService } from '@app/commercial/services/commercial.service';
import { Auth } from 'aws-amplify';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-commercial-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class FormComponent implements OnInit {
  @Input()
  isUpdateOperation;
  @Input()
  PK;
  @Output()
  action = new EventEmitter();
  isInitializing = true;
  isUploading = false;
  globalError: string;
  uploadError: string;
  successMessage: String;
  enterprises;
  contacts = [];
  items;  
  showTable = false;

  tableNames = {
    date: "Fecha",
    hour: "Hora",
    nextDate: "Fecha próximo contacto",
    description: "Descripción",
  };

  formControl = this.formBuilder.group({
    enterprise: ["", [Validators.required, Validators.pattern("^ENTR[0-9]+$"), Validators.maxLength(11)]],
    contact: ["", [Validators.required, Validators.pattern("^CONV[0-9]+$"), Validators.maxLength(11)]],
    commercialManagement: ["", [Validators.required]],
    title: ["", [Validators.required, Validators.pattern("^[a-zA-ZáéíóúÁÉÍÓÚñÑ ]+$"), Validators.maxLength(255)]],
    date: ["", [Validators.required]],
    hour: ["", [Validators.required, Validators.pattern("^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$")]],
    nextDate: ["", []],
    description: ["", [Validators.required, Validators.maxLength(4000)]],
  });

  constructor(
    private formBuilder: FormBuilder,
    private commercialService: CommercialService,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.formControl.get("enterprise").valueChanges.subscribe((value) => {
      if (value) {
        this.commercialService.getEnterprises({contacts:value}).subscribe(data=>{this.contacts = data["body"]});
      } else {
        this.contacts = [];
      }
    });  

    Auth.currentSession().then((credentials) => {
      let enterpriseRq = this.commercialService.getEnterprises({all:"ALL"});
    if (this.isUpdateOperation) {
      this.showTable = true;
      let commercialRq = this.commercialService.get({ PK: this.PK });
      forkJoin([commercialRq, enterpriseRq]).subscribe(
        ([commercialRs, enterpriseRs]) => {
          const COMMERCIAL = commercialRs["body"].filter(item => item.SK === this.PK)[0];
          COMMERCIAL.date = "";
          COMMERCIAL.hour = "";
          COMMERCIAL.nextDate = "";
          COMMERCIAL.description = "";
          this.enterprises = enterpriseRs["body"];
          this.items = commercialRs["body"].filter(item => item.SK !== this.PK);
          COMMERCIAL.nextDate = COMMERCIAL.nextDate ? COMMERCIAL.nextDate : "";          
          this.formControl.patchValue(COMMERCIAL);
          this.isInitializing = false;
        },
        (error) => {
          this.globalError =
            "Se ha presentado un error, por favor vuelva a intentarlo";
          this.isInitializing = false;
        }
      );
    } else {      
      forkJoin([enterpriseRq]).subscribe(
        ([enterpriseRs]) => {
          this.enterprises = enterpriseRs["body"];
          this.isInitializing = false;
        },
        (error) => {
          this.globalError =
            "Se ha presentado un error, por favor vuelva a intentarlo";
          this.isInitializing = false;
        }
      );
    }
    });
  }

  send() {
    this.isUploading = true;
    this.globalError = "";
    this.uploadError = "";
    this.successMessage = "";
    this.formControl.disable({ emitEvent: false });
    if (this.isUpdateOperation) {
      let data = this.formControl.getRawValue();
      data.PK = this.PK;
      this.commercialService.updateCommercial(data).subscribe(
        (data) => {
          this.formControl.enable({ emitEvent: false });
          this.successMessage = "Operación realizada con exito";
          this.isUploading = false;
        },
        (error) => {
          this.formControl.enable({ emitEvent: false });
          this.uploadError = error.error.status.message;
          this.isUploading = false;
        }
      );
    } else {
      let data = this.formControl.getRawValue();
      if(!data.nextDate) delete data.nextDate;
      this.commercialService.saveCommercial(data).subscribe(
        (data) => {
          this.formControl.enable({ emitEvent: false });
          this.formControl.reset();
          this.formControl.updateValueAndValidity({emitEvent: false});
          this.successMessage = "Operación realizada con exito";
          this.isUploading = false;
        },
        (error) => {
          this.formControl.enable({ emitEvent: false });
          this.uploadError = error.error.status.message;
          this.isUploading = false;
        }
      );
    }
  }  

  getButtonText() {
    return this.isUploading
      ? this.isUpdateOperation
        ? "Actualizando seguimiento comercial..."
        : "Creando seguimiento comercial..."
      : this.isUpdateOperation
      ? "Actualizar seguimiento comercial"
      : "Crear seguimiento comercial";
  }

  close() {
    this.action.emit();
  }
}
