<div *ngIf="globalError" class="alert alert-danger text-center">
  {{ globalError }}
</div>

<div
  *ngIf="isInitializing && !globalError"
  class="d-flex justify-content-center flex-column align-items-center mt-5"
>
  <h4 class="my-4">Cargando todo...</h4>
  <mat-spinner></mat-spinner>
</div>

<div *ngIf="!isInitializing && !globalError" class="container-fluid">
  <ng-container *ngIf="isUpdateOperation">
    <button
      class="d-inline mt-2"
      mat-raised-button
      color="primary"
      (click)="close()"
    >
      <mat-icon>keyboard_backspace</mat-icon> Regresar
    </button>
  </ng-container>
  <form [formGroup]="formControl" autocomplete="off">
    <br />
    <p>Los campos con asterisco &#40;&#42;&#41; son obligatorios</p>
    <div class="row">
      <div class="col-md-6">
        <mat-form-field appearance="outline" class="d-block w-100 mb-2">
          <mat-label>Orden *</mat-label>
          <input
            formControlName="order"
            type="number"
            (blur)="searchId($event.target.value)"
            matInput
          />
        </mat-form-field>
      </div>
      <div class="col-md-6">
        <mat-form-field appearance="outline" class="d-block w-100 mb-2">
          <mat-label>Empresa</mat-label>
          <input formControlName="enterpriseOrder" type="text" matInput />
        </mat-form-field>
      </div>
      <div class="col-md-6">
        <mat-form-field appearance="outline" class="d-block w-100 mb-2">
          <mat-label>Fecha inicial</mat-label>
          <input formControlName="startDateOrder" type="text" matInput />
        </mat-form-field>
      </div>
      <div class="col-md-6">
        <mat-form-field appearance="outline" class="d-block w-100 mb-2">
          <mat-label>Fecha final</mat-label>
          <input formControlName="finishDateOrder" type="text" matInput />
        </mat-form-field>
      </div>
      <div class="col-md-12">
        <mat-form-field appearance="outline" class="d-block w-100 mb-2">
          <mat-label>Descripción</mat-label>
          <textarea
            formControlName="descriptionOrder"
            type="text"
            matInput
            rows="7"
          ></textarea>
        </mat-form-field>
      </div>
      <div class="col-md-3">
        <mat-form-field
          appearance="outline"
          class="d-block w-100 mb-2"
          (keydown.enter)="$event.preventDefault()"
        >
          <mat-label>Fecha salida *</mat-label>
          <input
            readonly
            formControlName="departureDate"
            matInput
            [matDatepicker]="picker"
          />
          <mat-datepicker-toggle
            (click)="picker.open()"
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-datepicker touchUi #picker></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="col-md-3">
        <mat-form-field
          appearance="outline"
          class="d-block w-100 mb-2"
          (keydown.enter)="$event.preventDefault()"
        >
          <mat-label>Se remisiona *</mat-label>
          <mat-select formControlName="refer">
            <mat-option>Elige una</mat-option>
            <mat-option value="Si">Sí</mat-option>
            <mat-option value="No">No</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-md-3">
        <mat-form-field appearance="outline" class="d-block w-100 mb-2">
          <mat-label>Número remisión <span *ngIf="formControl.get('refer').value === 'Si'">*</span></mat-label>
          <input formControlName="numberRefer" type="tyext" matInput />
        </mat-form-field>
      </div>
      <div class="col-md-3">
        <mat-form-field
          appearance="outline"
          class="d-block w-100 mb-2"
          (keydown.enter)="$event.preventDefault()"
        >
          <mat-label>Se factura *</mat-label>
          <mat-select formControlName="bill">
            <mat-option>Elige una</mat-option>
            <mat-option value="Si">Sí</mat-option>
            <mat-option value="No">No</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-md-4">
        <mat-form-field appearance="outline" class="d-block w-100 mb-2">
          <mat-label>Número de factura <span *ngIf="formControl.get('bill').value === 'Si'">*</span></mat-label>
          <input formControlName="numberBill" type="text" matInput />
        </mat-form-field>
      </div>
      <div class="col-md-4">
        <mat-form-field appearance="outline" class="d-block w-100 mb-2">
          <mat-label>Transportadora *</mat-label>
          <input formControlName="transporter" type="text" matInput />
        </mat-form-field>
      </div>
      <div class="col-md-4">
        <mat-form-field appearance="outline" class="d-block w-100 mb-2">
          <mat-label>Número de guía *</mat-label>
          <input formControlName="guide" type="text" matInput />
        </mat-form-field>
      </div>
      <div class="col-md-12">
        <mat-form-field appearance="outline" class="d-block w-100 mb-2">
          <mat-label>Motivo *</mat-label>
          <textarea
            formControlName="motive"
            type="text"
            matInput
            rows="7"
          ></textarea>
        </mat-form-field>
      </div>
      <div class="col-md-12">
        <mat-form-field appearance="outline" class="d-block w-100 mb-2">
          <mat-label>Observaciones</mat-label>
          <textarea
            formControlName="observations"
            type="text"
            matInput
            rows="7"
          ></textarea>
        </mat-form-field>
      </div>
    </div>
    <hr />
    <h2>Items</h2>
    <div formArrayName="items">
      <div
        *ngFor="
          let itemrow of formControl.controls.items['controls'];
          let i = index
        "
        [formGroupName]="i"
      >
        <hr />
        <div class="row">
          <div class="col-md-12">
            <button
              class="d-block mt-2 button-remove"
              mat-raised-button
              color="primary"
              (click)="$event.preventDefault()"
              (click)="deleteRow(i)"
            >
              <mat-icon>remove</mat-icon>
            </button>
            <br />
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <mat-form-field
              appearance="outline"
              class="d-block w-100 mb-2"
              (keydown.enter)="$event.preventDefault()"
            >
              <mat-label>Categoría *</mat-label>
              <mat-select formControlName="category">
                <mat-option>Elige una</mat-option>
                <mat-option *ngFor="let category of categories" [value]="category.PK">
                  {{ category.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-md-3">
            <mat-form-field
              appearance="outline"
              class="d-block w-100 mb-2"
              (keydown.enter)="$event.preventDefault()"
            >
              <mat-label>Descripción *</mat-label>
              <input formControlName="description" type="text" matInput />
            </mat-form-field>
          </div>
          <div class="col-md-3">
            <mat-form-field
              appearance="outline"
              class="d-block w-100 mb-2"
              (keydown.enter)="$event.preventDefault()"
            >
              <mat-label>Unidad *</mat-label>
              <input formControlName="unity" type="text" matInput />
            </mat-form-field>
          </div>
          <div class="col-md-3">
            <mat-form-field
              appearance="outline"
              class="d-block w-100 mb-2"
              (keydown.enter)="$event.preventDefault()"
            >
              <mat-label>Cantidad *</mat-label>
              <input formControlName="quantity" type="number" [min]="0" matInput />
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
    <hr />
    <button
      class="d-block mt-2"
      mat-raised-button
      color="primary"
      style="margin: auto"
      (click)="addNewRow($event)"
    >
      <mat-icon>add</mat-icon>
    </button>
    <br />
    <div class="row">
      <div class="col-md-12">
        <mat-checkbox
          formControlName="send"
          style="position: relative; float: right"
        >
        <span *ngIf="profile === 'PROF1' || profile === 'PROF2'; else otherProfile">Guardar definitivamente (No se podrá modificar)</span>
        <ng-template #otherProfile>Enviar para aprobación</ng-template>
        </mat-checkbox>
      </div>
    </div>    

    <div *ngIf="uploadError" class="alert alert-danger text-center">
      {{ uploadError }}
    </div>

    <div *ngIf="successMessage" class="alert alert-success text-center">
      {{ successMessage }}
    </div>

    <button
      class="d-block w-100 mt-2"
      mat-raised-button
      color="primary"
      [disabled]="formControl.invalid || isUploading || formArr.length === 0"
      (click)="$event.preventDefault()"
      (click)="send()"
    >
      {{ getButtonText() }}
    </button>
  </form>
</div>
<br />
