import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { EnterprisesService } from '@app/enterprises/services/enterprises.service';
import { FormatsService } from '@app/enterprises/services/formats.service';
import { ShareService } from '@app/shared/services/share.service';
import { Auth } from 'aws-amplify';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-enterprises-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})
export class ReportComponent implements OnInit {
  isInitializing = false;
  isUploading = false;
  globalError: string;
  uploadError: string;
  data;
  positions;
  cities;
  sectors;

  formControl = this.formBuilder.group({
    typeDocument: ["", [Validators.required]],
    type: ["", []],
    city: ["", []],
    idSector: ["", []],
  });

  constructor(
    private formBuilder: FormBuilder,
    private entrerpriseService: EnterprisesService,
    private formatsService: FormatsService,
    private shareService: ShareService
  ) { }

  ngOnInit(): void {
    Auth.currentSession().then((credentials) => {
      let cityRq = this.shareService.getList("CITY");
      let sectorRq = this.shareService.getList("SECT");
      let positionRq = this.shareService.getList("POST");
      forkJoin([cityRq, sectorRq, positionRq]).subscribe(
        ([cityRs, sectorRs, positionRs]) => {
          this.cities = cityRs["body"];
          this.sectors = sectorRs["body"];
          this.positions = positionRs["body"];
        },
      );
    });
  }

  send() {
    this.globalError = "";
    this.uploadError = "";
    if (!this.formControl.get('city').value && !this.formControl.get('idSector').value && !this.formControl.get('type').value) {
      this.uploadError = "Seleccione al menos un criterio de búsqueda";
      return;
    }
    else {
      this.entrerpriseService
        .get({ city: this.formControl.value.city || undefined, sector: this.formControl.value.idSector || undefined, type: this.formControl.value.type || undefined })
        .subscribe(
          (data) => {
            this.data = data["body"];
            this.isInitializing = false;
            if (!this.data.length) {
              this.uploadError = "No se encontraron resultados";
              return;
            }
            let content =
              this.formControl.value.typeDocument === "PDF"
                ? this.formatsService.generateReport(this.data)
                : this.shareService.getTitles(this.formatsService.TRANSLATE, this.data);
            this.shareService.export(
              this.formControl.value.typeDocument,
              content,
              "Empresas",
              { pageSize: "FOLIO", pageOrientation: "landscape", title: "Informe Registro de empresas" }
            );
          },
          (error) => {
            this.uploadError =
              "Se ha presentado un error, por favor vuelva a intentarlo";
            this.isInitializing = false;
          }
        );
    }
  }

}
