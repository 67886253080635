import { CurrencyPipe } from '@angular/common';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FormatsService {

  constructor(
    private currencyPipe: CurrencyPipe
  ) { }

  TRANSLATE = {
    order: "Orden",
    startDate: "Fecha inicial",
    finishDate: "Fecha final",
    enterpriseName: "Empresa",
    personName: "Persona",
    totalExpense: "Total gastos",
    advanceCard: "Anticipo tarjeta",
    advanceCash: "Anticipo efectivo",
    devolution: "Devolución",
    totalLegalization: "Total legalizar"
  };

  generatePrint(data) {
    let document: Array<any> = [
      {
        lineHeight: 1,
        text: ".", color: "white",
      },
      {
        table: {
          headerRows: 1,
          widths: ["50%", "50%"],
          body: [            
            [
              { text: [{text: `Orden: `, bold: true}, {text: `${data.order || ''}`}]},
              { text: [{text: `Nombre del responsable: `, bold: true}, {text: `${data.personName}`}]},              
            ],
            [
              { text: [{text: `Área: `, bold: true}, {text: `${data.areaName}`}]},
              { text: [{text: `Empresa visitada: `, bold: true}, {text: `${data.enterpriseName}`}]},              
            ],
            [
              { text: [{text: `Fecha inicio del viaje: `, bold: true}, {text: `${data.startDate}`}]},
              { text: [{text: `Fecha fin del viaje: `, bold: true}, {text: `${data.finishDate}`}]},              
            ],
            [
              { text: [{text: `Fecha de elaboración del reporte: `, bold: true}, {text: `${data.creationDate}`}], colSpan: 2}, {},
            ],
            [
              { text: [{text: `Motivo del gasto: `, bold: true}, {text: `${data.motive}`}], colSpan: 2}, {},
            ],
          ],
        },
      },
    ];

    document.push(
      {
        lineHeight: 1,
        text: ".", color: "white",
      },
      {
        table: {
          headerRows: 1,
          widths: ["20%", "60%", "20%"],
          body: [
            [{ text: "Gastos", alignment: "center",  bold: true, colSpan: 3 }, {}, {}],
            [
              { text: `Fecha`, alignment: "center", bold: true},
              { text: `Concepto`, alignment: "center", bold: true},
              { text: `Valor`, alignment: "center", bold: true}
            ],
            ...data.items.map(d => ([{text: d.date}, {text: d.concept != 'LITE0' ? d.conceptName : d.conceptDescription}, {text: `${this.currencyPipe.transform(d.value, 'USD', 'symbol-narrow', '1.2-2')}`, alignment: 'right'}])),
            [
              { text: "Total gastos", alignment: "right",  bold: true, colSpan: 2 }, {}, 
              { text: `${this.currencyPipe.transform(data.totalExpense, 'USD', 'symbol-narrow', '1.2-2')}`, alignment: 'right'}
            ],            
            [
              { text: "Total anticipo transferencia", alignment: "right",  bold: true, colSpan: 2 }, {}, 
              { text: `${this.currencyPipe.transform(data.advanceTransfer || 0, 'USD', 'symbol-narrow', '1.2-2')}`, alignment: 'right'}
            ],            
            [
              { text: "Total anticipo efectivo", alignment: "right",  bold: true, colSpan: 2 }, {}, 
              { text: `${this.currencyPipe.transform(data.advanceCash, 'USD', 'symbol-narrow', '1.2-2')}`, alignment: 'right'}
            ],
            [
              { text: "Total anticipo tarjeta crédito", alignment: "right",  bold: true, colSpan: 2 }, {}, 
              { text: `${this.currencyPipe.transform(data.advanceCard, 'USD', 'symbol-narrow', '1.2-2')}`, alignment: 'right'}
            ],
            [
              { text: "Devolución", alignment: "right",  bold: true, colSpan: 2 }, {}, 
              { text: `${this.currencyPipe.transform(data.devolution, 'USD', 'symbol-narrow', '1.2-2')}`, alignment: 'right'}
            ],
          ],
        },
      },
      {
        lineHeight: 1,
        text: "Nota: KBT Solutions no se hace responsable de gastos por bebidas alcohólicas y productos de tabaco.", bold: true
      },
      {
        lineHeight: 1,
        text: ".", color: "white",
      },
      {
        table: {
          headerRows: 1,
          widths: ["100%"],
          body: [ 
            [{ text: "Observaciones:", bold: true}],
            [{ text: `${data.observations}`, alignment:'justify' }],
          ],
        },
      },      
    );

    if(data.urlAtt) {
      document.push( 
        {
          lineHeight: 2,
          text: ".", color: "white",
        }, 
        {
          table: {
            headerRows: 1,
            widths: ["10%", "90%"],
            body: [
              [{ text: "Adjunto",bold: true}, {text: `Descargar adjunto`, link: `${data.urlAtt}`, color: "blue", decoration: "underline"}],

            ],
          },
        },        
      );
    }

    document.push(
      {
        lineHeight: 2,
        text: ".", color: "white",
      },
      {
        alignment: 'center',
        columns: [
          {
            text: [
              {text: 'Firma del responsable \n\n\n', bold: true},
              {text: `Firma: ${data.personName}`},
            ]
          },
          {
            text: [
              {text: 'Firma de autorización \n\n\n', bold: true},
              {text: `Firma: ${data.approverName || '____________________________________'}`},
            ]
          }
        ]
      },
    );

    return document;
  }

  generateReport(data) {
    return [
      {
        table: {
          headerRows: 1,
          widths: ["5%", "8%", "8%", "12%", "12%", "11%", "11%", "11%", "11%", "11%"],
          body: [
            [{ text: 'Orden', bold: true, alignment: "center" }, { text: 'Fecha inicial', bold: true, alignment: "center" }, { text: 'Fecha final', bold: true, alignment: "center" }, { text: 'Empresa', bold: true, alignment: "center" }, { text: 'Persona', bold: true, alignment: "center" }, { text: 'Total gastos', bold: true, alignment: "center" }, { text: 'Anticipo tarjeta', bold: true, alignment: "center" }, { text: 'Anticipo efectivo', bold: true, alignment: "center" }, { text: 'Devolución', bold: true, alignment: "center" }, { text: 'Total legalizar', bold: true, alignment: "center" }],
            ...data.map(d => ([d.order || '', d.startDate, d.finishDate, d.enterpriseName, d.personName, { text: `${this.currencyPipe.transform(d.totalExpense, 'USD', 'symbol-narrow', '1.2-2')}`, alignment: 'right'}, { text: `${this.currencyPipe.transform(d.advanceCard, 'USD', 'symbol-narrow', '1.2-2')}`, alignment: 'right'}, { text: `${this.currencyPipe.transform(d.advanceCash, 'USD', 'symbol-narrow', '1.2-2')}`, alignment: 'right'}, { text: `${this.currencyPipe.transform(d.devolution, 'USD', 'symbol-narrow', '1.2-2')}`, alignment: 'right'}, { text: `${this.currencyPipe.transform(d.totalLegalization, 'USD', 'symbol-narrow', '1.2-2')}`, alignment: 'right'}])),
          ],
        },
      },
    ];
  }  
}
