import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "@environment";

@Injectable({
  providedIn: "root",
})
export class UsersService {
  constructor(private http: HttpClient) {}

  saveUser(data) {
    return this.http.put(
      `${environment.apiHost}/v1/personal-registration`,
      data
    );
  }

  updateUser(data) {
    return this.http.patch(
      `${environment.apiHost}/v1/personal-registration`,
      data
    );
  }

  get(data) {
    return this.http.post(
      `${environment.apiHost}/v1/personal-registration`,
      data
    );
  }
}
