<div *ngIf="globalError" class="alert alert-danger text-center">
    {{ globalError }}
</div>

<div
      *ngIf="isInitializing && !globalError"
      class="d-flex justify-content-center flex-column align-items-center mt-5"
    >
      <h4 class="my-4">Cargando todo...</h4>
      <mat-spinner></mat-spinner>
</div>

<div *ngIf="!isInitializing && !globalError">
  <mat-tab-group mat-stretch-tabs>
    <mat-tab
      label="Cotizaciones abiertas">
      <app-data-table
      [names]="quotationsOpenNames"
      [data]="quotationsOpenResult"
    ></app-data-table>
    </mat-tab>
    <mat-tab
      label="Próximos seguimientos">
      <app-data-table
      [names]="commercialNames"
      [data]="commercialResult"
    ></app-data-table>
    </mat-tab>
    <mat-tab
      label="Órdenes abiertas">
      <app-data-table
      [names]="pendingBillingOrdersNames"
      [data]="pendingBillingOrdersResult"
    ></app-data-table>
    </mat-tab>
    <mat-tab
      label="Órdenes por cerrar">
      <app-data-table
      [names]="pendingCloseOrdersNames"
      [data]="pendingCloseOrdersResult"
    ></app-data-table>
    </mat-tab>
    <mat-tab
      label="Recordatorio órdenes">
      <app-data-table
      [names]="reminderOrdersNames"
      [data]="reminderOrdersResult"
    ></app-data-table>
    </mat-tab>
  </mat-tab-group>
</div>



