import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';

@Injectable({
  providedIn: 'root'
})
export class WorkService {

  constructor(private http: HttpClient) { }

  saveWork(data) {
    return this.http.put(
      `${environment.apiHost}/v1/work-order`,
      data
    );
  }

  saveWorkInform(data) {
    return this.http.put(
      `${environment.apiHost}/v1/work-order-inform`,
      data
    );
  }

  updateWork(data) {
    return this.http.patch(
      `${environment.apiHost}/v1/work-order`,
      data
    );
  }

  updateWorkInform(data) {
    return this.http.patch(
      `${environment.apiHost}/v1/work-order-inform`,
      data
    );
  }

  get(data) {
    return this.http.post(
      `${environment.apiHost}/v1/work-order`,
      data
    );
  }

  getInform(data) {
    return this.http.post(
      `${environment.apiHost}/v1/work-order-inform`,
      data
    );
  }

  getEnterprises(data) {
    return this.http.post(
      `${environment.apiHost}/v1/vendor`,
      data
    );
  }

  getUsers(data) {
    return this.http.post(
      `${environment.apiHost}/v1/personal-registration`,
      data
    );
  }

  getResume(data) {
    return this.http.post(
      `${environment.apiHost}/v1/resume`,
      data
    );
  }

  closeWork(data) {
    return this.http.patch(
      `${environment.apiHost}/v1/work-order/close`,
      data
    );
  }

  updateStatusInform(data) {
    return this.http.patch(
      `${environment.apiHost}/v1/work-order-inform/update-status`,
      data
    );
  }

  editEnterpriseOc(data) {
    return this.http.patch(
      `${environment.apiHost}/v1/work-order-oc`,
      data
    );
  }

  sendEmail(data) {
    return this.http.patch(
      `${environment.apiHost}/v1/work-order-inform/send-email`,
      data
    );
  }

  updateStatus(data) {
    return this.http.patch(
      `${environment.apiHost}/v1/resume/update-status`,
      data
    );
  }
}
