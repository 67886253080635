import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormComponent } from './pages/form/form.component';
import { ListPermissionsComponent } from './pages/list-permissions/list-permissions.component';
import { SharedModule } from '@app/shared/shared.module';
import { ReportComponent } from './pages/report/report.component';
import { SearchComponent } from './pages/search/search.component';
import { httpInterceptorProviders } from '@app/auth/interceptors';



@NgModule({
  declarations: [FormComponent, ListPermissionsComponent, ReportComponent, SearchComponent],
  imports: [CommonModule, SharedModule],
  providers: [httpInterceptorProviders],
})
export class PermissionsModule { }
