import { Component, OnInit } from '@angular/core';
import { NotificationsService } from './notifications.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {
  globalError: string;
  isInitializing = true;
  reminderOrdersResult;
  pendingBillingOrdersResult;
  pendingCloseOrdersResult;
  commercialResult;
  quotationsOpenResult;

  reminderOrdersNames = {
    PK: "Consecutivo",
    enterpriseName: "Empresa",
    contactName: "Contacto",
    contactCellphone: "Celular",
    contactEmail: "Email",
  };

  pendingBillingOrdersNames = {
    PK: "Consecutivo",
    enterpriseName: "Empresa",
    startDate: "Fecha inicial|date",
    finishDate: "Fecha final|date",
  };

  pendingCloseOrdersNames = {
    PK: "Consecutivo",
    enterpriseName: "Empresa",
    startDate: "Fecha inicial|date",
    finishDate: "Fecha final|date",
  };

  commercialNames = {
    PK: "Consecutivo",
    enterpriseName: "Empresa",
    relation7: "Próxima fecha de contacto|date",
    title: "Título",
  };

  quotationsOpenNames = {
    PK: "Consecutivo",
    enterpriseName: "Empresa",
    date: "Fecha|date",
    subject: "Asunto",
    contactName: "Contacto",
    contactCellphone: "Celular",
    contactEmail: "Email",
  };

  constructor(
    private notificationsService: NotificationsService
  ) { }

  ngOnInit(): void {
    this.notificationsService.get().subscribe(
      (data) => {
        this.reminderOrdersResult = data["body"].reminderOrders;
        this.pendingBillingOrdersResult = data["body"].pendingBillingOrders;
        this.pendingCloseOrdersResult = data["body"].pendingCloseOrders;
        this.commercialResult = data["body"].commercial;
        this.quotationsOpenResult = data["body"].quotationsOpen;
        this.isInitializing = false;
      })
  }

}
