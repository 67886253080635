<div *ngIf="globalError" class="alert alert-danger text-center">
    {{ globalError }}
</div>

<div
  *ngIf="isInitializing && !globalError"
  class="d-flex justify-content-center flex-column align-items-center mt-5"
>
  <h4 class="my-4">Cargando todo...</h4>
  <mat-spinner></mat-spinner>
</div>

<div *ngIf="!isInitializing && !globalError" class="container-fluid">
    <form [formGroup]="formControl" (submit)="send()" autocomplete="off">
      <br>
      <p>Los campos con asterisco &#40;&#42;&#41; son obligatorios</p>
      <div class="row">
        <div class="col-md-2">
          <mat-form-field appearance="outline" class="d-block w-100 mb-2">
            <mat-label>Tipo de archivo *</mat-label>
            <mat-select formControlName="typeFile">
              <mat-option>Elige una</mat-option>
              <mat-option value="PDF">PDF</mat-option>
              <mat-option value="EXCEL">Excel</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-md-2">
          <mat-form-field appearance="outline" class="d-block w-100 mb-2">
            <mat-label>Tipo de informe*</mat-label>
            <mat-select formControlName="typeInform">
              <mat-option>Elige una</mat-option>
              <mat-option value="RESU">General</mat-option>
              <mat-option value="REIP">Equipos con pendientes</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-md-3">
            <mat-form-field appearance="outline" class="d-block w-100 mb-2">
              <mat-label>Empresa</mat-label>
              <mat-select formControlName="enterprise">
                <mat-option>Elige una</mat-option>
                <mat-option
                  *ngFor="let enterprise of enterprises"
                  [value]="enterprise.PK"
                >
                  {{ enterprise.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        <div class="col-md-3">
            <mat-form-field appearance="outline" class="d-block w-100 mb-2">
              <mat-label>Tipo de equipo</mat-label>
              <mat-select formControlName="typeEquipment">
                <mat-option>Elige una</mat-option>
                <mat-option
                  *ngFor="let typeEquipment of typeEquipments"
                  [value]="typeEquipment.PK"
                >
                  {{ typeEquipment.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-md-2">
            <mat-form-field appearance="outline" class="d-block w-100 mb-2">
              <mat-label>Marca</mat-label>
              <mat-select formControlName="mark">
                <mat-option>Elige una</mat-option>
                <mat-option
                  *ngFor="let mark of marks"
                  [value]="mark.PK"
                >
                  {{ mark.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <!-- <div class="col-md-6">
            <mat-form-field appearance="outline" class="d-block w-100 mb-2">
              <mat-label>Modelo</mat-label>
              <mat-select formControlName="model">
                <mat-option>Elige una</mat-option>
                <mat-option
                  *ngFor="let model of models"
                  [value]="model.PK"
                >
                  {{ model.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>           -->
      </div>
  
      <div *ngIf="uploadError" class="alert alert-danger text-center">
        {{ uploadError }}
      </div>
  
      <button
        class="d-block w-100 mt-2"
        mat-raised-button
        color="primary"        
        [disabled]="formControl.invalid || isUploading"
      >
        {{ isUploading ? "Generando..." : "Generar" }}
      </button>
    </form>
  </div>
  <br />