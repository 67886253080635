import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ListUsersComponent } from "./pages/list-users/list-users.component";
import { SharedModule } from "@app/shared/shared.module";
import { httpInterceptorProviders } from "@app/auth/interceptors";
import { FormComponent } from './pages/form/form.component';
import { SearchComponent } from './pages/search/search.component';
import { ReportComponent } from './pages/report/report.component';
import { NgxCurrencyInputModule } from "ngx-currency-input";

@NgModule({
  declarations: [ListUsersComponent, FormComponent, SearchComponent, ReportComponent],
  imports: [CommonModule, SharedModule, NgxCurrencyInputModule],
  exports: [],
  providers: [httpInterceptorProviders],
})
export class UsersModule {}
