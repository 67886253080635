import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';

@Injectable({
  providedIn: 'root'
})
export class TravelExpensesService {

  constructor(private http: HttpClient) { }

  saveTravelExpense(data) {
    return this.http.put(
      `${environment.apiHost}/v1/travel-expense`,
      data
    );
  }

  updateTravelExpense(data) {
    return this.http.patch(
      `${environment.apiHost}/v1/travel-expense`,
      data
    );
  }

  get(data) {
    return this.http.post(
      `${environment.apiHost}/v1/travel-expense`,
      data
    );
  }

  updateStatus(data) {
    return this.http.patch(
      `${environment.apiHost}/v1/travel-expense/update-status`,
      data
    );
  }

  getEnterprises(data) {
    return this.http.post(
      `${environment.apiHost}/v1/vendor`,
      data
    );
  }

  getUsers(data) {
    return this.http.post(
      `${environment.apiHost}/v1/personal-registration`,
      data
    );
  }

  getWork(data) {
    return this.http.post(
      `${environment.apiHost}/v1/work-order`,
      data
    );
  }
}