import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListTimeComponent } from './pages/list-time/list-time.component';
import { FormComponent } from './pages/form/form.component';
import { SearchComponent } from './pages/search/search.component';
import { ReportComponent } from './pages/report/report.component';
import { ApproveComponent } from './pages/approve/approve.component';
import { SharedModule } from '@app/shared/shared.module';
import { httpInterceptorProviders } from '@app/auth/interceptors';
import { CheckComponent } from './pages/check/check.component';



@NgModule({
  declarations: [ListTimeComponent, FormComponent, SearchComponent, ReportComponent, ApproveComponent, CheckComponent],
  imports: [CommonModule, SharedModule],
  providers: [httpInterceptorProviders],
})
export class TimeModule { }
