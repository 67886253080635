import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ListsService } from '@app/lists/services/lists.service';
import { ShareService } from '@app/shared/services/share.service';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class FormComponent implements OnInit {
  @Input()
  update = false;
  @Input()
  delete = false;

  isInitializing = true;
  isUploading = false;
  globalError: string;
  uploadError: string;
  successMessage: String;

  list;

  entities = [
    { entity: "CITY", name: "Ciudad" },
    { entity: "SETY", name: "Tipo de servicio" },
    { entity: "FAMI", name: "Familia" },
    { entity: "GROU", name: "Grupo" },
    { entity: "UNIT", name: "Unidad de medida Item" },
    { entity: "TYPE", name: "Tipo Item" },
    { entity: "CATE", name: "Categoria Item" },
    { entity: "AREA", name: "Área empleado" },
    { entity: "DELI", name: "Plazo de entrega" },
    { entity: "PAYM", name: "Forma de pago" }];

  constructor(
    private formBuilder: FormBuilder,
    private shareService: ShareService,
    private listsService: ListsService
  ) { }

  public formControl: FormGroup;

  ngOnInit(): void {
    this.formControl = this.formBuilder.group({
      entity: ["", [Validators.required]],
      PK: ["", [Validators.required]],
      name: ["", []],
    });

    if (this.update) this.formControl.get("name").setValidators([Validators.required]);

    this.isInitializing = false;

    this.formControl.get("entity").valueChanges.subscribe((value) => {
      if (value) {
        this.shareService.getList(value)
          .subscribe((data) => {
            this.list = data["body"];
          });
      } else {
        this.list = [];
      }
    });
  }

  send() {
    this.isUploading = true;
    this.globalError = "";
    this.uploadError = "";
    this.successMessage = "";
    this.formControl.disable({ emitEvent: false });
    let data = this.formControl.getRawValue();
    let service;
    if (this.update) service = this.listsService.updateList(data);
    else service = this.listsService.deleteList(data);
    service.subscribe(
      (data) => {
        this.formControl.enable({ emitEvent: false });
        this.formControl.reset();
        this.formControl.updateValueAndValidity({ emitEvent: false });
        this.successMessage = "Operación realizada con exito";
        this.isUploading = false;
      },
      (error) => {
        this.formControl.enable({ emitEvent: false });
        this.uploadError = error.error.status.message;
        this.isUploading = false;
      }
    );
  }

  getButtonText() {
    return this.isUploading
      ? this.update ? 
      "Actualizando lista..." 
      : "Eliminando lista"
      : this.delete ? 
      "Eliminar lista"
      : "Actualizar lista";
  }
}
