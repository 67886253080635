<div class="container-fluid">
  <mat-tab-group mat-stretch-tabs>
    <mat-tab
      *ngIf="authService.checkActionsPermission('INVE', ['CREATE'])"
      label="Crear"
      ><ng-template matTabContent
        ><app-inventory-form></app-inventory-form></ng-template
    ></mat-tab>
    <mat-tab
      *ngIf="authService.checkActionsPermission('INVE', ['PRINT'])"
      label="Buscar"
      ><ng-template matTabContent
        ><app-inventory-search></app-inventory-search></ng-template
    ></mat-tab>
  </mat-tab-group>
</div>
