import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FormatsService {
  TRANSLATE = {
    enterpriseName: "Empresa",
    contactName: "Contacto",
    startDate: "Fecha inicial",
    finishDate: "Fecha final",
    service: "Servicio",
    technicianName: "Técnico",
    description: "Descripción",
    status: "Estado"
  }

  TRANSLATETWO = {
    enterpriseName: "Empresa",
    startDate: "Fecha inicial",
    service: "Servicio",
    description: "Descripción",
    statusInform: "Estado"
  }

  constructor() { }

  generatePrint(data) {
    let document: Array<any> = [
      {
        lineHeight: 1,
        text: ".", color: "white",
      },
      {
        alignment: 'left',
        columns: [
          {
            text: [
              {text: 'Empresa: ', bold: true},
              {text: `${data.enterpriseName} \n`},
              {text: 'Celular: ', bold: true},
              {text: `${data.contactCellphone} \n`},
            ]
          },
          {
            text: [
              {text: 'Contacto: ', bold: true},
              {text: `${data.contactName}\n`},
              {text: 'Correo: ', bold: true},
              {text: `${data.contactEmail}\n`},
            ]
          }
        ]
      },
      {
        lineHeight: 1,
        text: ".", color: "white",
      },
      {
        table: {
          headerRows: 0,
          widths: ["*", "*", "*", "*", "*", "*", "*", "*", "*", "*", "*", "*"],
          body: [            
            [
              { text: [{text: `Fecha inicial: `, bold: true}, {text: `${data.startDate}`}], colSpan: 6 }, {}, {}, {}, {}, {},
              { text: [{text: `Fecha final: `, bold: true}, {text: `${data.finishDate}`}], colSpan: 6 }, {}, {}, {}, {}, {},              
            ],
            [
              { text: [{text: `Servicio: `, bold: true}, {text: `${data.service}`}], colSpan: 6 }, {}, {}, {}, {}, {},
              { text: [{text: `Técnico: `, bold: true}, {text: `${data.technicianName}`}], colSpan: 6 }, {}, {}, {}, {}, {},              
            ],
            [
              
              { text: [{text: `Lugar de prestación del servicio: `, bold: true}, {text: `${data.placeService}`}], colSpan: 12 }, {}, {}, {}, {}, {},
              
            ],
          ],
        },
      },
      {
        lineHeight: 1,
        text: ".", color: "white",
      },
      {
        table: {
          headerRows: 1,
          widths: ["*", "*", "*", "*", "*", "*", "*", "*", "*", "*", "*", "*"],
          body: [ 
            [{ text: "Descripción:", bold: true, colSpan: 12 }, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}],
            [
              { text: `${data.description}`, colSpan: 12, alignment:'justify' }, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {},
            ],
          ],
        },
      },
    ];

    if(data.reminder) {
      document.push(
        {
          lineHeight: 1,
          text: ".", color: "white",
        },
        {
          table: {
            headerRows: 0,
            widths: ["*", "*", "*", "*", "*", "*", "*", "*", "*", "*", "*", "*"],
            body: [ 
              [
                { text: [{text: `Estado: `, bold: true}, {text: `${data.status}`}], colSpan: 6 }, {}, {}, {}, {}, {},
                { text: [{text: `Recordatorio: `, bold: true}, {text: `${data.reminder || 0} meses`}], colSpan: 6 }, {}, {}, {}, {}, {},
              ],
            ],
          },
        },        
        {
          lineHeight: 1,
          text: ".", color: "white",
        },
        {
          table: {
            headerRows: 1,
            widths: ["*", "*", "*", "*", "*", "*", "*", "*", "*", "*", "*", "*"],
            body: [
              [{ text: "Horas laboradas", alignment: "center",  bold: true, colSpan: 12 }, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}],
              [
                { text: `Fecha`, alignment: "center", bold: true, colSpan: 3 }, {}, {},
                { text: `Técnico`, alignment: "center", bold: true, colSpan: 3 }, {}, {},
                { text: `Hora inicio`, alignment: "center", bold: true, colSpan: 3 }, {}, {},
                { text: `Hora fin`, alignment: "center", bold: true, colSpan: 3 }, {}, {},
              ],
              ...data.items.map(d => ([{text: d.date, colSpan: 3}, {}, {}, {text: d.technicianName, colSpan: 3}, {}, {}, {text: d.initialHour, colSpan: 3}, {}, {}, {text: d.finalHour, colSpan: 3}, {}, {},]))
            ],
          },
        },
      )
    }

    if (data.urlAtt) {
      document.push(
        {
          lineHeight: 1,
          text: ".", color: "white",
        },
        {
          table: {
            headerRows: 1,
            widths: ["10%", "90%"],
            body: [
              [{ text: "Adjunto",bold: true}, {text: `Descargar adjunto`, link: `${data.urlAtt}`, color: "blue", decoration: "underline"}],

            ],
          },
        },
      );      
    }

    document.push(
      {
        lineHeight: 2,
        text: ".", color: "white",
      },
      {
        layout: 'noBorders',
        dontBreakRows: true,
        table: {
          headerRows: 1,
          widths: ["100%"],
          body: [            
            [
              {text: `Atentamente \n\n ${data.name} \n ${data.position} \n ${data.email} \n KBT Solutions`}
            ], 
          ],
        },
      },
    );
    return document;
  }   

  generateInform(data) {
    let j = 1;
    let document: Array<any> = [
      {
        table: {
          widths: ["*", "*", "*", "*", "*", "*", "*", "*", "*", "*", "*", "*"],
          body: [
            [
              { text: [{text: `Empresa: `, bold: true}, {text: `${data.enterpriseName}`}], colSpan: 6 }, {}, {}, {}, {}, {},
              { text: [{text: `Tipo de equipo: `, bold: true}, {text: `${data.typeDevice}`}], colSpan: 6 }, {}, {}, {}, {}, {},              
            ],
            [
              { text: [{text: `Contacto: `, bold: true}, {text: `${data.contactName}`}], colSpan: 6 }, {}, {}, {}, {}, {},
              { text: [{text: `Marca: `, bold: true}, {text: `${data.mark}`}], colSpan: 6 }, {}, {}, {}, {}, {},                            
            ],
            [
              { text: [{text: `Ciudad: `, bold: true}, {text: `${data.enterpriseCity}`}], colSpan: 6 }, {}, {}, {}, {}, {},
              { text: [{text: `Potencia: `, bold: true}, {text: `${data.power}`}], colSpan: 6 }, {}, {}, {}, {}, {},                            
            ],
            [
              { text: [{text: `Fecha: `, bold: true}, {text: `${data.startDate}`}], colSpan: 6 }, {}, {}, {}, {}, {},
              { text: [{text: `Sistema modulación: `, bold: true}, {text: `${data.modulationSystem}`}], colSpan: 6 }, {}, {}, {}, {}, {},                            
            ],
          ],
        },         
      },
      {
        lineHeight: 1,
        text: ".", color: "white",
      },
      {
        table: {
          headerRows: 1,
            widths: ["*", "*", "*", "*", "*", "*", "*", "*", "*", "*", "*", "*"],
            body: [
              [{ text: "Servicio", bold: true}, { text: data.service + " " + data.description, colSpan: 11}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}],
            ]
        }
      },
      {
        lineHeight: 1,
        text: ".", color: "white",
      },
      {
        table: {
          headerRows: 1,
          widths: ["*", "*", "*", "*", "*", "*", "*", "*", "*", "*", "*", "*"],
          body: [
            [{ text: "item", bold: true, alignment: "center"}, { text: "Actividades", bold: true, colSpan: 4, alignment: "center"}, {}, {}, {}, {text: "Medición", bold: true, colSpan: 2, alignment: "center"}, {}, {text: "item", bold: true, alignment: "center"}, {text: "Actividades", bold: true, colSpan: 3, alignment: "center"}, {}, {}, {text: "Sí/No", bold: true, alignment: "center"}],
            [
              { text: "1", alignment: "center" }, {text:"Voltaje circuito control/potencia", colSpan: 4}, {}, {}, {}, {text: data.voltage, colSpan: 2},
              {}, {text: "6", alignment: "center" }, {text:"Revisión seguridades caldera/quemador/equipo", colSpan: 3}, {}, {}, {text: data.securityCheck, alignment: "center"},              
            ],
            [
              { text: "2", alignment: "center" }, {text:"Presión de aire", colSpan: 4}, {}, {}, {}, {text: data.airPressure, colSpan: 2},
              {}, {text: "7", alignment: "center" }, {text:"Revisión quemador", colSpan: 3}, {}, {}, {text: data.burnerCheck, alignment: "center"},              
            ],
            [
              { text: "3", alignment: "center" }, {text:"Presión gas reg. primera etapa (BAR-PSI)", colSpan: 4}, {}, {}, {}, {text: data.gasPressure1, colSpan: 2},
              {}, { text: "8", alignment: "center" }, {text:"Revisión suiche quemador", colSpan: 3}, {}, {}, {text: data.switchCheck, alignment: "center"},              
            ],
            [
              { text: "4", alignment: "center" }, {text:"Presión gas reg. segunda etapa (MBAR-INH20)", colSpan: 4}, {}, {}, {}, {text: data.gasPressure2, colSpan: 2},
              {}, { text: "9", alignment: "center" }, {text:"Revisión circuito eléctrico", colSpan: 3}, {}, {}, {text: data.circuitCheck, alignment: "center"},              
            ],
            [
              { text: "5", alignment: "center" }, {text:"Set valvula de seguridad", colSpan: 4}, {}, {}, {}, {text: data.setValve, colSpan: 2},
              {}, { text: "10", alignment: "center" }, {text:"Analisis de combustión", colSpan: 3}, {}, {}, {text: data.combustionAnalysis, alignment: "center"},              
            ],
          ],
        },         
      },
      {
        lineHeight: 1,
        text: ".", color: "white",
      },
      {
        table: {
          headerRows: 1,
            widths: ["*", "*", "*", "*", "*", "*", "*", "*", "*", "*", "*", "*"],
            body: [
              [{ text: "Describa de manera detallada como encontró la máquina e instrumentación (Antes y depués de la intervanción)",  bold: true, colSpan: 12 }, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}],
              [
                {text: data.detail, colSpan: 12, alignment:'justify'}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {},
              ]
            ]
        }
      },
      {
        lineHeight: 1,
        text: ".", color: "white",
      },            
    ];

    if(typeof data.recommendation != 'string') {
      document.push(        
        {
          table: {
            headerRows: 1,
              widths: ["100%"],
              body: [
                [{ text: "Recomendaciones y observaciones",  bold: true}],
                [
                  {text: data.recommendation.map((x, i) => `${i + 1}. ${x}\n`), alignment:'justify'}
                ]
              ]
          }
        },
      )
    } else {
      document.push(        
        {
          table: {
            headerRows: 1,
              widths: ["*", "*", "*", "*", "*", "*", "*", "*", "*", "*", "*", "*"],
              body: [
                [{ text: "Recomendaciones y observaciones",  bold: true, colSpan: 12 }, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}],
                [
                  {text: data.recommendation, colSpan: 12, alignment:'justify'}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {},
                ]
              ]
          }
        },
      )
    }

    document.push(
      {
        lineHeight: 1,
        text: ".", color: "white",
      },
      {
        text: [
          {text: "Nota: ", bold: true},
          {text: "Dar prioridad a las recomendaciones u observaciones para garantizar el buen funcionamiento y seguridad del equipo."},
        ]
      },
    )

    if(data.equipments.length > 0){ 
      for(let i = 0; i < data.equipments.length; i++) { 
        let parts = data.equipments[i].parts;       
        document.push(
          {
            lineHeight: 1,
            text: ".", color: "white",
          },
          {
            table: {
              headerRows: 1,
              widths: ["60%", "20%", "20%"],
              body: [            
                [
                  { text: [{text: `Equipo: `, bold: true}, {text: `${data.equipments[i].nameEquipment}`}]},
                  { text: [{text: `Fecha: `, bold: true}, {text: `${data.equipments[i].date}`}]},
                  { text: [{text: `Confiabilidad: `, bold: true}, {text: `${data.equipments[i].reliability * 100}%`}]},          
                ],
                [
                  { text: [{text: `Descripción: `, bold: true}, {text: `${data.equipments[i].description}`}], colSpan: 3}              
                ],
              ],
            },
          }
        )

        if(parts.length > 0) {
          document.push(          
            {
              table: {
                headerRows: 1,
                widths: ["40%","20%", "40%"],
                body: [
                  [
                    { text: `Parte`, alignment: "center", bold: true },
                    { text: `Valor`, alignment: "center", bold: true },
                    { text: `Descripción`, alignment: "center", bold: true },
                  ],
                  ...parts.map(d => ([{text: d.name || ''}, {text: `${d.value}%`, alignment: "center"}, {text: d.description}]))
                ],
              },
            },        
          ) 
        }                       
      }
    }
    if(data.items.length > 0) {      
      document.push(
        {
          lineHeight: 1,
          text: ".", color: "white",
        },
        {
          layout: 'noBorders',
          table: {
            headerRows: 1,
            dontBreakRows: true,
				    keepWithHeaderRows: 1,
            widths: ["100%"],
            body: [
              [{ text: "REGISTRO FOTOGRÁFICO\n\n", alignment: "center",  bold: true}],
              ...data.items.map(d => {
                let index = j;
                j++
                return [[{image: `image${index-1}`, fit: [250, 250], alignment: "center"}, {text: `Imagen ${index}: ${d.description}\n\n`, alignment: "center"}]]
              }),
              
            ],
          },
        },
      );
    }
    if (data.urlAtt) {
      document.push(
        {
          lineHeight: 1,
          text: ".", color: "white",
        },
        {
          table: {
            headerRows: 1,
            widths: ["30%", "70%"],
            body: [
              [{ text: "Archivo adjunto al informe",bold: true}, {text: `${data.PK}_${data.PK}.${data.fileExtension}`}],

            ],
          },
        },
      );      
    }
    
    if(data.signReceive) {
      document.push(
        {
          lineHeight: 2,
          text: ".", color: "white",
        },
        {
          layout: 'noBorders',
          table: {          
            widths: ["50%", "50%"],
            body: [            
              [
                { text: 'Representante técnico KBT SOLUTIONS S.A.S \n\n\n', alignment: "center", bold: true },
                { text: 'Representante cliente (Acepto conforme) \n\n\n', alignment: "center", bold: true},
              ],
              [
                { text: `Firma: ${data.name}`, alignment: "center"},
                { image: `${data.signReceive}`, fit: [100, 100], alignment: "center"},
              ],
              [
                {},
                {text: `${data.nameReceive}\n CC ${data.documentReceive}`, alignment: "center"},
              ],
            ],
          },
        },
      );
    } else {
      document.push(
      {
        lineHeight: 2,
        text: ".", color: "white",
      },
      {
        alignment: 'center',
        columns: [
          {
            text: [
              {text: 'Representante técnico KBT SOLUTIONS S.A.S \n\n\n', bold: true},
              {text: `Firma: ${data.name}`},
            ]
          },
          {
            text: [
              {text: 'Representante cliente (Acepto conforme) \n\n\n', bold: true},
              {text: 'Firma: ____________________________________'},
            ]
          }
        ]
      },
      {
        lineHeight: 2,
        text: ".", color: "white",
      },
      {
        text: "Nota: Devolver firmado o responder este correo con la aprobación del informe.", bold: true
      },);
    }
    return document;
  }

  generateReport(data) {
    return [
      {
        table: {
          headerRows: 1,
          widths: ["12%", "12%", "10%", "10%", "12%", "12%", "20%", "12%"],
          body: [
            [{ text: 'Empresa', bold: true, alignment: "center" }, { text: 'Contacto', bold: true, alignment: "center" }, { text: 'Fecha inicial', bold: true, alignment: "center" }, { text: 'Fecha final', bold: true, alignment: "center" }, { text: 'Servicio', bold: true, alignment: "center" }, { text: 'Técnico', bold: true, alignment: "center" }, { text: 'Descripción', bold: true, alignment: "center" }, { text: 'Estado', bold: true, alignment: "center" }],
            ...data.map(d => ([d.enterpriseName, d.contactName, d.startDate, d.finishDate, d.service, d.technicianName, d.description, d.status])),
          ],
        },
      },
    ];
  }

  generateReportInforms(data) {
    return [
      {
        table: {
          headerRows: 1,
          widths: ["17%", "17%", "17%", "32%", "17%"],
          body: [
            [{ text: 'Empresa', bold: true, alignment: "center" }, { text: 'Fecha inicial', bold: true, alignment: "center" }, { text: 'Servicio', bold: true, alignment: "center" }, { text: 'Descripción', bold: true, alignment: "center" }, { text: 'Estado', bold: true, alignment: "center" }],
            ...data.map(d => ([d.enterpriseName, d.startDate, d.service, d.description, d.statusInform])),
          ],
        },
      },
    ];
  }
}
