<div *ngIf="globalError" class="alert alert-danger text-center">
  {{ globalError }}
</div>

<div
  *ngIf="isInitializing && !globalError"
  class="d-flex justify-content-center flex-column align-items-center mt-5"
>
  <h4 class="my-4">Cargando todo...</h4>
  <mat-spinner></mat-spinner>
</div>

<div *ngIf="!isInitializing && !globalError" class="container-fluid">
  <ng-container *ngIf="isUpdateOperation">
    <button
      class="d-block mt-2"
      mat-raised-button
      color="primary"
      (click)="close()"
    >
      <mat-icon>keyboard_backspace</mat-icon> Regresar
    </button>
  </ng-container>
  <form [formGroup]="formControl" autocomplete="off">
    <br />
    <p>Los campos con asterisco &#40;&#42;&#41; son obligatorios</p>
    <div class="row">
      <div class="col-md-6">
        <mat-form-field
          appearance="outline"
          class="d-block w-100 mb-2"
          (keydown.enter)="$event.preventDefault()"
        >
          <mat-label>Empresa *</mat-label>
          <mat-select formControlName="enterprise">
            <mat-option>Elige una</mat-option>
            <mat-option
              *ngFor="let enterprise of enterprises"
              [value]="enterprise.PK"
            >
              {{ enterprise.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-md-6">
        <mat-form-field
          appearance="outline"
          class="d-block w-100 mb-2"
          (keydown.enter)="$event.preventDefault()"
        >
          <mat-label>Contacto *</mat-label>
          <mat-select formControlName="contact">
            <mat-option>Elige una</mat-option>
            <mat-option *ngFor="let contact of contacts" [value]="contact.SK">
              {{ contact.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-md-6">
        <mat-form-field appearance="outline" class="d-block w-100 mb-2" (keydown.enter)="$event.preventDefault()">
          <mat-label>Solicita *</mat-label>
          <mat-select formControlName="requester">
            <mat-option>Elige una</mat-option>
            <mat-option *ngFor="let user of users" [value]="user.PK">
              {{ user.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-md-6">
        <mat-form-field
          appearance="outline"
          class="d-block w-100 mb-2"
          (keydown.enter)="$event.preventDefault()"
        >
          <mat-label>Dirección envío *</mat-label>
          <input formControlName="deliveryAddress" type="text" matInput />
        </mat-form-field>
      </div>
      <div class="col-md-6">
        <mat-form-field
          appearance="outline"
          class="d-block w-100 mb-2"
          (keydown.enter)="$event.preventDefault()"
        >
          <mat-label>Ciudad envío *</mat-label>
          <input formControlName="deliveryCity" type="text" matInput />
        </mat-form-field>
      </div>
      <div class="col-md-6">
        <mat-form-field
          appearance="outline"
          class="d-block w-100 mb-2"
          (keydown.enter)="$event.preventDefault()"
        >
          <mat-label>Recibe envío *</mat-label>
          <input formControlName="deliveryReceive" type="text" matInput />
        </mat-form-field>
      </div>
      <div class="col-md-4">
        <mat-form-field
          appearance="outline"
          class="d-block w-100 mb-2"
          (keydown.enter)="$event.preventDefault()"
        >
          <mat-label>Celular envío *</mat-label>
          <input formControlName="deliveryCellphone" type="text" matInput />
        </mat-form-field>
      </div>
      <div class="col-md-4">
        <mat-form-field
          appearance="outline"
          class="d-block w-100 mb-2"
          (keydown.enter)="$event.preventDefault()"
        >
          <mat-label>Tiempo de entrega *</mat-label>
          <input formControlName="deliveryTime" type="text" matInput />
        </mat-form-field>
      </div>
      <div class="col-md-4">
        <mat-form-field
          appearance="outline"
          class="d-block w-100 mb-2"
          (keydown.enter)="$event.preventDefault()"
        >
          <mat-label>Términos de pago *</mat-label>
          <input formControlName="paymentForm" type="text" matInput />
        </mat-form-field>
      </div>
      <div class="col-md-12">
        <mat-form-field appearance="outline" class="d-block w-100 mb-2">
          <mat-label>Observaciones</mat-label>
          <textarea
            formControlName="observations"
            type="text"
            matInput
            rows="7"
          ></textarea>
        </mat-form-field>
      </div>
      <div class="col-md-12">         
        <mat-form-field appearance="outline" class="d-block w-100 mb-2">          
          <mat-label>Todos los items con IVA del</mat-label>
          <mat-select>
            <mat-option (click)="allItemsWithIVA('1')">Elige una</mat-option>
            <mat-option (click)="allItemsWithIVA('0')">0%</mat-option>
            <mat-option (click)="allItemsWithIVA('5')">5%</mat-option>
            <mat-option (click)="allItemsWithIVA('19')">19%</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <hr />
    <h2>Items</h2>
    <div formArrayName="items">
      <div
        *ngFor="
          let itemrow of formControl.controls.items['controls'];
          let i = index
        "
        [formGroupName]="i"
      >
        <hr />
        <div class="row">
          <div class="col-md-12">
            <button
              class="d-block mt-2 button-remove"
              mat-raised-button
              color="primary"
              (click)="$event.preventDefault()"
              (click)="deleteRow(i)"
            >
              <mat-icon>remove</mat-icon>
            </button>
            <br />
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <mat-form-field
              appearance="outline"
              class="d-block w-100 mb-2"
              (keydown.enter)="$event.preventDefault()"
            >
              <mat-label>Item *</mat-label>
              <input formControlName="item" type="text" matInput />
            </mat-form-field>
          </div>
          <div class="col-md-2">
            <mat-form-field
              appearance="outline"
              class="d-block w-100 mb-2"
              (keydown.enter)="$event.preventDefault()"
            >
              <mat-label>Cantidad *</mat-label>
              <input
                formControlName="quantity"
                type="number"
                min="0"
                matInput
              />
            </mat-form-field>
          </div>
          <div class="col-md-2">
            <mat-form-field
              appearance="outline"
              class="d-block w-100 mb-2"
              (keydown.enter)="$event.preventDefault()"
            >
              <mat-label>Valor *</mat-label>
              <input
                formControlName="value"
                [min]="0" ngxCurrencyInput [(ngModel)]="itemValue[i]"
                matInput
              />
            </mat-form-field>
          </div>
          <div class="col-md-2">
            <mat-form-field appearance="outline" class="d-block w-100 mb-2">
              <mat-label>IVA *</mat-label>
              <mat-select formControlName="IVA">
                <mat-option>Elige una</mat-option>
                <mat-option value="0">0%</mat-option>
                <mat-option value="5">5%</mat-option>
                <mat-option value="19">19%</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
    <hr />
    <button
      class="d-block mt-2"
      mat-raised-button
      color="primary"
      style="margin: auto"
      (click)="addNewRow($event)"
    >
      <mat-icon>add</mat-icon>
    </button>
    <br />             
    <div class="container" style="max-width:100%">
        <div class="row justify-content-end">
          <div class="col-md-3">
            <mat-form-field appearance="outline" class="d-block w-100 mb-2">
              <mat-label>Total IVA</mat-label>
              <input
              [disabled]="true"
              [min]="0" ngxCurrencyInput [(ngModel)]="totalIVA"
              [ngModelOptions]="{ standalone: true }"
                matInput
              />
            </mat-form-field>
          </div>
          <div class="col-md-3">
            <mat-form-field appearance="outline" class="d-block w-100 mb-2">
              <mat-label>Subtotal</mat-label>
              <input
              [disabled]="true"
              [min]="0" ngxCurrencyInput [(ngModel)]="subtotal"
              [ngModelOptions]="{ standalone: true }"
                matInput
              />
            </mat-form-field>
          </div>          
        </div>        
        <div class="row justify-content-end">
          <div class="col-md-3">
            <mat-form-field appearance="outline" class="d-block w-100 mb-2">
              <mat-label>% Retefuente</mat-label>
              <input
                formControlName="retefuente"
                type="number"
                min="0"
                max="100"
                matInput
                (blur)="updateAllValues()"
              />
            </mat-form-field>
          </div>
          <div class="col-md-3">
            <mat-form-field appearance="outline" class="d-block w-100 mb-2">
              <mat-label>Retefuente</mat-label>
              <input
                [disabled]="true"
                [min]="0" ngxCurrencyInput [(ngModel)]="retefuente"
                [ngModelOptions]="{ standalone: true }"
                matInput
              />
            </mat-form-field>
          </div>
        </div>
        <div class="row justify-content-end">
          <div class="col-md-3">
            <mat-form-field appearance="outline" class="d-block w-100 mb-2">
              <mat-label>ReteICA</mat-label>
              <input
                formControlName="reteica"
                type="number"
                min="0"
                max="100"
                matInput
                (blur)="updateAllValues()"
              />
            </mat-form-field>
          </div>
          <div class="col-md-3">
            <mat-form-field appearance="outline" class="d-block w-100 mb-2">
              <mat-label>ReteICA</mat-label>
              <input
                [disabled]="true"
                [min]="0" ngxCurrencyInput [(ngModel)]="reteica"
                [ngModelOptions]="{ standalone: true }"
                matInput
              />
            </mat-form-field>
          </div>
        </div>
        <div class="row justify-content-end">
            <div class="col-md-6">
              <mat-form-field appearance="outline" class="d-block w-100 mb-2">
                <mat-label>Descuento</mat-label>
                <input formControlName="discount" [min]="0" ngxCurrencyInput [(ngModel)]="discount" matInput (blur)="updateAllValues()" />
              </mat-form-field>
            </div>
          </div>        
        <div class="row justify-content-end">
          <div class="col-md-6">
            <mat-form-field appearance="outline" class="d-block w-100 mb-2">
              <mat-label>Total</mat-label>
              <input [min]="0" ngxCurrencyInput [(ngModel)]="total" [disabled]="true" [ngModelOptions]="{ standalone: true }" matInput />
            </mat-form-field>
          </div>
        </div>
      </div>
    <br />

    <div *ngIf="uploadError" class="alert alert-danger text-center">
      {{ uploadError }}
    </div>

    <div *ngIf="successMessage" class="alert alert-success text-center">
      {{ successMessage }}
    </div>

    <button
      class="d-block w-100 mt-2"
      mat-raised-button
      color="primary"
      [disabled]="formControl.invalid || isUploading || formArr.length === 0"
      (click)="$event.preventDefault()"
      (click)="send()"
    >
      {{ getButtonText() }}
    </button>
  </form>
</div>
<br />
