import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';

@Injectable({
  providedIn: 'root'
})
export class ResumeService {

  constructor(private http: HttpClient) { }

  saveResume(data) {
    return this.http.put(
      `${environment.apiHost}/v1/resume`,
      data
    );
  }

  updateResume(data) {
    return this.http.patch(
      `${environment.apiHost}/v1/resume`,
      data
    );
  }

  get(data) {
    return this.http.post(
      `${environment.apiHost}/v1/resume`,
      data
    );
  }

  getEnterprises(data) {
    return this.http.post(
      `${environment.apiHost}/v1/vendor`,
      data
    );
  }

  getDocuments(data) {
    return this.http.post(
      `${environment.apiHost}/v1/document-library`,
      data
    );
  }
}
