import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FormatsService {
  TRANSLATE = {
    PK: "Cons.",
    personName: "Solicitante",
    order: "Orden",
    enterpriseName: "Empresa",
    creationDate: "Fecha creación",
    departureDate: "Fecha salida",
    motive: "Motivo",
    observations: "Observaciones",
  }

  constructor() { }

  generatePrint(data) { 
    let machines = data.items.filter(x => x.category === 'CAMR1') || [];
    let inputs = data.items.filter(x => x.category === 'CAMR2') || [];
    let tools = data.items.filter(x => x.category === 'CAMR3') || [];
    let firstAidKits = data.items.filter(x => x.category === 'CAMR4') || [];
    let i = 0;

    let document: Array<any> = [
      {
        lineHeight: 1,
        text: ".", color: "white",
      },
      {
        table: {
          headerRows: 1,
          widths: ["50%", "50%"],
          body: [ 
            [
              { text: [{text: `Solicitante: `, bold: true}, {text: `${data.personName}`}]}, 
              { text: [{text: `Empresa: `, bold: true}, {text: `${data.enterpriseName}`}]}
            ],           
            [
              { text: [{text: `Fecha de salida: `, bold: true}, {text: `${data.departureDate}`}]},
              { text: [{text: `Orden: `, bold: true}, {text: `${data.order}`}]},              
            ],
            [
              { text: [{text: `¿Se remisiona?: `, bold: true}, {text: `${data.refer}`}]},
              { text: [{text: `No. remisión: `, bold: true}, {text: `${data.numberRefer || ''}`}]},              
            ],
            [
              { text: [{text: `¿Se factura?: `, bold: true}, {text: `${data.bill}`}]},
              { text: [{text: `No. factura: `, bold: true}, {text: `${data.numberBill || ''}`}]},              
            ],
          ],
        },
      },
    ];

    document.push(
      {
        lineHeight: 1,
        text: ".", color: "white",
      },
      {
        table: {
          headerRows: 1,
          widths: ["10%", "50%", "30%", "10%"],
          body: [
            [
              { text: "Item", alignment: "center",  bold: true },
              { text: "Descripción", alignment: "center",  bold: true }, 
              { text: "Unidad de medida", alignment: "center",  bold: true },
              { text: "Cantidad", alignment: "center",  bold: true }
            ],
          ]
        }
      }
    );

    if(machines.length > 0) {
      document.push(
        {
          table: {
            headerRows: 1,
            widths: ["10%", "50%", "30%", "10%"],
            body: [
              [
                { text: "Máquina deshollinadora", alignment: "center",  bold: true, colSpan: 4 }, {}, {}, {}
              ],
              ...machines.map(d => {
                i++;
                return [{text: `${i}`, alignment: "center"}, {text: `${d.description}`}, {text: `${d.unity}`, alignment: "center"}, {text: `${d.quantity}`, alignment: "center"}]
              })
            ]
          }
        }
      );
    }

    if(inputs.length > 0) {
      document.push(
        {
          table: {
            headerRows: 1,
            widths: ["10%", "50%", "30%", "10%"],
            body: [
              [
                { text: "Insumos", alignment: "center",  bold: true, colSpan: 4 }, {}, {}, {}
              ],
              ...inputs.map(d => {
                i++;
                return [{text: `${i}`, alignment: "center"}, {text: `${d.description}`}, {text: `${d.unity}`, alignment: "center"}, {text: `${d.quantity}`, alignment: "center"}]
              })
            ]
          }
        }
      );
    }

    if(tools.length > 0) {
      document.push(
        {
          table: {
            headerRows: 1,
            widths: ["10%", "50%", "30%", "10%"],
            body: [
              [
                { text: "Herramientas", alignment: "center",  bold: true, colSpan: 4 }, {}, {}, {}
              ],
              ...tools.map(d => {
                i++;
                return [{text: `${i}`, alignment: "center"}, {text: `${d.description}`}, {text: `${d.unity}`, alignment: "center"}, {text: `${d.quantity}`, alignment: "center"}]
              })
            ]
          }
        }
      );
    }

    if(firstAidKits.length > 0) {
      document.push(
        {
          table: {
            headerRows: 1,
            widths: ["10%", "50%", "30%", "10%"],
            body: [
              [
                { text: "Botiquín", alignment: "center",  bold: true, colSpan: 4 }, {}, {}, {}
              ],
              ...firstAidKits.map(d => {
                i++;
                return [{text: `${i}`, alignment: "center"}, {text: `${d.description}`}, {text: `${d.unity}`, alignment: "center"}, {text: `${d.quantity}`, alignment: "center"}]
              })
            ]
          }
        }
      );
    }

    if(data.observations) {
      document.push(
        {
          lineHeight: 1,
          text: ".", color: "white",
        },
        {
          table: {
            headerRows: 1,
            widths: ["100%",],
            body: [
              [
                { text: "Observaciones", alignment: "center",  bold: true}
              ],
              [
                { text: `${data.observations}`}
              ],
            ]
          }
        }
      );
    }

    document.push(
      {
        lineHeight: 4,
        text: ".", color: "white",
      },
      {
        alignment: 'center',
        columns: [
          {
            text: [
              {text: 'Firma de Entrega: ____________________________________'},
            ]
          },
          {
            text: [
              {text: 'Firma de Recibido: ____________________________________'},
            ]
          }
        ]
      },
      {
        lineHeight: 2,
        text: ".", color: "white",
      },
      {
        table: {
          headerRows: 1,
          widths: ["50%", "50%"],
          body: [  
            [
              { text: [{text: `Estado: `, bold: true}, {text: `${data.status}`}] },
              { text: [{text: `Aprobado por: `, bold: true}, {text: `${data.status === 'Aprobado' ? data.approverName : ''}`}]},
            ],          
            [
              { text: [{text: `Transportadora: `, bold: true}, {text: `${data.transporter}`}]},
              { text: [{text: `No. guía: `, bold: true}, {text: `${data.guide}`}]},              
            ],            
          ],
        },
      },
    )

    return document;
  }

  generateReport(data) {
    return [
      {
        table: {
          headerRows: 1,
          widths: ["5%", "15%", "5%", "15%", "10%", "10%", "20%", "20%"],
          body: [
            [{ text: 'Cons.', bold: true, alignment: "center" }, { text: 'Solicitante', bold: true, alignment: "center" }, { text: 'Orden', bold: true, alignment: "center" }, { text: 'Empresa', bold: true, alignment: "center" }, { text: 'Fecha creación', bold: true, alignment: "center" }, { text: 'Fecha salida', bold: true, alignment: "center" }, { text: 'Motivo', bold: true, alignment: "center" }, { text: 'Observaciones', bold: true, alignment: "center" }],
            ...data.map(d => ([d.PK.substring(4), d.personName, d.order, d.enterpriseName, d.creationDate, d.departureDate, d.motive, d.observations || ''])),
          ],
        },
      },
    ];
  }
}
