<div style="margin: 1rem">
  <mat-form-field appearance="standard" style="width: 100%">
    <mat-label>Filtro</mat-label>
    <input matInput (keyup)="applyFilter($event)" #input autocomplete="off" />
  </mat-form-field>

  <div class="my-table mat-elevation-z8" style="margin-bottom: 15px">
    <mat-table [dataSource]="dataSource" matSort (matSortChange)="announceSortChange($event)">
      <ng-container
        *ngFor="let column of displayedColumns"
        [matColumnDef]="column"
      >
        <ng-container [ngSwitch]="column">
          <ng-container *ngSwitchCase="'edit'">
            <mat-header-cell *matHeaderCellDef> Editar </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <button
                mat-flat-button
                (click)="
                  buttonClick('UPDATE', element); $event.stopPropagation()
                "
                mat-button
                matTooltip="Editar"
              >
                <mat-icon>edit</mat-icon>
              </button>
            </mat-cell>
          </ng-container>

          <ng-container *ngSwitchCase="'editPurchaseOrder'">
            <mat-header-cell *matHeaderCellDef> Editar </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <button
                *ngIf="
                displayedColumnsNames[column].includes(element['status'])
              "
                mat-flat-button
                (click)="
                  buttonClick('UPDATE', element); $event.stopPropagation()
                "
                mat-button
                matTooltip="Editar"
              >
                <mat-icon>edit</mat-icon>
              </button>
            </mat-cell>
          </ng-container>

          <ng-container *ngSwitchCase="'check'">
            <mat-header-cell *matHeaderCellDef> Revisar/Aprobar </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <button
                mat-raised-button
                mat-button
                color="primary"
                *ngIf="(element['status'] === 'Pendiente revisión' && profile === 'PROF2') ||
                (element['status'] === 'Pendiente aprobación' && profile === 'PROF1')"
                mat-flat-button
                (click)="
                  buttonClick('APPROVE', element); $event.stopPropagation()
                "
                mat-button
                matTooltip="Revisar/Aprobar"
              >
              Revisar/Aprobar
              </button>
            </mat-cell>
          </ng-container>

          <ng-container *ngSwitchCase="'editOrder'">
            <mat-header-cell *matHeaderCellDef> Editar </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <button
              *ngIf="
                  displayedColumnsNames[column].includes(element['status'])
                "
                mat-flat-button
                (click)="
                  buttonClick('UPDATE', element); $event.stopPropagation()
                "
                mat-button
                matTooltip="Editar"
              >
                <mat-icon>edit</mat-icon>
              </button>
            </mat-cell>
          </ng-container>

          <ng-container *ngSwitchCase="'crateInform'">
            <mat-header-cell *matHeaderCellDef> Crear Informe </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <button
              *ngIf="
                  displayedColumnsNames[column].includes(element['status'])
                "
                mat-flat-button
                (click)="
                  buttonClick('CREATE_INFORM', element); $event.stopPropagation()
                "
                mat-button
                matTooltip="Crear Informe"
              >
                <mat-icon>note_add</mat-icon>
              </button>
            </mat-cell>
          </ng-container>

          <ng-container *ngSwitchCase="'updateInform'">
            <mat-header-cell *matHeaderCellDef> Editar Informe </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <button
              *ngIf="!element.status && (profile === 'PROF1' || ((element.statusInform === 'Guardado' || element.statusInform === 'Pendiente aprobación' || element.statusInform === 'Rechazado') && (profile !== 'PROF4' || (profile === 'PROF4' && displayedColumnsNames[column].includes(element['creationUser'])))))"
                mat-flat-button
                (click)="
                  buttonClick('UPDATE_INFORM', element); $event.stopPropagation()
                "
                mat-button
                matTooltip="Editar Informe"
              >
                <mat-icon>note_alt</mat-icon>
              </button>
            </mat-cell>
          </ng-container>

          <ng-container *ngSwitchCase="'sendForm'">
            <mat-header-cell *matHeaderCellDef> Enviar Informe </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <button
              *ngIf="!element.status && element.statusInform === 'Aprobado'"
                mat-flat-button
                (click)="
                  buttonClick('SEND_FORM', element); $event.stopPropagation()
                "
                mat-button
                matTooltip="Enviar Informe"
              >
                <mat-icon>mail</mat-icon>
              </button>
            </mat-cell>
          </ng-container>

          <ng-container *ngSwitchCase="'sendQuotation'">
            <mat-header-cell *matHeaderCellDef> Enviar Cotización </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <button              
                mat-flat-button
                (click)="
                  buttonClick('SEND_FORM', element); $event.stopPropagation()
                "
                mat-button
                matTooltip="Enviar Cotización"
              >
                <mat-icon>mail</mat-icon>
              </button>
            </mat-cell>
          </ng-container>

          <ng-container *ngSwitchCase="'approveInform'">
            <mat-header-cell *matHeaderCellDef> Aprobar Informe </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <button
                mat-raised-button
                mat-button
                color="primary"
                *ngIf="!element.status && (displayedColumnsNames[column].includes(element['statusInform']) && 
                (profile === 'PROF1' || (profile === 'PROF2' && element.creatorProfile === 'PROF4')))"
                mat-flat-button
                (click)="
                  buttonClick('APPROVE_INFORM', element); $event.stopPropagation()
                "
                mat-button
                matTooltip="Aprobar"
              >
                Aprobar
              </button>
            </mat-cell>
          </ng-container>

          <ng-container *ngSwitchCase="'approveItem'">
            <mat-header-cell *matHeaderCellDef> Aprobar </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <button
                mat-raised-button
                mat-button
                color="primary"
                *ngIf="displayedColumnsNames[column].includes(element['status'])"
                mat-flat-button
                (click)="
                  buttonClick('APPROVE_ITEM', element); $event.stopPropagation()
                "
                mat-button
                matTooltip="Aprobar"
              >
                Aprobar
              </button>
            </mat-cell>
          </ng-container>

          <ng-container *ngSwitchCase="'approveItemMaterials'">
            <mat-header-cell *matHeaderCellDef> Aprobar </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <button
                mat-raised-button
                mat-button
                color="primary"
                *ngIf="displayedColumnsNames[column].includes(element['status']) && (element['person'] !== person)"
                mat-flat-button
                (click)="
                  buttonClick('APPROVE_ITEM_MATERIALS', element); $event.stopPropagation()
                "
                mat-button
                matTooltip="Aprobar"
              >
                Aprobar
              </button>
            </mat-cell>
          </ng-container>

          <ng-container *ngSwitchCase="'print'">
            <mat-header-cell *matHeaderCellDef> Imprimir </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <button
                mat-flat-button
                (click)="
                  buttonClick('PRINT', element); $event.stopPropagation()
                "
                mat-button
                matTooltip="Imprimir"
              >
                <mat-icon>print</mat-icon>
              </button>
            </mat-cell>
          </ng-container>

          <ng-container *ngSwitchCase="'invoiced'">
            <mat-header-cell *matHeaderCellDef> Facturada </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <button
                *ngIf="
                  displayedColumnsNames[column].includes(element['status'])
                "
                mat-flat-button
                (click)="
                  buttonClick('INVOICED', element); $event.stopPropagation()
                "
                mat-button
                matTooltip="Facturada"
              >
                <mat-icon>done</mat-icon>
              </button>
            </mat-cell>
          </ng-container>

          <ng-container *ngSwitchCase="'closeOrder'">
            <mat-header-cell *matHeaderCellDef> Finalizar </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <button
                *ngIf="
                  displayedColumnsNames[column].includes(element['status'])
                "
                mat-flat-button
                (click)="
                  buttonClick('CLOSE', element); $event.stopPropagation()
                "
                mat-button
                matTooltip="Finalizar"
              >
                <mat-icon>done</mat-icon>
              </button>
            </mat-cell>
          </ng-container>

          <ng-container *ngSwitchCase="'close'">
            <mat-header-cell *matHeaderCellDef> Finalizar </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <button
                mat-flat-button
                (click)="
                  buttonClick('CLOSE', element); $event.stopPropagation()
                "
                mat-button
                matTooltip="Finalizar"
              >
                <mat-icon>done_all</mat-icon>
              </button>
            </mat-cell>
          </ng-container>

          <ng-container *ngSwitchCase="'approve'">
            <mat-header-cell *matHeaderCellDef> Aprobar </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <button
                *ngIf="
                  displayedColumnsNames[column].includes(element['status'])
                "
                mat-flat-button
                (click)="
                  buttonClick('APPROVE', element); $event.stopPropagation()
                "
                mat-button
                matTooltip="Aprobar"
              >
                <mat-icon>done</mat-icon>
              </button>
            </mat-cell>
          </ng-container>

          <ng-container *ngSwitchCase="'reject'">
            <mat-header-cell *matHeaderCellDef> Rechazar </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <button
                *ngIf="
                  displayedColumnsNames[column].includes(element['status'])
                "
                mat-flat-button
                (click)="
                  buttonClick('REJECT', element); $event.stopPropagation()
                "
                mat-button
                matTooltip="Rechazar"
              >
                <mat-icon>close</mat-icon>
              </button>
            </mat-cell>
          </ng-container>
          
          <ng-container *ngSwitchCase="'download'">
            <mat-header-cell *matHeaderCellDef> Adjunto </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <a
                *ngIf="element.urlAtt"
                mat-raised-button
                mat-button
                color="primary"
                mat-flat-button 
                matTooltip="Adjunto"
                [href]="element.urlAtt" target="_blank"> Descargar</a>
            </mat-cell>
          </ng-container>

          <ng-container *ngSwitchCase="'PK'">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
              {{ displayedColumnsNames[column] }}
            </mat-header-cell>
            <mat-cell *matCellDef="let element">{{
              element["rev"]
                ? element["org"].substr(4) + "-" + element["rev"]
                : element[column].includes('WOIN') ? 
                  element[column].substr(4) + "- IND" 
                  : element[column].substr(4)
            }}</mat-cell>
          </ng-container>

          <ng-container *ngSwitchCase="'equipmentStatus'">
            <mat-header-cell *matHeaderCellDef>Estado</mat-header-cell>
            <mat-cell *matCellDef="let element">{{
              element["status"] === 'needIntervention' ? 'Necesita intervención' : 'Intervención urgente'
            }}</mat-cell>
          </ng-container>

          <ng-container *ngSwitchCase="'updateStatus'">
            <mat-header-cell *matHeaderCellDef> Cerrar </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <button
                mat-raised-button
                mat-button
                color="primary"
                *ngIf="
                  element['status'] !== 'added';
                  else button
                "
                mat-flat-button
                (click)="
                  buttonClick('CLOSE_EQUIPMENT', element); $event.preventDefault()
                "
                mat-button
                matTooltip="Cerrar"
              >
                Cerrar
              </button>
              <ng-template #button>
                <button
                mat-raised-button
                mat-button
                color="warn"
                mat-flat-button
                (click)="
                  buttonClick('REMOVE_EQUIPMENT', element); $event.preventDefault()
                "
                mat-button
                matTooltip="Quitar"
              >
                Quitar
              </button>
              </ng-template>
            </mat-cell>
          </ng-container>

          <ng-container *ngSwitchCase="'changeStatus'">
            <mat-header-cell *matHeaderCellDef>Aceptar/Rechazar</mat-header-cell>
            <mat-cell *matCellDef="let element">
              <mat-form-field appearance="outline" class="d-block w-100 mt-3">
                <mat-label>Estado</mat-label>
                <mat-select>
                  <mat-option (click)="buttonClick('Seleccione', element)">Elige una</mat-option>
                  <mat-option value="Aprobado" (click)="buttonClick('Aprobado', element)">Aprobada</mat-option>
                  <mat-option value="Rechazado" (click)="buttonClick('Rechazado', element)">Rechazada</mat-option>
                </mat-select>
              </mat-form-field>
            </mat-cell>
          </ng-container>

          <ng-container *ngSwitchCase="'partName'">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
              Parte
            </mat-header-cell>
            <mat-cell *matCellDef="let element">
              {{element['typeEquipmentName']}} - {{element['markName']}} - {{element['modelName']}}
            </mat-cell>
          </ng-container>

          <ng-container *ngSwitchCase="'value'">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
              Valor
            </mat-header-cell>
            <mat-cell *matCellDef="let element">
              {{element['value']}}%
            </mat-cell>
          </ng-container>

          <ng-container *ngSwitchCase="'enterpriseOc'">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
              OC cliente
            </mat-header-cell>
            <mat-cell *matCellDef="let element">
              {{element['enterpriseOc'] || ''}}
              <button
              *ngIf="
                  element['PK'].includes('WORD');
                "
                mat-flat-button
                (click)="
                  buttonClick('UPDATE_OC', element); $event.preventDefault()
                "
                matTooltip="Editar OC cliente"
              >
              <mat-icon>add</mat-icon>
              </button>
            </mat-cell>
          </ng-container>

          <ng-container *ngSwitchDefault>
            <mat-header-cell *matHeaderCellDef mat-sort-header>
              {{ displayedColumnsNames[column].replace("|date", "") }}
            </mat-header-cell>
            <mat-cell *matCellDef="let element">{{
              displayedColumnsNames[column].includes("|date")
                ? (element[column] | date: "yyyy-MM-dd")
                : element[column]
            }}</mat-cell>
          </ng-container>
        </ng-container>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row
        *matRowDef="let row; columns: displayedColumns"
        (click)="buttonClick('open', row)"
      ></mat-row>
    </mat-table>
  </div>
  <mat-paginator
    #paginator
    [pageSize]="5"
    [pageSizeOptions]="[5, 10, 20]"
    showFirstLastButtons
  ></mat-paginator>
</div>
