import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AuthenticationService } from '@app/auth/services/authentication.service';
import { AlertComponent } from '@app/shared/components/alert/alert.component';
import { SaveListComponent } from '@app/shared/components/save-list/save-list.component';
import { ShareService } from '@app/shared/services/share.service';
import { TravelExpensesService } from '@app/travel-expenses/services/travel-expenses.service';
import { Auth } from 'aws-amplify';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class FormComponent implements OnInit {
  @Input()
  isUpdateOperation;
  @Input()
  PK;
  @Output()
  action = new EventEmitter();
  isInitializing = true;
  isUploading = false;
  globalError: string;
  uploadError: string;
  successMessage: String;
  enterprises;
  users;
  concepts;
  areas;
  sendReport = false;

  valuesChange;
  totalSum: number = 0;
  returns: number = 0;

  fileName = "";
  file: File;
  extensionFile;
  url;
  documentAlert;
  fileSize;
  attachedError: string;

  constructor(
    private formBuilder: FormBuilder,
    private travelExpensesService: TravelExpensesService,
    private shareService: ShareService,
    public dialog: MatDialog,
    public authenticationService: AuthenticationService,
  ) { }

  public formControl: FormGroup;
  profile1 = this.authenticationService.cognitoUser["profile"] == 'PROF1' ? true : false;

  ngOnInit(): void {
    this.formControl = this.formBuilder.group({
      order: ["", [Validators.pattern("^[0-9]+$")]],
      enterpriseOrder: [{value: "", disabled: true}],
      startDateOrder: [{value: "", disabled: true}],
      finishDateOrder: [{value: "", disabled: true}],
      descriptionOrder: [{value: "", disabled: true}],  
      enterprise: ["", [Validators.required, Validators.pattern("^ENTR[0-9]+$")]],
      startDate: ["", [Validators.required]],
      finishDate: ["", [Validators.required]], 
      person: ["", [Validators.required, Validators.pattern("^PERR[0-9]+$")]],
      area: ["", [Validators.required, Validators.pattern("^AREA[0-9]+$")]],
      motive: ["", [Validators.required, Validators.maxLength(512)]],
      advanceCard: ["", [Validators.required, Validators.min(0)]],
      advanceCash: ["", [Validators.required, Validators.min(0)]],
      advanceTransfer: ["", [Validators.required, Validators.min(0)]],
      returns: [{value: "", disabled: true}], 
      totalExpenses: [{value: "", disabled: true}],
      observations: ["", [Validators.maxLength(4000)]],
      fileExtension: ["", []],
      items: this.formBuilder.array([]),
    });

    Auth.currentSession().then((credentials) => {
      let enterpriseRq = this.travelExpensesService.getEnterprises({all:"ALL"});
      let userRq = this.travelExpensesService.getUsers({all:"ALL"});
      let conceptRq = this.shareService.getList("LITE");
      let areaRq = this.shareService.getList("AREA");
      if (this.isUpdateOperation) {
        let travelExpensesRq = this.travelExpensesService.get({ PK: this.PK });
        forkJoin([travelExpensesRq, enterpriseRq, userRq, conceptRq, areaRq]).subscribe(
          ([travelExpensesRs, enterpriseRs, userRs, conceptRs, areaRs]) => {
            const TRAVELEXPENSES = travelExpensesRs["body"].filter(item => item.SK === this.PK)[0];
            this.enterprises = enterpriseRs["body"];
            this.users = userRs["body"];
            this.concepts = conceptRs["body"];  
            this.areas = areaRs["body"];
            TRAVELEXPENSES.items = travelExpensesRs["body"].filter(item => item.SK !== this.PK);
            TRAVELEXPENSES.startDate += "T05:00:00.000Z";
            TRAVELEXPENSES.finishDate += "T05:00:00.000Z";
            this.url = TRAVELEXPENSES.urlAtt;
            if(TRAVELEXPENSES.order) this.searchId(TRAVELEXPENSES.order)
            for (let i = 0; i < TRAVELEXPENSES.items.length; i++) {
              TRAVELEXPENSES.items[i].date += "T05:00:00.000Z";
              let group = this.initItemRows();
              group.addControl('SK', new FormControl('', Validators.required));
              this.formArr.push(group) 
              if(TRAVELEXPENSES.items[i].concept == 'LITE0') {
                this.formArr.controls[i].get('conceptDescription').enable({ emitEvent: false })
                this.formArr.controls[i].get('conceptDescription').setValidators([Validators.required, Validators.maxLength(255)])      
              }    
            }
            this.formControl.patchValue(TRAVELEXPENSES);          
            this.isInitializing = false;
          },
          (error) => {
            this.globalError =
              "Se ha presentado un error, por favor vuelva a intentarlo";
            this.isInitializing = false;
          }
        );      
      }else {      
        this.formArr.push(this.initItemRows());
        forkJoin([enterpriseRq, userRq, conceptRq, areaRq]).subscribe(
          ([enterpriseRs, userRs, conceptRs, areaRs]) => {
            this.enterprises = enterpriseRs["body"];
            this.users = userRs["body"];
            this.concepts = conceptRs["body"];
            this.areas = areaRs["body"];
            this.isInitializing = false;
          },
          (error) => {
            this.globalError =
              "Se ha presentado un error, por favor vuelva a intentarlo";
            this.isInitializing = false;
          }
        );
        }
      });

      this.valuesChange = this.formControl.controls["items"].valueChanges;
      this.valuesChange.subscribe((items) => this.updateTotalExpenses(items));
      
  }

  get formArr() {
    return this.formControl.get("items") as FormArray;
  }

  initItemRows() {
    return this.formBuilder.group({
      date: ["", [Validators.required]],
      concept: ["", [Validators.required, Validators.pattern("^LITE[0-9]+$")]],
      conceptDescription: [{value: "", disabled: true}],
      value: ["", [Validators.required, Validators.min(1)]],
    });
  }

  addNewRow(event) {
    event.stopPropagation();
    event.preventDefault();
    this.formArr.push(this.initItemRows());
  }

  deleteRow(index: number) {
    if (this.formArr.length > 1) this.formArr.removeAt(index); 
  } 

  onFileSelected(event) {
    this.file = event.target.files[0];
    if (this.file) {
      this.fileName = this.file.name;
      this.extensionFile = this.fileName.split(".").pop();      
      this.url = "";
      this.fileSize = this.file.size / 1024; //Convert bytes to kilobytes

      if (this.fileSize <= 3000 && this.extensionFile == 'pdf') {
        this.documentAlert = "";
        this.formControl.get("fileExtension").setValue(this.extensionFile);
      } else {
        this.documentAlert = "El archivo debe ser .pdf e inferior a 3MB";
        this.extensionFile = "";
        this.fileName = "";  
      }
    }
  }

  otherConcept(index) {
    let row = this.formArr.controls[index];
    if(row.get('concept').value == 'LITE0') {
      row.get('conceptDescription').enable({ emitEvent: false })
      row.get('conceptDescription').setValidators([Validators.required, Validators.maxLength(255)])      
    } else {
      row.get('conceptDescription').reset()
      row.get('conceptDescription').clearValidators()
      row.get('conceptDescription').disable({ emitEvent: false })      
    }
    row.get('conceptDescription').updateValueAndValidity({ emitEvent: false });
  }

  private updateTotalExpenses(items: any) {
    this.totalSum = 0;
    for (let i in items) {
      if (items[i].value) {
        this.totalSum += items[i].value;
      }
    }
    this.formControl.get('totalExpenses').patchValue(this.totalSum);
    this.calculateReturns();
  }

  calculateReturns() {
    this.returns = this.formControl.get('totalExpenses').value - this.formControl.get('advanceCard').value - this.formControl.get('advanceCash').value - this.formControl.get('advanceTransfer').value;
    this.formControl.get('returns').patchValue(this.returns);
  }

  searchId(event) {
    let PK = `WORD${event}`;
    let orderData = {
      enterpriseOrder: "",
      startDateOrder: "",
      finishDateOrder: "",
      descriptionOrder: "",
    }
    this.travelExpensesService.getWork({ PK }).subscribe(
      (data) => {
        if (data["body"].length !== 0) {
          orderData.enterpriseOrder = data["body"][0].enterpriseName;
          orderData.startDateOrder = data["body"][0].startDate;
          orderData.finishDateOrder = data["body"][0].finishDate;
          orderData.descriptionOrder = data["body"][0].description;

          this.formControl.patchValue(orderData);
        } else {
          this.noOrderFound("No existe la orden de trabajo digitada")
        }
      },
      (error) => {
        this.noOrderFound("Se presentó un error al consultar la orden, por favor intente nuevamente")
      }
    );     
  }

  noOrderFound(message) {
    this.formControl.get('enterpriseOrder').reset();
    this.formControl.get('startDateOrder').reset();
    this.formControl.get('finishDateOrder').reset();
    this.formControl.get('descriptionOrder').reset();
    this.formControl.get('order').reset();

    let action = true;
    let label = message;
    this.dialog.open(AlertComponent, {
      width: "500px",
      data: {
        label,
        action,
      },
    });
  }

  disabledInputs() {
    this.formControl.get("enterpriseOrder").disable({ emitEvent: false });
    this.formControl.get("startDateOrder").disable({ emitEvent: false });
    this.formControl.get("finishDateOrder").disable({ emitEvent: false }); 
    this.formControl.get("descriptionOrder").disable({ emitEvent: false });    
    this.formControl.get("totalExpenses").disable({ emitEvent: false });
    this.formControl.get("returns").disable({ emitEvent: false }); 
    for(let i = 0; i < this.formArr.length; i++) {
      this.otherConcept(i);
    }
  }

  sendInform(e) {
    this.sendReport = e.checked;
    if(this.sendReport && !this.extensionFile) {
      this.documentAlert = "Debe adjuntar un archivo para poder enviar la información";
      this.formControl.get('fileExtension').setValidators([Validators.required]);      
    } else {
      this.documentAlert = "";
      this.formControl.get('fileExtension').clearValidators()
    }
    this.formControl.get('fileExtension').updateValueAndValidity({ emitEvent: false });
  }

  send() {    
    if(this.sendReport) {
      let label =
        "¿Está seguro que desea enviar el documento para aprobación? Ya no podrá ingresar a modificar el registro";
      const dialogRef = this.dialog.open(AlertComponent, {
        width: "500px",
        data: {
          label,
        },
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) this.callSaveUpdateServices()
      });
    } else this.callSaveUpdateServices()
  }

  callSaveUpdateServices() {
    this.isUploading = true;
    this.globalError = "";
    this.uploadError = "";
    this.successMessage = "";
    this.attachedError = "";
    let values = this.formControl.value;
    if(!values.observations) delete values.observations;
    if(!values.order) delete values.order;
    values.send = this.sendReport;
    this.formControl.disable({ emitEvent: false });
    if (this.isUpdateOperation) {
      if(!this.extensionFile) delete values.fileExtension;
      values.PK = this.PK;
      this.travelExpensesService.updateTravelExpense(values).subscribe(
        (data) => {
          if(data["body"].url) {
              this.shareService.uploadFile(data["body"].url, this.file).subscribe(data=>{;
              this.successMessage = "Operación realizada con exito";
              this.fileName = 'Aún no se ha subido ningún archivo.';
            },
            (error) => {
              this.attachedError = "El registro se guardó correctamente pero se ha presentado un error al subir el archivo";
              this.fileName = 'Aún no se ha subido ningún archivo.';
            })
          }
          else {            
            this.successMessage = "Operación realizada con exito";            
          } 
          this.formControl.enable({ emitEvent: false });
          this.disabledInputs()
          this.isUploading = false;
        },
        (error) => {
          this.formControl.enable({ emitEvent: false });
          this.disabledInputs()
          this.uploadError = error.error.status.message;
          this.isUploading = false;
        }
      );
    } else {
      this.travelExpensesService.saveTravelExpense(values).subscribe(
        (data) => {
          if(data["body"].url) {
              this.shareService.uploadFile(data["body"].url, this.file).subscribe(data=>{
              this.successMessage = "Operación realizada con exito";
              this.fileName = 'Aún no se ha subido ningún archivo.';
            },
            (error) => {
              this.attachedError = "El registro se guardó correctamente pero se ha presentado un error al subir el archivo";
              this.fileName = 'Aún no se ha subido ningún archivo.';
            })
          }
          else {            
            this.successMessage = "Operación realizada con exito";            
          }
          this.formControl.enable({ emitEvent: false });
          this.disabledInputs()
          this.formControl.reset();
          this.formControl.updateValueAndValidity({ emitEvent: false });
          this.isUploading = false;
        },
        (error) => {
          this.formControl.enable({ emitEvent: false });
          this.disabledInputs()
          this.uploadError = error.error.status.message;
          this.isUploading = false;
        }
      );
    }
  }

  getButtonText() {
    return this.isUploading
      ? this.isUpdateOperation
        ? "Actualizando legalización de viáticos..."
        : "Creando legalización de viáticos..."
      : this.isUpdateOperation
      ? "Actualizar legalización de viáticos"
      : "Crear legalización de viáticos";
  }

  close() {
    this.action.emit();
  }
}
