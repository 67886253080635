import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { AuthenticationService } from "@app/auth/services/authentication.service";
import { PartialObserver } from "rxjs";

@Component({
  selector: "app-dashboard-outlet",
  templateUrl: "./dashboard-outlet.component.html",
  styleUrls: ["./dashboard-outlet.component.scss"],
})
export class DashboardOutletComponent implements OnInit {
  isLoading = true;
  visibleCards: any[] = [];
  isSubdasboard = false;
  cards = [];

  @Output() titleEmit = new EventEmitter<string>();

  constructor(private authService: AuthenticationService) {}

  ngOnInit() {
    const subscriber: PartialObserver<any> = {
      next: (data) => {
        this.cards = data;
        this.changeCards(this.cards)     
      },
      error: () => {
        // TODO: Handle errors
      },
      complete: () => {
        this.isLoading = false;
      },
    };

    this.authService.getUserModulesPermissions().subscribe(subscriber);
  }

  getTitle(item) {
    this.authService.title = item.name;
    if(item.entity === 'SERV') this.isSubdasboard = true;
    this.changeCards(this.cards)
    console.log(this.isSubdasboard) 
  }

  goBack() {
    this.authService.title = "Formatos";
    this.isSubdasboard = false;
    this.changeCards(this.cards)
  }

  changeCards(data) {
    let cardValidation = element => element.entity === "WORD" || element.entity === "WARR" || element.entity === "WOIN";
       
    if(this.isSubdasboard) this.visibleCards = data.filter(cardValidation);
    else this.visibleCards = data.filter(element => element.entity !== "WORD" && element.entity !== "WARR" && element.entity !== "WOIN");
            
    if(data.find(cardValidation) && !this.isSubdasboard) {
      this.visibleCards.push({
        name: 'Órdenes de trabajo',
        entity: 'SERV',
        icon: "receipt", 
        description: "Acá podrás administrar el módulo de órdenes de trabajo.",
        route: ['services']
      })          
    }    
  }
}
