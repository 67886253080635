<div class="pl-3 pt-3">
  <ng-container *ngIf="isSubdasboard">
    <button
      class="d-block mt-2"
      mat-raised-button
      color="primary"
      (click)="goBack()"
    >
      <mat-icon>keyboard_backspace</mat-icon> Regresar
    </button>
  </ng-container>
</div>

<div *ngIf="!isLoading; else loadingTemplate" class="cards-grid p-3">
  <mat-card *ngFor="let item of visibleCards" [routerLink]="item.route"
  (click)="getTitle(item)">
    <mat-card-header>
      <div        
        mat-card-avatar
        class="d-flex justify-content-center align-items-center"
        style="background-color: #2ec1ac"
      >
        <mat-icon style="color: #fff">{{ item.icon }}</mat-icon>
      </div>
      <mat-card-title>{{ item.name }}</mat-card-title>
      <mat-card-subtitle class="mb-0">{{ item.description }}</mat-card-subtitle>
    </mat-card-header>
  </mat-card>
</div>

<ng-template #loadingTemplate>
  <div
    class="d-flex justify-content-center align-items-center flex-column my-5 py-5"
  >
    <h4 class="mb-4">Cargando módulos...</h4>
    <mat-spinner></mat-spinner>
  </div>
</ng-template>
