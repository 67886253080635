import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/shared/shared.module';
import { httpInterceptorProviders } from '@app/auth/interceptors';
import { FormComponent } from './pages/form/form.component';
import { ListEnterprisesComponent } from './pages/list-enterprises/list-enterprises.component';
import { ReportComponent } from './pages/report/report.component';
import { SearchComponent } from './pages/search/search.component';



@NgModule({
  declarations: [FormComponent, ListEnterprisesComponent, ReportComponent, SearchComponent],
  imports: [CommonModule, SharedModule],
  exports: [],
  providers: [httpInterceptorProviders]
})
export class EnterprisesModule { }
