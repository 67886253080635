import { Component, OnInit } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { ShareService } from "@app/shared/services/share.service";
import { FormatsService } from "@app/users/services/formats.service";
import { UsersService } from "@app/users/services/users.service";

@Component({
  selector: "app-report",
  templateUrl: "./report.component.html",
  styleUrls: ["./report.component.scss"],
})
export class ReportComponent implements OnInit {
  isInitializing = false;
  isUploading = false;
  globalError: string;
  uploadError: string;
  data;

  formControl = this.formBuilder.group({
    type: ["", [Validators.required]],
    status: ["", [Validators.required]],
  });

  constructor(
    private formBuilder: FormBuilder,
    private userService: UsersService,
    private formatsService: FormatsService,
    private shareService: ShareService
  ) {}

  ngOnInit(): void {}

  send() {
    this.globalError = "";
    this.uploadError = "";
    this.userService
      .get({ status: this.formControl.value.status, type: "R" })
      .subscribe(
        (data) => {
          this.data = data["body"];
          this.isInitializing = false;
          if (!this.data.length) {
            this.uploadError = "No se encontraron resultados";
            return;
          }
          let content =
            this.formControl.value.type === "PDF"
              ? this.formatsService.generateReport(this.data)
              : this.shareService.getTitles(this.formatsService.TRANSLATE, this.data);
          this.shareService.export(
            this.formControl.value.type,
            content,
            "personal",
            { pageSize: "FOLIO", pageOrientation: "landscape", title: "Informe Registro de personal" }
          );
        },
        (error) => {
          this.uploadError =
            "Se ha presentado un error, por favor vuelva a intentarlo";
          this.isInitializing = false;
        }
      );
  }
}
