import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FormatsService {

  constructor() { }

  TRANSLATE = {
    order: "Orden",
    enterpriseName: "Empresa",
    startDate: "Fecha inicial",
    service: "Servicio",
    interveneEquipment: "Equipos a intervenir",
    description: "Descripción",
    date: "Fecha",
    technicianName: "Técnico",
    descriptionWarranty: "Descripción",
    observations: "Observaciones"
  }

  generatePrint(data) {
    let document: Array<any> = [
      {
        lineHeight: 1,
        text: ".", color: "white",
      },
      {
        table: {
          headerRows: 0,
          widths: ["*", "*", "*", "*", "*", "*", "*", "*", "*", "*", "*", "*"],
          body: [            
            [
              { text: [{text: `Orden: `, bold: true}, {text: `${data.order}`}], colSpan: 6 }, {}, {}, {}, {}, {},
              { text: [{text: `Empresa: `, bold: true}, {text: `${data.enterpriseName}`}], colSpan: 6 }, {}, {}, {}, {}, {},              
            ],
            [
              { text: [{text: `Fecha inicial: `, bold: true}, {text: `${data.startDate}`}], colSpan: 6 }, {}, {}, {}, {}, {},
              { text: [{text: `Servicio: `, bold: true}, {text: `${data.service}`}], colSpan: 6 }, {}, {}, {}, {}, {},              
            ],
            [
              
              { text: [{text: `Equipos a intervenir: `, bold: true}, {text: `${data.interveneEquipment}`}], colSpan: 12 }, {}, {}, {}, {}, {},
              
            ],
          ],
        },
      },
      {
        lineHeight: 1,
        text: ".", color: "white",
      },
      {
        table: {
          headerRows: 1,
          widths: ["*", "*", "*", "*", "*", "*", "*", "*", "*", "*", "*", "*"],
          body: [ 
            [{ text: "Descripción:", bold: true, colSpan: 12 }, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}],
            [
              { text: `${data.description}`, colSpan: 12, alignment:'justify' }, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {},
            ],
          ],
        },
      },
      {
        lineHeight: 1,
        text: ".", color: "white",
      },
      {
        table: {
          headerRows: 0,
          widths: ["*", "*", "*", "*", "*", "*", "*", "*", "*", "*", "*", "*"],
          body: [            
            [
              { text: [{text: `Fecha: `, bold: true}, {text: `${data.date}`}], colSpan: 6 }, {}, {}, {}, {}, {},
              { text: [{text: `Técnico: `, bold: true}, {text: `${data.technicianName}`}], colSpan: 6 }, {}, {}, {}, {}, {},              
            ],
          ],
        },
      },
      {
        lineHeight: 1,
        text: ".", color: "white",
      },
      {
        table: {
          headerRows: 1,
          widths: ["*", "*", "*", "*", "*", "*", "*", "*", "*", "*", "*", "*"],
          body: [ 
            [{ text: "Descripción:", bold: true, colSpan: 12 }, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}],
            [
              { text: `${data.descriptionWarranty}`, colSpan: 12, alignment:'justify' }, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {},
            ],
          ],
        },
      },
      {
        lineHeight: 1,
        text: ".", color: "white",
      },
      {
        table: {
          headerRows: 1,
          widths: ["*", "*", "*", "*", "*", "*", "*", "*", "*", "*", "*", "*"],
          body: [ 
            [{ text: "Observaciones:", bold: true, colSpan: 12 }, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}],
            [
              { text: `${data.observations}`, colSpan: 12, alignment:'justify' }, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {},
            ],
          ],
        },
      },
    ];

    document.push(
      {
        lineHeight: 2,
        text: ".", color: "white",
      },
      {
        layout: 'noBorders',
        dontBreakRows: true,
        table: {
          headerRows: 1,
          widths: ["100%"],
          body: [            
            [
              {text: `Atentamente \n\n ${data.name} \n ${data.position} \n ${data.email} \n KBT Solutions`}
            ], 
          ],
        },
      },
    );
    return document;
  } 

  generateReport(data) {
    return [
      {
        table: {
          headerRows: 1,
          widths: ["6%", "15%", "10%", "10%", "17%", "10%", "15%", "17%"],
          body: [
            [{ text: 'Orden', bold: true, alignment: "center" }, { text: 'Empresa', bold: true, alignment: "center" }, { text: 'Fecha inicial', bold: true, alignment: "center" }, { text: 'Servicio', bold: true, alignment: "center" }, { text: 'Equipos a intervenir', bold: true, alignment: "center" }, { text: 'Fecha', bold: true, alignment: "center" }, { text: 'Técnico', bold: true, alignment: "center" }, { text: 'Descripción', bold: true, alignment: "center" }],
            ...data.map(d => ([d.order, d.enterpriseName, d.startDate, d.service, d.interveneEquipment || "", d.date, d.technicianName, d.descriptionWarranty])),
          ],
        },
      },
    ];
  }
}
