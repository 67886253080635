<div *ngIf="globalError" class="alert alert-danger text-center">
  {{ globalError }}
</div>

<div
  *ngIf="isInitializing && !globalError"
  class="d-flex justify-content-center flex-column align-items-center mt-5"
>
  <h4 class="my-4">Cargando todo...</h4>
  <mat-spinner></mat-spinner>
</div>

<div *ngIf="!isInitializing && !globalError" class="container-fluid">
  <ng-container *ngIf="isUpdateOperation">
    <button
      class="d-block mt-2"
      mat-raised-button
      color="primary"
      (click)="close()"
    >
      <mat-icon>keyboard_backspace</mat-icon> Regresar
    </button>
  </ng-container>
  <form [formGroup]="formControl" autocomplete="off">
    <br />
    <p>Los campos con asterisco &#40;&#42;&#41; son obligatorios</p>
    <div class="row">
      <ng-container *ngIf="profile === 'PROF1' || profile === 'PROF2'">
        <div *ngIf="!isUpdateOperation" class="col-md-12">
          <mat-checkbox
            id="userCheckbox"
            style="position: relative; float: left"
            [(ngModel)]="manual"
            (change)="isManual()"
            [ngModelOptions]="{ standalone: true }"
            >Registro manual</mat-checkbox
          >
        </div>
        <div class="col-md-4">
          <mat-form-field
            appearance="outline"
            class="d-block w-100 mb-2"
            (keydown.enter)="$event.preventDefault()"
          >
            <mat-label>Persona</mat-label>
            <mat-select formControlName="person">
              <mat-option>Elige una</mat-option>
              <mat-option *ngFor="let user of users" [value]="user.PK">
                {{ user.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-form-field
            appearance="outline"
            class="d-block w-100 mb-2"
            (keydown.enter)="$event.preventDefault()"
          >
            <mat-label>Fecha</mat-label>
            <input
              readonly
              formControlName="date"
              matInput
              [matDatepicker]="picker"
            />
            <mat-datepicker-toggle
              (click)="picker.open()"
              matSuffix
              [for]="picker"
            ></mat-datepicker-toggle>
            <mat-datepicker touchUi #picker></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-form-field
            appearance="outline"
            class="d-block w-100 mb-2"
            (keydown.enter)="$event.preventDefault()"
          >
            <mat-label>Hora (Ejs. 08:15:20 / 17:00:00)</mat-label>
            <input formControlName="time" type="text" matInput />
          </mat-form-field>
        </div>
      </ng-container>
      <div class="col-md-4">
        <mat-form-field
          appearance="outline"
          class="d-block w-100 mb-2"
          (keydown.enter)="$event.preventDefault()"
        >
          <mat-label>Tipo *</mat-label>
          <mat-select formControlName="type">
            <mat-option>Elige una</mat-option>
            <mat-option value="Entrada">Entrada</mat-option>
            <mat-option value="Salida">Salida</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-md-4">
        <mat-form-field
          appearance="outline"
          class="d-block w-100 mb-2"
          (keydown.enter)="$event.preventDefault()"
        >
          <mat-label>Categoría *</mat-label>
          <mat-select formControlName="category">
            <mat-option
              *ngFor="let category of categories"
              [value]="category.PK"
            >
              {{ category.name }}</mat-option
            >
          </mat-select>
          <mat-icon
            *ngIf="profile === 'PROF1'"
            (click)="save($event, 'CATE')"
            matSuffix
            >playlist_add</mat-icon
          >
        </mat-form-field>
      </div>
      <div class="col-md-4">
        <mat-form-field appearance="outline" class="d-block w-100 mb-2">
          <mat-label>Orden</mat-label>
          <input
            formControlName="order"
            type="number"
            (blur)="searchId($event.target.value)"
            matInput
          />
        </mat-form-field>
      </div>
      <div class="col-md-4">
        <mat-form-field appearance="outline" class="d-block w-100 mb-2">
          <mat-label>Empresa</mat-label>
          <input formControlName="enterpriseOrder" type="text" matInput />
        </mat-form-field>
      </div>
      <div class="col-md-4">
        <mat-form-field appearance="outline" class="d-block w-100 mb-2">
          <mat-label>Fecha inicial</mat-label>
          <input formControlName="startDateOrder" type="text" matInput />
        </mat-form-field>
      </div>
      <div class="col-md-4">
        <mat-form-field appearance="outline" class="d-block w-100 mb-2">
          <mat-label>Fecha final</mat-label>
          <input formControlName="finishDateOrder" type="text" matInput />
        </mat-form-field>
      </div>
      <div class="col-md-12">
        <mat-form-field appearance="outline" class="d-block w-100 mb-2">
          <mat-label>Descripción</mat-label>
          <textarea
            formControlName="descriptionOrder"
            type="text"
            matInput
            rows="7"
          ></textarea>
        </mat-form-field>
      </div>
      <div class="col-md-4">
        <mat-form-field appearance="outline" class="d-block w-100 mb-2">
          <mat-label>Geolocalización *</mat-label>
          <input formControlName="location" readonly type="text" matInput />
        </mat-form-field>
      </div>
      <div class="col-md-8">
        <mat-form-field appearance="outline" class="d-block w-100 mb-2">
          <mat-label>Dirección *</mat-label>
          <input formControlName="address" readonly type="text" matInput />
        </mat-form-field>
      </div>
    </div>

    <div *ngIf="uploadError" class="alert alert-danger text-center">
      {{ uploadError }}
    </div>

    <div *ngIf="successMessage" class="alert alert-success text-center">
      {{ successMessage }}
    </div>

    <button
      class="d-block w-100 mt-2"
      mat-raised-button
      color="primary"
      [disabled]="formControl.invalid || isUploading"
      (click)="$event.preventDefault()"
      (click)="send()"
    >
      {{ getButtonText() }}
    </button>
  </form>
</div>
<br />
