import { AfterViewInit, Component, OnInit } from '@angular/core';
import { AuthenticationService } from '@app/auth/services/authentication.service';

@Component({
  selector: 'app-list-remission',
  templateUrl: './list-remission.component.html',
  styleUrls: ['./list-remission.component.scss']
})
export class ListRemissionComponent implements AfterViewInit {
  isUpdateOperation = false;

  constructor(public authService: AuthenticationService) { }

  ngAfterViewInit() {}

}
