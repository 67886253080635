import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "@environment";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { FormatsService } from "@app/users/services/formats.service";

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { CommonsImagesService } from "./commons-images.service";
import { sanitizeIdentifier } from "@angular/compiler";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const EXCEL_TYPE =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const EXCEL_EXTENSION = ".xlsx";

@Injectable({
  providedIn: "root",
})
export class ShareService {
  constructor(private http: HttpClient, private pdfService: FormatsService, private imageService: CommonsImagesService) {}

  getPermissionsList(entity) {
    return this.http.post(`${environment.apiHost}/v1/permission-list`, {
      entity,
    });
  }

  getList(entity) {
    return this.http.post(`${environment.apiHost}/v1/lists`, {
      entity,
    });
  }

  saveListItem(entity, name) {
    return this.http.put(`${environment.apiHost}/v1/lists`, {
      entity,
      name,
    });
  }

  getTitles(TRANSLATE, data: any[]) {
    data.unshift(TRANSLATE);
    return data;
  }  

  uploadFile(url, file) {
    return this.http.put(url, file, {headers: {"Content-Type": "binary/octet-stream"}})
  }

  export(type: string, content, fileName: string, pdfParams) {
    console.log(content);
    switch (type) {
      case "PDF":
        this.exportAsPDFFile(content, pdfParams);
        break;
      default:
        this.exportAsExcelFile(content, fileName);
        break;
    }
  }

  private exportAsPDFFile(content, params) {
    let titleStyle = {
      bold: true,
      fontSize: 13
    };
    let pageOrientation = params.pageOrientation || "portrait";
    let lineWidth;
    if (pageOrientation === "portrait") {
      lineWidth = 400;
    } else {
      lineWidth = 595;
    }
    let docDefinition = {      
      pageSize: params.pageSize || "LETTER",
      pageOrientation: pageOrientation,
      pageMargins: params.pageMargins || [30, 100, 30, 30],
      header: {layout: 'noBorders', table: {headerRows: 0, widths: [ 'auto', '*'], body: [[{},{image: this.imageService.getPDFImageReport(), width: 107, height: 44, alignment: "center"}],[{},{text: params.title || "Informe", alignment: "center", style: titleStyle}],[{},{}]]}, margin: [30,20]},
      content: content,
      footer: function(currentPage, pageCount) { return {columns: [{width: 'auto', canvas: [{ type: 'line', x1: 30, y1: 5, x2: lineWidth, y2: 5, lineWidth: 0.5}]}, {text: currentPage.toString() + ' de ' + pageCount, alignment: 'center'}]} },
      defaultStyle: {
        fontSize: 10,
        bold: false,
      },
      images: params.images,
    };
    pdfMake.createPdf(docDefinition).open();
  }

  private exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json, {
      skipHeader: true,
    });
    const workbook: XLSX.WorkBook = {
      Sheets: { data: worksheet },
      SheetNames: ["data"],
    };
    const excelBuffer: any = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(
      data,
      fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
    );
  }
}
