import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ShareService } from "@app/shared/services/share.service";

@Component({
  selector: "app-save-list",
  templateUrl: "./save-list.component.html",
  styleUrls: ["./save-list.component.scss"],
})
export class SaveListComponent {
  name: String;
  label: string;
  alert: String;

  constructor(
    public dialogRef: MatDialogRef<SaveListComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private shareService: ShareService
  ) {
    this.label = data.label;
    this.name = data.name;
  }

  cleanString(value) {
    let string = value;
    string = string.replace(/[áàäâÁÀÄÂ]/g, 'a');
    string = string.replace(/[éèëêÉÈËÊ]/g, 'e');
    string = string.replace(/[íìïîÍÌÏÎ]/g, 'i');
    string = string.replace(/[óòöôÓÒÖÔ]/g, 'o');
    string = string.replace(/[úùüûÚÙÜÛ]/g, 'u');
    string = string.replace(/ /g, "");
    string = string.toLowerCase();
    return string;
   }

  send() {
    if(this.data.entity !== "OPLI") {
      this.alert = "";
      for(let i of this.data.current) { 
        if(this.cleanString(i.name) === this.cleanString(this.name)) {
          this.alert = "El item que está ingresando ya existe en la lista";
          return;
        }
      }

      this.shareService.saveListItem(this.data.entity, this.name).subscribe(
        (data) => {
          this.dialogRef.close({ PK: data['body']["PK"], name: this.name });
        },
        (error) => {
          console.log(error);
        }
      );
    } else{
      this.dialogRef.close(this.name);
    }
  }
}
