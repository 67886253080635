<div mat-dialog-content>
  <mat-form-field appearance="outline" class="d-block w-100 mb-2">
    <mat-label>{{label}}</mat-label>
    <input matInput [(ngModel)]="name" />
  </mat-form-field>
  <mat-label style="color: red; font-size: 11px;">{{alert}}</mat-label>
</div>
<div mat-dialog-actions>
  <button mat-button mat-dialog-close>Cancelar</button>
  <button mat-button (click)="send()">
    Aceptar
  </button>
</div>
