<ng-container *ngIf="!isUpdateOperation">
  <div *ngIf="globalError" class="alert alert-danger text-center">
    {{ globalError }}
  </div>

  <div
    *ngIf="isInitializing && !globalError"
    class="d-flex justify-content-center flex-column align-items-center mt-5"
  >
    <h4 class="my-4">Cargando todo...</h4>
    <mat-spinner></mat-spinner>
  </div>

  <div *ngIf="!isInitializing && !globalError" class="container-fluid">
    <form [formGroup]="formControl" autocomplete="off" (submit)="send()">
      <br>
      <div class="row">
        <div class="col-md-6">
          <mat-form-field appearance="outline" class="d-block w-100 mb-2">
            <mat-label>Nombre</mat-label>
            <mat-select formControlName="PK">
              <mat-option>Elige una</mat-option>
              <mat-option *ngFor="let user of users" [value]="user.PK">
                {{ user.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <mat-form-field appearance="outline" class="d-block w-100 mb-2">
            <mat-label>Número de identificación</mat-label>
            <input
              formControlName="identification"
              type="text"
              matInput
            />
          </mat-form-field>
        </div>
      </div>

      <div *ngIf="uploadError" class="alert alert-danger text-center">
        {{ uploadError }}
      </div>

      <button
        class="d-block w-100 mt-2"
        mat-raised-button
        color="primary"
        [disabled]="formControl.invalid || isUploading"
      >
        {{ isUploading ? "Buscando..." : "Buscar" }}
      </button>
    </form>
    <div class="container-fluid" [hidden]="!showTable">
      <div class="row">
        <div class="col">
          <app-data-table
            [names]="tableNames"
            [data]="result"
            (action)="action($event)"
          ></app-data-table>
        </div>
      </div>
    </div>
  </div>

  
  <br />
</ng-container>

<ng-container *ngIf="isUpdateOperation">
    <app-form [isUpdateOperation]="isUpdateOperation" [PK]="PK" (action)="close()"></app-form>
</ng-container>
