import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { FormatsService } from '@app/material-request/services/formats.service';
import { MaterialRequestService } from '@app/material-request/services/material-request.service';
import { ShareService } from '@app/shared/services/share.service';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})
export class ReportComponent implements OnInit {
  isInitializing = false;
  isUploading = false;
  globalError: string;
  uploadError: string;
  users;
  data;

  formControl = this.formBuilder.group({
    typeDocument: ["", [Validators.required]],
    order: ["", [Validators.pattern("^[0-9]+$")]],
    person: ["", []],
    startDate: ["", []],
    finishDate: ["", []],
  });

  constructor(
    private formBuilder: FormBuilder,
      private materialRequestService: MaterialRequestService,
      private formatsService: FormatsService,
      private shareService: ShareService,
  ) { }

  ngOnInit(): void {
    this.materialRequestService.getUsers({ all: "ALL" }).subscribe(
      (data) => {
        this.users = data["body"];
        this.isInitializing = false;
      },
      (error) => {
        this.globalError =
          "Se ha presentado un error, por favor vuelva a intentarlo";
        this.isInitializing = false;
      }
    );
  }

  send() {
    this.globalError = "";
    this.uploadError = ""; 
    if((this.formControl.get("startDate").value && !this.formControl.get("finishDate").value) ||
    (!this.formControl.get("startDate").value && this.formControl.get("finishDate").value)) {
      this.uploadError = "Debe ingresar fecha inicial y fecha final";
      return;
    } else if (!this.formControl.get("startDate").value && !this.formControl.get("finishDate").value && 
    !this.formControl.get("order").value && !this.formControl.get("person").value) {
      this.uploadError = "Debe ingresar al menos un criterio de búsqueda";
      return;
    } else {
      let values = this.formControl.value;
      const order = values.order ? values.order: undefined;
      const person = values.person ? values.person: undefined;
      const startDate = values.startDate ? values.startDate: undefined;
      const finishDate = values.finishDate ? values.finishDate: undefined;
      this.materialRequestService.get({type: 'R', order, person, startDate, finishDate}).subscribe(
        (data) => {
          this.data = data["body"];
          this.isInitializing = false;
          if (!this.data.length) {
            this.uploadError = "No se encontraron resultados";
            return;
          }
          let content =
          this.formControl.value.typeDocument === "PDF"
            ? this.formatsService.generateReport(this.data)
            : this.shareService.getTitles(this.formatsService.TRANSLATE, this.data);
            this.shareService.export(
            this.formControl.value.typeDocument,
            content,
            "Solicitud de materiales",
            { pageSize: "FOLIO", pageOrientation: "landscape", title: "Informe de solicitudes de materiales" }
          );
        },
        (error) => {
          this.uploadError =
            "Se ha presentado un error, por favor vuelva a intentarlo";
          this.isInitializing = false;
        }
      );
    }
  }
}
