import { AfterViewInit, Component, OnInit } from '@angular/core';
import { AuthenticationService } from '@app/auth/services/authentication.service';

@Component({
  selector: 'app-list-orders',
  templateUrl: './list-orders.component.html',
  styleUrls: ['./list-orders.component.scss']
})
export class ListOrdersComponent implements AfterViewInit {
  isUpdateOperation = false;
  constructor(public authService: AuthenticationService) { }

  ngAfterViewInit() {}

}
