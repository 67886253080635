import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { FormatsService } from '@app/resume/services/formats.service';
import { ResumeService } from '@app/resume/services/resume.service';
import { ShareService } from '@app/shared/services/share.service';
import { Auth } from 'aws-amplify';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})
export class ReportComponent implements OnInit {
  isInitializing = false;
  isUploading = false;
  globalError: string;
  uploadError: string;
  enterprises;
  marks;
  //models;
  typeEquipments;
  data;

  formControl = this.formBuilder.group({
    typeFile: ["", [Validators.required]],
    typeInform: ["", [Validators.required]],
    enterprise: ["", []],
    typeEquipment: ["", []],
    mark: ["", []],
    //model: ["", []]
  });

  constructor(
    private formBuilder: FormBuilder,
    private resumesService: ResumeService,
    private shareService: ShareService,
    private formatsService: FormatsService,
  ) { }

  ngOnInit(): void {
    Auth.currentSession().then((credentials) => {
      let enterprisesRq = this.resumesService.getEnterprises({ all: "ALL" });
      let marksRq = this.shareService.getList("MARK");
      //let modelsRq = this.shareService.getList("MODE");
      let typeEquipmentsRq = this.shareService.getList("TYEQ");
      forkJoin([enterprisesRq, marksRq, typeEquipmentsRq]).subscribe(
        ([enterprisesRs, marksRs, typeEquipmentsRs]) => {
          this.enterprises = enterprisesRs["body"];
          this.marks = marksRs["body"];
          //this.models = modelsRs["body"];
          this.typeEquipments = typeEquipmentsRs["body"];
          this.isInitializing = false;
        },
        (error) => {
          this.globalError =
            "Se ha presentado un error, por favor vuelva a intentarlo";
          this.isInitializing = false;
        }
      );
    });
  }

  send() {
    this.globalError = "";
    this.uploadError = ""; 
    if (
      !this.formControl.get("enterprise").value &&
      !this.formControl.get("typeEquipment").value &&
      !this.formControl.get("mark").value
    ) {
      this.uploadError = "Debe ingresar al menos un criterio de búsqueda";
      return;
    } else {
      let values = this.formControl.value;
      const typeInform = values.typeInform;
      const enterprise = values.enterprise ? values.enterprise : undefined;
      const typeEquipment = values.typeEquipment ? values.typeEquipment : undefined;
      const mark = values.mark ? values.mark : undefined;
      const typeFile = values.typeFile;
      //const model = values.model ? values.model : undefined;
      this.resumesService.get({type: 'R', typeInform, enterprise, typeEquipment, mark, typeFile}).subscribe(
        (data) => {
          this.data = data["body"];
          this.isInitializing = false;
          if (!this.data.length) {
            this.uploadError = "No se encontraron resultados";
            return;
          }
          if(typeInform == "RESU") {
            let whiteImage = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAOCAYAAADwikbvAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAfSURBVDhPY/wPBAxkAiYoTRYY1UwiGNVMIhgozQwMAHN/BBjqp71vAAAAAElFTkSuQmCC';
            let images = [];
            for (let i = 0; i < this.data.length; i++) {
              images[`image${i}`] = this.data[i].urlAtt || whiteImage;
            }
            let content =
            this.formControl.value.typeFile === "PDF"
              ? this.formatsService.generateReport(this.data)
              : this.shareService.getTitles(this.formatsService.TRANSLATE, this.data);
              this.shareService.export(
              this.formControl.value.typeFile,
              content,
              "Hojas de vida",
              { pageSize: "FOLIO", pageOrientation: "landscape", title: "Informe de hojas de vida de equipos", images }
            );
          }
          else if(typeInform == "REIP") {
            let content =
            this.formControl.value.typeFile === "PDF"
              ? this.formatsService.generateReportEquipment(this.data)
              : this.shareService.getTitles(this.formatsService.TRANSLATETWO, this.data);
              this.shareService.export(
              this.formControl.value.typeFile,
              content,
              "Equipos con pendientes",
              { pageSize: "FOLIO", pageOrientation: "landscape", title: "Informe de equipos con pendientes" }
            );
          }          
        },
        (error) => {
          this.uploadError =
            "Se ha presentado un error, por favor vuelva a intentarlo";
          this.isInitializing = false;
        }
      );
    }
  }
}
