import { Injectable } from '@angular/core';
import { CurrencyPipe } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class FormatsService {
  TRANSLATE = {
    org: 'Cons',
    rev: 'Rev',
    enterpriseName: 'Empresa',
    contactName: 'Contacto',
    date: 'Fecha',
    subject: 'Asunto'
  }

  constructor(
    private currencyPipe: CurrencyPipe
  ) { }

  generatePrint(data){
    console.log(data)
    let document: Array<any> = [
      {
        lineHeight: 1,
        text: ".", color: "white",
      }, 
      {
        text: [
          {text: 'Fecha: ', bold: true},
          {text: `${data.date} \n\n`},
        ]
      }, 
      {
        alignment: 'left',
        columns: [
          {
            text: [
              {text: 'Señor(a): ', bold: true},
              {text: `${data.contactName} \n`},
              {text: 'Empresa: ', bold: true},
              {text: `${data.enterpriseName}`},
            ]
          },
          {
            text: [
              {text: 'Asunto: ', bold: true},
              {text: `${data.subject}`},
            ]
          }
        ]
      },
      {
        lineHeight: 1,
        text: ".", color: "white",
      },
      {
        text: [   
          {text: 'Para KBT Solutions S.A.S es un orgullo contar con la confianza y credibilidad que su empresa ha manifestado por la nuestra. Atendiendo su amable solicitud entregamos la cotización solicitada: \n'},
        ]
      }, 
      {
        lineHeight: 1,
        text: ".", color: "white",
      },  
    ];
    if(data.discount) {
      document.push(
        {
          table: {
            headerRows: 1,
            widths: ["5%", "55%", "5%", "17%", "18%"],
            body: [            
              [
                { text: `Item`, bold: true, alignment: "center"},
                { text: `Descripción`, bold: true, alignment: "center" },  
                { text: `Cant`, bold: true, alignment: "center" },
                { text: `Valor unidad`, bold: true, alignment: "center" },
                { text: `Valor total`, bold: true, alignment: "center" },
              ],
              ...data.items.map(d => ([{text: d.index, alignment: "center"}, {text: d.description, alignment: "justify"}, {text: d.quantity, alignment: "center"}, {text: this.currencyPipe.transform(d.unitValue, 'USD', 'symbol-narrow', '1.2-2'), alignment: 'right'}, {text: this.currencyPipe.transform(d.totalValue, 'USD', 'symbol-narrow', '1.2-2'), alignment: 'right'}])),
              [
                { text: `DESCUENTO: ${data.discountDescription ? data.discountDescription : ""}`, bold: true, colSpan: 4 }, {}, {}, {},
                { text: `${this.currencyPipe.transform(data.discount, 'USD', 'symbol-narrow', '1.2-2')}`, alignment: 'right'},
              ],
              [
                { text: "TOTAL", alignment: "right",  bold: true, colSpan: 4 }, {}, {}, {},
                { text: `${this.currencyPipe.transform(data.total, 'USD', 'symbol-narrow', '1.2-2')}`, alignment: 'right'},
              ],   
            ],
          },
        },
      );
    } else {
      document.push(
        {
          table: {
            headerRows: 1,
            widths: ["5%", "55%", "5%", "17%", "18%"],
            body: [            
              [
                { text: `Item`, bold: true, alignment: "center"},
                { text: `Descripción`, bold: true, alignment: "center" },  
                { text: `Cant`, bold: true, alignment: "center" },
                { text: `Valor unidad`, bold: true, alignment: "center" },
                { text: `Valor total`, bold: true, alignment: "center" },
              ],
              ...data.items.map(d => ([{text: d.index, alignment: "center"}, {text: d.description, alignment: "justify"}, {text: d.quantity, alignment: "center"}, {text: this.currencyPipe.transform(d.unitValue, 'USD', 'symbol-narrow', '1.2-2'), alignment: 'right'}, {text: this.currencyPipe.transform(d.totalValue, 'USD', 'symbol-narrow', '1.2-2'), alignment: 'right'}])),
              [
                { text: "TOTAL", alignment: "right",  bold: true, colSpan: 4 }, {}, {}, {},
                { text: `${this.currencyPipe.transform(data.total, 'USD', 'symbol-narrow', '1.2-2')}`, alignment: 'right'},
              ],   
            ],
          },
        },
      );
    }    
    
    if(data.optionalItems.length > 0) {
      document.push(
        {
          lineHeight: 1,
          text: ".", color: "white",
        },
        {
          text: "Items opcionales", bold: true, alignment: "center",
        },
        {
          table: {
            headerRows: 1,
            widths: ["5%", "55%", "5%", "17%", "18%"],
            body: [            
              [
                { text: `Item`, bold: true, alignment: "center"},
                { text: `Descripción`, bold: true, alignment: "center" },  
                { text: `Cant`, bold: true, alignment: "center" },
                { text: `Valor unidad`, bold: true, alignment: "center" },
                { text: `Valor total`, bold: true, alignment: "center" },
              ],
              ...data.optionalItems.map(d => ([{text: d.index, alignment: "center"}, {text: d.optionalDescription}, {text: d.optionalQuantity, alignment: "center"}, {text: this.currencyPipe.transform(d.optionalUnitValue, 'USD', 'symbol-narrow', '1.2-2'), alignment: 'right'}, {text: this.currencyPipe.transform(d.optionalTotalValue, 'USD', 'symbol-narrow', '1.2-2'), alignment: 'right'}])),  
            ],
          },
        },
  
      );
    }    

    document.push(
      {
        text: [
          {text: '**Estos valores no incluyen IVA \n\n', bold: true},
        ]
      },      
    );

    if(data.itemsImgs.length > 0) {   
      const length = data.itemsImgs.length;      
      let body = [];
      let currentRow = []
      for(let i = 0; i < length; i++) {
        currentRow.push(
          [{image: `image${i}`, fit: [90, 90], alignment: "center"}, {text: `Item ${data.itemsImgs[i].index} \n\n`, alignment: "center"}]
        )

        if(currentRow.length === 3 || i === length - 1) {
          if (currentRow.length === 1) currentRow.push([], [])
          else if (currentRow.length === 2) currentRow.push([])
          body.push(currentRow);
          currentRow = [];
        }
      }

      document.push(
        {
          lineHeight: 1,
          text: ".", color: "white",
        },
        {
          text: [
            {text: 'Imágenes items \n\n', bold: true, alignment: "center"},
          ]
        },
        {
          layout: 'noBorders',
          table: {
            widths: ['33%', '33%', '34%'],
            body: body
          }
        },
      );
    }

    if(data.optionalItemsImgs.length > 0) {   
      const itemsLength = data.itemsImgs.length || 0;
      const length = itemsLength + data.optionalItemsImgs.length;      
      let body = [];
      let currentRow = []
      for(let i = itemsLength, j = 0; i < length; i++, j++) {
        currentRow.push(
          [{image: `image${i}`, fit: [90, 90], alignment: "center"}, {text: `Item opcional ${data.optionalItemsImgs[j].index} \n\n`, alignment: "center"}]
        )

        if(currentRow.length === 3 || i === length - 1) {
          if (currentRow.length === 1) currentRow.push([], [])
          else if (currentRow.length === 2) currentRow.push([])
          body.push(currentRow);
          currentRow = [];
        }
      }

      console.log(body)
      
      document.push(
        {
          lineHeight: 2,
          text: ".", color: "white",
        },
        {
          text: [
            {text: 'Imágenes items opcionales \n\n', bold: true, alignment: "center"},
          ]
        },
        {
          layout: 'noBorders',
          table: {
            widths: ['33%', '33%', '34%'],
            body: body
          }
        },
        {
          lineHeight: 2,
          text: ".", color: "white",
        },
      );
    }

    if(data.scope) {
      document.push(
        {
          text: [
            {text: data.scope ? 'Alcance: \n\n': "", bold: true},
            {text: data.scope ? `${data.scope} \n\n` : "", alignment:'justify'},                  
          ]
        }, 
      )      
    }
    document.push(
      {
        text: [
          {text: 'TERMINOS Y CONDICIONES DE LA OFERTA \n\n',alignment:'center', bold: true},
        ]
      }, 
      {
        alignment: 'left',
        columns: [
          {
            text: [
              {text: 'Producto: ', bold: true},
              {text: `${data.product} \n`},
              {text: 'Tiempo de entrega: ', bold: true},
              {text: `${data.deliveryTime} \n`},
              {text: 'Validez de la oferta: ', bold: true},
              {text: `${data.offerValidityName} \n`},
              {text: data.warranty ? 'Garantía: ' : "", bold: true},
              {text: data.warranty ? `${data.warranty} \n` : ""},
            ]
          },
          {
            text: [
              {text: 'Instalación: ', bold: true},
              {text: `${data.installationName} \n`},              
              {text: 'Moneda: ', bold: true},
              {text: `${data.currencyName} \n`},
              {text: 'Forma de pago: ', bold: true},
              {text: `${data.paymentMethod} \n`},
            ]
          }   
        ]
      },
      {
        lineHeight: 1,
        text: ".", color: "white",
      },
      {
        layout: 'noBorders',
        table: {
          headerRows: 1,
          widths: ["1%", "99%"],
          body: [            
            [
              { text: `Notas`, bold: true, colSpan: 2},
              { },
            ],
            ...data.optionsList.map(d => [{text: "-"}, {text: d, alignment:'justify'}]),  
          ],
        },
      },
      {
        lineHeight: 1,
        text: ".", color: "white",
      },
      {
        text: [
          {text: data.notes ? 'Notas adicionales: \n\n': "", bold: true},
          {text: data.notes ? `${data.notes} \n\n` : "", alignment:'justify'},                  
        ]
      },      
    );
    if(data.urlAtt) {
      document.push(  
        {
          text: [
            {text: 'Archivo adjunto a la cotización: ', bold: true},
            {text: `${data.PK}_${data.PK}.${data.fileExtension} \n\n`},  
          ]
        }        
      );
    }
    document.push(
      {
        text: [
          {text: 'Agradecemos de antemano la oportunidad que usted le brinda a KBT Solutions S.A.S para ofrecerle sus productos, esperamos que esta oferta cumpla sus expectativas. \n\n'},
        ]
      },
      {
        layout: 'noBorders',
        dontBreakRows: true,
        table: {
          headerRows: 1,
          widths: ["100%"],
          body: [            
            [
              {text: `Atentamente \n\n ${data.name} \n ${data.position} \n ${data.email} \n KBT Solutions`}
            ], 
          ],
        },
      },
    );
    return document;
  }

  generateReport(data) {
    return [
      {
        table: {
          headerRows: 1,
          widths: ["5%","20%", "20%", "15%", "40%"],
          body: [
            [{ text: 'Cons.', bold: true, alignment: "center" }, { text: 'Empresa', bold: true, alignment: "center" }, { text: 'Contacto', bold: true, alignment: "center" }, { text: 'Fecha', bold: true, alignment: "center" }, { text: 'Asunto', bold: true, alignment: "center" }],
            ...data.map(d => ([d.rev !== 0 ? d.org.substr(4) + "-" + d.rev : d.org.substr(4), d.enterpriseName, d.contactName, d.date, d.subject || ""])),
          ],
        },
      },
    ];
  }
}
