import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { SaveListComponent } from "@app/shared/components/save-list/save-list.component";
import { ShareService } from "@app/shared/services/share.service";
import { UsersService } from "@app/users/services/users.service";
import { Auth } from "aws-amplify";
import { forkJoin } from "rxjs";

@Component({
  selector: "app-form",
  templateUrl: "./form.component.html",
  styleUrls: ["./form.component.scss"],
})
export class FormComponent implements OnInit {
  @Input()
  isUpdateOperation;
  @Input()
  PK;
  @Output()
  action = new EventEmitter();
  isInitializing = true;
  isUploading = false;
  globalError: string;
  uploadError: string;
  successMessage: String;
  profiles;
  positions;
  user;
  showCreateUser = false;
  idMessage = false;

  constructor(
    private formBuilder: FormBuilder,
    private userService: UsersService,
    private shareService: ShareService,
    public dialog: MatDialog
  ) { }

  public formControl: FormGroup;

  ngOnInit(): void {
    this.formControl = this.formBuilder.group({
      name: [
        "",
        [
          Validators.required,
          Validators.pattern("^[a-zA-ZáéíóúÁÉÍÓÚñÑ ]+$"),
          Validators.maxLength(255),
        ],
      ],
      birthdate: ["", []],
      identification: [
        "",
        [
          Validators.required,
          Validators.pattern("^[0-9]+$"),
          Validators.maxLength(15),
        ],
      ],
      position: [
        "",
        [
          Validators.required,
          Validators.pattern("^POST[0-9]+$"),
          Validators.maxLength(11),
        ],
      ],
      address: [
        "",
        [
          Validators.pattern("^[a-zA-Z0-9-#áéíóúÁÉÍÓÚ ]+$"),
          Validators.maxLength(255),
        ],
      ],
      email: [
        "",
        [
          Validators.required,
          Validators.pattern(
            "^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$"
          ),
        ],
      ],
      phone: [
        "",
        [Validators.pattern("^[a-zA-Z0-9- ]+$"), Validators.maxLength(50)],
      ],
      cellphone: [
        "",
        [
          Validators.required,
          Validators.pattern("^[a-zA-Z0-9- ]+$"),
          Validators.maxLength(50),
        ],
      ],
      username: [
        "",
        [
          Validators.pattern("^[a-z0-9]+$"),
          Validators.minLength(6),
          Validators.maxLength(14),
        ],
      ],
      password: [
        "",
        [
          Validators.pattern(
            "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\\^$*.\\[\\]{}\\(\\)?\\-“!@#%&/,><\\’:;|_~`])\\S{8,14}$"
          ),
        ],
      ],
      profile: [
        "",
        [Validators.pattern("^PROF[0-9]+$"), Validators.maxLength(11)],
      ],
      status: ["", [Validators.required]],
      salary: [
        null,
        [Validators.required, Validators.min(1)],
      ],
    });

    Auth.currentSession().then((credentials) => {
      let profilesRq = this.shareService.getPermissionsList("PROF");
      let positionsRq = this.shareService.getList("POST");
      if (this.isUpdateOperation) {
        let userRq = this.userService.get({ PK: this.PK });
        forkJoin([profilesRq, positionsRq, userRq]).subscribe(
          ([profilesRs, positionsRs, userRs]) => {
            this.profiles = profilesRs["body"];
            this.positions = positionsRs["body"];
            let user = userRs["body"][0];
            delete user.positionName;
            user.password = "";
            if(user.birthdate) user.birthdate += "T05:00:00.000Z";               
            else user.birthdate = ""; 
            if(!user.salary) user.salary = null;
            user.address = user.address ? user.address : "";
            user.phone = user.phone ? user.phone : "";
            user.username = user.username ? user.username : "";
            user.profile = user.profile ? user.profile : "";
            this.formControl.setValue(userRs["body"][0]);
            if (this.formControl.get("username").value) {
              this.formControl.get("username").disable({ emitEvent: false });
              this.formControl
                .get("profile")
                .setValidators([Validators.required]);
              this.formControl
                .get("profile")
                .updateValueAndValidity({ emitEvent: false });
            } else {
              this.showCreateUser = true;
              this.formControl.get("username").disable({ emitEvent: false });
              this.formControl.get("password").disable({ emitEvent: false });
              this.formControl.get("profile").disable({ emitEvent: false });
            }
            this.isInitializing = false;
          },
          (error) => {
            this.globalError =
              "Se ha presentado un error, por favor vuelva a intentarlo";
            this.isInitializing = false;
          }
        );
      } else {
        forkJoin([profilesRq, positionsRq]).subscribe(
          ([profilesRs, positionsRs]) => {
            this.profiles = profilesRs["body"];
            this.positions = positionsRs["body"];
            this.isInitializing = false;
          },
          (error) => {
            this.globalError =
              "Se ha presentado un error, por favor vuelva a intentarlo";
            this.isInitializing = false;
          }
        );
        this.showCreateUser = true;
        this.formControl.get("username").disable({ emitEvent: false });
        this.formControl.get("password").disable({ emitEvent: false });
        this.formControl.get("profile").disable({ emitEvent: false });
      }
    });
  }

  createUser(e) {
    this.user = e.checked;

    if (this.user) {
      this.formControl.get("username").setValidators([Validators.required, Validators.pattern("^[a-z0-9]+$"),
      Validators.minLength(6), Validators.maxLength(14),]);
      this.formControl.get("username").enable({ emitEvent: false });
      this.formControl
        .get("username")
        .updateValueAndValidity({ emitEvent: false });
      this.formControl.get("password").setValidators([Validators.required, Validators.pattern(
        "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\\^$*.\\[\\]{}\\(\\)?\\-“!@#%&/,><\\’:;|_~`])\\S{8,14}$"
      ),]);
      this.formControl.get("password").enable({ emitEvent: false });
      this.formControl
        .get("password")
        .updateValueAndValidity({ emitEvent: false });
      this.formControl.get("profile").setValidators([Validators.required, Validators.pattern("^PROF[0-9]+$"), Validators.maxLength(11)]);
      this.formControl.get("profile").enable({ emitEvent: false });
      this.formControl
        .get("profile")
        .updateValueAndValidity({ emitEvent: false });
    } else {
      this.formControl.get("username").clearValidators();
      this.formControl.get("username").reset();
      this.formControl.get("username").disable({ emitEvent: false });
      this.formControl
        .get("username")
        .updateValueAndValidity({ emitEvent: false });
      this.formControl.get("password").clearValidators();
      this.formControl.get("password").reset();
      this.formControl.get("password").disable({ emitEvent: false });
      this.formControl
        .get("password")
        .updateValueAndValidity({ emitEvent: false });
      this.formControl.get("profile").clearValidators();
      this.formControl.get("profile").reset();
      this.formControl.get("profile").disable({ emitEvent: false });
      this.formControl
        .get("profile")
        .updateValueAndValidity({ emitEvent: false });
    }
  }

  searchId(event) {
    if (this.idMessage) this.idMessage = false;
    if(event) {
      this.userService.get({ identification: event }).subscribe(
        (data) => {
          if ((data["body"].length !== 0 && !this.isUpdateOperation)  || 
          (data["body"].length !== 0 && this.isUpdateOperation && data["body"][0].PK !== this.PK)) this.idMessage = true;
        },
        (error) => {
        }
      );
    }    
  }

  send() {
    this.isUploading = true;
    this.globalError = "";
    this.uploadError = "";
    this.successMessage = "";
    this.formControl.disable({ emitEvent: false });
    if (this.isUpdateOperation) {
      let values = this.formControl.getRawValue();
      values.PK = this.PK;
      if (!values.password) {
        delete values.password;
      }
      this.userService.updateUser(values).subscribe(
        (data) => {
          this.formControl.enable({ emitEvent: false });
          this.successMessage = "Operación realizada con exito";
          this.isUploading = false;
          if (values.username) {
            this.showCreateUser = false;
            this.formControl.get("username").disable({ emitEvent: false });
            this.formControl
              .get("profile")
              .setValidators([Validators.required]);
            this.formControl
              .get("profile")
              .updateValueAndValidity({ emitEvent: false });
          }
          else {
            this.showCreateUser = true;
            this.formControl.get("username").disable({ emitEvent: false });
            this.formControl.get("password").disable({ emitEvent: false });
            this.formControl.get("profile").disable({ emitEvent: false });
          }
        },
        (error) => {
          this.formControl.enable({ emitEvent: false });
          if(this.formControl.get("username").value) {
            this.formControl.get("username").disable({ emitEvent: false });
          } else {
            this.formControl.get("username").disable({ emitEvent: false });
            this.formControl.get("password").disable({ emitEvent: false });
            this.formControl.get("profile").disable({ emitEvent: false });
          }
          this.uploadError = error.error.status.message;
          this.isUploading = false;
        }
      );
    } else {
      let data = this.formControl.getRawValue();
      if (!data.username) {
        delete data.username;
        delete data.password;
        delete data.profile;
      }
      if(!data.phone) delete data.phone;
      if(!data.address) delete data.address;
      this.userService.saveUser(data).subscribe(
        (data) => {
          this.formControl.enable({ emitEvent: false });
          this.formControl.reset();
          this.formControl.updateValueAndValidity({ emitEvent: false });
          this.successMessage = "Operación realizada con exito";
          this.isUploading = false;
          if (!this.user) {
            this.formControl.get("username").disable({ emitEvent: false });
            this.formControl.get("password").disable({ emitEvent: false });
            this.formControl.get("profile").disable({ emitEvent: false });            
          }
        },
        (error) => {
          this.formControl.enable({ emitEvent: false });
          this.uploadError = error.error.status.message;
          this.isUploading = false;
          if (!this.user) {
            this.formControl.get("username").disable({ emitEvent: false });
            this.formControl.get("password").disable({ emitEvent: false });
            this.formControl.get("profile").disable({ emitEvent: false });            
          }
        }
      );
    }
  }

  save(event, entity) {
    event.stopPropagation();
    event.preventDefault();
    const dialogRef = this.dialog.open(SaveListComponent, {
      width: "500px",
      data: {
        current: this.positions,
        entity,
        label: "Cargo",
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.positions.push(result);
        this.formControl.get("position").setValue(result["PK"]);
      }
    });
  }

  getButtonText() {
    return this.isUploading
      ? this.isUpdateOperation
        ? "Actualizando usuario..."
        : "Creando usuario..."
      : this.isUpdateOperation
        ? "Actualizar usuario"
        : "Crear usuario";
  }

  close() {
    this.action.emit();
  }
}
