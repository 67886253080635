<div *ngIf="globalError" class="alert alert-danger text-center">
    {{ globalError }}
</div>

<div
  *ngIf="isInitializing && !globalError"
  class="d-flex justify-content-center flex-column align-items-center mt-5"
>
  <h4 class="my-4">Cargando todo...</h4>
  <mat-spinner></mat-spinner>
</div>

<div *ngIf="!isInitializing && !globalError" class="container-fluid">
    <ng-container *ngIf="isUpdateOperation">
        <button
        class="d-inline mt-2"
        mat-raised-button
        color="primary"
        (click)="close()"
      >
        <mat-icon>keyboard_backspace</mat-icon> Regresar
      </button>     
    </ng-container>
    <form [formGroup]="formControl" autocomplete="off">
      <br>
      <p>Los campos con asterisco &#40;&#42;&#41; son obligatorios</p>
        <div class="row">   
            <div class="col-md-6">
                <mat-form-field appearance="outline" class="d-block w-100 mb-2">
                  <mat-label>Orden</mat-label>
                  <input formControlName="order" type="number" (blur)="searchId($event.target.value)" matInput />
                </mat-form-field>
            </div>  
            <div class="col-md-6">
                <mat-form-field appearance="outline" class="d-block w-100 mb-2">
                  <mat-label>Empresa</mat-label>
                  <input formControlName="enterprise" type="text" matInput />
                </mat-form-field>
            </div>
            <div class="col-md-6">
                <mat-form-field appearance="outline" class="d-block w-100 mb-2">
                  <mat-label>Fecha inicial</mat-label>
                  <input formControlName="startDate" type="text" matInput />
                </mat-form-field>
            </div>
            <div class="col-md-6">
                <mat-form-field appearance="outline" class="d-block w-100 mb-2">
                  <mat-label>Servicio</mat-label>
                  <input formControlName="service" type="text" matInput />
                </mat-form-field>
            </div>
            <div class="col-md-12">
                <mat-form-field appearance="outline" class="d-block w-100 mb-2">
                  <mat-label>Equipo(s) a intervenir</mat-label>
                  <input formControlName="interveneEquipment" type="text" matInput />
                </mat-form-field>
            </div>
            <div class="col-md-12">
                <mat-form-field appearance="outline" class="d-block w-100 mb-2">
                  <mat-label>Descripción</mat-label>
                  <textarea formControlName="description" type="text" matInput rows="7"></textarea>
                </mat-form-field>
            </div> 
            <div class="col-md-6">
                <mat-form-field appearance="outline" class="d-block w-100 mb-2" (keydown.enter)="$event.preventDefault()">
                  <mat-label>Fecha *</mat-label>
                  <input
                    readonly
                    formControlName="date"
                    matInput
                    [matDatepicker]="picker"
                  />
                  <mat-datepicker-toggle
                    (click)="picker.open()"
                    matSuffix
                    [for]="picker"
                  ></mat-datepicker-toggle>
                  <mat-datepicker touchUi #picker></mat-datepicker>
                </mat-form-field>
            </div>  
            <div class="col-md-6">
                <mat-form-field appearance="outline" class="d-block w-100 mb-2" (keydown.enter)="$event.preventDefault()">
                  <mat-label>Técnico *</mat-label>
                  <mat-select formControlName="technician">
                    <mat-option>Elige una</mat-option>
                    <mat-option *ngFor="let user of users" [value]="user.PK">
                      {{ user.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
            </div>  
            <div class="col-md-12">
                <mat-form-field appearance="outline" class="d-block w-100 mb-2">
                  <mat-label>Descripción *</mat-label>
                  <textarea formControlName="descriptionWarranty" type="text" matInput rows="7"></textarea>
                </mat-form-field>
            </div> 
            <div class="col-md-12">
                <mat-form-field appearance="outline" class="d-block w-100 mb-2">
                  <mat-label>Observaciones *</mat-label>
                  <textarea formControlName="observations" type="text" matInput rows="7"></textarea>
                </mat-form-field>
            </div>                 
      </div>
        
        <div *ngIf="uploadError" class="alert alert-danger text-center">
          {{ uploadError }}
        </div>
    
        <div *ngIf="successMessage" class="alert alert-success text-center">
          {{ successMessage }}
        </div>
    
        <button
          class="d-block w-100 mt-2"
          mat-raised-button
          color="primary"
          [disabled]="formControl.invalid || isUploading || invalidOrder"
          (click)="$event.preventDefault()"
          (click)="send()"          
        >
          {{ getButtonText() }}
        </button>
    </form>
</div>
<br />

