import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';

@Injectable({
  providedIn: 'root'
})
export class MaterialRequestService {

  constructor(private http: HttpClient) { }

  saveMaterialRequest(data) {
      return this.http.put(
        `${environment.apiHost}/v1/material-request`,
        data
      );
    }
  
    updateMaterialRequest(data) {
      return this.http.patch(
        `${environment.apiHost}/v1/material-request`,
        data
      );
    }
  
    get(data) {
      return this.http.post(
        `${environment.apiHost}/v1/material-request`,
        data
      );
    }
  
    updateStatus(data) {
      return this.http.patch(
        `${environment.apiHost}/v1/material-request/update-status`,
        data
      );
    }

    getWork(data) {
      return this.http.post(
        `${environment.apiHost}/v1/work-order`,
        data
      );
    }

    getUsers(data) {
      return this.http.post(
        `${environment.apiHost}/v1/personal-registration`,
        data
      );
    }
}
