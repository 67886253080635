<div *ngIf="globalError" class="alert alert-danger text-center">
  {{ globalError }}
</div>

<div
  *ngIf="isInitializing && !globalError"
  class="d-flex justify-content-center flex-column align-items-center mt-5"
>
  <h4 class="my-4">Cargando todo...</h4>
  <mat-spinner></mat-spinner>
</div>

<div *ngIf="!isInitializing && !globalError" class="container-fluid">
  <ng-container *ngIf="isUpdateOperation">
    <button
      class="d-block mt-2"
      mat-raised-button
      color="primary"
      (click)="close()"
    >
      <mat-icon>keyboard_backspace</mat-icon> Regresar
    </button>
  </ng-container>
  <form [formGroup]="formControl" autocomplete="off">
    <br />
    <p>Los campos con asterisco &#40;&#42;&#41; son obligatorios</p>
    <div class="row">
      <div class="col-md-4">
        <mat-form-field
          appearance="outline"
          class="d-block w-100 mb-2"
          (keydown.enter)="$event.preventDefault()"
        >
          <mat-label>Empresa *</mat-label>
          <mat-select formControlName="enterprise">
            <mat-option>Elige una</mat-option>
            <mat-option
              *ngFor="let enterprise of enterprises"
              [value]="enterprise.PK"
            >
              {{ enterprise.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-md-4">
        <mat-form-field
          appearance="outline"
          class="d-block w-100 mb-2"
          (keydown.enter)="$event.preventDefault()"
        >
          <mat-label>Contacto *</mat-label>
          <mat-select formControlName="contact">
            <mat-option>Elige una</mat-option>
            <mat-option *ngFor="let contact of contacts" [value]="contact.SK">
              {{ contact.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-md-4">
        <mat-form-field
          appearance="outline"
          class="d-block w-100 mb-2"
          (keydown.enter)="$event.preventDefault()"
        >
          <mat-label>Fecha *</mat-label>
          <input
            readonly
            formControlName="date"
            matInput
            [matDatepicker]="picker"
          />
          <mat-datepicker-toggle
            (click)="picker.open()"
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-datepicker touchUi #picker></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="col-md-12" *ngIf="!isUpdateOperation">
        <mat-form-field
          appearance="outline"
          class="d-block w-100 mb-2"
          (keydown.enter)="$event.preventDefault()"
        >
          <mat-label>Asunto *</mat-label>
          <input formControlName="subject" type="text" matInput />
        </mat-form-field>
      </div>
      <div class="col-md-8" *ngIf="isUpdateOperation">
        <mat-form-field
          appearance="outline"
          class="d-block w-100 mb-2"
          (keydown.enter)="$event.preventDefault()"
        >
          <mat-label>Asunto *</mat-label>
          <input formControlName="subject" type="text" matInput />
        </mat-form-field>
      </div>
      <div class="col-md-4" *ngIf="isUpdateOperation">
        <mat-form-field
          appearance="outline"
          class="d-block w-100 mb-2"
          (keydown.enter)="$event.preventDefault()"
        >
          <mat-label>Estado *</mat-label>
          <mat-select formControlName="status">
            <mat-option>Elige una</mat-option>
            <mat-option value="Enviada">Enviada</mat-option>
            <mat-option value="Aprobada">Aprobada</mat-option>
            <mat-option value="Rechazada">Rechazada</mat-option>
            <mat-option value="Anulada">Anulada</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <hr />
    <h2>Items</h2>
    <div formArrayName="items">
      <div
        *ngFor="
          let itemrow of formControl.controls.items['controls'];
          let i = index
        "
        [formGroupName]="i"
      >
        <hr />
        <div class="row">
          <div class="col-md-12">
            <button
              class="d-block mt-2 button-remove"
              mat-raised-button
              color="primary"
              (click)="$event.preventDefault()"
              (click)="deleteRow(i)"
            >
              <mat-icon>remove</mat-icon>
            </button>
            <br />
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <mat-form-field
              appearance="outline"
              class="d-block w-100 mb-2"
              (keydown.enter)="$event.preventDefault()"
            >
              <mat-label>Descripción *</mat-label>
              <input formControlName="description" type="text" matInput />
            </mat-form-field>
          </div>
          <div class="col-md-2">
            <mat-form-field
              appearance="outline"
              class="d-block w-100 mb-2"
              (keydown.enter)="$event.preventDefault()"
            >
              <mat-label>Cantidad *</mat-label>
              <input formControlName="quantity" type="number" min='0' matInput />
            </mat-form-field>
          </div>
          <div class="col-md-2">
            <mat-form-field
              appearance="outline"
              class="d-block w-100 mb-2"
              (keydown.enter)="$event.preventDefault()"
            >
              <mat-label>Valor unidad *</mat-label>
              <input formControlName="unitValue" [min]="0" ngxCurrencyInput [(ngModel)]="unitValue[i]" matInput />
            </mat-form-field>
          </div>
          <div class="col-md-2">
            <mat-form-field
              appearance="outline"
              class="d-block w-100 mb-2"
              (keydown.enter)="$event.preventDefault()"
            >
              <mat-label>Valor total</mat-label>
              <input formControlName="totalValue" [min]="0" ngxCurrencyInput [(ngModel)]="totalValue[i]" matInput />
            </mat-form-field>
          </div>
          <div class="col-12 d-block w-100 mb-2">
            <input
              type="file"
              accept="image/*"
              maxFileSize="512000"
              class="file-input"
              (change)="onImageSelected($event, i)"
              #fileUpload
            />
            <div class="file-upload">
              <mat-label
                >Adjunto:
                {{ imageName[i] || "Aún no se ha subido ningún archivo."}}
              </mat-label>
              <button
                mat-mini-fab
                color="primary"
                class="upload-btn"
                (click)="$event.preventDefault()"
                (click)="fileUpload.click()"
              >
                <mat-icon>attach_file</mat-icon>
              </button>
              <ng-container *ngIf="imageUrl[i]">
                <a [href]="imageUrl[i]" target="_blank">
                  Descargar archivo adjunto</a
                >
              </ng-container>
              <p class="text-danger">{{ imageAlert[i] || ""}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr />
    <button
      class="d-block mt-2"
      mat-raised-button
      color="primary"
      style="margin: auto"
      (click)="addNewRow($event)"
    >
      <mat-icon>add</mat-icon>
    </button>
    <br />
    <div class="row justify-content-end">
      <div class="col-md-8">
        <mat-form-field
          appearance="outline"
          class="d-block w-100 mb-2"
          (keydown.enter)="$event.preventDefault()"
        >
          <mat-label>Descripción descuento</mat-label>
          <input formControlName="discountDescription" type="text" (blur)="validateDiscount()" matInput />
        </mat-form-field>
      </div>
      <div class="col-md-2">
        <mat-form-field
          appearance="outline"
          class="d-block w-100 mb-2"
          (keydown.enter)="$event.preventDefault()"
        >
          <mat-label>Descuento</mat-label>
          <input formControlName="discount" [min]="0" ngxCurrencyInput matInput (blur)="restDiscount()" />
        </mat-form-field>
      </div>
      <div class="col-md-2">
        <mat-form-field
          appearance="outline"
          class="d-block w-100 mb-2"
          (keydown.enter)="$event.preventDefault()"
        >
          <mat-label>Total</mat-label>
          <input formControlName="total" [min]="0" ngxCurrencyInput [(ngModel)]="totalSum" matInput />
        </mat-form-field>
      </div>
    </div>
    <br />
    <h2>Items opcionales</h2>
    <div formArrayName="optionalItems">
      <div
        *ngFor="
          let itemrow of formControl.controls.optionalItems['controls'];
          let j = index
        "
        [formGroupName]="j"
      >
        <hr />
        <div class="row">
          <div class="col-md-12">
            <button
              class="d-block mt-2 button-remove"
              mat-raised-button
              color="primary"
              (click)="$event.preventDefault()"
              (click)="deleteOptionalRow(j)"
            >
              <mat-icon>remove</mat-icon>
            </button>
            <br />
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <mat-form-field
              appearance="outline"
              class="d-block w-100 mb-2"
              (keydown.enter)="$event.preventDefault()"
            >
              <mat-label>Descripción *</mat-label>
              <input
                formControlName="optionalDescription"
                type="text"
                matInput
              />
            </mat-form-field>
          </div>
          <div class="col-md-2">
            <mat-form-field
              appearance="outline"
              class="d-block w-100 mb-2"
              (keydown.enter)="$event.preventDefault()"
            >
              <mat-label>Cantidad *</mat-label>
              <input
                formControlName="optionalQuantity"
                type="number"
                min='0'
                matInput
              />
            </mat-form-field>
          </div>
          <div class="col-md-2">
            <mat-form-field
              appearance="outline"
              class="d-block w-100 mb-2"
              (keydown.enter)="$event.preventDefault()"
            >
              <mat-label>Valor unidad *</mat-label>
              <input
                formControlName="optionalUnitValue"
                [min]="0" ngxCurrencyInput [(ngModel)]="optionalUnitValue[j]"
                matInput
              />
            </mat-form-field>
          </div>
          <div class="col-md-2">
            <mat-form-field
              appearance="outline"
              class="d-block w-100 mb-2"
              (keydown.enter)="$event.preventDefault()"
            >
              <mat-label>Valor total *</mat-label>
              <input
                formControlName="optionalTotalValue"
                [min]="0" ngxCurrencyInput [(ngModel)]="optionalTotalValue[j]" 
                matInput
              />
            </mat-form-field>
          </div>
          <div class="col-12 d-block w-100 mb-2">
            <input
              type="file"
              accept="image/*"
              maxFileSize="512000"
              class="file-input"
              (change)="onImageSelectedOptionals($event, j)"
              #fileUpload
            />
            <div class="file-upload">
              <mat-label
                >Adjunto:
                {{ imageNameOptionals[j] || "Aún no se ha subido ningún archivo."}}
              </mat-label>
              <button
                mat-mini-fab
                color="primary"
                class="upload-btn"
                (click)="$event.preventDefault()"
                (click)="fileUpload.click()"
              >
                <mat-icon>attach_file</mat-icon>
              </button>
              <ng-container *ngIf="imageUrlOptionals[j]">
                <a [href]="imageUrlOptionals[j]" target="_blank">
                  Descargar archivo adjunto</a
                >
              </ng-container>
              <p class="text-danger">{{ imageAlertOptionals[j] || ""}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr />
    <button
      class="d-block mt-2"
      mat-raised-button
      color="primary"
      style="margin: auto"
      (click)="addNewOptionalRow($event)"
    >
      <mat-icon>add</mat-icon>
    </button>
    <br />
    <br />
    <div class="row">
      <div class="col-md-12">
        <mat-form-field appearance="outline" class="d-block w-100 mb-2">
          <mat-label>Alcance</mat-label>
          <textarea
            formControlName="scope"
            type="text"
            matInput
            rows="7"
          ></textarea>
        </mat-form-field>
      </div>      
      <div class="col-md-6">
        <mat-form-field
          appearance="outline"
          class="d-block w-100 mb-2"
          (keydown.enter)="$event.preventDefault()"
        >
          <mat-label>Producto *</mat-label>
          <input formControlName="product" type="text" matInput />
        </mat-form-field>
      </div>
      <div class="col-md-6">
        <mat-form-field appearance="outline" class="d-block w-100 mb-2">
          <mat-label>Instalación *</mat-label>
          <mat-select formControlName="installation">
            <mat-option>Elige una</mat-option>
            <mat-option
              *ngFor="let installation of instalations"
              [value]="installation.PK"
            >
              {{ installation.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-md-6">
        <mat-form-field
          appearance="outline"
          class="d-block w-100 mb-2"
          (keydown.enter)="$event.preventDefault()"
        >
          <mat-label>Tiempo de entrega *</mat-label>
          <input formControlName="deliveryTime" type="text" matInput />
        </mat-form-field>
      </div>
      <div class="col-md-6">
        <mat-form-field appearance="outline" class="d-block w-100 mb-2">
          <mat-label>Moneda *</mat-label>
          <mat-select formControlName="currency">
            <mat-option>Elige una</mat-option>
            <mat-option
              *ngFor="let currency of currencies"
              [value]="currency.PK"
            >
              {{ currency.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-md-6">
        <mat-form-field appearance="outline" class="d-block w-100 mb-2">
          <mat-label>Validez oferta *</mat-label>
          <mat-select formControlName="offerValidity">
            <mat-option>Elige una</mat-option>
            <mat-option *ngFor="let offer of offers" [value]="offer.PK">
              {{ offer.name }}
            </mat-option>
          </mat-select>
          <mat-icon (click)="save($event, 'VALI')" matSuffix
            >playlist_add</mat-icon
          >
        </mat-form-field>
      </div>
      <div class="col-md-6">
        <mat-form-field
          appearance="outline"
          class="d-block w-100 mb-2"
          (keydown.enter)="$event.preventDefault()"
        >
          <mat-label>Forma de pago *</mat-label>
          <input formControlName="paymentMethod" type="text" matInput />
        </mat-form-field>
      </div>
      <div class="col-md-12">
        <mat-form-field
          appearance="outline"
          class="d-block w-100 mb-2"
          (keydown.enter)="$event.preventDefault()"
        >
          <mat-label>Garantía</mat-label>
          <input formControlName="warranty" type="text" matInput />
        </mat-form-field>
      </div>
      <div class="col-md-12">
        <mat-form-field
          appearance="outline"
          class="d-block w-100 mb-2"
          (keydown.enter)="$event.preventDefault()"
        >
          <mat-label>Notas:</mat-label>
          <mat-select multiple formControlName="optionsList">
            <mat-option *ngFor="let optionalNote of optionalNotes" [value]="optionalNote">
              {{ optionalNote }}</mat-option>            
          </mat-select>
          <mat-icon (click)="save($event, 'OPLI')" matSuffix
            >playlist_add</mat-icon
          >
        </mat-form-field>
      </div>
      <div class="col-md-12">
        <mat-form-field appearance="outline" class="d-block w-100 mb-2">
          <mat-label>Notas adicionales</mat-label>
          <textarea
            formControlName="notes"
            type="text"
            matInput
            rows="7"
          ></textarea>
        </mat-form-field>
      </div>
      <div class="col-md-6 d-block w-100 mb-2">
        <input
          type="file"
          class="file-input"
          (change)="onFileSelected($event)"
          #fileUpload
        />
        <div class="file-upload">
          <mat-label
            >Adjunto: {{ fileName || "Aún no se ha subido ningún archivo." }}
          </mat-label>
          <button
            mat-mini-fab
            color="primary"
            class="upload-btn"
            (click)="$event.preventDefault()"
            (click)="fileUpload.click()"
          >
            <mat-icon>attach_file</mat-icon>
          </button>
          <ng-container *ngIf="url">
            <a [href]="url" target="_blank"> Descargar archivo adjunto</a>
          </ng-container>
        </div>
      </div>
      <div class="col-md-6">
        <button
          class="d-inline mt-2"
          style="position: relative; float: right"
          mat-raised-button
          color="primary"
          [disabled]="formControl.invalid"
          (click)="$event.preventDefault()"
          (click)="previewQuotation()"
        >
          <mat-icon>remove_red_eye</mat-icon> Previsualizar cotización
        </button>
      </div>
    </div>
    <br />
    <div class="row">
      <div class="col-md-12">
        <mat-form-field
          appearance="outline"
          class="d-block w-100 mb-2"
          (keydown.enter)="$event.preventDefault()"
        >
          <mat-label>Enviar cotización a:</mat-label>
          <mat-select multiple formControlName="sendList">
            <mat-option *ngFor="let contact of contacts" [value]="contact.SK">
              {{ contact.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div *ngIf="uploadError" class="alert alert-danger text-center">
      {{ uploadError }}
    </div>

    <div *ngIf="successMessage" class="alert alert-success text-center">
      {{ successMessage }}
    </div>

    <div *ngIf="attachedError" class="alert alert-warning text-center">
      {{ attachedError }}
    </div>

    <button
      class="d-block w-100 mt-2"
      mat-raised-button
      color="primary"
      [disabled]="formControl.invalid || isUploading || formArr.length === 0"
      (click)="$event.preventDefault()"
      (click)="send()"
    >
      {{ getButtonText() }}
    </button>
  </form>
</div>
<br />
