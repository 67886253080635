<ng-container>
  <div *ngIf="globalError" class="alert alert-danger text-center">
    {{ globalError }}
  </div>

  <div
    *ngIf="isInitializing && !globalError"
    class="d-flex justify-content-center flex-column align-items-center mt-5"
  >
    <h4 class="my-4">Cargando todo...</h4>
    <mat-spinner></mat-spinner>
  </div>

  <div *ngIf="!isInitializing && !globalError" class="container-fluid">
    <div class="container-fluid" [hidden]="!showTable">
      <div class="row">
        <div class="col">
          <app-data-table [names]="tableNames" [data]="result" (action)="action($event)"></app-data-table>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="uploadError" class="alert alert-danger text-center">
    {{ uploadError }}
  </div>

  <div *ngIf="successMessage" class="alert alert-success text-center">
    {{ successMessage }}
  </div>

  <button
    class="d-block w-100 mt-2"
    mat-raised-button
    color="primary"
    [disabled]="formControl.invalid || isUploading || formArr.length === 0"
    (click)="$event.preventDefault()"
    (click)="send()"
  >
    {{ getButtonText() }}
  </button>

  <br />
</ng-container>
