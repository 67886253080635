import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  ViewChild,
  Output,
} from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";

import { MatSort, Sort } from "@angular/material/sort";
import { LiveAnnouncer } from "@angular/cdk/a11y";
import { AuthenticationService } from "@app/auth/services/authentication.service";

@Component({
  selector: "app-data-table",
  templateUrl: "./data-table.component.html",
  styleUrls: ["./data-table.component.scss"],
})
export class DataTableComponent implements OnChanges {
  @Input()
  names;
  @Input()
  data;
  @Output()
  action = new EventEmitter();
  dataSource;
  displayedColumns;
  displayedColumnsNames;
  crudTable = true;

  @ViewChild(MatPaginator)
  paginator: MatPaginator;
  @ViewChild(MatSort)
  sort: MatSort;

  showPaginator: boolean;

  constructor(private _liveAnnouncer: LiveAnnouncer,
    public authenticationService: AuthenticationService,) {}

    profile = this.authenticationService.cognitoUser["profile"];
    person = this.authenticationService.cognitoUser["custom:id"];    

  ngOnInit() {
    this.showPaginator = false;
    console.log(this.person)
  }  

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  ngOnChanges() {
    this.displayedColumns = Object.keys(this.names);
    this.displayedColumnsNames = this.names;
    this.dataSource = new MatTableDataSource(this.data);
    this.tableTranslate();
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  tableTranslate() {
    /*
    this.paginator._intl.itemsPerPageLabel = "Elementos por página";
    this.paginator._intl.firstPageLabel = "Primera página";
    this.paginator._intl.lastPageLabel = "Última página";
    this.paginator._intl.previousPageLabel = "Anterior";
    this.paginator._intl.nextPageLabel = "Siguiente";
    */
  }

  ngAfterViewInit(): void {   
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

  buttonClick(action: string, element: any) {
    this.action.emit({ action, element });
  }
}
