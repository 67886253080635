<div class="container-fluid">
  <form [formGroup]="formControl" autocomplete="off">
    <br />
    <p>Los campos con asterisco &#40;&#42;&#41; son obligatorios</p>
    <div class="row">
      <div class="col-md-12 d-block w-100 mb-2">
        <input
          type="file"
          accept=".xlsx"
          class="file-input"
          (change)="onFileSelected($event)"
          #fileUpload
        />
        <div class="file-upload">
          <mat-label
            >* Adjunto: {{ fileName || "Aún no se ha subido ningún archivo." }}
          </mat-label>
          <button
            mat-mini-fab
            color="primary"
            class="upload-btn"
            (click)="$event.preventDefault()"
            (click)="fileUpload.click()"
          >
            <mat-icon>attach_file</mat-icon>
          </button>
          <p class="text-danger">{{ fileAlert || ""}}</p>
        </div>
      </div>
    </div>

    <div *ngIf="uploadError" class="alert alert-danger text-center">
      {{ uploadError }}
    </div>

    <div *ngIf="successMessage" class="alert alert-success text-center">
      {{ successMessage }}
    </div>

    <div *ngIf="attachedError" class="alert alert-warning text-center">
      {{ attachedError }}
    </div>

    <button
      class="d-block w-100 mt-2"
      mat-raised-button
      color="primary"
      [disabled]="formControl.invalid || isUploading"
      (click)="$event.preventDefault()"
      (click)="send()"
    >
      {{ getButtonText() }}
    </button>
  </form>
</div>
