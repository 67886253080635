import { Component, OnInit, AfterViewInit, ViewChild } from "@angular/core";
import { AuthenticationService } from "@app/auth/services/authentication.service";

@Component({
  selector: "app-list-users",
  templateUrl: "./list-users.component.html",
  styleUrls: ["./list-users.component.scss"],
})
export class ListUsersComponent implements AfterViewInit {
  isUpdateOperation = false;
  constructor(public authService: AuthenticationService) {}

  ngAfterViewInit() {}
}
