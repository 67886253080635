import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FormatsService {
  TRANSLATE = {
    PK: "Consecutivo",
    outDate: "Fecha de salida",
    enterpriseName: "Empresa",
    contactName: "Contacto",
    guide: "Guía",
    transporter: "Transportadora",
    observations: "Observaciones",
  }

  constructor() { }

  generatePrint(data) {
    let i = 1;
    let document: Array<any> = [
      {
        lineHeight: 2,
        text: ".", color: "white",
      }, 
      {
        text: [
          {text: 'Fecha de salida: ', bold: true},
          {text: `${data.outDate} \n\n`},
        ]
      },
      {
        alignment: 'left',
			  columns: [
          {
            text: [
              {text: 'Empresa: ', bold: true},
              {text: `${data.enterpriseName} \n`},
              {text: 'Dirección: ', bold: true},
              {text: `${data.enterpriseAddress}, ${data.enterpriseCity} \n`},
              {text: 'Guía: ', bold: true},
              {text: `${data.guide} \n`},
            ]
          },
          {
            text: [
              {text: 'Contacto: ', bold: true},
              {text: `${data.contactName} \n`},              
              {text: 'Telefono: ', bold: true},
              {text: `${data.enterprisePhone} \n`},
              {text: 'Transportadora: ', bold: true},
              {text: `${data.transporter} \n`},
            ]
          }
        ]
      },
      {
        lineHeight: 2,
        text: ".", color: "white",
      },
      {
        table: {
          headerRows: 1,
          widths: ["*", "*", "*", "*", "*", "*", "*", "*", "*", "*", "*", "*"],
          body: [           
            [
              { text: `Item`, alignment: "center", bold: true, colSpan: 1 },
              { text: `Producto`, alignment: "center", bold: true, colSpan: 10 }, {}, {}, {}, {}, {}, {}, {}, {}, {},
              { text: `Cant.`, bold: true, colSpan: 1, alignment: "center" },
            ],
            ...data.items.map(d => ([{text: i++, colSpan: 1, alignment: "center"}, {text: d.product, colSpan: 10}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {text: d.quantity, colSpan: 1, alignment: "center"},]))
          ],
        },
      },
    ];
    if(data.observations) {
      document.push(
        {
          lineHeight: 2,
          text: ".", color: "white",
        },
        {
          text: [
            {text: 'Observaciones: \n\n', bold: true},
            {text:  `${data.observations}`, alignment:'justify'},
          ]
        },
      );
    }
    document.push(
      {
        lineHeight: 3,
        text: ".", color: "white",
      },
      {
        alignment: 'center',
			  columns: [
          {
            text: [
              {text: 'ELABORADO POR: \n\n\n\n', alignment: "center",  bold: true},
              {text: '__________________________________________\n'},
              {text: 'KBT SOLUTIONS', bold: true},
            ]
          },
          {
            text: [
              {text: 'RECIBIDO POR: \n\n\n\n', alignment: "center",  bold: true},
              {text: '__________________________________________\n'},
              {text: 'Firma y cédula',  bold: true},
            ]
          }
        ]
      },
    );
    return document;
  }

  generateReport(data) {
    return [
      {
        table: {
          headerRows: 1,
          widths: ["10%", "12%", "12%", "12%", "12%", "12%", "30%"],
          body: [
            [{ text: 'Consecutivo', bold: true, alignment: "center" }, { text: 'Fecha de salida', bold: true, alignment: "center" }, { text: 'Empresa', bold: true, alignment: "center" }, { text: 'Contacto', bold: true, alignment: "center" }, { text: 'Guía', bold: true, alignment: "center" }, { text: 'Transportadora', bold: true, alignment: "center" }, { text: 'Observaciones', bold: true, alignment: "center" }],
            ...data.map(d => ([d.PK.substr(4), d.outDate, d.enterpriseName, d.contactName, d.guide, d.transporter, d.observations || ""])),
          ],
        },
      },
    ];
  }
}
